import { LitElement, css, html, nothing } from "lit";
import {
  danger,
  neutral,
  primary,
  warning,
} from "../../../legl-ui/lds-colours";
import "../../../legl-ui/lds-icon";
import { spacing } from "../../../legl-ui/lds-spacing";
import { typographyPresets } from "../../../legl-ui/lds-typography";

export class LdsTag extends LitElement {
  static get styles() {
    return css`
            :host {
                display: inline-block;
                ${typographyPresets.medium};
                color: ${neutral["800"]};
            }
            :host([small]) {
                ${typographyPresets.small};
            }
            :host([colour="primary"]) {
                color: ${primary["500"]};
            }
            :host([colour="danger"]) {
                color: ${danger["500"]};
            }
            :host([colour="warning"]) {
                color: ${warning["800"]};
            }
            :host([disabled]) {
                color: ${neutral["400"]};
            }
            :host([colour="primary"][disabled]) {
                color: ${primary["300"]};
            }
            :host([colour="danger"][disabled]) {
                color: ${danger["300"]};
            }
            :host([colour="warning"][disabled]) {
                color: ${warning["300"]};
            }
            :host([variant="outlined"]) {
                color: ${neutral["800"]};
                border: 1px solid;
                border-radius: 16px;
                font-weight: 600;
                background-color: ${neutral["100"]};
                border-color: ${neutral["400"]};
            }
            :host([variant="outlined"][small]) {
                border-radius: 12px;
                ${typographyPresets.smallBold};
                font-weight: 600;
            }
            :host([variant="outlined"][disabled]) {
                color: ${neutral["300"]};
                border-color: ${neutral["300"]};
            }
            :host([variant="outlined"]) .wrapper {
                padding: ${spacing.xxs} ${spacing.xs};
            }
            :host([colour="primary"][variant="outlined"]) {
                background-color: ${primary["100"]};
                border-color: ${primary["400"]};
            }
            :host([colour="danger"][variant="outlined"]) {
                background-color: ${danger["100"]};
                border-color: ${danger["400"]};
            }
            :host([colour="warning"][variant="outlined"]) {
                background-color: ${warning["100"]};
                border-color: ${warning["400"]};
            }
            :host([colour="primary"][variant="outlined"][disabled]) {
                color: ${neutral["400"]};
                border-color: ${primary["300"]};
            }
            :host([colour="danger"][variant="outlined"][disabled]) {
                color: ${neutral["400"]};
                border-color: ${danger["300"]};
            }
            :host([colour="warning"][variant="outlined"][disabled]) {
                color: ${neutral["400"]};
                border-color: ${warning["300"]};
            }
            .wrapper {
                display: flex;
                align-items: center;
                gap: ${spacing.xxs};
            }
        `;
  }

  static get properties() {
    return {
      variant: { reflect: true },
      colour: { reflect: true },
      disabled: { type: Boolean },
      small: { type: Boolean },
      icon: {},
    };
  }

  render() {
    return html`
            <div class="wrapper">
                ${
                  this.icon
                    ? html`<lds-icon
                          ?small=${!!this.small}
                          name=${this.icon}
                      ></lds-icon>`
                    : nothing
                }
                <slot></slot>
            </div>
        `;
  }
}

customElements.define("lds-tag", LdsTag);
