import { LitElement, css, html } from "lit";
import { put } from "../../../../../../../../../legl-ui/utils/fetch.js";
import { formatDateTime } from "../../../../../../../../../legl-ui/utils/functions.js";

import { neutral } from "../../../../../../../../../legl-ui/lds-colours";
import { LdsModal } from "../../../../../../../../../legl-ui/lds-modal/index.js";
import { spacing } from "../../../../../../../../../legl-ui/lds-spacing";

export class RefundReview extends LitElement {
  static get styles() {
    return css`
            .container {
                display: grid;
                grid-template-columns: 1fr;
                grid-gap: ${spacing.xs};
            }

            .refund-info {
                display: grid;
                grid-template-columns: auto 1fr;
                grid-gap: ${spacing.s};
                padding: ${spacing.s};
                border: 1px solid ${neutral["200"]};
                font-size: 14px;
            }
            .divider {
                width: 100%;
                height: 1px;
                background-color: ${neutral["200"]};
                margin: ${spacing.s} 0;
                border: none;
            }

            .bank-account-container {
                display: flex;
                justify-content: flex-start;
                gap: ${spacing.s};
                margin-top: ${spacing.s};
            }

            .bank-account-information {
                width: 100%;
                display: grid;
                grid-template-columns: auto 1fr;
                grid-gap: ${spacing.s};
            }

            .bank-account-item {
                display: contents;
            }

            .bank-account-item span:first-of-type {
                font-weight: bold;
            }

            .capitalize {
                text-transform: capitalize;
            }

            .info-item {
                display: contents;
            }

            .info-item span:first-of-type {
                font-weight: bold;
            }

            .instructions {
                grid-column: 1;
                margin: ${spacing.s} 0;
            }

            .actions {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                gap: ${spacing.s};
            }

            .row {
                display: flex;
                justify-content: flex-end;
                gap: ${spacing.s};
            }

            .cancel-refund-button {
                font-size: 14px;
                padding: 6px 10px;
            }
            .continue-to-payment-button {
                display: flex;
                align-items: center;
                gap: ${spacing.xs};
            }
        `;
  }

  static get properties() {
    return {
      payment: { type: Object },
      refundRequest: { type: Object },
      isCancelling: { state: true },
    };
  }

  constructor() {
    super();
    this.isCancelling = false;
  }

  oncancelRefundRequest() {
    LdsModal.create({
      title: "Are you sure you want to cancel this refund?",
      primaryButtonAttributes: {
        label: "Yes",
        colour: "danger",
        onClick: async () => {
          await this.cancelRefundRequest();
        },
      },
    });
  }

  async cancelRefundRequest() {
    this.isCancelling = true;
    const response = await put("/refund/", {
      body: JSON.stringify({
        reference: this.payment.reference,
      }),
    });

    const JSONResponse = await response.json();

    if (JSONResponse.status === "cancelled") {
      this.refundRequest.payment_link = null;

      window.dispatchEvent(new CustomEvent("reload-payment-drawer-review"));
    } else {
      LdsToast.showError({ text: JSONResponse.error });
    }
    this.isCancelling = false;
  }

  render() {
    return html`
            <div class="container">
                <div class="refund-info">
                    <div class="info-item">
                        <span>Refund amount</span>
                        <span
                            ><legl-currency .currency=${this.payment.currency}
                                >${this.refundRequest.amount}</legl-currency
                            ></span
                        >
                    </div>
                    <div class="info-item">
                        <span>Refund type</span>
                        <span class="capitalize"
                            >${this.refundRequest.refund_type}</span
                        >
                    </div>
                    <div class="info-item">
                        <span>Refund reason</span>
                        <span>${this.refundRequest.reason}</span>
                    </div>
                    <div class="info-item">
                        <span>Date request created</span>
                        <span
                            >${formatDateTime(this.refundRequest.created_at)}</span
                        >
                    </div>
                    <div class="info-item">
                        <span>Requested by</span
                        ><span>${this.refundRequest.requested_by}</span>
                    </div>
                </div>
                <div class="instructions">
                When you click ‘Open payment link’ you will be taken to the <b>Adyen refunds page</b>, 
                where you will be required to make a payment for the amount you would like to refund to your client. 
                <br><br>
                If someone else in your firm is required to complete this payment, you can copy the link to share with them.
                </div>
                <div class="actions">
                    <legl-copy-button
                        variant="outlined"
                        size="medium"
                        copy-value="${this.refundRequest.payment_link}"
                    >
                        Copy link
                    </legl-copy-button>
                    <lds-link-button
                        colour="primary"
                        href="${this.refundRequest.payment_link}"
                        ?open-in-new-tab="${true}"
                        ><span class="continue-to-payment-button"
                            ><lds-icon
                                class="check-icon"
                                name="lds-icon-ExternalLink"
                            ></lds-icon
                            >Open payment link</span
                        >
                    </lds-link-button>
                </div>
                <hr class="divider">
                <div class="row">
                        If you would prefer to make a bank transfer, please use the following bank account details and include the Legl reference:
                    </div>
                <div class="bank-account-container">
                        <div class="bank-account-information">
                            <div class="bank-account-item">
                                <span>Account name</span>
                                <span>THE JUSTICE PLATFORM LTD</span>
                            </div>
                            <div class="bank-account-item">
                                <span>Sort code</span>
                                <span>23-14-70</span>
                            </div>
                            <div class="bank-account-item">
                                <span>Account number</span>
                                <span>77849153</span>
                            </div>
                            <div class="bank-account-item">
                                <span>Bank name</span>
                                <span>Wise Payments Limited</span>
                            </div>
                            <div class="bank-account-item">
                                <span>Legl reference</span>
                                <span>${this.payment.reference}</span>
                            </div>
                            <div class="bank-account-item">
                                <span>Amount</span>
                                <span><legl-currency .currency=${this.payment.currency}
                                >${this.refundRequest.amount}</legl-currency
                            ></span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <lds-button
                            variant="outlined"
                            class="cancel-refund-button"
                            colour="danger"
                            size="small"
                            ?disabled=${this.isCancelling}
                            .isLoading=${this.isCancelling}
                            @click="${this.oncancelRefundRequest}"
                            >Cancel refund request</lds-button
                        >
                    </div>
                </div>
            </div>
        `;
  }
}

customElements.define("refund-review", RefundReview);
