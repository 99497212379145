import { css, html } from "lit";

import { nothing } from "lit";

import "../../../../../../legl-ui/input/legl-input-file-upload";
import { toastService } from "../../../../../../legl-ui/toast";
import { ElementBase } from "../element_base.js";
import "../html-heading.js";

export class CddDocumentsElement extends ElementBase {
  static get styles() {
    return css`
            .optional-documents {
                padding: 1rem 2rem;
            }

            .save-btn {
                display: block;
                border-radius: 0px 0px 4px 4px;
            }

            .selector-no-additional-docs {
                display: block;
                margin-bottom: 30px;
            }
        `;
  }

  constructor() {
    super();
    this.documentResults = { documents: new Set() };
    this.hasOptionalDocs = false;
    this.isLoading = false;
  }

  static get properties() {
    return {
      ...super.properties,
      documentResults: {
        type: Object,
      },
      description: {
        type: String,
      },
      documentUploadUrl: {
        type: String,
        attribute: "document-upload-url",
      },
      editModel: {
        type: Object,
      },
      internationalAddressesOnly: {
        type: Boolean,
      },
      documents: {},
      hasOptionalDocs: {
        type: Boolean,
        attribute: false,
      },
      optional: {
        type: Boolean,
      },
    };
  }

  firstUpdated() {
    if (this.optional) {
      this.addEventListener("options-selection", (el) => {
        this.setHasOptionaDocs(el.detail);
        this.manageDocumentRemoval(el.detail);
      });
    }
  }

  setHasOptionaDocs(details) {
    this.hasOptionalDocs = details.optionSelected.result;
  }

  manageDocumentRemoval(details) {
    // if we click on No and the file upload gets closed
    if (details.optionSelected?.result === false) {
      // loop through array removing one by one
      this.documentResults.documents.forEach((document) =>
        this.removeDocument(document),
      );
      // clear the set
      this.documentResults.documents.clear();
    }
  }

  get eventDetailPayload() {
    const results = { ...this.documentResults };
    results.documents = Array.from(results.documents);
    return {
      results,
    };
  }

  addDocument(document) {
    this.documentResults.documents.add(document);
    this.requestUpdate();
  }

  removeDocument(document) {
    this.documentResults.documents.forEach((doc) => {
      if (doc.upload_token === document.upload_token) {
        this.documentResults.documents.delete(doc);
      }
    });
    this.sendResults({
      cddDocumentsAction: "delete",
      upload_token: document.upload_token,
    });
    this.requestUpdate();
  }

  handleSubmit(event) {
    this.submitButton = event.target;
    this.submitButton.disabled = true;
    this.isLoading = true;
    this.sendResults({
      cddDocumentsAction: false,
    });
  }

  handleResultResponse(response, status) {
    if (status !== 200) {
      toastService.showError(
        "An unexpected error has occurred, please check your internet connection and try again.",
      );
      this.isLoading = false;
      return;
    }
    if (response && response.removed_document) {
      this.isLoading = false;
      return;
    }
    this.sendComplete();
    this.submitButton.disabled = false;
    this.isLoading = false;
  }

  get hasDocuments() {
    return this.documentResults.documents.size >= 1;
  }

  render() {
    return html` <div class="optional-documents">
                ${
                  this.optional
                    ? html`
                          <p>
                              Do you have any additional documents to include?
                              (Eg. additional proof of address). Any files
                              uploaded in this section will not be externally
                              verified, but will be included in the final PDF
                              report for review.
                          </p>
                          <legl-options-selector
                              class="${
                                !this.hasOptionalDocs
                                  ? "selector-no-additional-docs"
                                  : null
                              }"
                              .options=${[
                                {
                                  result: true,
                                  title: "Yes",
                                },
                                {
                                  result: false,
                                  title: "No",
                                },
                              ]}
                          ></legl-options-selector>
                      `
                    : nothing
                }
                ${
                  !this.optional || this.hasOptionalDocs
                    ? html`
                          <legl-input-file-upload
                              data-cy-cdd-documents-upload
                              upload-url="${this.documentUploadUrl}"
                              .initialValue=${
                                this.documents ? this.documents : null
                              }
                              @file-uploaded="${(e) => {
                                this.addDocument(e.detail.token);
                              }}"
                              @file-removed="${(e) => {
                                this.removeDocument(e.detail.token);
                              }}"
                              required
                          >
                              <legl-html-heading
                                  data-cy="additional-docs-description"
                                  .heading=${this.description}
                                  slot="start"
                              ></legl-html-heading>
                          </legl-input-file-upload>
                      `
                    : nothing
                }
            </div>
            <legl-button
                class="save-btn"
                full-width
                .isLoading=${this.isLoading}
                data-cy-submit-documents
                .disabled=${
                  !this.hasDocuments &&
                  (!this.optional || (this.optional && this.hasOptionalDocs))
                }
                @click="${this.handleSubmit}"
                >Submit</legl-button
            >`;
  }
}

customElements.define("legl-cdd-documents", CddDocumentsElement);
