import { spacing } from "../../../legl-ui/lds-spacing";
import { typographyPresets } from "../../../legl-ui/lds-typography";

import { LitElement, css, html } from "lit";
import { LdsButtonStylesMixin } from "../../lds-button/src/lds-button-styles-mixin.js";

export class LdsLinkButton extends LdsButtonStylesMixin(LitElement) {
  static get styles() {
    return [
      super.styles,
      css`
          a {
              text-decoration: none;
              color: inherit;
          }
          :host {
              ${typographyPresets.bodySemiBold};
              padding: calc(${spacing.xxs} + ${spacing.xs}) ${spacing.m};
              display: inline-block;
          }
      `,
    ];
  }

  static get properties() {
    return {
      colour: {
        reflect: true,
      },
      variant: {
        reflect: true,
      },
      small: {
        type: Boolean,
        reflect: true,
      },
      openInNewTab: {
        type: Boolean,
        attribute: "open-in-new-tab",
      },
      href: {
        type: String,
      },
    };
  }

  constructor() {
    super();
    this.colour = "primary";
    this.variant = "contained";
    this.openInNewTab = false;
  }

  render() {
    return html`
      <a
          href="${this.href}"
          target="${this.openInNewTab ? "_blank" : "_self"}"
          rel="noopener noreferrer"
      >
          <slot></slot>
      </a>
  `;
  }
}

customElements.define("lds-link-button", LdsLinkButton);
