import { html } from "lit";
import { toastService } from "../../../../../../legl-ui/toast";
import { post } from "../../../../../../legl-ui/utils/fetch";
import { userConfig } from "../../config/user-config.js";
import { SelectOptionConfirmationModal } from "../../dialog-manager/select-option-confirmation-modal.js";

export async function changeReviewer(
  assignedReviewerId = null,
  engageApplicationReference = null,
  reviewerOptions = userConfig.engageReviewers,
) {
  if (!engageApplicationReference) {
    return;
  }
  let updatedReviewerId = assignedReviewerId;
  let changeComment = "";
  try {
    const { value, comment } = await SelectOptionConfirmationModal.create({
      title: "Change Reviewer of Engage Request",
      message: html`If you change the assigned reviewer for this Engage
                request, it will become <strong>visible</strong> in their Engage
                table immediately and will
                <strong>send a notification</strong> when it is ready for review
                or further action.`,
      confirmText: "Confirm Change",
      cancelText: "Cancel",
      label: "Reviewer",
      value: assignedReviewerId,
      options: reviewerOptions,
      includeComment: true,
      commentLabel: "Optional Note",
    });
    changeComment = comment;
    updatedReviewerId = value;
  } catch (error) {
    //noop
    return false;
  }
  if (!!updatedReviewerId) {
    try {
      const { ok, status } = await post(`/engage/change-reviewer/`, {
        body: JSON.stringify({
          reviewer_id: updatedReviewerId,
          reference: engageApplicationReference,
          optional_note: changeComment,
        }),
      });
      if (ok) {
        toastService.showSuccess(
          `Reviewer has been changed on the engage request.`,
        );
        window.dispatchEvent(new CustomEvent("reload-engage-table"));
        return true;
      } else {
        throw new Error(`${status}: Unable to update reviewer`);
      }
    } catch (error) {
      toastService.showError(
        "Unable to update reviewer. Please try again later.",
      );
      return false;
    }
  }
  return false;
}

export default {
  changeReviewer,
};
