import { LdsToast } from "../../legl-ui/lds-toast";
import { toastService } from "../../legl-ui/toast";
import { get } from "../../legl-ui/utils/fetch.js";
import location from "../../legl-ui/utils/location.js";
import { countries } from "./countries";

export class StripeWrapperError extends Error {}

export class StripeWrapper {
  constructor(
    key = stripe_key,
    // if accept acceptHigherFeeCards is not defined in the django template
    acceptHigherFeeCardsInput = typeof acceptHigherFeeCards !== "undefined" &&
      acceptHigherFeeCards,
  ) {
    this.key = key;
    this.stripe = null;
    this.elements = null;
    this.card = null;
    this.paymentRequest = null;
    this.paymentRequestButton = null;
    if (typeof acceptHigherFeeCardsInput === "string") {
      const acceptLowerCase = acceptHigherFeeCardsInput.toLowerCase();
      this.acceptHigherFeeCards = acceptLowerCase === "true";
    } else {
      this.acceptHigherFeeCards = Boolean(acceptHigherFeeCardsInput);
    }
  }

  initStripe() {
    if (!this.stripe) {
      this.stripe = Stripe(this.key);
    }
    return this;
  }

  initElements() {
    this.initStripe();
    if (!this.elements) {
      this.elements = this.stripe.elements({
        fonts: [
          {
            cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
          },
        ],
        locale: window.__exampleLocale,
      });
    }
    return this;
  }

  initElement(elementSelector = "#card-element", paymentMethod = "card") {
    this.initElements();
    if (!this.card && paymentMethod === "card") {
      this.card = this.elements.create("card", {
        style: {
          base: {
            iconColor: "#5461ff",
            color: "#535353",
            fontWeight: 500,
            fontFamily: "Lato, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": {
              color: "#c9cbd9",
            },
          },
          invalid: {
            iconColor: "#ff0000",
            color: "#ff0000",
          },
        },
      });
      this.card.mount(elementSelector);
    }
    return this;
  }

  initPaymentRequest(amount = 0) {
    this.initStripe();
    if (!this.paymentRequest) {
      this.paymentRequest = this.stripe.paymentRequest({
        country: "GB",
        currency: "gbp",
        total: {
          //Apple Pay doesn't play nice with & and a lot of firm names contain them so we strip them out for the stripe label
          label: companyName.replace(/(&amp;)|(&)/g, "and"),
          amount: Math.round(amount),
        },
        requestPayerName: false,
        requestPayerEmail: false,
      });
    }

    return this;
  }

  initPaymentRequestButton(amount = 0) {
    this.initPaymentRequest(amount);
    this.initElements();
    if (!this.paymentRequestButton) {
      this.paymentRequestButton = this.elements.create("paymentRequestButton", {
        paymentRequest: this.paymentRequest,
      });
    }
    return this;
  }

  async fetchCompanySettings() {
    try {
      const res = await get("/api/company_payment_settings/");
      if (!res.ok) {
        LdsToast.showError({
          title: "Error",
          text: "Unable to fetch company payment settings",
        });
      } else {
        return res.json();
      }
    } catch (e) {
      console.error(e);
    }
  }

  async createPaymentMethod(additionalData = {}, card = this.card) {
    this.initStripe();
    const result = await this.stripe.createPaymentMethod("card", card, {
      billing_details: additionalData,
    });
    let errorMessage;

    const paymentSettings = await this.fetchCompanySettings();
    const validCountries = this.validCountries(paymentSettings);
    if (result.error) {
      if (result.error.message) {
        errorMessage = result.error.message;
      } else {
        errorMessage =
          "There was an error handling your request. Please retry.";
      }
    } else if (
      paymentSettings.accept_higher_fee_cards === false &&
      result.paymentMethod.card.brand !== "mastercard" &&
      result.paymentMethod.card.brand !== "visa"
    ) {
      errorMessage = `${companyName} does not accept this payment method, please use a MasterCard or Visa to complete payment`;
    } else if (
      !result.paymentMethod.card.country ||
      !validCountries.includes(result.paymentMethod.card.country)
    ) {
      errorMessage = `${result.paymentMethod.card.country} is not a supported country.`;
    }

    if (errorMessage) {
      LdsToast.showError({
        title: "Card Error",
        text: errorMessage,
      });
      throw new StripeWrapperError(errorMessage);
    }
    return result;
  }

  validCountries(paymentSettings) {
    if (
      paymentSettings.accept_european_cards &&
      paymentSettings.accept_international_non_european_cards
    ) {
      return [...countries.european, ...countries.international, "GB"];
    } else if (
      paymentSettings.accept_european_cards &&
      !paymentSettings.accept_international_non_european_cards
    ) {
      return [...countries.european, "GB"];
    } else if (
      !paymentSettings.accept_european_cards &&
      paymentSettings.accept_international_non_european_cards
    ) {
      return [...countries.international, "GB"];
    }
    return ["GB"];
  }

  static isResponseSuccessful(response) {
    if (!response.error) {
      return true;
    }
    if (response.restart) {
      // This means something has very badly wrong and we need to restart the whole process
      location.reloadWindow();
      return false;
    }
    let message = "Please try again or use a different card.";
    if (response.stripe_code === "card_declined") {
      if (response.message !== "Your card was declined.") {
        message = response.message;
      } else if (
        // TODO: because this is a static method it doesn't have access to `this` so below will be never hit
        // needs investigating and fixing in the methods using isResponseSuccessful
        this.acceptHigherFeeCards === false &&
        response.message === "Your card was declined."
      ) {
        message = response.message;
      }
    } else if (response.session_terminated) {
      return false;
    } else if (response.message) {
      message = response.message;
    }
    LdsToast.showError({
      title: "Error",
      text: message,
    });
    return false;
  }
}
