import { LitElement, css, html } from "lit";
import { neutral } from "../../../../../../legl-ui/lds-colours";
import { spacing } from "../../../../../../legl-ui/lds-spacing";
import { typographyPresets } from "../../../../../../legl-ui/lds-typography";
import { userConfig } from "../../config/user-config";

class PhonePaymentScript extends LitElement {
  static get styles() {
    return css`
            .phone-payment-script-container {
                margin: ${spacing.s} 0;
            }
            h3 {
              ${typographyPresets.h3};
              color: ${neutral["800"]};
              margin: ${spacing.s} 0;
            }
            p {
              ${typographyPresets.body};
              color: ${neutral["700"]};
            }
            .body-container {
              padding: ${spacing.s};
              background-color: ${neutral["100"]};
              border-radius: 4px;
              margin-bottom: ${spacing.s};
            }
        `;
  }

  static get properties() {
    return {
      amount: { type: Number },
    };
  }

  render() {
    return html`
            <div class="phone-payment-script-container">
                <h3>Suggested script for completing phone payment:</h3>
                <div class="body-container">    
                    "Your payment will be processed by Legl (L-E-G-L) who are our
                    selected payment processing partner and comply with the
                    standards of the FCA and PCI.
                    <p>
                        Legl's full terms of use and privacy policy can be found on
                        their website www.legl.com.
                    </p>

                    <p>
                        You will see the charge on your statement appear as
                        LEGL*${userConfig.companySlug}.
                    </p>

                    Please confirm that you are happy for me to process the payment
                    for
                    <legl-currency .value=${this.amount}>
                        }</legl-currency
                    >."
                </div>  
            </div> 
        `;
  }
}

customElements.define("phone-payment-script", PhonePaymentScript);
