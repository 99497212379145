import i18next, { t } from "i18next";
import I18NextVue from "i18next-vue";

import translations from "./translations/en.json";

async function initI18n() {
  await i18next.init({
    lng: "en", // if you're using a language detector, do not define the lng option
    debug: false,
    resources: {
      en: {
        translation: {
          ...translations,
        },
      },
    },
    fallbackLng: "en",
    // use `debug: true` to get more info about missing keys!
    missingKeyHandler: (lng, ns, key, fallbackValue) => {
      console.error(`Missing i18n key: ${key}`);
    },
  });
}

initI18n();

export { I18NextVue, i18next, t };
