import { LitElement, css, html } from "lit";

export class LeglTooltip extends LitElement {
  static get styles() {
    return css`
            :host {
                --legl-tooltip-vertical-offset: 10px;
                position: relative;
            }

            :host .content {
                display: none;
                position: absolute;
                padding: 5px;
                background: #666;
                border-radius: 0.25rem;
                color: #ffffff;
                min-width: 300px;
                max-width: 400px;
                font-size: small;
                text-align: center;
                z-index: 1100;
                font-style: normal;
                bottom: calc(100% + var(--legl-tooltip-vertical-offset));
                top: auto;
                line-height: normal;
            }

            :host .content:before {
                content: "";
                display: block;
                position: absolute;
                background: #666;
                width: 0;
                height: 0;
                overflow: visible;
                border: 6px solid #666;
                z-index: -1;
                right: 11px;
                bottom: -6px;
                transform: rotate(45deg);
            }

            :host([variant="top-right"]) .content {
                right: auto;
                left: 0;
            }

            :host([variant="top-left"]) .content {
                right: -10px;
                left: auto;
            }

            :host([variant="top-right"]) .content:before {
                right: auto;
                left: 9px;
            }

            :host([variant="top-center"]) .content {
                right: 50%;
                margin-right: -15px;
                left: auto;
            }

            :host([size="small"]) .content {
                min-width: 130px;
                max-width: 300px;
            }

            :host([align="left"]) .content {
                text-align: left;
            }

            :host(:hover) .content {
                display: block;
            }
        `;
  }

  static get properties() {
    return {
      content: {
        type: String,
      },
      variant: {
        type: String,
        reflect: true,
      },
      size: {
        type: String,
        reflect: true,
      },
      align: {
        type: String,
        reflect: true,
      },
    };
  }

  constructor() {
    super();
    this.variant = "top-left";
    this.size = "";
    this.align = "";
  }
  render() {
    return html`
            <slot></slot>
            <span class="content">
                <slot name="content">${this.content}</slot>
            </span>
        `;
  }
}

customElements.define("legl-tooltip", LeglTooltip);
