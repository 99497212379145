import { LitElement, css, html, nothing } from "lit";
import "../../../../../../legl-ui/button";
import {
  LeglIsDate,
  LeglMaxDate,
  LeglMinDate,
} from "../../../../../../legl-ui/input/legl-input-date.js";
import "../../../../../../legl-ui/lds-alert";
import { spacing } from "../../../../../../legl-ui/lds-spacing";
import "../../../../../../legl-ui/steps";
import { toastService } from "../../../../../../legl-ui/toast";
import { formatDate } from "../../../../../../legl-ui/utils";
import { get, post } from "../../../../../../legl-ui/utils/fetch";
import { countryList } from "../../../../../engage-app/static-src/js/components/basic-information/supported-applicant-countries";
import companyEngageSettings from "../../config/company-engage-settings";
import "../manualCDD/reviewer-selection.js";
import "../manualCDD/summary-screen";
import { InstantScreeningWorkflow } from "../manualCDD/workflows";

export class LeglInstantScreening extends LitElement {
  static get styles() {
    return css`
            .instant-screening-paragraph {
                color: var(--lds-colour-neutral-800);
                margin-bottom: 32px;
                margin-top: 45px;
            }
            .section-container {
                margin-top: 61px;
            }
            .input-container {
                display: flex;
                margin-bottom: 25px;
            }
            .input-container .dob-input {
                margin-left: 26px;
            }
            .contact-selector-container {
                display: flex;
                flex-direction: column;
            }
            .contact-buttons-container {
                display: flex;
                justify-content: flex-end;
                padding-top: 15px;
                border-top: 1px solid #d0d0d0;
            }
            .manual-cdd-matter-reference-container {
                display: grid;
                grid-template-columns: 50% 50%;
            }

            .manual-cdd-matter-reference-text {
                color: var(--legl-grey-dark);
                font-size: var(--label-font-size);
                font-weight: 700;
                margin-top: 45px;
                margin-right: 20px;
                display: flex;
                align-items: flex-start;
                margin-top: 12px;
            }

            .button-container {
                display: flex;
                justify-content: flex-end;
                margin-top: 50px;
                padding: 15px 0;
                border-top: 1px solid var(--legl-grey-light);
            }

            lds-alert {
                margin-bottom: ${spacing.l};
            }
        `;
  }

  constructor() {
    super();
    this._selectedContact = null;
    this._application = null;
    this._dateOfBirth = null;
    this._matterReference = null;
    this._reviewer = null;
    this._note = null;
    this._countryOfResidence = "unknown";
    this._contactConfirmed = false;
    this._isSubmitting = false;
    this._basicInformationSent = false;
    this._disableBackButton = false;
    this._isTimeout = false;
  }

  static get properties() {
    return {
      _selectedContact: { state: true },
      _application: { state: true },
      _countryOfResidence: { state: true },
      _dateOfBirth: { state: true },
      _matterReference: { state: true },
      _matterReferenceRequired: { state: true },
      _reviewer: { state: true },
      _note: { state: true },
      _contactConfirmed: { type: Boolean, state: true },
      _isSubmitting: { type: Boolean, state: true },
      _basicInformationSent: { type: Boolean, state: true },
      _disableBackButton: { type: Boolean, state: true },
      _isTimeout: { type: Boolean, state: true },
    };
  }

  onContactChanged(e) {
    if (!e?.detail?.uid) {
      this._selectedContact = null;
      return;
    }
    this._selectedContact = e.detail;
    if (this._selectedContact.country) {
      this._countryOfResidence = this._selectedContact.country;
    }
  }

  updateReviewer(event) {
    this._reviewer = event.detail.reviewer;
    this._note = event.detail.note;
  }

  connectedCallback() {
    super.connectedCallback();
    if (!countryList.find((country) => country.alpha3 === "unknown")) {
      countryList.unshift({
        name: "Unknown",
        alpha3: "unknown",
        region: "",
        subregion: "",
        supported_identity_report: true,
        supported_document_report: true,
      });
    }
    this._dateOfBirth = this.params.get("dateOfBirth");
  }
  async onSubmit() {
    if (!this._isSubmitting) {
      this._isSubmitting = true;
      try {
        if (!this._application) {
          await this.createApplication();
        }

        if (this._application && !this._basicInformationSent) {
          await this.sendBasicInformation();
        } else {
          throw "Error creating application";
        }

        if (this._basicInformationSent) {
          const response = await this.processApplication();

          if (response.ok) {
            this._disableBackButton = true;
            this.onNext();
          } else if (response.status === 503) {
            throw "timeout";
          } else {
            throw "Error processing application";
          }
        } else {
          throw "Error sending basic information step";
        }
      } catch (e) {
        if (e === "timeout") {
          this._isTimeout = true;
        } else if (typeof e === "string") {
          const errorMessage =
            "There was an error processing this request. Please submit again.";
          toastService.showError(errorMessage);
        } else {
          toastService.showError(e.toString());
        }
        this._isSubmitting = false;
      }
    }
  }

  async createApplication() {
    const body = InstantScreeningWorkflow;

    body.assigned_reviewer = `http://account.dev.legl.tech/api/lawyers/${this._reviewer}/`;
    body.first_name = this._selectedContact.first_name;
    body.last_name = this._selectedContact.last_name;
    body.email = this._selectedContact.email;
    body.contact_uid = this._selectedContact.uid;
    body.client_reference = this._selectedContact.client_reference;
    body.optional_note = this.note;
    body.matter_reference = this._matterReference;
    body.company_report_person_reference = this.params.get(
      "companyReportPersonReference",
    );
    body.company_report_person_identifier = this.params.get(
      "companyReportPersonIdentifier",
    );
    body.company_report_reference = this.params.get("companyReportReference");
    body.business_id = this.params.get("business_id");

    const res = await post("/api/engage_applications/", {
      body: JSON.stringify(body),
    });

    if (!res.ok) {
      const errorMessage = await res.text();
      if (errorMessage) {
        const error_key = Object.keys(JSON.parse(errorMessage))[0];
        throw new Error(
          error_key.replace("_", " ") +
            ": " +
            JSON.parse(errorMessage)[error_key],
        );
      } else {
        throw new Error("There was a problem when creating the application");
      }
    }

    this._application = await res.json();
  }

  async sendBasicInformation() {
    const basicInformationResponse = await get(
      `/engage/${this._application.reference}/step/${this._application.all_steps[0].id}/`,
    );

    const { props: basicInformation } = await basicInformationResponse.json();

    await post(
      `/engage/${this._application.reference}/step/${this._application.all_steps[0].id}/`,
      {
        body: JSON.stringify({
          ...basicInformation,
          date_of_birth: this.formattedDateOfBirth,
          country:
            this._countryOfResidence === "unknown"
              ? null
              : this._countryOfResidence,
        }),
      },
    );
    this._basicInformationSent = true;
  }

  async processApplication() {
    const response = await post(
      `/engage/${this._application.reference}/step/${this._application.all_steps[1].id}/`,
      {
        body: JSON.stringify({}),
      },
    );
    return response;
  }

  onNext() {
    this.steps.next();
  }

  get steps() {
    return this.shadowRoot.querySelector("legl-steps");
  }

  get formattedDateOfBirth() {
    if (this._dateOfBirth) {
      const [dd, mm, yyyy] = `${this._dateOfBirth}`.split("/");
      return `${yyyy}-${mm}-${dd}`;
    }
    return null;
  }

  get dateOfBirthConflict() {
    const reportDateOfBirth = this.params.get("dateOfBirth");
    const contactDateOfBirth = formatDate(this._selectedContact.date_of_birth);

    return (
      reportDateOfBirth &&
      contactDateOfBirth &&
      reportDateOfBirth !== contactDateOfBirth
    );
  }

  async fetchCompanyEngageSettings() {
    await companyEngageSettings.fetch();
    this._matterReferenceRequired =
      companyEngageSettings.matterReferenceRequired;
  }

  firstUpdated() {
    this.fetchCompanyEngageSettings();
  }

  render() {
    return html`<div>
            <legl-steps .disableBackButton=${this._disableBackButton}>
                <legl-step .title=${"Verify contact details"}>
                    <div class="instant-screening-paragraph">
                        <p>
                            Run an Instant screening check on the associated
                            individual from the Business Report. The check will
                            be run instantly and return results without
                            notifying them.
                        </p>
                        <p>
                            Details about the individual are automatically
                            copied from the report. Please verify this
                            information and add further details if known.
                        </p>
                    </div>
                    ${
                      !this._contactConfirmed
                        ? html`<div class="contact-selector-container">
                              <legl-contact-selector
                                  .selectedContactUid=${
                                    this._selectedContact?.uid ??
                                    this.params.get("contactUid")
                                  }
                                  .hideExpandButton=${true}
                                  @contact-changed=${this.onContactChanged}
                                  .emailRequired="${false}"
                                  .isManualCDD="${true}"
                                  .backButtonText=${"Change Contact"}
                                  .firstName=${this.params.get("firstName")}
                                  .lastName=${this.params.get("lastName")}
                                  .businessAssociations=${[
                                    {
                                      id: this.params.get("business_id"),
                                      type: this.params.get("type"),
                                      role: this.params.get("role"),
                                      shareholder_percentage:
                                        this.params.get(
                                          "shareholder_percentage",
                                        ) || 0,
                                    },
                                  ]}
                              >
                              </legl-contact-selector>
                              ${
                                this._selectedContact
                                  ? html`<hr />
                                        <div class="contact-buttons-container">
                                            <legl-button
                                                id="instant-screening-confirm-contact-continue"
                                                size="medium"
                                                @click=${() =>
                                                  (this._contactConfirmed = true)}
                                                >Continue</legl-button
                                            >
                                        </div>`
                                  : nothing
                              }
                          </div>`
                        : html`${
                            this.dateOfBirthConflict
                              ? html`<lds-alert
                                        .type=${"warning"}
                                        .title=${"Date of birth conflict"}
                                        .message=${`You’ve selected a contact with a different date of birth (${formatDate(
                                          this._selectedContact.date_of_birth,
                                        )}) to the date of birth provided in the company report (${this.params.get(
                                          "dateOfBirth",
                                        )}). You may want to double-check this before proceeding.`}
                                    ></lds-alert>`
                              : nothing
                          }
                              <legl-card class="card-col">
                                  <h2 slot="title">
                                      Date of birth and country of residence
                                  </h2>
                                  <div>
                                      <div class="input-container">
                                          <legl-select
                                              id="instant-screening-cor-input"
                                              label="Country of Residence"
                                              name="country"
                                              autocomplete="country"
                                              .modelValue=${
                                                this._countryOfResidence
                                              }
                                              @model-value-changed="${(e) => {
                                                this._countryOfResidence =
                                                  e.target.modelValue;
                                              }}"
                                          >
                                              <select slot="input">
                                                  ${countryList.map((option) =>
                                                    option.supported_document_report
                                                      ? html`
                                                                <option
                                                                    class="ph-no-capture"
                                                                    value="${option.alpha3}"
                                                                >
                                                                    ${option.name}
                                                                </option>
                                                            `
                                                      : ``,
                                                  )}
                                              </select>
                                          </legl-select>
                                          <legl-input-date
                                              id="instant-screening-dob-input"
                                              class="dob-input"
                                              label="Date of Birth (DD/MM/YYYY)"
                                              name="date_of_birth"
                                              .validators="${[
                                                new LeglIsDate(),
                                                new LeglMaxDate(new Date()),
                                                new LeglMinDate(
                                                  new Date("01/01/1900"),
                                                ),
                                              ]}"
                                              .modelValue="${
                                                this.params.get(
                                                  "dateOfBirth",
                                                ) ||
                                                formatDate(
                                                  this._selectedContact
                                                    .date_of_birth,
                                                )
                                              }"
                                              @model-value-changed="${(e) => {
                                                this._dateOfBirth =
                                                  e.target.modelValue;
                                              }}"
                                              data-cy-basic-info-dob
                                          >
                                          </legl-input-date>
                                      </div>
                                      <legl-button
                                          id="instant-screening-dob-cor-continue"
                                          class="submit-btn"
                                          data-cy-contact-selector-submit-btn
                                          full-width
                                          @click=${() => this.onNext()}
                                          >Continue</legl-button
                                      >
                                  </div>
                              </legl-card>`
                    }
                </legl-step>
                <legl-step .title=${"Assign reviewer"}>
                    <div class="section-container">
                        <label class="manual-cdd-matter-reference-text"
                            >Is there a matter reference to associate with this
                            workflow?
                        </label>
                        ${
                          this._matterReferenceRequired !== undefined
                            ? html`<lds-input
                                  label="Matter reference"
                                  name="matter_reference"
                                  .modelValue="${this._matterReference || ""}"
                                  id="instant-screening-matter-ref-input"
                                  class="matter-reference"
                                  @model-value-changed="${(e) => {
                                    this._matterReference = e.target.value;
                                  }}"
                                  .optional="${!this._matterReferenceRequired}"
                              ></lds-input>`
                            : ""
                        }
                    </div>
                    <legl-reviewer-selection
                        @reviewer-selection-change=${this.updateReviewer}
                    ></legl-reviewer-selection>
                    <div class="button-container">
                        <legl-button
                            size="medium"
                            id="instant-screening-submit"
                            @click=${() => this.onSubmit()}
                            ?is-loading=${this._isSubmitting}
                            ?disabled=${this._isSubmitting}
                            >Submit</legl-button
                        >
                    </div>
                </legl-step>

                <legl-step .title=${"Summary"}>
                    ${
                      this._application
                        ? html`<div class="section-container">
                              <legl-manual-cdd-summary
                                  .application=${this._application}
                                  .reviewer=${this._reviewer}
                                  .note=${this._note}
                                  .selectedContact=${this._selectedContact}
                                  .heading="${
                                    this._isTimeout
                                      ? "This instant check has been submitted and will be ready for review shortly."
                                      : "This instant check is ready for review!"
                                  }"
                                  .text="${
                                    this._isTimeout
                                      ? "The assigned reviewer will be notified as soon as this request is ready for review."
                                      : "The assigned reviewer had been notified. Click below to view the results."
                                  }"
                              ></legl-manual-cdd-summary>
                          </div> `
                        : nothing
                    }
                </legl-step>
            </legl-steps>
        </div>`;
  }
}

customElements.define("legl-instant-screening", LeglInstantScreening);
