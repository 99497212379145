import { html, render } from "lit";
import { BaseAdapter } from "./base-adapter";

export class DummyAdapter extends BaseAdapter {
  async _appendPaymentMethod(DomElement) {
    const component = html`<dummy-payment
            url=${this.providerDetails.dummy_url}
        ></dummy-payment>`;

    render(component, DomElement);

    await component.updateComplete;
  }
}
