import { createPopper } from "@popperjs/core";
import { LitElement, css, html } from "lit";
import { neutral } from "../../../legl-ui/lds-colours";
import { typographyPresets } from "../../../legl-ui/lds-typography";

const showEvents = ["mouseenter", "focus"];
const hideEvents = ["mouseleave", "blur"];

export class LdsTooltip extends LitElement {
  static get styles() {
    return css`
            :host {
                position: relative;
                display: block;
            }

            #tooltip {
                position: absolute;
                background: ${neutral["900"]};
                color: #ffffff;
                padding: 8px;
                font-size: 13px;
                border-radius: 4px;
                display: none;
                z-index: 1011;
                width: max-content;
                max-width: 600px;
            }

            #tooltip[data-show] {
                display: block;
            }

            #arrow,
            #arrow::before {
                position: absolute;
                z-index: -1;
                width: 16px;
                height: 16px;
                background: inherit;
            }

            #arrow {
                visibility: hidden;
            }

            #arrow::before {
                visibility: visible;
                content: "";
                transform: rotate(45deg);
            }

            #tooltip[data-popper-placement^="top"] > #arrow {
                bottom: -4px;
            }

            #tooltip[data-popper-placement^="bottom"] > #arrow {
                top: -4px;
            }

            #tooltip[data-popper-placement^="left"] > #arrow {
                right: -4px;
            }

            #tooltip[data-popper-placement^="right"] > #arrow {
                left: -4px;
            }

            .text {
                margin: 0;
                ${typographyPresets.medium};
            }
        `;
  }

  static get properties() {
    return {
      text: {},
      // placement options: bottom, bottom-start, bottom-end, top, top-start, top-end
      placement: {},
    };
  }

  constructor() {
    super();
    this.placement = "top";
  }

  show() {
    // Make the tooltip visible
    this.tooltip.setAttribute("data-show", "");

    // Enable the event listeners
    this.popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        { name: "eventListeners", enabled: true },
      ],
    }));

    // Update its position
    this.popperInstance.update();
  }

  hide() {
    // Hide the tooltip
    this.tooltip.removeAttribute("data-show");

    // Disable the event listeners
    this.popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        { name: "eventListeners", enabled: false },
      ],
    }));
  }

  boundShow = this.show.bind(this);
  boundHide = this.hide.bind(this);

  firstUpdated() {
    this.trigger = this.shadowRoot
      .querySelector("slot")
      .assignedElements({ flatten: true })[0];

    this.tooltip = this.shadowRoot.querySelector("#tooltip");

    this.popperInstance = createPopper(this.trigger, this.tooltip, {
      placement: this.placement,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
      ],
    });

    showEvents.forEach((event) => {
      this.trigger.addEventListener(event, this.boundShow);
    });

    hideEvents.forEach((event) => {
      this.trigger.addEventListener(event, this.boundHide);
    });
  }

  disconnectedCallBack() {
    super.disconnectedCallBack();

    showEvents.forEach((event) => {
      this.trigger.removeEventListener(event, this.boundShow);
    });

    hideEvents.forEach((event) => {
      this.trigger.removeEventListener(event, this.boundHide);
    });
  }

  render() {
    return html`
            <slot id="trigger" aria-describedby="tooltip"></slot>
            <div id="tooltip" role="tooltip">
                <p class="text">${this.text}</p>
                <div id="arrow" data-popper-arrow></div>
            </div>
        `;
  }
}

customElements.define("lds-tooltip", LdsTooltip);
