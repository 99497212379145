import { LitElement, css, html } from "lit";
import "./results-step.js";

class ResultsSteps extends LitElement {
  static get styles() {
    return css`
            :host {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                font-size: var(--step-font-size, 12px);
            }

            :host ::slotted(legl-results-step) {
                margin: 0.33em 1.5em 0.66em 0em;
            }
        `;
  }
  render() {
    return html`<slot></slot>`;
  }
}

customElements.define("legl-results-steps", ResultsSteps);
