import { LitElement, css, html, nothing } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { neutral } from "../../legl-ui/lds-colours";
import { spacing } from "../../legl-ui/lds-spacing";

import "../../legl-ui/avatar";

class ContactsListItem extends LitElement {
  static get properties() {
    return {
      name: {
        type: String,
      },
      link: {
        type: String,
      },
      email: {
        type: String,
      },
      ref: {
        type: String,
      },
      isButton: {
        type: Boolean,
      },
      businesses: {
        type: Array,
      },
      // if we need to support different styling for notes we can remove the class and make the styling and the
      // note composition the responsibility of the component which needs to pass a note. Now it's not required.
      hasNote: {
        type: Boolean,
      },
      truncatedEmail: {
        attribute: false,
      },
    };
  }

  static get styles() {
    return css`
            :host {
                font-size: 12px;
                width: 100%;
                --legl-action-bar-min-width: 0px;
            }

            ::slotted(legl-action-bar) {
                justify-self: flex-end;
            }

            .list-item {
                display: block;
                color: ${neutral["800"]};
                text-decoration: none;
                padding: 18px 10px;
            }

            .list-item--is-button {
                cursor: pointer;
                border: solid 1px ${neutral["200"]};
                border-radius: 6px;
                padding: 10px;
            }

            .list-item__container {
                display: flex;
                align-items: start;
                align-self: center;
                margin-right: 6px;
            }

            .list-item__value {
                overflow: hidden;
                margin: 0;
            }

            .list-item__value--name {
                font-size: 14px;
                font-weight: bold;
                word-break: break-word;
            }

            .list-item__value--email,
            .list-item__value--associated-businesses {
                display: flex;
                align-items: center;
                margin: 2px 0;
            }

            .list-item__value--email {
                word-break: break-all;
                font-size: 12px;
            }

            .list-item__value--associated-businesses lds-icon,
            .list-item__value--email lds-icon {
                margin-right: ${spacing.xs};
                color: ${neutral["500"]};
            }

            .list-item__value--ref {
                word-break: break-all;
                font-size: 10px;
            }

            .list-item:hover {
                background-color: ${neutral["100"]};
                border-radius: 4px;
                box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.08);
            }

            .list-item__contact-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-left: 12px;
            }
            .list-item__note-container {
                background-color: ${neutral["200"]};
                color: ${neutral["800"]};
                margin: 7px -10px -10px;
                padding: 10px;
                line-height: 16px;
                font-size: 11px;
                display: flex;
            }
            .list-item__note-container-icon {
                padding-top: 2px;
            }
            .list-item__note-container-text {
                margin: 0;
                padding-left: 5px;
            }

            .list-item:not(.list-item--is-button) {
                border-bottom: solid 1px ${neutral["300"]};
            }
        `;
  }

  get classes() {
    return {
      "list-item": !this.link,
      "list-item--has-children": !this.link && this.hasChildren,
      "list-item--is-button": this.isButton,
    };
  }

  get contact() {
    return html`
            <div class="list-item__container">
                <div class="list-item__fields">
                    <span
                        class="list-item__value list-item__value--name"
                        data-cy-contact-list-item-name
                        >${this.name}</span
                    >
                    ${
                      this.businesses?.length > 0
                        ? html`
                              <p
                                  class="list-item__value list-item__value--associated-businesses"
                                  data-cy-contact-list-item-associated-businesses
                              >
                                  <lds-icon
                                      name="lds-icon-CompanyReport"
                                      small
                                  ></lds-icon>
                                  ${this.businesses
                                    .map((business) => business.name)
                                    .join(", ")}
                              </p>
                          `
                        : nothing
                    }
                    ${
                      this.email
                        ? html`
                              <p
                                  title=${this.truncatedEmail}
                                  class="list-item__value list-item__value--email"
                                  data-cy-contact-list-item-email
                              >
                                  <lds-icon
                                      name="lds-icon-Mail"
                                      small
                                  ></lds-icon>
                                  ${this.email}
                              </p>
                          `
                        : nothing
                    }
                    ${
                      this.ref
                        ? html`
                              <p
                                  class="list-item__value list-item__value--ref"
                                  data-cy-contact-list-item-clientref
                              >
                                  Client Ref: ${this.ref}
                              </p>
                          `
                        : nothing
                    }
                </div>
            </div>
        `;
  }

  get hasChildren() {
    return this.children.length > 0;
  }

  componentBody = () => html`
        <div class="${classMap(this.classes)}">
            ${
              this.link
                ? html`
                      <a
                          class="${classMap({
                            "list-item": true,
                            "list-item--has-children": this.hasChildren,
                            "list-item--is-button": this.isButton,
                          })}"
                          href="${this.link}"
                          data-cy-contacts-list-item-link
                      >
                          <div class="list-item__contact-container">
                              ${this.contact}
                              <div class="list-item__slot">
                                  <slot></slot>
                              </div>
                          </div>
                      </a>
                  `
                : html`
                      <div class="list-item__contact-container">
                          ${this.contact}
                          <div class="list-item__slot">
                              <slot></slot>
                          </div>
                      </div>
                  `
            }
            ${
              this.hasNote
                ? html`<div class="list-item__note-container">
                      <legl-icon
                          class="list-item__note-container-icon"
                          name="legl-icon-warning-triangle"
                      ></legl-icon>
                      <p class="list-item__note-container-text">
                          The selected contact does not have an email address
                          associated and will not be able to receive any payment
                          receipts or requests that may be emailed. An email
                          address can be added on the contact page by clicking
                          <strong>view this contact.</strong>
                      </p>
                  </div>`
                : nothing
            }
        </div>
    `;

  render() {
    return html`${this.componentBody()}`;
  }
}

customElements.define("legl-contacts-list-item", ContactsListItem);
