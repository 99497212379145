import { LitElement, css, html, nothing } from "lit";
import LeglFormValidationMixin from "../../../../../../../../../../static-src/js/mixins/LeglFormValidationMixin";
import {
  LeglMaxAmount,
  LeglMinAmount,
  LeglRequired,
} from "../../../../../../../../../legl-ui/lds-input";
import { LdsInputAmount } from "../../../../../../../../../legl-ui/lds-input-amount";
import { LdsLinkButton } from "../../../../../../../../../legl-ui/lds-link-button";
import { LdsModal } from "../../../../../../../../../legl-ui/lds-modal";
import { spacing } from "../../../../../../../../../legl-ui/lds-spacing";
import { LdsToast } from "../../../../../../../../../legl-ui/lds-toast";
import { post } from "../../../../../../../../../legl-ui/utils/fetch.js";

import { RefundReview } from "./refund-review";

export class RefundModal extends LeglFormValidationMixin(LitElement) {
  static get styles() {
    return css`
            .refund-modal {
                padding: 0 ${spacing.m} ${spacing.m} ${spacing.m};
            }
            .refund-modal-button-container {
                display: flex;
                justify-content: flex-end;
                margin-top: ${spacing.s};
                gap: ${spacing.s};
            }
            .refund-amount {
                width: 240px;
            }
        `;
  }

  static get properties() {
    return {
      showOther: { state: true },
      showPartialInput: { state: true },
      payment: { type: Object },
      isSubmitting: { state: true },
      refundRequest: { type: Object },
    };
  }

  constructor() {
    super();
    this.isSubmitting = false;
    this.showOther = false;
    this.showPartialInput = false;
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener("reload-payment-drawer-review", () => {
      window.dispatchEvent(new CustomEvent("reload-payment-drawer"));
      this.dispatchReloadPaymentTableEvent();
      this.onClickClose();
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.validate();
    if (this.isValid) {
      this.isSubmitting = true;
      const response = await post("/refund/", {
        body: JSON.stringify({
          reference: this.payment.reference,
          refund_type: this.refundRequest.refund_type,
          reason: this.refundRequest.reason,
          amount: this.refundRequest.amount,
        }),
      });

      const JSONResponse = await response.json();

      if (response.status === 201) {
        this.refundRequest.payment_link = JSONResponse.payment_link;
        this.refundRequest.created_at = JSONResponse.created_at;
        this.refundRequest.requested_by = JSONResponse.requested_by;

        window.dispatchEvent(new CustomEvent("reload-payment-drawer"));
        this.dispatchReloadPaymentTableEvent();
      } else {
        LdsToast.showError({ text: JSONResponse.error });
      }
    }
    this.isSubmitting = false;
  }

  dispatchReloadPaymentTableEvent() {
    this.dispatchEvent(
      new CustomEvent("reload-payments-table", {
        bubbles: true,
        composed: true,
      }),
    );
  }

  onClickClose() {
    this.parentElement.onClickClose();
  }

  get displayCurrency() {
    if (this.payment.currency === "GBP") {
      return "£";
    } else if (this.payment.currency === "EUR") {
      return "€";
    }
  }

  get outstandingRefundBalance() {
    // Note the GET returns pennies but we should also post in pennies and not decimals
    return (this.refundRequest.outstanding_refund_balance / 100).toFixed(2);
  }

  // List of refund reasons to be displayed in the radio group
  get refundReasonChoices() {
    return [
      "Client is no longer instructing",
      "We cannot accept funds from the client",
      "Returning unused funds",
      "Duplicate payment",
      "Client accidentally paid too much",
      "Other",
    ];
  }

  render() {
    return html`
            <div class="refund-modal">
                ${
                  this.refundRequest.payment_link
                    ? html`<refund-review
                          .payment=${this.payment}
                          .refundRequest=${this.refundRequest}
                      ></refund-review>`
                    : html`<form @submit=${this.handleSubmit}>
                          <lds-radio-group
                              name="amount"
                              class="source-input"
                              label="How much would you like to refund?"
                              .validators=${[new LeglRequired()]}
                              @model-value-changed="${(e) => {
                                const { modelValue } = e.target;

                                this.showPartialInput =
                                  modelValue === "partial";
                                this.refundRequest.amount =
                                  modelValue === "full"
                                    ? this.outstandingRefundBalance
                                    : this.refundRequest.amount;
                                this.refundRequest.refund_type = modelValue;
                              }}"
                          >
                              <lds-radio
                                  label="Full refund (${this.displayCurrency}${
                                    this.outstandingRefundBalance
                                  })"
                                  .choiceValue=${"full"}
                              ></lds-radio>
                              <lds-radio
                                  label="Partial refund"
                                  .choiceValue=${"partial"}
                              ></lds-radio>
                          </lds-radio-group>
                          ${
                            this.showPartialInput
                              ? html`<lds-input-amount
                                    label="Amount to be refunded:"
                                    class="source-input"
                                    type="number"
                                    @model-value-changed=${(e) => {
                                      this.refundRequest.amount =
                                        e.target.modelValue;
                                    }}
                                    .currencyCode=${this.payment.currency}
                                    .validators=${[
                                      new LeglRequired(),
                                      new LeglMinAmount(0.01, {
                                        currency: this.currency,
                                        locale: "en-GB",
                                      }),
                                      new LeglMaxAmount(
                                        this.outstandingRefundBalance,
                                        {
                                          currency: this.currency,
                                          locale: "en-GB",
                                        },
                                      ),
                                    ]}
                                >
                                </lds-input-amount>`
                              : nothing
                          }
                          <br />
                          <lds-radio-group
                              name="refund_reason"
                              class="source-input"
                              .validators=${[new LeglRequired()]}
                              label="Reason for refund:"
                              @model-value-changed=${(e) => {
                                const { modelValue } = e.target;

                                this.showOther = modelValue === "Other";
                                this.refundRequest.reason = modelValue;
                              }}
                          >
                              ${this.refundReasonChoices.map(
                                (reason) => html`<lds-radio
                                      label=${reason}
                                      .choiceValue=${reason}
                                  ></lds-radio>`,
                              )}
                          </lds-radio-group>
                          ${
                            this.showOther
                              ? html`<lds-textarea
                                    label="Other (please specify)"
                                    class="source-input"
                                    .validators=${[new LeglRequired()]}
                                    maxlength="200"
                                    @model-value-changed=${(e) => {
                                      this.refundRequest.reason =
                                        "Other - " + e.target.modelValue;
                                    }}
                                ></lds-textarea>`
                              : nothing
                          }
                          <br />
                          <div class="refund-modal-button-container">
                              <lds-button
                                  colour="neutral"
                                  variant="outlined"
                                  @click=${this.onClickClose}
                                  ?disabled=${this.isSubmitting}
                                  >Cancel</lds-button
                              >
                              <lds-button
                                  colour="primary"
                                  ?disabled=${this.isSubmitting}
                                  .isLoading=${this.isSubmitting}
                                  >Next</lds-button
                              >
                          </div>
                      </form> `
                }
            </div>
        `;
  }
}

customElements.define("legl-refund-modal", RefundModal);

const createRefundModal = (payment, refundRequest) => {
  LdsModal.create({
    title:
      refundRequest.status === "link_required"
        ? html`Create refund Request`
        : html`View refund request`,
    slotComponent: "legl-refund-modal",
    slotComponentProperties: {
      payment: payment,
      refundRequest: refundRequest,
    },
    hideCloseBtn: false,
    headerCloseBtn: true,
    closeOnClickOutside: true,
  });
};

export { createRefundModal };
