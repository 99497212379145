import "../../../../../../apps/engage-app/static-src/js/components/basic-information/element_basic_information.js";
import "../../../../../../apps/engage-app/static-src/js/components/cdd-documents/element_cdd_documents.js";
import "../../../../../../apps/engage-app/static-src/js/components/element-onfido/element_onfido.js";
import "../../../../../../apps/engage-app/static-src/js/components/element_manual_biometrics.js";
import { EngageAccordion } from "../../../../../../apps/engage-app/static-src/js/components/engage_accordion";
import { post } from "../../../../../../legl-ui/utils/fetch";

class ManualCDDAccordion extends EngageAccordion {
  static get properties() {
    return {
      reportType: {
        type: String,
        attribute: "report-type",
      },
      isActiveStep: {
        type: Boolean,
        attribute: "is-active-step",
      },
    };
  }

  constructor() {
    super();
    this.locked = true;
  }

  connectedCallback() {
    super.connectedCallback();
    if (this.isActiveStep) {
      this.locked = false;
    }
  }

  dispatchCompletedEvent() {
    this.dispatchEvent(
      new CustomEvent("manual-cdd-loading-finished", {
        bubbles: true,
        composed: true,
      }),
    );
  }

  completeStep(event) {
    let betaContent = "";
    if (event) {
      betaContent = event.detail.betaContent;
    }
    const section = this.querySelector('section[slot="content-beta"]');
    section.setAttribute("slot", "content-beta");
    section.innerHTML = betaContent;
    section.classList.add("complete");
    this.showAlpha = false;
    this.complete = true;
    this.locked = true;
    const nextAccordion = document
      .querySelector("legl-manual-cdd")
      .querySelector("manual-cdd-accordion[show-alpha] + manual-cdd-accordion");
    if (nextAccordion) {
      nextAccordion.locked = false;
      nextAccordion.showAlpha = true;
    } else {
      this.onFlowComplete();
      this.dispatchCompletedEvent();
    }
  }

  async postResults(payload) {
    const response = await post(this.stepUrl, {
      body: JSON.stringify(payload),
    });
    // If response is OK or a 400 we assume the underlying element will handle the response on it's own
    // Otherwise we want to show a generic error to the user and not allow them to proceed
    if (!(response.ok || response.status === 400)) {
      throw new Error("ERROR_SAVING");
    }

    if (this.hidden) {
      this.completeStep();
    } else {
      try {
        const body = await response.json();
        this.childComponent.resultResponse = {
          status: response.status,
          body,
        };
      } catch (err) {
        // Non-JSON response
        this.dispatchEvent(
          new CustomEvent("manual-cdd-loading", {
            bubbles: true,
            composed: true,
          }),
        );
        this.childComponent.resultResponse = {
          status: response.status,
        };
      }
    }
  }
}

customElements.define("manual-cdd-accordion", ManualCDDAccordion);
