import { LitElement, css, html } from "lit";
import { userConfig } from "../../config/user-config";

import { neutral } from "../../../../../../legl-ui/lds-colours";
import { spacing } from "../../../../../../legl-ui/lds-spacing";

class PhonePaymentSuccess extends LitElement {
  static get styles() {
    return css`
            .phone-payment-success-container {
                padding: ${spacing.m};
                border-radius: 8px;
                background-color: ${neutral["0"]};
            }

            .card-statement-container {
                margin: ${spacing.m} 0;
                padding: ${spacing.s};
                border-radius: 4px;
                background-color: ${neutral["100"]};
            }

            .phone-payment-info {
                display: grid;
                grid-template-columns: auto 1fr;
                grid-gap: ${spacing.s};
                padding: ${spacing.s} 0;
                font-size: 14px;
            }

            .info-item {
                display: contents;
            }

            .info-item-title {
                font-weight: bold;
            }
        `;
  }

  static get properties() {
    return {
      phonePaymentDetails: { type: Object },
    };
  }

  get clientName() {
    return (
      this.phonePaymentDetails?.first_name +
      " " +
      this.phonePaymentDetails?.last_name
    );
  }

  render() {
    return html`
            <div class="phone-payment-success-container">
                <h2 data-cy-confirmation-phone-payment-success>
                    <legl-icon
                        class="pay_success-icon"
                        name="legl-icon-confirm-circle"
                        size="xx-large"
                        color="green"
                    ></legl-icon>
                    Payment Complete
                </h2>
                If the contact has an email address listed, a receipt was emailed.  
                <div class="card-statement-container">
                    This will appear on the clients card statement as 'LEGL*${userConfig.companySlug}'.
                </div>
                <div class="phone-payment-info">
                    <div class="info-item">
                        <span class="info-item-title">Total amount</span>
                        <span>
                            <legl-currency
                                data-cy-confirmation-payment-amount
                                .value=${this.phonePaymentDetails?.amount}
                            ></legl-currency>
                        </span>
                    </div>
                    <div class="info-item">
                        <span class="info-item-title">To account</span>
                        <span>${this.phonePaymentDetails?.bank_account_name}</span>
                    </div>
                    <div class="info-item">
                        <span class="info-item-title">Client name</span>
                        <span>${this.clientName}</span>
                    </div>
                    <div class="info-item">
                        <span class="info-item-title">Email address</span>
                        <span>${this.phonePaymentDetails?.email}</span>
                    </div>
                    <div class="info-item">
                        <span class="info-item-title">Invoice reference</span>
                        <span>${this.phonePaymentDetails?.invoice_reference}</span>
                    </div>
                    <div class="info-item">
                        <span class="info-item-title">Matter reference</span>
                        <span>${this.phonePaymentDetails?.matter_reference}</span>
                    </div>
                </div>
            </div>`;
  }
}

customElements.define("phone-payment-success", PhonePaymentSuccess);
