import { LitElement, css, html, nothing } from "lit";
import { neutral } from "../../../legl-ui/lds-colours";
import { typographyPresets } from "../../../legl-ui/lds-typography";
import { hasFlag } from "./flag-list";

export class LdsFlagIcon extends LitElement {
  static get styles() {
    return css`
            .container {
                display: flex;
                align-items: center;
            }
            .flag {
                width: 16px;
                height: 12px;
                margin-left: 7px;
            }
            .country-code {
                ${typographyPresets.medium};
                color: ${neutral["800"]};
                margin: 0;
            }
        `;
  }

  static get properties() {
    return {
      countryCode: {},
      hideCountryCode: { type: Boolean },
      flag: {
        state: true,
      },
    };
  }

  async importFlag() {
    this.flag = await import(`./flags/${this.countryCode}.svg`);
  }

  connectedCallback() {
    super.connectedCallback();
    if (hasFlag(this.countryCode)) {
      this.importFlag();
    }
  }

  render() {
    return html`
            <div class="container">
                ${
                  this.hideCountryCode
                    ? nothing
                    : html`<p class="country-code">${this.countryCode}</p>`
                }
                ${
                  hasFlag(this.countryCode) && this.flag?.default
                    ? html`<img
                          class="flag"
                          alt=${this.countryCode}
                          src=${this.flag?.default}
                      />`
                    : nothing
                }
            </div>
        `;
  }
}

customElements.define("lds-flag-icon", LdsFlagIcon);
