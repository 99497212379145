import { defineStore } from "pinia";
import type { AsyncComponentLoader } from "vue";
import { ref } from "vue";

import type { Drawer } from "@/drawers/types";

export const DrawerNames = {
  CONFLICTS: {
    RESULT_DETAIL: "result-detail",
  },
  SCREENING: {
    ENTITY_DETAIL: "entity-detail",
  },
};

const drawers: Drawer[] = [
  {
    name: DrawerNames.CONFLICTS.RESULT_DETAIL,
    component: () =>
      import(
        "@/views/conflicts/check-details/drawers/result-detail/ResultDetail.vue"
      ),
  },
  {
    name: DrawerNames.SCREENING.ENTITY_DETAIL,
    component: () =>
      import("@/views/screening/drawers/entity-detail/EntityDetail.vue"),
  },
];

export const useDrawer = defineStore("use-drawer", () => {
  const isOpen = ref(false);
  const name = ref<string | null>(null);
  const component = ref<AsyncComponentLoader>();
  const props = ref<any>(null);
  const scrollY = ref<number>(0);
  const urlHash = window.location.hash;

  if (urlHash.includes("#/drawer-open")) {
    try {
      const urlSearchParams = new URLSearchParams(urlHash.split("?")[1]);
      const hashDrawerName = urlHash.split("/")[2];
      const hashProps = Object.fromEntries(urlSearchParams.entries());

      findAndOpenDrawer(hashDrawerName, hashProps);
    } catch (e) {
      console.error(e);
    }
  }

  function updateURLHash(name: string, props: any) {
    let queryString = "";
    if (props) {
      const urlSearchParamsInstance = new URLSearchParams();

      for (const [key, value] of Object.entries(props)) {
        urlSearchParamsInstance.append(key, value as string);
      }
      queryString = `?${urlSearchParamsInstance.toString()}`;
    }
    window.location.hash = `#/drawer-open/${name}/${queryString}`;
  }

  function open({
    name: drawerName,
    props: drawerProps,
  }: { name: string; props?: any }) {
    if (isOpen.value) {
      close();
    }
    scrollY.value = window.scrollY;

    updateURLHash(drawerName, drawerProps);

    findAndOpenDrawer(drawerName, drawerProps);
  }

  async function findAndOpenDrawer(drawerName: string, drawerProps?: any) {
    try {
      const drawer = drawers.find((drawer) => drawer.name === drawerName);

      if (!drawer) {
        throw new Error(`Drawer not found: ${drawerName}`);
      }

      component.value = drawer.component;
      name.value = drawerName;
      props.value = drawerProps;
      isOpen.value = true;

      window.dispatchEvent(
        new CustomEvent("legl-drawer:opened", {
          detail: {
            name: name.value,
          },
        }),
      );
    } catch (e) {
      console.error(e);
    }
  }

  function close() {
    window.dispatchEvent(
      new CustomEvent("legl-drawer:closed", {
        detail: {
          name: name.value,
        },
      }),
    );
    name.value = null;
    props.value = null;
    isOpen.value = false;
    window.location.hash = "";
    window.scrollTo(0, scrollY.value);
  }

  return {
    component,
    name,
    props,
    isOpen,
    open,
    close,
  };
});
