import { createAction } from "@reduxjs/toolkit";

export const updateOngoingMonitoring = createAction("ongoingMonitoring/update");
export const updateOngoingMonitoringAlert = createAction(
  "ongoingMonitoring/alert/update",
);

export const addOngoingMonitoring = createAction("ongoingMonitoring/add");

export const resetOngoingMonitoring = createAction("ongoingMonitoring/reset");

export default {
  updateOngoingMonitoring,
  addOngoingMonitoring,
  updateOngoingMonitoringAlert,
  resetOngoingMonitoring,
};
