import { createAction } from "@reduxjs/toolkit";

export const loadContacts = createAction("contacts/load");
export const loadMoreContacts = createAction("contacts/loadMoreContacts");
export const setNextPageUrl = createAction("contacts/setNextPageUrl");
export const setContactsCount = createAction("contacts/setContactsCount");
export const updateContact = createAction("contact/update");
export const addContact = createAction("contact/add");
export const setCurrentContact = createAction("contacts/setCurrentContact");

export const resetContacts = createAction("contact/reset");

export default {
  loadContacts,
  loadMoreContacts,
  setNextPageUrl,
  setContactsCount,
  updateContact,
  addContact,
  resetContacts,
  setCurrentContact,
};
