import { LitElement, css, html } from "lit";
import "../../../../../../legl-ui/lds-button";

class TermsOfAgreement extends LitElement {
  static get styles() {
    return [
      css`
                .terms-block__container {
                    font-family: "Lato", sans-serif;
                }
                .terms-block__paragraph {
                    margin-bottom: 0;
                }
            `,
    ];
  }

  render() {
    return html`
            <div class="terms-block__container">
                <p class="terms-block__paragraph">
                    <strong>By clicking "I agree" below, you agree:</strong>
                </p>
                <div class="terms-block__paragraph">
                    Each time you run an "In-Person" check that includes a
                    Financial Screening, before conducting the check, you will:
                    <ol class="terms-block__list">
                        <li class="terms-block__list-item">
                            notify your client that their personal data will be
                            disclosed to Equifax Limited, a credit reference
                            agency, which may keep a record of that information
                            and provide it (and the fact that a search was made)
                            to its other customers to verify identity, assess
                            the risk of giving credit, prevent fraud and money
                            laundering, and trace debtors; and
                        </li>
                        <li class="terms-block__list-item">
                            you will provide your client with a copy of the
                            <a
                                href="https://www.equifax.co.uk/crain"
                                target="_blank"
                                >CRA Information Notice</a
                            >.
                        </li>
                    </ol>
                </div>
                <div class="terms-block__paragraph">
                    You further agree that:
                    <ol class="terms-block__list">
                        <li class="terms-block__list-item">
                            you will indemnify Legl from and against any claims,
                            losses or costs resulting from your breach of the
                            above obligations; and
                        </li>
                        <li class="terms-block__list-item">
                            both Legl and Equifax Limited have the right to
                            request a copy of the notification used to notify
                            your clients.
                        </li>
                    </ol>
                </div>
            </div>
        `;
  }
}

customElements.define("legl-terms-of-agreement", TermsOfAgreement);
