import { createReducer } from "@reduxjs/toolkit";
import {
  addOngoingMonitoring,
  resetOngoingMonitoring,
  updateOngoingMonitoring,
  updateOngoingMonitoringAlert,
} from "../../actions/ongoing-monitoring";

function transformAlerts(monitoringRecord) {
  return {
    ...monitoringRecord,
    alerts: (monitoringRecord?.alerts || []).reduce((alertsOut, alertIn) => {
      return { ...alertsOut, [alertIn.url]: alertIn };
    }, {}),
  };
}

const ongoingMonitoringReducer = createReducer({}, (builder) => {
  builder.addCase(addOngoingMonitoring, (state, action) => {
    state[action.payload.contactUid] = transformAlerts(
      action.payload.monitoringRecord,
    );
    return state;
  });
  builder.addCase(updateOngoingMonitoring, (state, action) => {
    state[action.payload.contactUid] = {
      ...state[action.payload.contactUid],
      ...action.payload.monitoringRecord,
    };
    return state;
  });
  builder.addCase(updateOngoingMonitoringAlert, (state, action) => {
    if (!state[action.payload.contactUid]) {
      state[action.payload.contactUid] = {
        alerts: {},
      };
    }
    state[action.payload.contactUid].alerts[action.payload.alertUrl] = {
      ...(state?.[action.payload.contactUid]?.alerts?.[
        action.payload.alertUrl
      ] || {}),
      ...action.payload.alertRecord,
    };

    return state;
  });

  builder.addCase(resetOngoingMonitoring, (state) => {
    state = {};
    return state;
  });
});

export default ongoingMonitoringReducer;
