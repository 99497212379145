import { createAction } from "@reduxjs/toolkit";

export const fetchPaymentDetails = createAction("pay/fetch-payment-details");

export const updatePaymentStatus = createAction("pay/update-payment-status");

export default {
  fetchPaymentDetails,
  updatePaymentStatus,
};
