import { createAction } from "@reduxjs/toolkit";

export const selectedContact = createAction("engage/contact-selected");

export const fetchApplicationDetails = createAction(
  "engage/fetch-application-details",
);

export const updateApplicationStatus = createAction(
  "engage/update-application-status",
);

export default {
  selectedContact,
  fetchApplicationDetails,
  updateApplicationStatus,
};
