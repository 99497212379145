import { LitElement, css, html } from "lit";
export class LeglIcon extends LitElement {
  static get styles() {
    return css`
            @keyframes spin {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }
            :host {
                display: inline-block;
                vertical-align: middle;
                line-height: 1;
            }

            :host i[data-icon] {
                /* use !important to prevent issues with browser extensions that change fonts */
                font-family: "legl-icon";
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;

                /* Enable Ligatures ================ */
                letter-spacing: 0;
                -webkit-font-feature-settings: "liga";
                -moz-font-feature-settings: "liga=1";
                -moz-font-feature-settings: "liga";
                -ms-font-feature-settings: "liga" 1;
                font-feature-settings: "liga";
                -webkit-font-variant-ligatures: discretionary-ligatures;
                font-variant-ligatures: discretionary-ligatures;

                /* Better Font Rendering =========== */
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                box-sizing: border-box;
                display: inline-flex;
                justify-content: center;
                align-items: center;
            }

            :host([isSpinning]) i[data-icon] {
                animation: spin 2s linear infinite;
                display: inline-block;
                width: 100%;
                height: 100%;
            }

            :host([size="small"]) {
                font-size: 14px;
            }

            :host([size="medium"]) {
                font-size: 16px;
            }

            :host([size="large"]) {
                font-size: 20px;
            }

            :host([size="x-large"]) {
                font-size: 26px;
            }

            :host([size="xx-large"]) {
                font-size: 30px;
            }

            :host([color="green"]) {
                color: var(--legl-state-success);
            }

            :host([color="red"]) {
                color: var(--legl-red);
            }

            :host([color="grey"]) {
                color: var(--legl-grey);
            }

            :host([padding="padding-right"]) {
                padding-right: 15px;
            }

            :host([disabled]) {
                opacity: 0.5;
            }

            :host([size="tiny"]) {
                font-size: 10px;
            }
        `;
  }

  static get properties() {
    return {
      name: {
        type: String,
      },
      isSpinning: {
        type: Boolean,
        reflect: true,
      },
      dynamicStyle: {
        attribute: false,
      },
      disabled: {
        type: Boolean,
        reflect: true,
      },
      size: {
        type: String,
        reflect: true,
      },
      color: {
        type: String,
        reflect: true,
      },
      padding: {
        type: String,
        reflect: true,
      },
    };
  }

  get dynamicStyle() {
    return html`<style>
            i[data-icon]:before {
                content: var(--${this.name});
            }
        </style>`;
  }

  render() {
    return html`
            ${this.dynamicStyle}<i data-icon class="${this.name}"></i>
        `;
  }
}

customElements.define("legl-icon", LeglIcon);
