import { LitElement, css, html } from "lit";
import { neutral, primary } from "../../../legl-ui/lds-colours";
import "../../../legl-ui/lds-tooltip";

export class LdsProgressBar extends LitElement {
  static get styles() {
    return css`
            .item-grid {
                display: grid;
                grid-auto-columns: 1fr;
                grid-auto-flow: column;
                grid-template-rows: 1;
                column-gap: 1px;
                box-sizing: border-box;
                width: 115px;
                padding: 1px;
                background: #ffffff;
                border: 1px solid ${neutral["200"]};
            }
            .item {
                height: 5px;
            }
            .complete {
                background-color: ${primary["400"]};
            }
            .incomplete {
                background-color: ${neutral["200"]};
            }
        `;
  }

  static get properties() {
    return {
      items: {
        type: Object,
      },
    };
  }

  get completeItems() {
    return this.items.filter((item) => item.complete);
  }

  get incompleteItems() {
    return this.items.filter((item) => !item.complete);
  }

  render() {
    return html`
            <lds-tooltip
                .text="${this.completeItems.length}/${this.items.length} completed"
                placement="top-start"
            >
                <div
                    class="item-grid"
                    role="progressbar"
                    aria-label="Number of completed items"
                    aria-valuetext="${this.completeItems.length} out of ${
                      this.items.length
                    } items have been completed"
                    aria-valuemin="0"
                    aria-valuemax=${this.items.length}
                    aria-valuenow=${this.completeItems.length}
                >
                    ${this.completeItems.map(
                      () => html`<div class="item complete"></div>`,
                    )}
                    ${this.incompleteItems.map(
                      () => html`<div class="item incomplete"></div>`,
                    )}
                </div>
            </lds-tooltip>
        `;
  }
}

customElements.define("lds-progress-bar", LdsProgressBar);
