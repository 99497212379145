export const InstantScreeningWorkflow = {
  flow_name: "Instant screening",
  create_steps: [
    {
      element_type: "basic-information",
      element_config: {
        create_onfido_applicant: true,
        hide_name_and_email_field: true,
      },
    },
    {
      element_type: "onfido",
      element_config: {
        reports: ["watchlist_kyc"],
      },
    },
  ],
};

export const AMLOnlyWorkflow = {
  flow_name: "In-person: Watchlist Screening",
  validate_matter_reference: false,
  create_steps: [
    {
      element_type: "basic-information",
      element_config: {
        create_onfido_applicant: true,
        hide_name_and_email_field: true,
      },
    },
    {
      element_type: "onfido",
      element_config: {
        reports: ["watchlist_kyc"],
      },
    },
  ],
};

export const FinancialCheckOnlyWorkflow = {
  flow_name: "In-person: Financial Screening",
  validate_matter_reference: false,
  create_steps: [
    {
      element_type: "basic-information",
      element_config: {
        create_onfido_applicant: true,
        hide_name_and_email_field: true,
      },
    },
    {
      element_type: "onfido",
      element_config: {
        reports: ["financial_checks"],
      },
    },
  ],
};

export const EnableMonitoringWorkflow = {
  ...AMLOnlyWorkflow,
  flow_name: "Initial Check for Monitoring",
  enabling_monitoring: true,
};

export const ManualStandardCDDWorkflow = {
  flow_name: "In-person: Standard CDD",
  validate_matter_reference: false,
  create_steps: [
    {
      element_type: "basic-information",
      element_config: {
        create_onfido_applicant: true,
        hide_name_and_email_field: true,
      },
    },
    {
      element_type: "onfido",
      element_config: {
        reports: ["document", "identity", "watchlist_kyc"],
      },
    },
    {
      element_type: "manual-biometrics",
      element_config: {},
    },
    {
      element_type: "cdd-documents",
      element_config: {
        title: "Additional documents",
        collect_additional_docs_for_all: true,
        optional: true,
      },
    },
  ],
};

export const ManualStandardCDDNoIDWorkflow = {
  flow_name: "In-person: Standard CDD - No ID Document Verification",
  validate_matter_reference: false,
  create_steps: [
    {
      element_type: "basic-information",
      element_config: {
        create_onfido_applicant: true,
        hide_name_and_email_field: true,
      },
    },
    {
      element_type: "onfido",
      element_config: {
        reports: ["identity", "watchlist_kyc"],
      },
    },
    {
      element_type: "cdd-documents",
      element_config: {
        title: "Supporting documents",
        collect_additional_docs_for_all: true,
        optional: true,
      },
    },
  ],
};
