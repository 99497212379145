import { html, render } from "lit";
import { BaseAdapter } from "./base-adapter";

export class BankedAdapter extends BaseAdapter {
  scriptSrc = "https://js.banked.com/v3";

  async _appendPaymentMethod(DomElement) {
    const component = html`<banked-checkout
            data-testid="banked-checkout"
            api-key=${this.providerDetails.api_key}
            payment-id=${this.providerDetails.payment_id}
            hide-header
            hide-payment-details
            options=${JSON.stringify({ is_graceful_exit_disabled: true })}
        ></banked-checkout>`;

    render(component, DomElement);

    await component.updateComplete;

    if (window.isNewPayApp && window.BankedGlobalCheckout) {
      window.BankedGlobalCheckout.init({
        onSuccess: () =>
          window.dispatchEvent(new CustomEvent("payment-completed")),
      });
    }
  }
}
