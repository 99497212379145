import { LitElement, css, html } from "lit";
import "../../../../../legl-ui/permissions-overlay";

class DialogPermissionOverlayContainer extends LitElement {
  static get styles() {
    return css`
            :host {
                display: flex;
                padding-top: 5rem;
                width: 100%;
                flex-direction: column;
                justify-content: flex-start;
            }

            legl-permissions-overlay {
                --legl-permissions-overlay-width: 100%;
                --legl-permissions-overlay-message-font-size: 18px;
                --legl-permissions-overlay-icon-font-size: 36px;
                --legl-permissions-overlay-message-max-width: 300px;
            }
        `;
  }
  render() {
    return html` <legl-permissions-overlay></legl-permissions-overlay> `;
  }
}

customElements.define(
  "legl-dialog-permission-overlay-container",
  DialogPermissionOverlayContainer,
);
