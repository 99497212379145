import { LitElement, css, html } from "lit";
import "../../legl-ui/icon";
import { neutral } from "../../legl-ui/lds-colours";
class ResultsStep extends LitElement {
  static get styles() {
    return css`
            :host {
                font-size: var(--step-font-size, 14px);
            }

            :host([color="default"]) {
                --step-content-color: var(--legl-grey-dark);
            }

            :host([color="pending"]) {
                --step-content-color: ${neutral["800"]};
            }

            :host([color="success"]) {
                --step-content-color: var(--legl-state-success);
            }

            :host([color="warning"]) {
                --step-content-color: var(--legl-yellow);
            }

            :host([color="error"]) {
                --step-content-color: var(--legl-red);
            }

            .content {
                padding-left: 1.25em;
                padding-top: 0.5em;
                position: relative;
                font-weight: bold;
                color: var(--step-content-color);
                display: flex;
                align-items: center;
                gap: 0.25rem;
            }

            h3 {
                margin: 0;
                padding: 0;
                font-size: var(--step-font-size, 14px);
                color: var(--legl-grey-dark);
                text-transform: capitalize;
            }

            .content:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 1.25em;
                border-left: solid 2px var(--step-border-color, #d1d1d1);
            }

            .content:after {
                content: "";
                position: absolute;
                top: 1.25em;
                left: 0;
                width: 0.75em;
                border-bottom: solid 2px var(--step-border-color, #d1d1d1);
            }

            ::slotted(legl-results-steps) {
                --step-font-size: 12px;
            }
            ::slotted(h3) {
                color: var(--legl-gray-dark);
            }
            ::slotted ;
        `;
  }
  static get properties() {
    return {
      title: {
        type: String,
        reflect: true,
      },
      icon: {
        type: String,
      },
      color: {
        type: String,
        reflect: true,
      },
    };
  }
  render() {
    return html`
            <div class="step">
                <h3>${this.title}</h3>
                <div class="content">
                    ${
                      this.icon
                        ? html`<lds-icon .name="${this.icon}"></lds-icon>`
                        : ""
                    }

                    <slot></slot>
                </div>
            </div>
        `;
  }
}

customElements.define("legl-results-step", ResultsStep);
