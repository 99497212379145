import { dedupeMixin } from "@lion/core";
import { css } from "lit";

import "../../legl-ui/icon";
import { danger, neutral } from "../../legl-ui/lds-colours";
import "../../legl-ui/tooltip-old";

export const LeglBaseInputMixin = dedupeMixin(
  (superclass) =>
    class LeglBaseInputMixin extends superclass {
      static get styles() {
        return [
          super.styles,
          css`
                        :host {
                            width: 100%;
                            position: relative;
                            -webkit-box-sizing: border-box;
                            box-sizing: border-box;
                            display: inline-flex;
                            margin: 10px 0;
                            min-height: 50px;
                            padding-top: 50px;
                            flex-direction: column-reverse;
                            line-height: normal;
                        }

                        :host .form-field__label {
                            border-radius: 25px;
                            position: absolute;
                            z-index: 1;
                            background-color: #ffffff;
                            color: ${neutral["700"]};
                            font-style: italic;
                            display: block;
                            top: 25px;
                            left: 7px;
                            right: 7px;
                            font-size: 17px;
                            padding: 0 0.1rem;
                            -webkit-transform: translateY(-50%);
                            -ms-transform: translateY(-50%);
                            transform: translateY(-50%);
                            -webkit-transition: top 0.25s ease-in-out,
                                font-size 0.25s ease-in-out;
                            -o-transition: top 0.25s ease-in-out,
                                font-size 0.25s ease-in-out;
                            transition: top 0.25s ease-in-out,
                                font-size 0.25s ease-in-out,
                                color 0.25s ease-in-out;
                            letter-spacing: 0.4px;
                        }

                        :host .input-group {
                            -webkit-box-sizing: border-box;
                            box-sizing: border-box;
                            border: 1px solid ${neutral["200"]};
                            background-color: #ffffff;
                            border-radius: 2px;
                            padding: 0 1rem 1rem 0.5rem;
                            position: absolute;
                            width: 100%;
                            height: 50px;
                            top: 0;
                            transition: border-color 0.25s ease-in-out;
                        }

                        :host .form-field__group-one,
                        :host .form-field__group-two {
                            text-align: left;
                            font-size: 0.8rem;
                        }

                        :host .form-field__feedback {
                            text-align: right;
                            color: #840800;
                            font-style: italic;
                        }

                        .input-group__container
                            > .input-group__input
                            ::slotted(.legl-form-control) {
                            top: 11px;
                            font-family: "Lato", sans-serif !important;
                            position: relative;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                            width: calc(100% - 30px);
                            border: none;
                            background-color: transparent;
                            outline-width: 0;
                            font-weight: normal;
                            font-size: 1.3em;
                            color: ${neutral["700"]};
                            padding-right: 30px;
                            box-shadow: none;
                            text-align: left;
                            z-index: 1;
                            outline: none;
                            border: 0;
                        }

                        :host([hide-validation-icons]) .input-group:after {
                            display: none;
                        }

                        :host .input-group:after {
                            display: none;
                            font-family: "legl-icon";
                            position: absolute;
                            right: 6px;
                            top: 15px;
                            font-size: 18px;
                            z-index: 1;
                        }

                        :host([shows-feedback-for="error"]) .input-group:after {
                            content: var(--legl-icon-warning-circle);
                            color: ${danger["600"]};
                            display: block;
                        }

                        :host([filled][shows-feedback-for=""])
                            .input-group:after {
                            content: var(--legl-icon-confirm-circle-o);
                            color: #28a745;
                            display: block;
                        }

                        :host([hide-validation-icons])
                            .input-group.input-group:after {
                            display: none;
                        }

                        :host([focused]) .form-field__label,
                        :host([filled]) .form-field__label,
                        :host([pseudo-focused]) .form-field__label {
                            top: -2px;
                            font-size: 10px;
                            right: auto;
                        }

                        :host([focused]) .form-field__label {
                            color: ${neutral["800"]};
                            font-weight: bold;
                            letter-spacing: normal;
                        }

                        :host([focused]) .input-group {
                            border-bottom-color: ${neutral["800"]};
                        }

                        :host .form-field__label ::slotted(label) {
                            /* Getting some styles from bootstrap that are interfering here */
                            display: inline !important;
                        }

                        :host([shows-feedback-for="error"]) .input-group {
                            border-bottom-color: #840800;
                        }

                        .input-group__after {
                            position: absolute;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            padding-right: 11px;
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            z-index: 1;
                        }

                        .input-group__after ::slotted(.info-tooltip) {
                            position: absolute;
                            top: 50%;
                            margin-top: -32px;
                            right: 11px;
                            font-size: 1rem;
                            color: ${neutral["700"]};
                            background-color: #fff;
                            font-size: 11px;
                        }

                        :host([disabled]) .input-group {
                            background-color: ${neutral["100"]};
                        }
                        :host([disabled]) .form-field__label {
                            background-color: ${neutral["100"]};
                        }

                        :host([readonly]) {
                            pointer-events: none;
                            min-height: 40px;
                            padding-top: 40px;
                        }
                        :host([readonly]) .input-group {
                            border: none;
                            left: -2px;
                            height: 40px;
                        }
                    `,
        ];
      }

      static get properties() {
        return {
          infoText: {
            type: String,
          },
          pseudoFocused: {
            type: Boolean,
            attribute: "pseudo-focused",
            reflect: true,
          },
          hideValidationIcons: {
            type: Boolean,
            attribute: "hide-validation-icons",
            reflect: true,
          },
        };
      }

      /* Original conditions are (this.dirty && this.touched) || this.prefilled || this.submitted;
             touched doesn't seem to happen until after the first blur event so changin this seems roughly akin to the
             validation happening on keyup */

      _showFeedbackConditionFor() {
        return this.dirty || this.prefilled || this.submitted;
      }

      _enhanceLightDomClasses() {
        if (this._inputNode) {
          this._inputNode.classList.add("legl-form-control");
        }
      }

      get slots() {
        const slots = {
          ...super.slots,
        };
        if (this.infoText) {
          slots.after = () => {
            const icon = document.createElement("legl-icon");
            icon.setAttribute("name", "legl-icon-info-circle");
            const tooltip = document.createElement("legl-tooltip");
            tooltip.content = this.infoText;
            tooltip.classList.add("info-tooltip");
            tooltip.variant = "top-left";
            tooltip.appendChild(icon);
            return tooltip;
          };
        }

        return slots;
      }
    },
);
