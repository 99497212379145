import { LitElement, css, html, nothing } from "lit";
import "../../../../../../../legl-ui/results-steps";
import "../../../components/legl_assist/cdd-summary";
import "../../../components/legl_assist/feedback";

const resultLabels = {
  document: "ID Data Validation",
  identity: "Identity Validation",
  identity_enhanced: "Identity Validation",
  watchlist: "PEPs and Sanctions",
  watchlist_standard: "PEPs and Sanctions",
  watchlist_enhanced: "PEPs and Sanctions",
  watchlist_kyc: "PEPs and Sanctions",
  facial_similarity: "Biometrics",
  facial_similarity_photo: "Biometrics",
  facial_similarity_video: "Biometrics",
  financial_checks_result: "Financial Screening",
};

export class LeglEngageCDD extends LitElement {
  static get styles() {
    return css`
            :host {
                text-transform: capitalize;
            }

            .additional-info {
                text-transform: none;
                /* Small */

                font-family: "Lato", sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.2px;
                color: #253658;
            }

            .sanctions-review {
              border: 1px solid #EDEFF2;
              border-radius: 6px;
              display: flex;
              flex-direction: column;
              //margin: 12px 0;
              margin-left: -40px;
              padding: 16px 20px 16px 16px;

              h3 {
                margin: 0;
              }

              p {
                margin: 0 0 12px 0;
                text-transform: none;
              }

              lds-link-button {
                height: fit-content;
                width: fit-content;
              }
            }

        `;
  }

  static get properties() {
    return {
      onfidoStep: {
        type: Object,
      },
      application_id: {
        type: Number,
      },
      app_uuid: {
        type: String,
      },
    };
  }

  constructor() {
    super();
  }

  get hasOnfidoResults() {
    return (
      this.onfidoStep.onfido &&
      this.onfidoStep.onfido.report_results &&
      Object.keys(this.onfidoStep.onfido.report_results).length > 0
    );
  }

  static getResultIconName(result) {
    if (result) {
      switch (result.toLowerCase()) {
        case "clear":
        case "completed":
        case "confirmed":
          return "lds-icon-CheckCircleFilled";
        case "negative":
        case "rejected":
        case "failed":
          return "lds-icon-Block";
        case "inactive":
        case "skipped":
          return "lds-icon-Cancel";
        case "warning":
        case "consider":
        case "suspected":
        case "caution":
        case "eddrequired":
          return "lds-icon-WarningFilled";
        case "signed":
          return "lds-icon-Edit";
        case "unidentified":
          return "lds-icon-HelpFilled";
        case "processing":
          return "lds-icon-SettingsFilled";
        case "incomplete":
        case "force completed":
        case "unconfirmed":
          return "lds-icon-RemoveCircleFilled";
        case "created":
          return "lds-icon-InProgress";
        default:
          return "";
      }
    }
    return "";
  }

  static getResultColor(result) {
    if (result) {
      switch (result.toLowerCase()) {
        case "clear":
        case "completed":
        case "confirmed":
          return "success";
        case "negative":
        case "rejected":
        case "failed":
        case "unidentified":
          return "error";
        case "warning":
        case "consider":
        case "suspected":
        case "caution":
        case "eddrequired":
          return "warning";
        case "processing":
          return "pending";
        case "skipped":
          return "default";
        case "force completed":
          return "error";
        default:
          return "";
      }
    }
    return "";
  }

  static getResultLabel(name) {
    return resultLabels[name] || name.replace(/_/g, " ");
  }

  static mutateResult(result) {
    if (result === "N/A") return result + " *";

    return result || "-";
  }

  get complyAdvantageResults() {
    if (this.onfidoStep.comply_advantage_status_on_onfido_step) {
      this.reportType = "watchlist";
      return html`<legl-results-step
                .title=${LeglEngageCDD.getResultLabel(this.reportType)}
                .icon=${LeglEngageCDD.getResultIconName(
                  this.onfidoStep.comply_advantage_status_on_onfido_step,
                )}
                .color=${LeglEngageCDD.getResultColor(
                  this.onfidoStep.comply_advantage_status_on_onfido_step,
                )}
                >${this.onfidoStep.comply_advantage_status_on_onfido_step}</legl-results-step
            > `;
    }
  }

  get financialChecksResult() {
    const financial_checks_object = this.onfidoStep.financial_checks_result;

    if (financial_checks_object) {
      const key = financial_checks_object.key;
      const text = financial_checks_object.text;
      const reportType = "financial_checks_result";
      const asterix = key === "not_available" ? " *" : "";

      return html`<legl-results-step
                .title=${LeglEngageCDD.getResultLabel(reportType)}
                .icon=${LeglEngageCDD.getResultIconName(key)}
                .color=${LeglEngageCDD.getResultColor(key)}
                >${text}${asterix}</legl-results-step
            >`;
    }
  }

  get visualVerificationResults() {
    if (this.onfidoStep.visual_verification_status_on_manual_biometrics_step) {
      return html`<legl-results-step
                title="Visual verification"
                .icon=${LeglEngageCDD.getResultIconName(
                  this.onfidoStep
                    .visual_verification_status_on_manual_biometrics_step,
                )}
                .color=${LeglEngageCDD.getResultColor(
                  this.onfidoStep
                    .visual_verification_status_on_manual_biometrics_step,
                )}
                >${
                  this.onfidoStep
                    .visual_verification_status_on_manual_biometrics_step
                }</legl-results-step
            > `;
    }

    return nothing;
  }

  get reportResults() {
    return Object.entries(this.onfidoStep.onfido.report_results)
      .sort(([aName], [bName]) => {
        return (
          Object.keys(resultLabels).indexOf(aName) -
          Object.keys(resultLabels).indexOf(bName)
        );
      })
      .map(([name, results]) => {
        return html`<legl-results-step
                    .title=${LeglEngageCDD.getResultLabel(name)}
                    .icon=${LeglEngageCDD.getResultIconName(results.result)}
                    .color=${LeglEngageCDD.getResultColor(results.result)}
                    >${LeglEngageCDD.mutateResult(results?.result)}</legl-results-step
                > `;
      });
  }

  get reportConfig() {
    return [...this.onfidoStep.element_config["reports"]]
      .sort((aName, bName) => {
        return (
          Object.keys(resultLabels).indexOf(aName) -
          Object.keys(resultLabels).indexOf(bName)
        );
      })
      .map((name) => {
        return html`<legl-results-step
                    color="default"
                    .title=${LeglEngageCDD.getResultLabel(name)}
                    >-</legl-results-step
                > `;
      });
  }

  get nfcResults() {
    if (!this.onfidoStep?.nfc?.result) {
      return nothing;
    }
    return html`<legl-results-step
        title="NFC"
        .icon=${LeglEngageCDD.getResultIconName(this.onfidoStep.nfc.result)}
        .color=${LeglEngageCDD.getResultColor(this.onfidoStep.nfc.result)}
        >${this.onfidoStep.nfc.result}</legl-results-step
      >
      ${this.financialChecksResult}${this.complyAdvantageResults}
    `;
  }

  get allReports() {
    if (this.onfidoStep.element_type === "nfc") {
      return this.nfcResults;
    }

    if (
      this.hasOnfidoResults &&
      this.onfidoStep.comply_advantage_status_on_onfido_step
    ) {
      return html`${this.reportResults}${this.visualVerificationResults}${this.financialChecksResult}${this.complyAdvantageResults}`;
    } else if (this.hasOnfidoResults) {
      return this.reportResults;
    } else if (this.onfidoStep.comply_advantage_status_on_onfido_step) {
      return this.complyAdvantageResults;
    } else {
      return this.reportConfig;
    }
  }

  isOnly(report = "watchlist_kyc") {
    const reports = this.onfidoStep?.element_config?.reports;
    return reports?.length === 1 && reports.includes(report);
  }

  get resultAdditionalInformation() {
    const financial_checks_object = this.onfidoStep.financial_checks_result;
    const identity = this.onfidoStep.onfido?.report_results?.identity;
    const identity_enhanced =
      this.onfidoStep.onfido?.report_results?.identity_enhanced;

    if (financial_checks_object?.key == "not_available") {
      return `*${financial_checks_object.report_text}`;
    } else if (
      identity_enhanced?.result === "N/A" ||
      identity?.result === "N/A"
    ) {
      return "*Check was not completed, due to the client's address being outside the UK.";
    }
  }

  renderLeglAssistSummary() {
    if (this.onfidoStep?.legl_assist?.response) {
      return html`<cdd-legl-assist-summary
                .content=${this.onfidoStep?.legl_assist?.response}
                .pdfUrl=${this.onfidoStep?.legl_assist?.pdf_summary}
                .pdfHasSummary=${
                  this.onfidoStep?.legl_assist?.summary_included_to_pdf
                }
                .leglAssistId=${this.onfidoStep?.legl_assist?.id}
            >
                <legl-feedback
                    slot="after"
                    .request_data=${{
                      legl_assist_id: this.onfidoStep?.legl_assist?.id,
                      application_id: this.application_id,
                    }}
                ></legl-feedback>
            </cdd-legl-assist-summary>`;
    }

    return nothing;
  }

  renderReviewScreening() {
    const screeningResult =
      this.onfidoStep?.comply_advantage_status_on_onfido_step;
    const flagEnabled = waffle.flag_is_active("enable_screening");
    const isConsiderResult = screeningResult?.toLowerCase() === "consider";

    if (flagEnabled && isConsiderResult) {
      return html`<div class="sanctions-review">
          <h3>Contact screening results</h3>
          <p>View PEPs, Sanctions, Adverse Media, Fitness & Probity and Warnings for your contact</p>
          <lds-link-button small colour="primary" variant="contained" .href="/screening/${this.app_uuid}?appId=${this.application_id}">View screening results</lds-link-button>
        </div>
      `;
    }

    return nothing;
  }

  render() {
    const additionalInformation = this.isOnly("watchlist_kyc")
      ? nothing
      : html`<div class="additional-info text-small">
                  ${this.resultAdditionalInformation}
              </div>`;

    if (this.isOnly("financial_checks")) {
      return html`<div class="cdd">
                <legl-results-steps
                    >${this.financialChecksResult}</legl-results-steps
                >
                ${this.renderLeglAssistSummary()}
            </div>`;
    }

    const isNfc = this.onfidoStep.element_type === "nfc";

    return this.hasOnfidoResults ||
      isNfc ||
      this.onfidoStep.comply_advantage_status_on_onfido_step
      ? html`<div class="cdd">
          <legl-results-steps>${this.allReports}</legl-results-steps>
        </div>
        ${this.renderLeglAssistSummary()}
        ${this.renderReviewScreening()}
        ${additionalInformation}`
      : "";
  }
}

customElements.define("legl-engage-cdd", LeglEngageCDD);
