import { css, html } from "lit";
import { neutral } from "../../../../../legl-ui/lds-colours";

import "../../../../../../frontend/legl-ui/two-options-selector/options-selector.js";
import { toastService } from "../../../../../legl-ui/toast";
import { ElementBase } from "./element_base.js";

export class ManualBiometricsElement extends ElementBase {
  static get styles() {
    return css`
            .accordion-content {
                padding: 1rem 2rem;
            }

            p {
                color: ${neutral["700"]};
            }

            .save-btn {
                border-radius: 0 0 4px 4px;
                display: block;
            }
        `;
  }

  constructor() {
    super();
    this.hasResult = false;
  }

  handleSubmit(event) {
    this.submitButton = event.target;
    this.sendResults({
      visualVerification: this.visualVerification,
    });
  }

  updateVerification(details) {
    this.visualVerification = details.optionSelected.result;
    this.hasResult = true;
    this.requestUpdate();
  }

  handleResultResponse(response) {
    this.sendComplete();
    this.lock();
  }

  firstUpdated() {
    this.addEventListener("options-selection", (el) =>
      this.updateVerification(el.detail),
    );
  }

  render() {
    return html`<div class="accordion-content">
                <p>
                    Can you confirm that the person you are completing the check
                    for matches the person on the ID document provided? This
                    response will be included in the final PDF report review.
                </p>
                <legl-options-selector
                    .options=${[
                      {
                        result: true,
                        title: "Yes, I confirm",
                        text: "I have visually verified the person against the ID document via in person meeting, video call or similar method.",
                      },
                      {
                        result: false,
                        title: "No, I have not",
                        text: "visually verified the person against the ID document via in person meeting, video call or similar method.",
                      },
                    ]}
                ></legl-options-selector>
                <br />
            </div>
            <legl-button
                class="save-btn"
                full-width
                @click="${this.handleSubmit}"
                .disabled="${!this.hasResult}"
                >Submit</legl-button
            >`;
  }
}

customElements.define("legl-manual-biometrics", ManualBiometricsElement);
