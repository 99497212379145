import { LitElement, css, html, nothing } from "lit";
import "../../../../legl-ui/lds-button";
import { danger, neutral } from "../../../../legl-ui/lds-colours";
import { spacing } from "../../../../legl-ui/lds-spacing";

export class AddAdditionalSignersModal extends LitElement {
  static get styles() {
    return css`
            :host {
                padding: 0 ${spacing.l} ${spacing.l} ${spacing.l};
            }
            .rows-container {
                padding-top: 12px;
                padding-left: 2px;
                padding-right: 2px;
                padding-bottom: 2px;
                max-height: 570px;
                overflow-y: auto;
            }
            .additional-signer-row {
                display: flex;
                align-items: flex-end;
                margin-bottom: ${spacing.s};
                width: 100%;
                box-sizing: border-box;
            }
            .additional-signer-row:last-of-type {
                margin-bottom: 0;
            }
            lds-input {
                flex-grow: 1;
                margin-right: ${spacing.s};
                width: 215px;
            }
            lds-input:last-of-type {
                margin-right: 0;
                width: 330px;
            }
            .tooltip-container {
                position: relative;
                width: 56px;
                height: 48px;
            }
            lds-tooltip {
                position: absolute;
            }
            .primary-signer-tooltip-icon {
                padding: 12px;
                margin-left: ${spacing.xs};
            }
            .remove-additional-signer-container {
                width: 54px;
            }
            .signer-action-button {
                margin-left: ${spacing.xs};
            }
            .add-additional-signer-button-container {
                margin: ${spacing.m} 0 ${spacing.m} 2px;
            }
            .button-container {
                display: flex;
                justify-content: flex-end;
                margin-top: ${spacing.m};
            }
            .cancel-additional-signers-button {
                margin-right: ${spacing.xs};
            }
        `;
  }

  static get properties() {
    return {
      setAdditionalSigners: {
        attribute: false,
      },
      additionalSigners: {
        attribute: false,
      },
      primarySigner: {
        attribute: false,
      },
      isLoading: {
        state: true,
      },
      signers: {
        state: true,
      },
    };
  }

  constructor() {
    super();
    document.activeElement.blur();
    this.isLoading = false;
    this.signers = [];
  }

  async connectedCallback() {
    super.connectedCallback();
    if (this.additionalSigners.length > 0) {
      this.signers = this.additionalSigners;
    }
  }

  close() {
    this.parentElement.onClickClose();
  }

  async addAdditionalSigner() {
    this.signers.push({
      first_name: "",
      last_name: "",
      email_address: "",
    });
    this.requestUpdate();
    await this.updateComplete;

    const lastAdditionalSignerNameInput = this.shadowRoot.querySelector(
      "#last-additional-signer-name-input",
    );
    if (lastAdditionalSignerNameInput) {
      document.activeElement.blur();
      lastAdditionalSignerNameInput.focus();
    }
  }

  removeAdditionalSigner(index) {
    this.signers.splice(index, 1);
    this.requestUpdate();
  }

  get formIsValid() {
    return this.signers.every((signer, index) => {
      return (
        (signer.first_name &&
          signer.last_name &&
          signer.email_address &&
          !signer.invalid_email) ||
        (signer.first_name === "" &&
          signer.last_name === "" &&
          signer.email_address === "")
      );
    });
  }

  async updateEmail(email, index) {
    // Using custom email validation as hellosign email validation doesn't line up with lion validator
    if (!email.match(/^[\w-+\.]*[\w-+]@([\w-]+\.)+[\w-]{2,4}$/g)) {
      this.signers[index].invalid_email = true;
    } else if (this.signers[index].invalid_email) {
      delete this.signers[index].invalid_email;
    }
    this.signers[index].email_address = email;
    this.requestUpdate();
  }

  getFirstNameErrorMessage(index) {
    if (
      !this.signers[index].first_name &&
      (this.signers[index].email_address || this.signers[index].last_name)
    ) {
      return "First name is a required field";
    }
    return "";
  }

  getLastNameErrorMessage(index) {
    if (
      !this.signers[index].last_name &&
      (this.signers[index].email_address || this.signers[index].first_name)
    ) {
      return "Last name is a required field";
    }
    return "";
  }

  getEmailErrorMessage(index) {
    if (this.signers[index].invalid_email) {
      return "Please enter a valid email address";
    } else if (
      !this.signers[index].email_address &&
      (this.signers[index].first_name || this.signers[index].last_name)
    ) {
      return "Email address is a required field";
    }
    return "";
  }

  handleConfirmAddtionalSigners() {
    this.isLoading = true;
    const signersWithoutEmptyRows = this.signers.filter(
      (signer) => signer.first_name && signer.last_name && signer.email_address,
    );
    this.setAdditionalSigners(signersWithoutEmptyRows);
  }

  render() {
    return html`
            <div>
                <div class="rows-container">
                    <div class="additional-signer-row">
                        <lds-input
                            label="First name"
                            name="first_name"
                            disabled
                            .modelValue="${this.primarySigner.first_name}"
                        >
                        </lds-input>
                        <lds-input
                            label="Last name"
                            name="last_name"
                            disabled
                            .modelValue="${this.primarySigner.last_name}"
                        >
                        </lds-input>
                        <lds-input
                            label="Email address"
                            name="email"
                            disabled
                            .modelValue="${this.primarySigner.email_address}"
                        >
                        </lds-input>
                        <div class="tooltip-container">
                            <lds-tooltip
                                text="This contact must provide their signature as part of the engage request."
                                placement="top-end"
                            >
                                <lds-icon
                                    class="primary-signer-tooltip-icon"
                                    name="lds-icon-ErrorFilled"
                                ></lds-icon>
                            </lds-tooltip>
                        </div>
                    </div>
                    ${this.signers.map(
                      (signer, index) => html`
                            <div class="additional-signer-row">
                                <lds-input
                                    id=${
                                      index === this.signers.length - 1
                                        ? "last-additional-signer-name-input"
                                        : ""
                                    }
                                    label="First name"
                                    name="first_name"
                                    .modelValue="${
                                      this.signers[index].first_name
                                    }"
                                    .errorMessage=${this.getFirstNameErrorMessage(
                                      index,
                                    )}
                                    @model-value-changed="${(e) => {
                                      this.signers[index].first_name =
                                        e.target.modelValue;
                                      this.requestUpdate();
                                    }}"
                                >
                                </lds-input>
                                <lds-input
                                    label="Last name"
                                    name="last_name"
                                    .modelValue="${
                                      this.signers[index].last_name
                                    }"
                                    .errorMessage=${this.getLastNameErrorMessage(
                                      index,
                                    )}
                                    @model-value-changed="${(e) => {
                                      this.signers[index].last_name =
                                        e.target.modelValue;
                                      this.requestUpdate();
                                    }}"
                                >
                                </lds-input>
                                <lds-input
                                    label="Email address"
                                    name="email"
                                    .modelValue="${
                                      this.signers[index].email_address
                                    }"
                                    .errorMessage=${this.getEmailErrorMessage(
                                      index,
                                    )}
                                    @model-value-changed="${(e) =>
                                      this.updateEmail(
                                        e.target.modelValue,
                                        index,
                                      )}"
                                >
                                </lds-input>
                                <div class="remove-additional-signer-container">
                                    <lds-icon-button
                                        class="signer-action-button"
                                        name="lds-icon-DeleteOutline"
                                        variant="text"
                                        colour="danger"
                                        ?disabled=${this.isLoading}
                                        @click=${() =>
                                          this.removeAdditionalSigner(index)}
                                    ></lds-icon-button>
                                </div>
                            </div>
                        `,
                    )}
                </div>
                <div class="add-additional-signer-button-container">
                    <lds-button
                        id="add-additional-signer-button"
                        @click=${this.addAdditionalSigner}
                        colour="primary"
                        variant="outlined"
                        icon="lds-icon-AddPerson"
                    >
                        Add another signer
                    </lds-button>
                </div>
                <div class="button-container">
                    <lds-button
                        id="cancel-additional-signers-button"
                        class="cancel-additional-signers-button"
                        variant="text"
                        colour="neutral"
                        @click=${this.close}
                    >
                        Cancel
                    </lds-button>
                    <lds-button
                        id="accept-additional-signers-button"
                        ?disabled=${!this.formIsValid || this.isLoading}
                        @click=${this.handleConfirmAddtionalSigners}
                        colour="primary"
                        .isLoading=${this.isLoading}
                    >
                        Continue
                    </lds-button>
                </div>
            </div>
        `;
  }
}

customElements.define(
  "legl-add-additional-signers-modal",
  AddAdditionalSignersModal,
);
