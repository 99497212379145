/* this is some old code copied over from pre-testing JS days and
because of the deep jquery dependency would be difficult to test
effectively and with diminishing returns so ignoring below */

import { toastService } from "../../../../../legl-ui/toast";

export function createClipboardButton(
  target = '[data-action="copy-link-button"]',
  toastConfirmation = false,
) {
  var clipboard = new ClipboardJS(target);
  clipboard.on("success", (e) => {
    if (e.trigger.dataset.toastSuccess) {
      toastService.showSuccess("Successfully copied!");
    } else {
      var preTooltipTitle = e.trigger.getAttribute("title");
      if (preTooltipTitle) {
        e.trigger.dataset.preTooltipTitle = preTooltipTitle;
      }
      e.trigger.removeAttribute("title");

      $(e.trigger)
        .tooltip("dispose")
        .tooltip({
          title: "Copied",
          placement: "top",
          delay: { show: 0, hide: 3000 },
        })
        .tooltip("show")
        .on("hidden.bs.tooltip", () => {
          $(e.trigger).tooltip("dispose");
          e.trigger.setAttribute(
            "title",
            e.trigger.dataset.preTooltipTitle || "",
          );
          e.trigger.dataset.preTooltipTitle = null;
        });
    }
  });
}
