import { LitElement, css, html } from "lit";
import { drawers } from "../../apps/lawyers-app/static-src/js/dialog-manager/drawer-manager";
import "../../legl-ui/icon";
import { neutral } from "../../legl-ui/lds-colours";

export class LeglTooltipMenuItem extends LitElement {
  static get styles() {
    return css`
            .tooltip-menu-item.disabled {
                color: var(--legl-grey);
                cursor: not-allowed;
            }
            .tooltip-menu-item:hover:not(.disabled) {
                background: ${neutral["900"]};
                border-radius: 5px;
            }
            .tooltip-menu-item {
                display: grid;
                grid-template:
                    "icon text" 40px
                    "icon sub-text" 40px / auto 1fr;
                color: ${neutral["0"]};
                text-decoration: none;
            }
            .tooltip-menu-item__icon {
                grid-area: icon;
                align-self: center;
                margin: 0 20px;
            }
            .tooltip-menu-item__text {
                grid-area: text;
                align-self: end;
            }
            .tooltip-menu-item__sub-text {
                grid-area: sub-text;
            }
        `;
  }

  static get properties() {
    return {
      text: {
        type: String,
        attribute: "text",
      },
      subText: {
        type: String,
        attribute: "sub-text",
      },
      icon: {
        type: String,
        attribute: "icon",
      },
      drawerElement: {
        type: String,
        attribute: "drawer-element",
      },
      drawerId: {
        type: String,
        attribute: "drawer-id",
      },
      drawerTitle: {
        type: String,
        attribute: "drawer-title",
      },
      drawerIcon: {
        type: String,
        attribute: "drawer-icon",
      },
      disabled: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();
    this.disabled = false;
  }

  get href() {
    return `#/drawer/${this.drawerId}`;
  }

  connectedCallback() {
    if (!this.disabled) {
      drawers.register(
        this.drawerElement,
        {
          drawerTitle: this.drawerTitle,
          drawerIcon: this.drawerIcon || this.icon,
        },
        this.drawerId,
      );
    }
    super.connectedCallback();
  }

  render() {
    if (this.disabled) {
      return html`
                <span class="disabled tooltip-menu-item" data-cy-tooltip-menu-a>
                    <lds-icon
                        class="tooltip-menu-item__icon"
                        name="${this.icon}"
                    ></lds-icon>
                    <strong class="tooltip-menu-item__text">
                        ${this.text}
                    </strong>
                    <small class="tooltip-menu-item__sub-text">
                        ${this.subText}
                    </small>
                </span>
            `;
    }
    return html`
            <a
                class="tooltip-menu-item"
                .href=${this.href}
                data-cy-tooltip-menu-a
            >
                <lds-icon
                    class="tooltip-menu-item__icon"
                    name="${this.icon}"
                ></lds-icon>
                <strong class="tooltip-menu-item__text"> ${this.text} </strong>
                <small class="tooltip-menu-item__sub-text">
                    ${this.subText}
                </small>
            </a>
        `;
  }
}

customElements.define("legl-tooltip-menu-item", LeglTooltipMenuItem);
