import { LitElement, css, html } from "lit";
import "../../../../../../apps/lawyers-contacts/contact-mini-view.js";
import "../../../../../../legl-ui/button";
import "../../../../../../legl-ui/card";
import { neutral } from "../../../../../../legl-ui/lds-colours";
import { formatDate } from "../../../../../../legl-ui/utils";
import { userConfig } from "../../config/user-config.js";

export class LeglContactCreate extends LitElement {
  static get styles() {
    return css`
            .success-section {
                display: grid;
                gap: 12px;
                grid-template:
                    "title title title"
                    "message message message" 80px
                    "button--contact button--engage button--pay"
                    "card card card";
                justify-content: center;
            }
            .success-section__button--contact {
                grid-area: button--contact;
                margin-left: 50px;
            }
            .success-section__button--engage {
                grid-area: button--engage;
            }
            .success-section__button--pay {
                grid-area: button--pay;
                margin-right: 50px;
            }
            .success-section__message {
                align-self: center;
                color: var(--legl-gray-dark);
                font-family: Lato;
                font-size: 16px;
                grid-area: message;
                justify-self: center;
                margin: 0;
                padding: 0 60px;
                text-align: center;
            }
            .success-section__title {
                color: ${neutral["800"]};
                font-size: 20px;
                grid-area: title;
                justify-self: center;
                margin: 0;
            }
            .card {
                display: grid;
                grid-area: card;
                grid-template:
                    "title-contact title-create-at"
                    "value-contact value-create-at";
                margin-top: 20px;
                padding: 38px 28px;
            }
            .card__title {
                color: var(--legl-grey-dark);
                font-size: 12px;
                font-weight: 600;
            }
            .card__value {
                color: var(--legl-grey-dark);
                font-size: 12px;
                font-weight: 400;
            }

            .card__title--contact {
                grid-area: title-contact;
            }
            .card__title--created-at {
                grid-area: title-create-at;
            }
            .card__value--contact {
                grid-area: value-contact;
            }
            .card__value--create-at {
                grid-area: value-create-at;
            }
        `;
  }

  static get properties() {
    return {
      contact: { type: Object },
    };
  }

  get engageUrl() {
    const urlParams = new URLSearchParams();
    urlParams.append("firstName", this.contact.first_name);
    urlParams.append("middleName", this.contact.middle_name);
    urlParams.append("lastName", this.contact.last_name);
    urlParams.append("email", this.contact.email);
    urlParams.append("clientReference", this.contact.client_reference);
    urlParams.append("contactUid", this.contact.uid);
    return `#/drawer/engage-create/?${urlParams.toString()}`;
  }

  get payUrl() {
    const urlParams = new URLSearchParams();
    urlParams.append("firstName", this.contact.first_name);
    urlParams.append("middleName", this.contact.middle_name);
    urlParams.append("lastName", this.contact.last_name);
    urlParams.append("email", this.contact.email);
    urlParams.append("clientReference", this.contact.client_reference);
    urlParams.append("contactUid", this.contact.uid);
    return `#/drawer/pay-create/?${urlParams.toString()}`;
  }

  get successMessage() {
    return html`
            <section class="success-section">
                <h1 class="success-section__title" data-cy-success-title>
                    The contact has been created!
                </h1>
                <p class="success-section__message">
                    You can now find the contact in all search results, and use
                    any of the quick actions you have access to.
                </p>
                <legl-button
                    class="success-section__button--contact"
                    variant="outlined"
                    icon="legl-icon-individual"
                    size="medium"
                    link="/contacts/${this.contact.uid}/overview"
                    >View contact</legl-button
                >
                <legl-button
                    class="success-section__button--engage"
                    data-cy-create-contact-success-engage-button
                    variant="outlined"
                    icon="legl-icon-engage"
                    size="medium"
                    link="${this.engageUrl}"
                    @click="${this.createEngageRequest}"
                    ?disabled=${
                      !userConfig?.permissions?.engage ||
                      !userConfig?.permissions?.create_engage_request
                    }
                    >Engage request</legl-button
                >
                <legl-button
                    class="success-section__button--pay"
                    data-cy-create-contact-success-pay-button
                    variant="outlined"
                    icon="legl-icon-pay-request"
                    size="medium"
                    link="${this.payUrl}"
                    @click="${this.createPayRequest}"
                    ?disabled=${!userConfig?.permissions?.pay}
                    >Pay request</legl-button
                >
                <legl-card class="card">
                    <h3 slot="title" variant="corner">Contact Details</h3>
                    <strong class="card__title card__title--contact"
                        >Contact</strong
                    >
                    <legl-contact-mini-view
                        class="card__value card__value--contact"
                        contact-name="${this.contact?.first_name} ${
                          this.contact?.last_name
                        }"
                        .contactEmail=${this.contact?.email}
                    ></legl-contact-mini-view>
                    <strong class="card__title card__title--created-at"
                        >Created</strong
                    >
                    <p class="card__value card__value--create-at">
                        ${formatDate(this.contact?.created_at, {
                          month: "short",
                          day: "numeric",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: false,
                        })}
                    </p>
                </legl-card>
            </section>
        `;
  }

  get selector() {
    return this.shadowRoot.querySelector("legl-contact-selector");
  }

  submitted(event) {
    this.contact = event.detail;
  }

  render() {
    if (this.contact) {
      return this.successMessage;
    }
    return html`
            <legl-contact-selector
                .createOnly=${true}
                intro-text="Creating a contact enables you to aggregate all the information and interactions you have with an individual in a single place."
                button-text="Create Contact"
                @contact-changed=${this.submitted}
            >
            </legl-contact-selector>
        `;
  }
}

customElements.define("legl-contact-create", LeglContactCreate);
