import { LitElement, css, html, nothing } from "lit";
import "../../../../../../../../../legl-ui/lds-button";
import { LdsModal } from "../../../../../../../../../legl-ui/lds-modal";
import "../../../../../../../../../legl-ui/lds-select";
import { spacing } from "../../../../../../../../../legl-ui/lds-spacing";
import { LdsToast } from "../../../../../../../../../legl-ui/lds-toast";
import { put } from "../../../../../../../../../legl-ui/utils/fetch.js";
import location from "../../../../../../../../../legl-ui/utils/location";

export class PaymentPlanCancellationModal extends LitElement {
  static get styles() {
    return css`
            :host {
                padding: 0 ${spacing.m} ${spacing.m};
            }
            .default-buttons-container {
                display: flex;
                justify-content: flex-end;
                gap: ${spacing.s};
                margin-top: ${spacing.m};
            }

            lds-textarea {
                margin-top: ${spacing.m};
            }
        `;
  }

  static get properties() {
    return {
      uid: { attribute: false },
      setDrawerState: { attribute: false },
      cancellationReason: { state: true },
      showOther: { state: true },
    };
  }

  onClickClose() {
    this.parentElement.onClickClose();
  }

  async onPrimaryActionClick() {
    this.isLoading = true;
    await this.cancelPaymentPlan();
    this.isLoading = false;
    this.onClickClose();
  }

  async cancelPaymentPlan() {
    const currentLocation = location.getCurrentLocation();
    const body = JSON.stringify({
      reason: this.cancellationReason,
    });
    const response = await put(`/api/payment_schedules/${this.uid}/cancel/`, {
      body,
    });
    if (response.ok) {
      const responseJson = await response.json();
      if (this.setDrawerState) {
        this.setDrawerState(responseJson);
      }
      if (currentLocation.indexOf("payment-requests") > -1) {
        window.dispatchEvent(new CustomEvent("reload-payments-table"));
      }
      if (currentLocation.indexOf("contacts") > -1) {
        const contactDetails = document.querySelector("legl-contact-details");
        contactDetails?.getContactInfo();
      }
    } else {
      LdsToast.showError({
        text: "Something went wrong. Please try again later.",
      });
    }
  }

  render() {
    return html`
            <div id="payment-plan-cancellation-modal" class="container">
                <p>
                    Cancelling a payment plan will stop any further instalments
                    from being taken. Any outstanding payment links will also be
                    closed.
                </p>
                <lds-select
                    label="Cancellation reason"
                    @model-value-changed=${(e) => {
                      if (e.target.modelValue === "Other") {
                        this.showOther = true;
                        this.cancellationReason = null;
                      } else {
                        this.showOther = false;
                        this.cancellationReason = e.target.modelValue;
                      }
                    }}
                >
                    <select slot="input">
                        <option value="" disabled selected>
                            Select a reason for cancellation
                        </option>
                        <option value="The client paid in a different way">
                            The client paid in a different way
                        </option>
                        <option value="I was testing this feature">
                            I was testing this feature
                        </option>
                        <option value="I need to recreate this plan">
                            I need to recreate this plan
                        </option>
                        <option value="Other">Other (please specify)</option>
                    </select>
                </lds-select>
                ${
                  this.showOther
                    ? html`<lds-textarea
                          label="Other (please specify)"
                          maxlength="200"
                          @model-value-changed=${(e) =>
                            (this.cancellationReason = e.target.modelValue)}
                      ></lds-textarea>`
                    : nothing
                }
                <div class="default-buttons-container">
                    <lds-button
                        id="lds-modal-close-button"
                        variant="outlined"
                        colour="neutral"
                        @click=${this.onClickClose}
                        ?disabled=${this.isLoading}
                        >Close</lds-button
                    >
                    <lds-button
                        id="lds-modal-primary-button"
                        class="primary-button"
                        variant="contained"
                        colour="danger"
                        @click=${this.onPrimaryActionClick}
                        .isLoading=${this.isLoading}
                        ?disabled=${this.isLoading || !this.cancellationReason}
                        >Yes, cancel</lds-button
                    >
                </div>
            </div>
        `;
  }
}

customElements.define(
  "legl-payment-plan-cancellation-modal",
  PaymentPlanCancellationModal,
);

const createCancellationModal = (uid, setDrawerState) => {
  LdsModal.create({
    title: html`<lds-icon
                name="lds-icon-ErrorFilled"
                colour="danger"
            ></lds-icon>
            Are you sure you want to cancel this payment plan?`,
    slotComponentProperties: {
      uid,
      setDrawerState,
    },
    slotComponent: "legl-payment-plan-cancellation-modal",
    hideCloseBtn: false,
  });
};

export { createCancellationModal };
