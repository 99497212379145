import { LionStep } from "@lion/steps";
import { css, html } from "lit";

export class LeglStep extends LionStep {
  static get properties() {
    return {
      title: {
        type: String,
        attribute: "title",
      },
      subTitle: {
        type: String,
        attribute: "sub-title",
      },
    };
  }
}

customElements.define("legl-step", LeglStep);
