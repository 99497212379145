import getDay from "date-fns/getDay";
import getDaysInMonth from "date-fns/getDaysInMonth";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import isSameDay from "date-fns/isSameDay";
import isToday from "date-fns/isToday";
import startOfMonth from "date-fns/startOfMonth";
import { LitElement, css, html } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { neutral, primary } from "../../lds-colours";

export class LdsDatePickerCalendar extends LitElement {
  static get styles() {
    return css`
            .calendar-month-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .calendar {
                display: grid;
                grid-template-columns: repeat(7, 1fr);
            }
            .day {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 48px;
                height: 48px;
                background-color: transparent;
                border: none;
                cursor: pointer;
                color: ${neutral["800"]};
            }
            .day.no-pointer {
                cursor: default;
            }
            .today {
                border: 2px solid ${neutral["700"]};
                border-radius: 4px;
            }
            .day.hover:hover {
                border-radius: 4px;
                background-color: ${neutral["200"]};
                color: ${neutral["900"]};
            }
            .is-selected-date {
                background-color: ${primary["500"]};
                border: none;
                border-radius: 4px;
                color: #ffffff;
            }
            .is-disabled {
                cursor: no-drop;
                color: ${neutral["300"]};
            }
        `;
  }

  static get properties() {
    return {
      onSelectDay: {
        type: Object,
        attribute: false,
      },
      currentDate: {
        attribute: false,
      },
      date: {
        attribute: false,
      },
      minDate: {
        attribute: false,
      },
      maxDate: {
        attribute: false,
      },
    };
  }

  get previousMonthDays() {
    const firstOfMonth = startOfMonth(this.currentDate);
    const dayOfWeekFirstOfMonth = getDay(firstOfMonth);

    let previousMonthDays = dayOfWeekFirstOfMonth - 1;
    if (dayOfWeekFirstOfMonth === 0) {
      previousMonthDays = 6;
    }
    return previousMonthDays;
  }

  render() {
    return html`<div class="calendar-conatiner">
            <div class="calendar">
                ${["M", "T", "W", "T", "F", "S", "S"].map(
                  (day) => html`<div class="day no-pointer">${day}</div>`,
                )}
                ${[...Array(this.previousMonthDays)].map(
                  () => html`<div class="day no-pointer"></div>`,
                )}
                ${[...Array(getDaysInMonth(this.currentDate, 1))].map(
                  (element, day) => {
                    const dayDate = new Date(
                      getYear(this.currentDate),
                      getMonth(this.currentDate),
                      day + 1,
                    );
                    const isDayToday = isToday(dayDate);
                    const isSelectedDate = isSameDay(dayDate, this.date);
                    const isOutsideMaxDate = isAfter(dayDate, this.maxDate);
                    const isMaxDateOrMinDate =
                      isSameDay(dayDate, this.maxDate) ||
                      isSameDay(dayDate, this.minDate);
                    const isOutsideMinDate = isBefore(dayDate, this.minDate);
                    const isDisabled =
                      (isOutsideMaxDate || isOutsideMinDate) &&
                      !isMaxDateOrMinDate;
                    return html`<button
                            data-testid="data-date-${dayDate.getDate()}"
                            .disabled=${isDisabled}
                            @click=${() => this.onSelectDay(dayDate)}
                            class=${classMap({
                              day: true,
                              hover: !isDisabled && !isSelectedDate,
                              today: isDayToday,
                              "is-selected-date": isSelectedDate,
                              "is-disabled": isDisabled,
                            })}
                        >
                            ${day + 1}
                        </button>`;
                  },
                )}
            </div>
        </div>`;
  }
}

customElements.define("lds-date-picker-calendar", LdsDatePickerCalendar);
