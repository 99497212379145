import { AdyenAdapter } from "../adapters/adyen-adapter";
import { BankedAdapter } from "../adapters/banked-adapter";
import { DummyAdapter } from "../adapters/dummy-adapter";
import { StripeAdapter } from "../adapters/stripe-adapter";

export const PROVIDER_STRIPE = "stripe";
export const PROVIDER_BANKED = "banked";
export const PROVIDER_DUMMY = "dummy";
export const PROVIDER_ADYEN = "adyen";

export const PAYMENT_METHOD_CONFIG = [
  {
    external_provider_key: "banked",
    provider: PROVIDER_BANKED,
    method: "open_banking",
    label: "Pay by bank",
    iconName: "lds-icon-Banked",
    tooltip:
      "Pay via a simple, speedy and secure bank transfer. All you need is a valid UK bank account. It's the quickest way of paying.",
  },
  {
    external_provider_key: "stripe",
    provider: PROVIDER_STRIPE,
    method: "card",
    label: "Pay by card",
    iconName: "lds-icon-CreditCard",
  },
  {
    external_provider_key: "dummy",
    provider: PROVIDER_DUMMY,
    method: "dummy",
    label: "Pay by dummy",
    iconName: "lds-icon-SettingsOutlined",
    tooltip:
      "This is a test payment provider, it should never be seen in production!",
  },
  {
    external_provider_key: "adyen",
    provider: PROVIDER_ADYEN,
    method: "card",
    label: "Pay by card",
    iconName: "lds-icon-CreditCard",
  },
];

export const PAYMENT_ADAPTER_CONFIG = {
  [PROVIDER_STRIPE]: {
    key: "stripe-adapter",
    adapter: StripeAdapter,
  },
  [PROVIDER_BANKED]: {
    key: "banked-adapter",
    adapter: BankedAdapter,
  },
  [PROVIDER_DUMMY]: {
    key: "dummy-adapter",
    adapter: DummyAdapter,
  },
  [PROVIDER_ADYEN]: {
    key: "adyen-adapter",
    adapter: AdyenAdapter,
  },
};
