import { LitElement, html, nothing } from "lit";
import { toastService } from "../../../legl-ui/toast";

import { LeglRequired } from "../../../legl-ui/input";
import "../../../legl-ui/input/legl-select.js";
import { spacing } from "../../../legl-ui/lds-spacing";
import "../../../legl-ui/postcode-lookup";
import countries from "./countryList";
import states from "./stateList";
const addressPartKeys = ["line1", "line2", "line3", "postcode"];

export class PostcodeLookupController extends LitElement {
  static get properties() {
    return {
      ...super.properties,
      country: { type: String },
      line1: { type: String },
      line2: { type: String },
      line3: { type: String },
      state: { type: String },
      postcode: { type: String },
      countries: { type: String },
      foundAddresses: { type: Array },
      addressLookupSkipped: { type: Boolean },
      serverErrorMessages: { type: Object },
      lookupUrl: { type: String, attribute: "lookup-url" },
      errorMessages: { attribute: false },
    };
  }

  constructor() {
    super();
    this.serverErrorMessages = {};
    this.countries = countries;
    this.country = "GBR";
    this.state = "null";
  }

  displayOptions(option) {
    return html` <option name=${option.label} value="${option.value}">${option.label}</option> `;
  }

  get postcodeLookup() {
    return html` <div style="margin-bottom: ${spacing.s}">
            <legl-postcode-lookup
                data-testid="postcode-lookup"
                lookup-url=${this.lookupUrl}
                @lookupLoaded=${(e) => {
                  this.addressLookupSkipped = false;
                  this.foundAddresses = e.detail.addresses;
                  this.requestUpdate();
                }}
                @lookupSkipped=${() => {
                  this.addressLookupSkipped = true;
                  this.foundAddresses = null;
                }}
                @lookupError=${(e) => {
                  toastService.showError(
                    "Something went wrong. Please try again later.",
                  );
                }}
            ></legl-postcode-lookup>
        </div>`;
  }

  handleValueChange() {
    const modal = document.querySelector("legl-form-modal");
    if (modal) modal.dispatchEvent(new CustomEvent("form-has-changed"));
  }

  dispatchCustomEvent() {
    const event = new CustomEvent("address-updated", {
      detail: {
        line1: this.line1?.trim(),
        line2: this.line2?.trim(),
        line3: this.line3?.trim(),
        // there is some weird concatenation in the select implementation which seems to change null to "null"
        // TODO: investigate and fix as it is a bug
        state: this.state === "null" ? null : this.state,
        country: this.country,
        postcode: this.postcode?.trim(),
      },
      bubbles: true, // so it bubbles pass the shadow DOM
      composed: true, // so it bubbles pass the shadow DOM
    });
    this.dispatchEvent(event);
    this.handleValueChange();
  }

  render() {
    return html`
            <div>
                <div>
                    <lds-select
                        label="Country of Residence"
                        style="margin-bottom: ${spacing.s}"
                        name="country"
                        autocomplete="country"
                        .modelValue="${this.country}"
                        @model-value-changed="${(e) => {
                          this.country = e.target.modelValue;
                          this.foundAddresses = null;
                          this.state = null;
                          this.dispatchCustomEvent();
                        }}"
                    >
                        <select slot="input">
                            ${this.countriesForSelectBox.map((option) =>
                              this.displayOptions(option),
                            )}
                        </select>
                    </lds-select>
                    ${this.country === "GBR" ? this.postcodeLookup : nothing}
                </div>
                ${this.addressSelector}
                <div id="manual-address" .hidden=${!this.showAddressParts}>
                    <lds-input
                        style="margin-bottom: ${spacing.s}"
                        label="Address line 1"
                        name="line1"
                        .errorMessage="${this.errorMessages?.line1}"
                        .validators="${this.getValidatorsForField("line1")}"
                        .modelValue="${this.line1}"
                        @model-value-changed="${(e) => {
                          this.line1 = e.target.modelValue;
                          this.dispatchCustomEvent();
                        }}"
                    >
                    </lds-input>
                    <lds-input
                        style="margin-bottom: ${spacing.s}"
                        label="Address line 2"
                        name="line2"
                        .validators="${this.getValidatorsForField("line2")}"
                        .modelValue="${this.line2}"
                        @model-value-changed="${(e) => {
                          this.line2 = e.target.modelValue;
                          this.dispatchCustomEvent();
                        }}"
                    >
                    </lds-input>
                    <div>
                        <div>
                            <lds-input
                                style="margin-bottom: ${spacing.s}"
                                label="Town/City"
                                name="line3"
                                .validators="${this.getValidatorsForField(
                                  "line3",
                                )}"
                                .modelValue="${this.line3}"
                                @model-value-changed="${(e) => {
                                  this.line3 = e.target.modelValue;
                                  this.dispatchCustomEvent();
                                }}"
                            >
                            </lds-input>
                        </div>
                        ${this.stateSelector}
                        <div>
                            <lds-input
                                style="margin-bottom: ${spacing.s}"
                                label="${this.postcodeLabel}"
                                name="postcode"
                                autocomplete="postal-code"
                                .modelValue="${this.postcode}"
                                .validators="${this.getValidatorsForField(
                                  "postcode",
                                )}"
                                .errorMessage="${this.errorMessages?.postcode}"
                                @model-value-changed="${(e) => {
                                  this.postcode = e.target.modelValue;
                                  this.dispatchCustomEvent();
                                }}"
                            >
                            </lds-input>
                        </div>
                    </div>
                </div>
            </div>
        `;
  }

  get postcodeLabel() {
    if (this.country === "USA") {
      return "Zip code";
    }
    return "Postcode";
  }

  get countriesForSelectBox() {
    return JSON.parse(this.countries);
  }

  get usStatesForSelectBox() {
    return [{ label: "Select State", value: null }, ...JSON.parse(states)];
  }

  get stateSelector() {
    return this.country === "USA"
      ? html` <lds-select
                  style="margin-bottom: ${spacing.s}"
                  label="State"
                  name="state"
                  .validators="${this.getValidatorsForField("state")}"
                  .modelValue="${this.state}"
                  @model-value-changed="${(e) => {
                    this.state = e.target.modelValue;
                    this.dispatchCustomEvent();
                  }}"
              >
                  <select slot="input">
                      ${this.usStatesForSelectBox.map((option) =>
                        this.displayOptions(option),
                      )}
                  </select>
              </lds-select>`
      : html`
                  <lds-input
                      style="margin-bottom: ${spacing.s}"
                      label="State/Province"
                      placeholder=""
                      name="state"
                      .validators="${this.getValidatorsForField("state")}"
                      .modelValue="${this.state}"
                      @model-value-changed="${(e) => {
                        this.state = e.target.modelValue;
                        this.dispatchCustomEvent();
                      }}"
                  >
                  </lds-input>
              `;
  }

  get showAddressParts() {
    // Any country other than the UK will not use the address search at this time
    if (this.country !== "GBR") {
      return true;
    }

    // This occurs when the user explicitly selects "Find manually"
    if (this.addressLookupSkipped) {
      return true;
    }

    if (Object.keys(this.serverErrorMessages).length !== 0) {
      return true;
    }

    // If we can't find any matches for their postcode then they'll likely want to enter it manually
    if (this.foundAddresses && this.foundAddresses.length === 0) {
      return true;
    }

    // Otherwise if any address fields are filled out from prior form
    // submission or them selecting an address
    return addressPartKeys.reduce(
      (shouldShow, key) => shouldShow || (this[key] && this[key].length > 0),
      false,
    );
  }

  get addressesFormattedForSelect() {
    if (!this.foundAddresses || this.foundAddresses.length === 0) {
      return [{ label: "0 Results", value: null }];
    } else {
      return [
        { label: "Select an address", value: null },
        ...this.foundAddresses.map((address) => {
          return {
            label: PostcodeLookupController.formatAddress(
              ...Object.values(address),
            ),
            value: JSON.stringify(address),
          };
        }),
      ];
    }
  }

  get addressSelector() {
    if (this.country === "GBR" && this.foundAddresses) {
      return html` <lds-select
                label="Select an address"
                style="margin-bottom: ${spacing.s}"
                .disabled=${this.foundAddresses.length === 0}
                @model-value-changed=${(e) => {
                  if (e.target.modelValue && e.target.modelValue !== "null") {
                    this.setAddress(JSON.parse(e.target.modelValue));
                  } else {
                    this.setAddress({});
                  }
                  this.dispatchCustomEvent();
                }}
            >
                <select slot="input">
                    ${this.addressesFormattedForSelect.map((option) =>
                      this.displayOptions(option),
                    )}
                </select>
            </lds-select>`;
    }
    return "";
  }

  createRenderRoot() {
    return this;
  }

  setAddress(parts) {
    this.line1 = [
      parts.flat_number,
      parts.building_number,
      parts.building_name,
      parts.street,
    ]
      .filter(Boolean)
      .join(", ");
    this.line2 = parts.sub_street || "";
    this.line3 = parts.town || "";
    this.postcode = parts.postcode;
  }

  static formatAddress(...args) {
    return args
      .filter((addressPart) => addressPart && addressPart.toString().length > 0)
      .join(", ");
  }

  getValidatorsForField(fieldName) {
    const validators = {
      country: [new LeglRequired()],
      line1: [],
      line2: [],
      line3: [],
      state: [],
      postcode: [],
    };
    return [...validators[fieldName]];
  }
}

customElements.define(
  "legl-postcode-lookup-controller",
  PostcodeLookupController,
);
