import { LitElement, css, html } from "lit";
import { neutral } from "../../../../../../legl-ui/lds-colours";
import { spacing } from "../../../../../../legl-ui/lds-spacing";
import { typographyPresets } from "../../../../../../legl-ui/lds-typography";
class PhonePaymentEditDetails extends LitElement {
  static get styles() {
    return css`
      .payment-information-container {
        margin-top: ${spacing.s};
        display: flex;
        justify-content: space-between;
        border-radius: 4px;
        border: 1px solid ${neutral["200"]};
        padding: ${spacing.m} ${spacing.s};
      }
      p {
        margin: 0;
      }
      .amount {
        color: ${neutral["800"]};
        ${typographyPresets.bodyBold};
      }
      .detail {
        color: ${neutral["800"]};
        ${typographyPresets.small};
      }
        `;
  }

  static get properties() {
    return {
      phonePaymentDetails: {
        attribute: false,
      },
    };
  }

  get currencySymbol() {
    switch (this.phonePaymentDetails?.currency) {
      case "USD":
        return "$";
      case "EUR":
        return "€";
      default:
        return "£";
    }
  }

  render() {
    return html`<div class="payment-information-container">
    <div>
      <p class="amount">${this.currencySymbol}${this.phonePaymentDetails.amount}</p>
      <p class="detail">To account: ${
        this.phonePaymentDetails.bank_account_name
      }</p>
      <p class="detail">Invoice ref: ${
        this.phonePaymentDetails.invoice_reference || "-"
      }</p>
      <p class="detail">Matter ref: ${
        this.phonePaymentDetails.matter_reference || "-"
      }</p>
    </div>
    <div>
      <lds-button
        colour="primary"
        variant="outlined"
        small
        @click=${() => {
          this.dispatchEvent(new CustomEvent("edit-payment-details"));
        }}
        >Edit information</lds-button
      >
    </div>
  </div>`;
  }
}

customElements.define(
  "legl-phone-payment-edit-details",
  PhonePaymentEditDetails,
);
