import { LitElement, css, html, nothing } from "lit";
import { neutral } from "../../../../../../legl-ui/lds-colours";
import { LdsToast } from "../../../../../../legl-ui/lds-toast";
import { post } from "../../../../../../legl-ui/utils/fetch";

export class LeglFeedback extends LitElement {
  static get styles() {
    return css`
            :host {
                text-transform: initial;
            }

            .container {
                background: #dee5fe;
                color: ${neutral["900"]};
                display: flex;
                padding: 24px;
                border-radius: 0 0 4px 4px;
                flex-direction: column;
            }

            .form-container {
                display: flex;
                flex-direction: column;
                gap: 16px;
                margin-top: 16px;
            }

            h3 {
                margin: 0;
            }

            div[data-target="form-content"] {
                overflow-y: hidden;
                max-height: 300px; /* approximate max height */
                padding: 0 2px;

                transition-property: all;
                transition-duration: 0.5s;
                transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
            }

            div[data-target="form-content"].closed {
                max-height: 0;
                margin-top: 0;
            }

            .button-row {
                display: flex;
                flex-direction: row;
                margin-right: auto;
                gap: 6px;
            }

            .button-row lds-button {
                width: 108px;
                box-sizing: border-box;
            }

            lds-button {
                display: flex;
                margin-left: auto;
            }
        `;
  }

  static get properties() {
    return {
      header: {
        type: String,
      },
      submitted: {
        type: Boolean,
      },
      data: {
        type: Object,
      },
      request_data: {
        type: Object,
      },
      diplaySubmitSection: {
        type: Boolean,
        state: true,
      },
    };
  }

  constructor() {
    super();
    this.header = "Was this summary helpful?";
    this.submitted = false;
    this.data = {
      sentiment: null,
      comment: null,
    };
    this.diplaySubmitSection = false;
  }

  updateValues(key, value) {
    this.data = {
      ...this.data,
      [key]: value,
    };
  }

  async onSubmitFunc() {
    const res = await post("/api/legl_assist_feedback", {
      body: JSON.stringify({
        ...this.data,
        ...this.request_data,
      }),
    });

    if (res.ok) {
      const formContent = this.shadowRoot?.querySelector(
        "[data-target='form-content']",
      );
      formContent?.classList.add("closed");
      this.submitted = true;
    } else {
      LdsToast.showError({
        title: "Unable to submit feedback. Please try again later.",
        autoClose: true,
      });
      this.submitted = false;
    }
  }

  getVariant(value) {
    if (this.data?.sentiment === value) {
      return "contained";
    }

    return "outlined";
  }

  renderSubmitSection() {
    return html` <div>
            <lds-textarea
                id="feedback"
                label="Additional feedback"
                optional="true"
                placeholder="Please tell us anything that may help us improve this feature for you"
                .modelValue=${null}
                @model-value-changed=${(e) => {
                  this.updateValues("comment", e.target?.modelValue?.trim());
                }}
            ></lds-textarea>
        </div>`;
  }

  render() {
    return html`
            <div class="container">
                <h3>
                    ${
                      this.submitted
                        ? "Thank you for your feedback!"
                        : this.header
                    }
                </h3>
                <div data-target="form-content" class="form-container">
                    <div class="button-row">
                        <lds-button
                            variant=${this.getVariant("positive")}
                            colour="neutral"
                            id="positive-feedback"
                            type="button"
                            icon="lds-icon-ThumbUp"
                            ?small=${false}
                            @click=${() => {
                              this.updateValues("sentiment", "positive");
                              this.diplaySubmitSection = true;
                            }}
                            >Yes</lds-button
                        >
                        <lds-button
                            variant=${this.getVariant("negative")}
                            colour="neutral"
                            id="negative-feedback"
                            type="button"
                            ?small=${false}
                            icon="lds-icon-ThumbDown"
                            @click=${() => {
                              this.updateValues("sentiment", "negative");
                              this.diplaySubmitSection = true;
                            }}
                            >No</lds-button
                        >
                    </div>
                    ${
                      this.diplaySubmitSection
                        ? this.renderSubmitSection()
                        : nothing
                    }
                    <lds-button
                        variant="success"
                        id="submit-feedback"
                        data-cy="submit-feedback"
                        colour="primary"
                        type="button"
                        .disabled=${this.submitted || !this.data?.sentiment}
                        @click=${() => this.onSubmitFunc()}
                        >Submit feedback</lds-button
                    >
                </div>
            </div>
        `;
  }
}

customElements.define("legl-feedback", LeglFeedback);
