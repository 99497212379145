import { LitElement, css, html, nothing } from "lit";
import {
  StripeWrapper,
  StripeWrapperError,
} from "../../../../../..//services/stripe-wrapper";
import { neutral } from "../../../../../../legl-ui/lds-colours";
import { spacing } from "../../../../../../legl-ui/lds-spacing";
import { LdsToast } from "../../../../../../legl-ui/lds-toast";
import { typographyPresets } from "../../../../../../legl-ui/lds-typography";
import { post } from "../../../../../../legl-ui/utils/fetch";
import { userConfig } from "../../config/user-config";
class PhonePaymentStripeForm extends LitElement {
  static get styles() {
    return css`
            :host,
            :host * {
                box-sizing: border-box;
            }
            .stripe-elements {
                display: block;
                width: 100%;
                height: 50px;
                padding: 1rem 0 2rem 1rem;
                border-radius: 6px;
                border: 1px solid var(--legl-grey-borders);
                margin-bottom: 1em;
            }
            h3 {
              ${typographyPresets.h3};
              color: ${neutral["800"]};
              margin: ${spacing.s} 0;
            }
            p {
              ${typographyPresets.body};
              color: ${neutral["700"]};
            }
            .body-container {
              padding: ${spacing.s};
              background-color: ${neutral["100"]};
              border-radius: 4px;
              margin-bottom: ${spacing.s};
            }
            .pay-button-container {
                display: flex;
                justify-content: flex-end;
            }
        `;
  }
  static get properties() {
    return {
      contactUid: {
        type: String,
        attribute: "contact-uid",
      },
      phonePaymentDetails: {
        type: Object,
        attribute: "phone-payment-details",
      },
      acceptHigherFeeCards: {
        type: Boolean,
        attribute: "accept-higher-fee-cards",
      },
      stripeWrapper: {
        attribute: false,
      },
      isStripeComplete: {
        attribute: false,
      },
      stripeError: {
        attribute: false,
      },
      isConfirmingPayment: {
        state: true,
      },
    };
  }

  async initStripe() {
    if (!this.stripeWrapper) {
      const element = document.createElement("div");
      element.id = "stripe-card";
      element.slot = "stripe";
      this.appendChild(element);

      this.stripeWrapper = new StripeWrapper(
        userConfig.stripeKey,
        this.acceptHigherFeeCards,
      );
      this.stripeWrapper.initElement(element);

      this.stripeWrapper.card.addEventListener("change", (e) => {
        this.onStripeChange(e?.error?.message, e.complete);
      });
    }
  }

  onStripeChange(errorMessage, complete = false) {
    this.stripeError = errorMessage;
    this.isStripeComplete = complete;
  }

  dispatchPhonePaymentComplete() {
    this.dispatchEvent(
      new CustomEvent("phone-payment-complete", {
        bubbles: true,
        composed: true,
      }),
    );
  }

  async onPaymentSubmit(e) {
    e.preventDefault();
    if (this.stripeWrapper && !this.isConfirmingPayment) {
      this.isConfirmingPayment = true;
      const spinner = document.createElement("legl-fullscreen-loading-spinner");
      document.body.appendChild(spinner);
      try {
        const results = await this.stripeWrapper.createPaymentMethod({
          name: `${this.phonePaymentDetails.first_name} ${this.phonePaymentDetails.last_name}`,
        });

        const body = JSON.stringify({
          payment_method_id: results.paymentMethod.id,
          contact_uid: this.contactUid,
        });
        const res = await post(this.phonePaymentDetails.checkout_url, {
          body,
        });
        if (res.ok) {
          this.dispatchPhonePaymentComplete();
        } else {
          const body = await res.json();
          if (body.error && body.message) {
            LdsToast.showError({ text: body.message });
          } else {
            throw new Error(res.status);
          }
        }
      } catch (err) {
        if (!(err instanceof StripeWrapperError)) {
          LdsToast.showError({
            text: "There was an error submitting your payment. Please try again later.",
          });
        }
      } finally {
        this.isConfirmingPayment = false;
        spinner.parentElement.removeChild(spinner);
        window.dispatchEvent(new CustomEvent("reload-payments-table"));
      }
    }
  }

  onClose() {
    this.dispatchEvent(
      new CustomEvent("close", { bubbles: true, composed: true }),
    );
  }

  get stripeErrorTemplate() {
    if (this.stripeError) {
      return html`<p class="error">${this.stripeError}</p>`;
    }
    return nothing;
  }

  constructor() {
    super();
    this.isStripeComplete = false;
    this.acceptHigherFeeCards = false;
    this.isConfirmingPayment = false;
  }

  firstUpdated(properties) {
    super.firstUpdated(properties);
    this.initStripe();
  }

  get contactEmail() {
    if (!this.phonePaymentDetails?.email) {
      return "-";
    }
    return this.phonePaymentDetails.email;
  }

  get currencySymbol() {
    switch (this.phonePaymentDetails?.currency) {
      case "USD":
        return "$";
      case "EUR":
        return "€";
      default:
        return "£";
    }
  }

  render() {
    return html`
            <h3>Suggested script for completing phone payment:</h3>
            <div class="body-container">
              <p>
                  Your payment will be processed by Legl (L-E-G-L) who are our
                  selected payment processing partner and comply with the
                  standards of the FCA and PCI.
              </p>
              <p>
                  Legl’s full terms of use and privacy policy can be found on
                  their website www.legl.com.
              </p>
  
              <p>
                  You will see the charge on your statement appear as
                  LEGL*${userConfig.companySlug}.
              </p>
  
              <p>
                  Please confirm that you are happy for me to process the payment
                  for
                  <legl-currency .value=${this?.phonePaymentDetails?.amount}>
                      }</legl-currency
                  >.
              </p>
  
              <p>
                  If you have any questions about this payment, please contact us
                  directly - you will find our contact details on all letters
                  received from us and in your client care pack.
              </p>
            </div>
            <form @submit="${this.onPaymentSubmit}">
                <div class="stripe-elements">
                    <slot name="stripe"></slot>
                </div>
                ${this.stripeErrorTemplate}
                <div class="pay-button-container">
                  <lds-button
                    colour="primary"
                    icon="lds-icon-LockFilled"
                    data-confirm-card-payment-button
                    .disabled=${
                      !this.isStripeComplete || this.isConfirmingPayment
                    }
                  >
                    ${this.currencySymbol}${this.phonePaymentDetails?.amount}</lds-button>
                </div>
            </form>
            `;
  }
}

customElements.define("legl-phone-payment-stripe-form", PhonePaymentStripeForm);
