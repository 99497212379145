export const countryList = [
  {
    name: "Afghanistan",
    alpha3: "AFG",
    region: "Asia",
    subregion: "Southern Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Albania",
    alpha3: "ALB",
    region: "Europe",
    subregion: "Southern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Algeria",
    alpha3: "DZA",
    region: "Africa",
    subregion: "Northern Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "American Samoa",
    alpha3: "ASM",
    region: "Oceania",
    subregion: "Polynesia",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Andorra",
    alpha3: "AND",
    region: "Europe",
    subregion: "Southern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Angola",
    alpha3: "AGO",
    region: "Africa",
    subregion: "Middle Africa",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Anguilla",
    alpha3: "AIA",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Antarctica",
    alpha3: "ATA",
    region: "",
    subregion: "",
    supported_identity_report: false,
    supported_document_report: false,
  },
  {
    name: "Antigua and Barbuda",
    alpha3: "ATG",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Argentina",
    alpha3: "ARG",
    region: "Americas",
    subregion: "South America",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Armenia",
    alpha3: "ARM",
    region: "Asia",
    subregion: "Western Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Aruba",
    alpha3: "ABW",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Australia",
    alpha3: "AUS",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Austria",
    alpha3: "AUT",
    region: "Europe",
    subregion: "Western Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Azerbaijan",
    alpha3: "AZE",
    region: "Asia",
    subregion: "Western Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Bahamas",
    alpha3: "BHS",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Bahrain",
    alpha3: "BHR",
    region: "Asia",
    subregion: "Western Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Bangladesh",
    alpha3: "BGD",
    region: "Asia",
    subregion: "Southern Asia",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Barbados",
    alpha3: "BRB",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Belarus",
    alpha3: "BLR",
    region: "Europe",
    subregion: "Eastern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Belgium",
    alpha3: "BEL",
    region: "Europe",
    subregion: "Western Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Belize",
    alpha3: "BLZ",
    region: "Americas",
    subregion: "Central America",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Benin",
    alpha3: "BEN",
    region: "Africa",
    subregion: "Western Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Bermuda",
    alpha3: "BMU",
    region: "Americas",
    subregion: "Northern America",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Bhutan",
    alpha3: "BTN",
    region: "Asia",
    subregion: "Southern Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Bolivia (Plurinational State of)",
    alpha3: "BOL",
    region: "Americas",
    subregion: "South America",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    alpha3: "BES",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: false,
    supported_document_report: false,
  },
  {
    name: "Bosnia and Herzegovina",
    alpha3: "BIH",
    region: "Europe",
    subregion: "Southern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Botswana",
    alpha3: "BWA",
    region: "Africa",
    subregion: "Southern Africa",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Bouvet Island",
    alpha3: "BVT",
    region: "",
    subregion: "",
    supported_identity_report: false,
    supported_document_report: false,
  },
  {
    name: "Brazil",
    alpha3: "BRA",
    region: "Americas",
    subregion: "South America",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "British Indian Ocean Territory",
    alpha3: "IOT",
    region: "Africa",
    subregion: "Eastern Africa",
    supported_identity_report: false,
    supported_document_report: false,
  },
  {
    name: "Brunei Darussalam",
    alpha3: "BRN",
    region: "Asia",
    subregion: "South-Eastern Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Bulgaria",
    alpha3: "BGR",
    region: "Europe",
    subregion: "Eastern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Burkina Faso",
    alpha3: "BFA",
    region: "Africa",
    subregion: "Western Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Burundi",
    alpha3: "BDI",
    region: "Africa",
    subregion: "Eastern Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Cabo Verde",
    alpha3: "CPV",
    region: "Africa",
    subregion: "Western Africa",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Cambodia",
    alpha3: "KHM",
    region: "Asia",
    subregion: "South-Eastern Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Cameroon",
    alpha3: "CMR",
    region: "Africa",
    subregion: "Middle Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Canada",
    alpha3: "CAN",
    region: "Americas",
    subregion: "Northern America",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Cayman Islands",
    alpha3: "CYM",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Central African Republic",
    alpha3: "CAF",
    region: "Africa",
    subregion: "Middle Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Chad",
    alpha3: "TCD",
    region: "Africa",
    subregion: "Middle Africa",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Chile",
    alpha3: "CHL",
    region: "Americas",
    subregion: "South America",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "China",
    alpha3: "CHN",
    region: "Asia",
    subregion: "Eastern Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Christmas Island",
    alpha3: "CXR",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Cocos (Keeling) Islands",
    alpha3: "CCK",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Colombia",
    alpha3: "COL",
    region: "Americas",
    subregion: "South America",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Comoros",
    alpha3: "COM",
    region: "Africa",
    subregion: "Eastern Africa",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Congo",
    alpha3: "COG",
    region: "Africa",
    subregion: "Middle Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Congo (Democratic Republic of the)",
    alpha3: "COD",
    region: "Africa",
    subregion: "Middle Africa",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Cook Islands",
    alpha3: "COK",
    region: "Oceania",
    subregion: "Polynesia",
    supported_identity_report: true,
    supported_document_report: false,
  },
  {
    name: "Costa Rica",
    alpha3: "CRI",
    region: "Americas",
    subregion: "Central America",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Croatia",
    alpha3: "HRV",
    region: "Europe",
    subregion: "Southern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Cuba",
    alpha3: "CUB",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Curaçao",
    alpha3: "CUW",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Cyprus",
    alpha3: "CYP",
    region: "Asia",
    subregion: "Western Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Czech Republic",
    alpha3: "CZE",
    region: "Europe",
    subregion: "Eastern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Côte d'Ivoire",
    alpha3: "CIV",
    region: "Africa",
    subregion: "Western Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Denmark",
    alpha3: "DNK",
    region: "Europe",
    subregion: "Northern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Djibouti",
    alpha3: "DJI",
    region: "Africa",
    subregion: "Eastern Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Dominica",
    alpha3: "DMA",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Dominican Republic",
    alpha3: "DOM",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Ecuador",
    alpha3: "ECU",
    region: "Americas",
    subregion: "South America",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Egypt",
    alpha3: "EGY",
    region: "Africa",
    subregion: "Northern Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "El Salvador",
    alpha3: "SLV",
    region: "Americas",
    subregion: "Central America",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Equatorial Guinea",
    alpha3: "GNQ",
    region: "Africa",
    subregion: "Middle Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Eritrea",
    alpha3: "ERI",
    region: "Africa",
    subregion: "Eastern Africa",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Estonia",
    alpha3: "EST",
    region: "Europe",
    subregion: "Northern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Ethiopia",
    alpha3: "ETH",
    region: "Africa",
    subregion: "Eastern Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Falkland Islands (Malvinas)",
    alpha3: "FLK",
    region: "Americas",
    subregion: "South America",
    supported_identity_report: false,
    supported_document_report: false,
  },
  {
    name: "Faroe Islands",
    alpha3: "FRO",
    region: "Europe",
    subregion: "Northern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Fiji",
    alpha3: "FJI",
    region: "Oceania",
    subregion: "Melanesia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Finland",
    alpha3: "FIN",
    region: "Europe",
    subregion: "Northern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "France",
    alpha3: "FRA",
    region: "Europe",
    subregion: "Western Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "French Guiana",
    alpha3: "GUF",
    region: "Americas",
    subregion: "South America",
    supported_identity_report: false,
    supported_document_report: false,
  },
  {
    name: "French Polynesia",
    alpha3: "PYF",
    region: "Oceania",
    subregion: "Polynesia",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "French Southern Territories",
    alpha3: "ATF",
    region: "",
    subregion: "",
    supported_identity_report: false,
    supported_document_report: false,
  },
  {
    name: "Gabon",
    alpha3: "GAB",
    region: "Africa",
    subregion: "Middle Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Gambia",
    alpha3: "GMB",
    region: "Africa",
    subregion: "Western Africa",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Georgia",
    alpha3: "GEO",
    region: "Asia",
    subregion: "Western Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Germany",
    alpha3: "DEU",
    region: "Europe",
    subregion: "Western Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Ghana",
    alpha3: "GHA",
    region: "Africa",
    subregion: "Western Africa",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Gibraltar",
    alpha3: "GIB",
    region: "Europe",
    subregion: "Southern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Greece",
    alpha3: "GRC",
    region: "Europe",
    subregion: "Southern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Greenland",
    alpha3: "GRL",
    region: "Americas",
    subregion: "Northern America",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Grenada",
    alpha3: "GRD",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Guadeloupe",
    alpha3: "GLP",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: false,
    supported_document_report: false,
  },
  {
    name: "Guam",
    alpha3: "GUM",
    region: "Oceania",
    subregion: "Micronesia",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Guatemala",
    alpha3: "GTM",
    region: "Americas",
    subregion: "Central America",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Guernsey",
    alpha3: "GGY",
    region: "Europe",
    subregion: "Northern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Guinea",
    alpha3: "GIN",
    region: "Africa",
    subregion: "Western Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Guinea-Bissau",
    alpha3: "GNB",
    region: "Africa",
    subregion: "Western Africa",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Guyana",
    alpha3: "GUY",
    region: "Americas",
    subregion: "South America",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Haiti",
    alpha3: "HTI",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Heard Island and McDonald Islands",
    alpha3: "HMD",
    region: "",
    subregion: "",
    supported_identity_report: false,
    supported_document_report: false,
  },
  {
    name: "Holy See",
    alpha3: "VAT",
    region: "Europe",
    subregion: "Southern Europe",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Honduras",
    alpha3: "HND",
    region: "Americas",
    subregion: "Central America",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Hong Kong",
    alpha3: "HKG",
    region: "Asia",
    subregion: "Eastern Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Hungary",
    alpha3: "HUN",
    region: "Europe",
    subregion: "Eastern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Iceland",
    alpha3: "ISL",
    region: "Europe",
    subregion: "Northern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "India",
    alpha3: "IND",
    region: "Asia",
    subregion: "Southern Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Indonesia",
    alpha3: "IDN",
    region: "Asia",
    subregion: "South-Eastern Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Iran (Islamic Republic of)",
    alpha3: "IRN",
    region: "Asia",
    subregion: "Southern Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Iraq",
    alpha3: "IRQ",
    region: "Asia",
    subregion: "Western Asia",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Ireland",
    alpha3: "IRL",
    region: "Europe",
    subregion: "Northern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Isle of Man",
    alpha3: "IMN",
    region: "Europe",
    subregion: "Northern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Israel",
    alpha3: "ISR",
    region: "Asia",
    subregion: "Western Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Italy",
    alpha3: "ITA",
    region: "Europe",
    subregion: "Southern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Jamaica",
    alpha3: "JAM",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Japan",
    alpha3: "JPN",
    region: "Asia",
    subregion: "Eastern Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Jersey",
    alpha3: "JEY",
    region: "Europe",
    subregion: "Northern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Jordan",
    alpha3: "JOR",
    region: "Asia",
    subregion: "Western Asia",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Kazakhstan",
    alpha3: "KAZ",
    region: "Asia",
    subregion: "Central Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Kenya",
    alpha3: "KEN",
    region: "Africa",
    subregion: "Eastern Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Kiribati",
    alpha3: "KIR",
    region: "Oceania",
    subregion: "Micronesia",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Korea (Democratic People's Republic of)",
    alpha3: "PRK",
    region: "Asia",
    subregion: "Eastern Asia",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Korea (Republic of)",
    alpha3: "KOR",
    region: "Asia",
    subregion: "Eastern Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Kuwait",
    alpha3: "KWT",
    region: "Asia",
    subregion: "Western Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Kyrgyzstan",
    alpha3: "KGZ",
    region: "Asia",
    subregion: "Central Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Lao People's Democratic Republic",
    alpha3: "LAO",
    region: "Asia",
    subregion: "South-Eastern Asia",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Latvia",
    alpha3: "LVA",
    region: "Europe",
    subregion: "Northern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Lebanon",
    alpha3: "LBN",
    region: "Asia",
    subregion: "Western Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Lesotho",
    alpha3: "LSO",
    region: "Africa",
    subregion: "Southern Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Liberia",
    alpha3: "LBR",
    region: "Africa",
    subregion: "Western Africa",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Libya",
    alpha3: "LBY",
    region: "Africa",
    subregion: "Northern Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Liechtenstein",
    alpha3: "LIE",
    region: "Europe",
    subregion: "Western Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Lithuania",
    alpha3: "LTU",
    region: "Europe",
    subregion: "Northern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Luxembourg",
    alpha3: "LUX",
    region: "Europe",
    subregion: "Western Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Macao",
    alpha3: "MAC",
    region: "Asia",
    subregion: "Eastern Asia",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Madagascar",
    alpha3: "MDG",
    region: "Africa",
    subregion: "Eastern Africa",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Malawi",
    alpha3: "MWI",
    region: "Africa",
    subregion: "Eastern Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Malaysia",
    alpha3: "MYS",
    region: "Asia",
    subregion: "South-Eastern Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Maldives",
    alpha3: "MDV",
    region: "Asia",
    subregion: "Southern Asia",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Mali",
    alpha3: "MLI",
    region: "Africa",
    subregion: "Western Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Malta",
    alpha3: "MLT",
    region: "Europe",
    subregion: "Southern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Marshall Islands",
    alpha3: "MHL",
    region: "Oceania",
    subregion: "Micronesia",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Martinique",
    alpha3: "MTQ",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: false,
    supported_document_report: false,
  },
  {
    name: "Mauritania",
    alpha3: "MRT",
    region: "Africa",
    subregion: "Western Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Mauritius",
    alpha3: "MUS",
    region: "Africa",
    subregion: "Eastern Africa",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Mayotte",
    alpha3: "MYT",
    region: "Africa",
    subregion: "Eastern Africa",
    supported_identity_report: false,
    supported_document_report: false,
  },
  {
    name: "Mexico",
    alpha3: "MEX",
    region: "Americas",
    subregion: "Central America",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Micronesia (Federated States of)",
    alpha3: "FSM",
    region: "Oceania",
    subregion: "Micronesia",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Moldova (Republic of)",
    alpha3: "MDA",
    region: "Europe",
    subregion: "Eastern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Monaco",
    alpha3: "MCO",
    region: "Europe",
    subregion: "Western Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Mongolia",
    alpha3: "MNG",
    region: "Asia",
    subregion: "Eastern Asia",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Montenegro",
    alpha3: "MNE",
    region: "Europe",
    subregion: "Southern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Montserrat",
    alpha3: "MSR",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Morocco",
    alpha3: "MAR",
    region: "Africa",
    subregion: "Northern Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Mozambique",
    alpha3: "MOZ",
    region: "Africa",
    subregion: "Eastern Africa",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Myanmar",
    alpha3: "MMR",
    region: "Asia",
    subregion: "South-Eastern Asia",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Namibia",
    alpha3: "NAM",
    region: "Africa",
    subregion: "Southern Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Nauru",
    alpha3: "NRU",
    region: "Oceania",
    subregion: "Micronesia",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Nepal",
    alpha3: "NPL",
    region: "Asia",
    subregion: "Southern Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Netherlands",
    alpha3: "NLD",
    region: "Europe",
    subregion: "Western Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "New Caledonia",
    alpha3: "NCL",
    region: "Oceania",
    subregion: "Melanesia",
    supported_identity_report: false,
    supported_document_report: false,
  },
  {
    name: "New Zealand",
    alpha3: "NZL",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Nicaragua",
    alpha3: "NIC",
    region: "Americas",
    subregion: "Central America",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Niger",
    alpha3: "NER",
    region: "Africa",
    subregion: "Western Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Nigeria",
    alpha3: "NGA",
    region: "Africa",
    subregion: "Western Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Niue",
    alpha3: "NIU",
    region: "Oceania",
    subregion: "Polynesia",
    supported_identity_report: false,
    supported_document_report: false,
  },
  {
    name: "Norfolk Island",
    alpha3: "NFK",
    region: "Oceania",
    subregion: "Australia and New Zealand",
    supported_identity_report: true,
    supported_document_report: false,
  },
  {
    name: "North Macedonia",
    alpha3: "MKD",
    region: "Europe",
    subregion: "Southern Europe",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Northern Mariana Islands",
    alpha3: "MNP",
    region: "Oceania",
    subregion: "Micronesia",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Norway",
    alpha3: "NOR",
    region: "Europe",
    subregion: "Northern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Oman",
    alpha3: "OMN",
    region: "Asia",
    subregion: "Western Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Pakistan",
    alpha3: "PAK",
    region: "Asia",
    subregion: "Southern Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Palau",
    alpha3: "PLW",
    region: "Oceania",
    subregion: "Micronesia",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Palestine, State of",
    alpha3: "PSE",
    region: "Asia",
    subregion: "Western Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Panama",
    alpha3: "PAN",
    region: "Americas",
    subregion: "Central America",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Papua New Guinea",
    alpha3: "PNG",
    region: "Oceania",
    subregion: "Melanesia",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Paraguay",
    alpha3: "PRY",
    region: "Americas",
    subregion: "South America",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Peru",
    alpha3: "PER",
    region: "Americas",
    subregion: "South America",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Philippines",
    alpha3: "PHL",
    region: "Asia",
    subregion: "South-Eastern Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Pitcairn",
    alpha3: "PCN",
    region: "Oceania",
    subregion: "Polynesia",
    supported_identity_report: false,
    supported_document_report: false,
  },
  {
    name: "Poland",
    alpha3: "POL",
    region: "Europe",
    subregion: "Eastern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Portugal",
    alpha3: "PRT",
    region: "Europe",
    subregion: "Southern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Puerto Rico",
    alpha3: "PRI",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Qatar",
    alpha3: "QAT",
    region: "Asia",
    subregion: "Western Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Romania",
    alpha3: "ROU",
    region: "Europe",
    subregion: "Eastern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Russian Federation",
    alpha3: "RUS",
    region: "Europe",
    subregion: "Eastern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Rwanda",
    alpha3: "RWA",
    region: "Africa",
    subregion: "Eastern Africa",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Réunion",
    alpha3: "REU",
    region: "Africa",
    subregion: "Eastern Africa",
    supported_identity_report: false,
    supported_document_report: false,
  },
  {
    name: "Saint Barthélemy",
    alpha3: "BLM",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    alpha3: "SHN",
    region: "Africa",
    subregion: "Western Africa",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Saint Kitts and Nevis",
    alpha3: "KNA",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Saint Lucia",
    alpha3: "LCA",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Saint Martin (French part)",
    alpha3: "MAF",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Saint Pierre and Miquelon",
    alpha3: "SPM",
    region: "Americas",
    subregion: "Northern America",
    supported_identity_report: false,
    supported_document_report: false,
  },
  {
    name: "Saint Vincent and the Grenadines",
    alpha3: "VCT",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Samoa",
    alpha3: "WSM",
    region: "Oceania",
    subregion: "Polynesia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "San Marino",
    alpha3: "SMR",
    region: "Europe",
    subregion: "Southern Europe",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Sao Tome and Principe",
    alpha3: "STP",
    region: "Africa",
    subregion: "Middle Africa",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Saudi Arabia",
    alpha3: "SAU",
    region: "Asia",
    subregion: "Western Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Senegal",
    alpha3: "SEN",
    region: "Africa",
    subregion: "Western Africa",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Serbia",
    alpha3: "SRB",
    region: "Europe",
    subregion: "Southern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Seychelles",
    alpha3: "SYC",
    region: "Africa",
    subregion: "Eastern Africa",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Sierra Leone",
    alpha3: "SLE",
    region: "Africa",
    subregion: "Western Africa",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Singapore",
    alpha3: "SGP",
    region: "Asia",
    subregion: "South-Eastern Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Sint Maarten (Dutch part)",
    alpha3: "SXM",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Slovakia",
    alpha3: "SVK",
    region: "Europe",
    subregion: "Eastern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Slovenia",
    alpha3: "SVN",
    region: "Europe",
    subregion: "Southern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Solomon Islands",
    alpha3: "SLB",
    region: "Oceania",
    subregion: "Melanesia",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Somalia",
    alpha3: "SOM",
    region: "Africa",
    subregion: "Eastern Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "South Africa",
    alpha3: "ZAF",
    region: "Africa",
    subregion: "Southern Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    alpha3: "SGS",
    region: "Americas",
    subregion: "South America",
    supported_identity_report: false,
    supported_document_report: false,
  },
  {
    name: "South Sudan",
    alpha3: "SSD",
    region: "Africa",
    subregion: "Northern Africa",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Spain",
    alpha3: "ESP",
    region: "Europe",
    subregion: "Southern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Sri Lanka",
    alpha3: "LKA",
    region: "Asia",
    subregion: "Southern Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Sudan",
    alpha3: "SDN",
    region: "Africa",
    subregion: "Northern Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Suriname",
    alpha3: "SUR",
    region: "Americas",
    subregion: "South America",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Svalbard and Jan Mayen",
    alpha3: "SJM",
    region: "Europe",
    subregion: "Northern Europe",
    supported_identity_report: false,
    supported_document_report: false,
  },
  {
    name: "Sweden",
    alpha3: "SWE",
    region: "Europe",
    subregion: "Northern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Switzerland",
    alpha3: "CHE",
    region: "Europe",
    subregion: "Western Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Syrian Arab Republic",
    alpha3: "SYR",
    region: "Asia",
    subregion: "Western Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Taiwan, Province of China",
    alpha3: "TWN",
    region: "Asia",
    subregion: "Eastern Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Tajikistan",
    alpha3: "TJK",
    region: "Asia",
    subregion: "Central Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Tanzania, United Republic of",
    alpha3: "TZA",
    region: "Africa",
    subregion: "Eastern Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Thailand",
    alpha3: "THA",
    region: "Asia",
    subregion: "South-Eastern Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Timor-Leste",
    alpha3: "TLS",
    region: "Asia",
    subregion: "South-Eastern Asia",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Togo",
    alpha3: "TGO",
    region: "Africa",
    subregion: "Western Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Tokelau",
    alpha3: "TKL",
    region: "Oceania",
    subregion: "Polynesia",
    supported_identity_report: false,
    supported_document_report: false,
  },
  {
    name: "Tonga",
    alpha3: "TON",
    region: "Oceania",
    subregion: "Polynesia",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Trinidad and Tobago",
    alpha3: "TTO",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Tunisia",
    alpha3: "TUN",
    region: "Africa",
    subregion: "Northern Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Turkey",
    alpha3: "TUR",
    region: "Asia",
    subregion: "Western Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Turkmenistan",
    alpha3: "TKM",
    region: "Asia",
    subregion: "Central Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Turks and Caicos Islands",
    alpha3: "TCA",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Tuvalu",
    alpha3: "TUV",
    region: "Oceania",
    subregion: "Polynesia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Uganda",
    alpha3: "UGA",
    region: "Africa",
    subregion: "Eastern Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Ukraine",
    alpha3: "UKR",
    region: "Europe",
    subregion: "Eastern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "United Arab Emirates",
    alpha3: "ARE",
    region: "Asia",
    subregion: "Western Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "United Kingdom of Great Britain and Northern Ireland",
    alpha3: "GBR",
    region: "Europe",
    subregion: "Northern Europe",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "United States Minor Outlying Islands",
    alpha3: "UMI",
    region: "Americas",
    subregion: "Northern America",
    supported_identity_report: false,
    supported_document_report: false,
  },
  {
    name: "United States of America",
    alpha3: "USA",
    region: "Americas",
    subregion: "Northern America",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Uruguay",
    alpha3: "URY",
    region: "Americas",
    subregion: "South America",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Uzbekistan",
    alpha3: "UZB",
    region: "Asia",
    subregion: "Central Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Vanuatu",
    alpha3: "VUT",
    region: "Oceania",
    subregion: "Melanesia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Venezuela (Bolivarian Republic of)",
    alpha3: "VEN",
    region: "Americas",
    subregion: "South America",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Viet Nam",
    alpha3: "VNM",
    region: "Asia",
    subregion: "South-Eastern Asia",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Virgin Islands (British)",
    alpha3: "VGB",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Virgin Islands (U.S.)",
    alpha3: "VIR",
    region: "Americas",
    subregion: "Caribbean",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Wallis and Futuna",
    alpha3: "WLF",
    region: "Oceania",
    subregion: "Polynesia",
    supported_identity_report: false,
    supported_document_report: false,
  },
  {
    name: "Western Sahara",
    alpha3: "ESH",
    region: "Africa",
    subregion: "Northern Africa",
    supported_identity_report: false,
    supported_document_report: false,
  },
  {
    name: "Yemen",
    alpha3: "YEM",
    region: "Asia",
    subregion: "Western Asia",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Zambia",
    alpha3: "ZMB",
    region: "Africa",
    subregion: "Eastern Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "Zimbabwe",
    alpha3: "ZWE",
    region: "Africa",
    subregion: "Eastern Africa",
    supported_identity_report: true,
    supported_document_report: true,
  },
  {
    name: "eSwatini",
    alpha3: "SWZ",
    region: "Africa",
    subregion: "Southern Africa",
    supported_identity_report: false,
    supported_document_report: true,
  },
  {
    name: "Åland Islands",
    alpha3: "ALA",
    region: "Europe",
    subregion: "Northern Europe",
    supported_identity_report: true,
    supported_document_report: false,
  },
];
