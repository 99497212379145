import Fuse from "fuse.js";

const defaultOptions = {
  includeScore: true,
  keys: [
    "name",
    "client_reference",
    {
      name: "email",
      weight: 0.9,
    },
  ],
  threshold: 0.2,
};

class ContactsSearch {
  constructor(list, options = {}) {
    this.rawList = list;
    this.fuse = new Fuse(list, { ...defaultOptions, ...options });
  }

  add(item) {
    this.rawList.push(item);
    this.setList(this.rawList);
  }

  setList(list) {
    this.rawList = list;
    this.fuse.setCollection(this.rawList);
  }

  search(searchQuery) {
    return this.fuse.search(searchQuery).map((el) => el.item);
  }

  searchByExactField(field, searchQuery) {
    const results = this.rawList.filter((item) => {
      return (
        item?.[field]?.trim?.()?.toLowerCase?.() ===
        searchQuery.trim().toLowerCase()
      );
    });
    return results;
  }

  searchResultsAndCount = (searchQuery) => {
    const result = this.search(searchQuery);
    return { result, count: result.length };
  };
}

export { ContactsSearch as default };
