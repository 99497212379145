import { LitElement, css, html } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { neutral } from "../../lds-colours";
import { spacing } from "../../lds-spacing";
import { typographyPresets } from "../../lds-typography";
export class LdsDatePickerSelector extends LitElement {
  static get styles() {
    return css`
            .select-buttons-container {
                height: 240px;
                overflow: auto;
                margin: ${spacing.xs} 0;
                box-sizing: border-box;
            }
            .select-button {
                ${typographyPresets.body};
                color: ${neutral["700"]};
                padding: ${spacing.s};
                width: 100%;
                border-radius: ${spacing.xxs};
                border: none;
                background-color: transparent;
                text-align: left;
                cursor: pointer;
            }
            .is-selected {
                background-color: ${neutral["200"]};
                scroll-snap-align: center;
            }
            .is-disabled {
                pointer-events: none;
                color: ${neutral["300"]};
            }
        `;
  }

  static get properties() {
    return {
      options: {
        attribute: false,
      },
      currentSelection: {
        attribute: false,
      },
    };
  }

  onSelect(option) {
    this.dispatchEvent(
      new CustomEvent("on-select", {
        detail: {
          selection: option,
        },
        bubbles: true,
        composed: true,
      }),
    );
  }

  firstUpdated() {
    const selectButtonsContainer = this.shadowRoot.querySelector(
      ".select-buttons-container",
    );
    const selectedOption = this.shadowRoot.querySelector(".is-selected");
    selectButtonsContainer.scrollTo({
      top: selectedOption.offsetTop - 172,
    });
  }

  render() {
    return html`<div class="select-buttons-container">
            ${this.options.map(
              (option) => html`
                    <button
                        class="${classMap({
                          "select-button": true,
                          "is-selected": option.value === this.currentSelection,
                          "is-disabled": option.isDisabled,
                        })}"
                        @click=${() => this.onSelect(option.value)}
                        .disabled=${option.isDisabled}
                    >
                        ${option.label}
                    </button>
                `,
            )}
        </div> `;
  }
}

customElements.define("lds-date-picker-selector", LdsDatePickerSelector);
