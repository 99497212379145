import { LitElement, css, html } from "lit";
import "./legl-mini-accordion-item.js";

export class LeglMiniAccordion extends LitElement {
  static get properties() {
    return {
      items: {
        type: Object,
        attribute: "items",
      },
      selectItem: {
        type: String,
        attribute: "selected-item",
      },
    };
  }

  closeItems() {
    this.shadowRoot
      .querySelectorAll("legl-mini-accordion-item")
      .forEach((item) => item.close());
  }

  handleSelect(event) {
    this.closeItems();
    event.target.open();
    this.selectItem = event.target;
    this.requestUpdate();
  }

  get isValid() {
    const selectedWorkflow = this.shadowRoot.querySelector("[selected]");
    if (selectedWorkflow) {
      return selectedWorkflow.isValid;
    }
    return false;
  }

  get selectedWorkflowSteps() {
    if (this.isValid) {
      const selectedWorkflow = this.shadowRoot.querySelector("[selected]");
      return selectedWorkflow.steps;
    }
    return false;
  }

  render() {
    return html`
            ${this.items?.map(
              (item) => html`
                    <legl-mini-accordion-item
                        data-cy-item="${item.pk}"
                        .url=${item.url}
                        .title=${item.name}
                        .description=${item.description}
                        .steps=${item.steps}
                        @click=${this.handleSelect}
                    ></legl-mini-accordion-item>
                `,
            )}
        `;
  }
}

customElements.define("legl-mini-accordion", LeglMiniAccordion);
