import {
  LionCheckbox,
  LionCheckboxGroup,
  LionCheckboxIndeterminate,
} from "@lion/checkbox-group";
import { css, html } from "lit";
import { danger, neutral, primary } from "../../../legl-ui/lds-colours";
import { LdsBaseInputMixin } from "../../../legl-ui/lds-input";
import { spacing } from "../../../legl-ui/lds-spacing";
import { typographyPresets } from "../../../legl-ui/lds-typography";
import { handleErrorMessage } from "../../lds-input/src/lds-base-input-mixin.js";

export class LdsCheckbox extends LionCheckbox {
  static get styles() {
    return [
      ...super.styles,
      css`
                :host {
                    position: relative;
                    z-index: 1;
                    text-align: left;
                    flex-direction: column;
                    line-height: 20px;
                }

                .lion-content-container {
                    display: flex;
                }

                .choice-field__help-text {
                    flex-basis: unset;
                }

                :host([disabled]) ::slotted([id^="lds-checkbox-"]) {
                    border: 2px solid ${neutral["300"]};
                }
                :host([disabled]) ::slotted(label) {
                    color: ${neutral["400"]};
                }
                :host ::slotted([id^="lds-checkbox-"]) {
                    appearance: none;
                    width: 18px;
                    height: 18px;
                    background: white;
                    border: 2px solid ${neutral["700"]};
                    border-radius: 4px;
                    position: relative;
                    margin: 1px 10px 0 0;
                    cursor: pointer;
                    flex-shrink: 0;
                }
                :host ::slotted(label) {
                    color: ${neutral["800"]};
                }
                :host([checked]) ::slotted([id^="lds-checkbox-"]),
                :host([indeterminate]) ::slotted([id^="lds-checkbox-"]) {
                    background-color: ${primary["500"]};
                    border: none;
                }
                :host([checked][disabled]) ::slotted([id^="lds-checkbox-"]),
                :host([indeterminate][disabled])
                    ::slotted([id^="lds-checkbox-"]) {
                    background-color: ${neutral["300"]};
                }
                :host ::slotted([id^="lds-checkbox-"])::before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    width: 40px;
                    height: 40px;
                    border-radius: 20px;
                    top: -13px;
                    left: -13px;
                }
                :host([indeterminate]) ::slotted([id^="lds-checkbox-"])::before,
                :host([checked]) ::slotted([id^="lds-checkbox-"])::before {
                    top: -11px;
                    left: -11px;
                }
                :host(:hover) ::slotted([id^="lds-checkbox-"])::before {
                    background-color: ${neutral["200"]};
                }
                :host ::slotted([id^="lds-checkbox-"])::after {
                    content: "";
                    position: absolute;
                }
                :host([checked]) ::slotted([id^="lds-checkbox-"])::after {
                    width: 4px;
                    height: 8px;
                    top: 2px;
                    left: 6px;
                    border-radius: 0px;
                    border-style: solid;
                    border-image: initial;
                    background-color: transparent;
                    border-width: 0px 2.5px 2.5px 0px;
                    transform: rotate(45deg);
                    border-color: transparent rgb(255, 255, 255)
                        rgb(255, 255, 255) transparent;
                }
                :host([indeterminate]) ::slotted([id^="lds-checkbox-"])::after {
                    height: 2px;
                    width: 10px;
                    margin-left: 4px;
                    margin-top: 8px;
                    background-color: #ffffff;
                    border: none;
                }
                :host([hide-label]) ::slotted(label) {
                    border: 0;
                    clip: rect(0 0 0 0);
                    height: 1px;
                    margin: -1px;
                    overflow: hidden;
                    padding: 0;
                    position: absolute;
                    width: 1px;
                }

                ::slotted([slot="feedback"][type="error"]),
                ::slotted([slot="feedback"][has-error="show"]) {
                    ${typographyPresets.small};
                    display: flex;
                    color: ${danger["500"]};
                    margin-bottom: ${spacing.xxs};
                }
            `,
    ];
  }

  static get properties() {
    return {
      errorMessage: { attribute: false },
    };
  }

  // Manual override to set and display the errorMessages when input is used with vee-validate
  updated(changedProperties) {
    super.updated(changedProperties);
    handleErrorMessage(changedProperties, this);
  }

  render() {
    return html`
            <div class="form-field__feedback">
                <slot name="feedback"></slot>
            </div>
            <div class="lion-content-container">${super.render()}</div>
        `;
  }
}

customElements.define("lds-checkbox", LdsCheckbox);

export class LdsCheckboxGroup extends LdsBaseInputMixin(LionCheckboxGroup) {
  static get styles() {
    return [
      ...super.styles,
      css`
                ::slotted([slot="feedback"][type="error"]) {
                    ${typographyPresets.small};
                    display: flex;
                    color: ${danger["500"]};
                    margin-bottom: ${spacing.xxs};
                }
                ::slotted(lds-checkbox) {
                    margin-bottom: ${spacing.xs};
                }
            `,
    ];
  }
}

customElements.define("lds-checkbox-group", LdsCheckboxGroup);

export class LdsCheckboxIndeterminate extends LionCheckboxIndeterminate {
  static get styles() {
    return [
      ...super.styles,
      css`
                :host {
                    position: relative;
                    z-index: 1;
                }
                ::slotted([id^="lds-checkbox-indeterminate"]) {
                    appearance: none;
                    width: 18px;
                    height: 18px;
                    background: white;
                    border: 2px solid ${neutral["700"]};
                    border-radius: 4px;
                    position: relative;
                    margin: 2px 10px 0 0;
                }
                ::slotted([id^="label-lds-checkbox-indeterminate"]) {
                    color: ${neutral["800"]};
                }
                ::slotted([id^="lds-checkbox-indeterminate"])::before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    width: 40px;
                    height: 40px;
                    border-radius: 20px;
                    top: -13px;
                    left: -13px;
                }
                :host([indeterminate])
                    ::slotted([id^="lds-checkbox-indeterminate"])::before,
                :host([checked])
                    ::slotted([id^="lds-checkbox-indeterminate"])::before {
                    top: -11px;
                    left: -11px;
                }
                ::slotted([id^="lds-checkbox-indeterminate"]:hover)::before {
                    background-color: ${neutral["200"]};
                }
                :host([checked][disabled]) ::slotted(label),
                :host([indeterminate][disabled]) ::slotted(label) {
                    color: ${neutral["400"]};
                }
                :host([checked]) ::slotted([id^="lds-checkbox-indeterminate"]),
                :host([indeterminate])
                    ::slotted([id^="lds-checkbox-indeterminate"]) {
                    background-color: ${primary["500"]};
                    border: none;
                }
                :host([checked][disabled])
                    ::slotted([id^="lds-checkbox-indeterminate"]),
                :host([indeterminate][disabled])
                    ::slotted([id^="lds-checkbox-indeterminate"]) {
                    background-color: ${neutral["300"]};
                }
                ::slotted([id^="lds-checkbox-indeterminate"])::after {
                    content: "";
                    position: absolute;
                }
                :host([checked])
                    ::slotted([id^="lds-checkbox-indeterminate"])::after {
                    width: 4px;
                    height: 8px;
                    top: 2px;
                    left: 6px;
                    border-radius: 0px;
                    border-style: solid;
                    border-image: initial;
                    background-color: transparent;
                    border-width: 0px 2.5px 2.5px 0px;
                    transform: rotate(45deg);
                    border-color: transparent rgb(255, 255, 255)
                        rgb(255, 255, 255) transparent;
                }
                :host([indeterminate])
                    ::slotted([id^="lds-checkbox-indeterminate"])::after {
                    content: "";
                    position: absolute;
                    height: 2px;
                    width: 10px;
                    margin-left: 4px;
                    margin-top: 8px;
                    background-color: #ffffff;
                    border: none;
                }
                ::slotted(lds-checkbox) {
                    margin-bottom: ${spacing.xs};
                }
                ::slotted(lds-checkbox:first-child) {
                    margin-top: ${spacing.xs};
                }
            `,
    ];
  }
}

customElements.define("lds-checkbox-indeterminate", LdsCheckboxIndeterminate);
