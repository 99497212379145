import location from "../../../../../legl-ui/utils/location.js";
import { userConfig } from "../config/user-config";
import "./dialog-permission-overlay-container.js";
export class DialogRouter {
  constructor(urlSlug = "dialog") {
    this.dialogRegex = new RegExp(`#/${urlSlug}/([a-zA-Z0-9-]*)`);
    this.dialogComponents = new Map();
    this.activeDialog = null;
    this.currentlyShowing = null;
  }

  get hasActiveDialog() {
    return !!this.activeDialog;
  }

  startRouter() {
    window.addEventListener("hashchange", () => {
      this.currentlyShowing = null;
      this.handleHashChange();
    });
    this.handleHashChange();
  }

  handleHashChange() {
    const hash = location.getCurrentHash();
    if (hash.match(this.dialogRegex)) {
      const parts = this.dialogRegex.exec(hash);
      const params = new URLSearchParams(hash.split("?")[1]);
      const dialogId = parts[1];
      if (
        this.hasDialog(dialogId) &&
        // https://app.shortcut.com/legl/story/11469
        // do not call this.show if we are already displaying a drawer with this exact dialogId
        this.currentlyShowing !== dialogId
      ) {
        this.currentlyShowing = dialogId;
        this.show(dialogId, params);
      }
    }
  }

  hasDialog(dialogId) {
    return this.dialogComponents.has(dialogId);
  }

  register(elementName, props, id, permissionName) {
    const registeredId = id || elementName;
    if (this.hasDialog(registeredId)) {
      return;
    }
    this.dialogComponents.set(registeredId, {
      props,
      elementName,
      permissionName,
    });
    this.handleHashChange();
  }

  shouldShowDialog(id) {
    return this.hasDialog(id) && this.activeDialog !== id;
  }

  getDialogConfig(id, params) {
    if (this.shouldShowDialog(id, params)) {
      const config = this.dialogComponents.get(id);
      let component;
      if (
        Boolean(config.permissionName) &&
        userConfig.permissions[config.permissionName] === false
      ) {
        component = document.createElement(
          "legl-dialog-permission-overlay-container",
        );
      } else {
        component = document.createElement(config.elementName);
      }

      if (config.props) {
        Object.entries(config.props).forEach(([key, value]) => {
          component[key] = value;
        });
      }
      return {
        config,
        component,
      };
    }
    return {
      config: null,
      component: null,
    };
  }

  show() {}

  hideactiveDialog() {}
}
