import { PAYMENT_ADAPTER_CONFIG } from "../config/payment-config.js";

export class RenderPayment {
  static async render(method, amount, providerDetails, paymentDetails) {
    if (method in PAYMENT_ADAPTER_CONFIG) {
      const config = PAYMENT_ADAPTER_CONFIG[method];
      const adapterName = config.adapter;
      const adapter = new adapterName();

      adapter.setConfig(providerDetails, amount, paymentDetails);

      await adapter.render();

      return config;
    }
    return null;
  }
}
