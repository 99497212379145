import { WaffleFlags } from "../../../../../../waffle-flags";

const isFinancialCheckEnabled = waffle.flag_is_active(
  "enable_financial_checks",
);

const enable_financial_checks = isFinancialCheckEnabled
  ? ["CCJs & Decrees", "Insolvency", "Debt Collection"]
  : [];

const financialChecksCddOption = {
  financial_checks: {
    title: "Financial Screening",
    reportBreakdown: ["CCJs & Decrees", "Insolvency", "Debt Collection"],
    message: "You will need: Name • DOB • Address of residency",
  },
};

const standaloneFinancialChecksCddOption = isFinancialCheckEnabled
  ? financialChecksCddOption
  : {};

const standardCDDNoId = waffle.flag_is_active(WaffleFlags.STANDARD_CDD_NO_ID)
  ? {
      standard_cdd_no_id: {
        kyc: ["Identity Verification"],
        reportBreakdown: ["PEPs & Sanctions"].concat(enable_financial_checks),
        title: "Standard CDD - No ID Document Verification",
        subTitle: "(UK residents only)",
        message: "You will need: Name • DOB • Address of Residency",
      },
    }
  : {};

export const cddOptions = {
  standard_cdd: {
    kyc: [
      "Identity Verification",
      "ID Document Verification",
      "Visual Verification",
    ],
    reportBreakdown: ["PEPs & Sanctions"].concat(enable_financial_checks),
    title: "Standard CDD",
    message:
      "You will need: Name • DOB • Address of Residency • Government-issued ID",
  },
  ...standardCDDNoId,
  peps_and_sanctions_only: {
    reportBreakdown: ["PEPs & Sanctions"],
    title: "Watchlist Screening",
    message:
      "You will need: Name • DOB • Address of residency (optional but recommended)",
  },
  ...standaloneFinancialChecksCddOption,
};
