import { LionCheckbox, LionCheckboxGroup } from "@lion/checkbox-group";
import { css } from "lit";
import { neutral, primary } from "../../legl-ui/lds-colours";

export class LeglCheckbox extends LionCheckbox {
  static get styles() {
    return [
      ...super.styles,
      css`
                :host([disabled]) {
                    opacity: 0.3;
                }

                :host ::slotted(.legl-form-control) {
                    display: none;
                }

                .choice-field__label {
                    display: block;
                    position: relative;
                    text-align: left;
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                }

                .choice-field__label ::slotted(label) {
                    padding-left: 26px;
                    z-index: 1;
                    position: relative;
                    cursor: pointer;
                    display: block;
                    line-height: 18px;
                }

                .choice-field__label:before {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 0;
                }

                .choice-field__label:after {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 0;
                }

                :host([variant="normal"]) .choice-field__label:hover:before {
                    box-shadow: 0 0 0 8px ${neutral["200"]};
                }

                /*Checkbox*/
                :host([variant="normal"]) .choice-field__label:before {
                    width: 14px;
                    height: 14px;
                    background: white;
                    border: 2px solid ${neutral["700"]};
                    border-radius: 4px;
                }
                :host([variant="normal"][indeterminate])
                    .choice-field__label:before {
                    width: 18px;
                    height: 18px;
                    background: ${primary["500"]};
                    border-radius: 4px;
                    border: none;
                }

                :host([variant="normal"][indeterminate])
                    .choice-field__label:after {
                    height: 2px;
                    width: 10px;
                    margin-left: 4px;
                    margin-top: 8px;
                    background-color: #ffffff;
                    border: none;
                }

                :host([variant="normal"][checked]) .choice-field__label:before {
                    width: 18px;
                    height: 18px;
                    background: ${primary["500"]};
                    border-radius: 4px;
                    border: none;
                }

                :host([variant="normal"][checked]) .choice-field__label:after {
                    width: 4px;
                    height: 8px;
                    top: 2px;
                    left: 6px;
                    border-radius: 0px;
                    border-style: solid;
                    border-image: initial;
                    background-color: transparent;
                    border-color: transparent rgb(255, 255, 255)
                        rgb(255, 255, 255) transparent;
                    border-width: 0px 2.5px 2.5px 0px;
                    transform: rotate(45deg);
                }

                /*Toggle*/
                :host([variant="toggle"])
                    .choice-field__label
                    ::slotted(label) {
                    padding-left: 3.4375em;
                    display: inline-block;
                }
                :host([variant="toggle"]) .choice-field__label:before {
                    width: 2.625em;
                    height: 1.25em;
                    background-color: var(--legl-grey);
                    border-radius: 0.625em;
                    transition: background-color 0.25s ease-in-out;
                    top: 0.125em;
                }

                :host([variant="toggle"][checked]) .choice-field__label:before {
                    background-color: var(
                        --legl-checkbox-checked-background-color,
                        #a7eac4
                    );
                }

                :host([variant="toggle"]) .choice-field__label:after {
                    content: "";
                    position: absolute;
                    left: -0.125em;
                    top: 0px;
                    width: 1.5em;
                    height: 1.5em;
                    border-radius: 0.75em;
                    background-color: var(--legl-grey-disabled);
                    box-shadow: 0px 0.125em 0.125em 0px rgba(0, 0, 0, 0.24);
                    transition: left 0.25s ease-in-out, right 0.25s ease-in-out,
                        background-color 0.25s ease-in-out;
                }

                :host([variant="toggle"][checked]) .choice-field__label:after {
                    left: 1.25em;
                    background-color: var(
                        --legl-checkbox-checked-color,
                        #29d273
                    );
                }

                /* Left side label */
                :host([label-side="left"]) .choice-field__label:before {
                    left: auto;
                    right: 0;
                }

                :host([label-side="left"][checked]) .choice-field__label:after {
                    left: auto;
                    right: 5px;
                }

                :host([label-side="left"])
                    .choice-field__label
                    ::slotted(label) {
                    padding-right: 26px;
                    padding-left: 0;
                }

                :host([variant="toggle"][label-side="left"])
                    .choice-field__label
                    ::slotted(label) {
                    padding-right: 3.4375em;
                    padding-left: 0;
                }

                :host([variant="toggle"][label-side="left"])
                    .choice-field__label:after {
                    left: auto;
                    right: 1.25em;
                }

                :host([variant="toggle"][checked][label-side="left"])
                    .choice-field__label:after {
                    left: auto;
                    right: -0.125em;
                }

                /*Show asterisk when field is required*/
                :host([required="required"])
                    .choice-field__label
                    ::slotted(label):after {
                    color: var(--legl-red);
                    content: "*";
                }
            `,
    ];
  }

  static get properties() {
    return {
      ...super.properties,
      variant: {
        type: String,
        attribute: "variant",
        reflect: true,
      },
      labelSide: {
        type: String,
        attribute: "label-side",
        reflect: true,
      },
      required: {
        type: String,
        reflect: true,
      },
      indeterminate: {
        type: Boolean,
      },
    };
  }

  constructor() {
    super();
    this.variant = "normal";
    this.labelSide = "right";
  }

  _enhanceLightDomClasses() {
    if (this._inputNode) {
      this._inputNode.classList.add("legl-form-control");
    }
  }
}

export class LeglCheckboxGroup extends LionCheckboxGroup {
  static get styles() {
    return [
      ...super.styles,
      css`
                /* reset conflicting bootstrap styles in IE11/edgeHTML */
                :host(.legl-form-control) {
                    width: auto;
                    height: auto;
                    border: none;
                    border-radius: 0;
                    color: inherit;
                }
            `,
    ];
  }
}

customElements.define("legl-checkbox", LeglCheckbox);
customElements.define("legl-checkbox-group", LeglCheckboxGroup);
