import { LionRadio, LionRadioGroup } from "@lion/radio-group";
import { css } from "lit";
import { neutral, primary } from "../../../legl-ui/lds-colours";
import { LdsBaseInputMixin } from "../../../legl-ui/lds-input";
import { spacing } from "../../../legl-ui/lds-spacing";

export class LdsRadio extends LionRadio {
  static get styles() {
    return [
      ...super.styles,
      css`
                :host {
                    position: relative;
                    z-index: 1;
                    display: grid;
                    grid-template-columns: 28px 1fr;
                    text-align: left;
                    align-items: center;
                }

                :host ::slotted(label) {
                    color: ${neutral["800"]};
                    display: block;
                    word-break: break-word;
                }

                :host([disabled]) ::slotted([id^="lds-radio-"]) {
                    border: 2px solid ${neutral["300"]};
                }

                :host([checked][disabled]) ::slotted([id^="lds-radio-"]) {
                    border: 2px solid ${neutral["300"]};
                }

                :host([disabled]) ::slotted(label) {
                    color: ${neutral["400"]};
                }

                :host ::slotted([id^="lds-radio-"]) {
                    appearance: none;
                    width: 18px;
                    height: 18px;
                    background: transparent;
                    border: 2px solid ${neutral["700"]};
                    border-radius: 50%;
                    position: relative;
                    margin: 2px 10px 0 0;
                }

                :host ::slotted([id^="lds-radio-"])::after {
                    content: "";
                    position: absolute;
                }

                :host([checked]) ::slotted([id^="lds-radio-"]) {
                    border-color: ${primary["500"]};
                }

                :host([checked][disabled])
                    ::slotted([id^="lds-radio-"])::after {
                    background-color: ${neutral["300"]};
                }

                :host([checked]) ::slotted([id^="lds-radio-"])::after {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: ${primary["500"]};
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                }

                :host ::slotted([id^="lds-radio-"])::before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    width: 40px;
                    height: 40px;
                    border-radius: 20px;
                    top: -13px;
                    left: -13px;
                }

                :host(:hover) ::slotted([id^="lds-radio-"])::before {
                    background-color: ${neutral["200"]};
                }
            `,
    ];
  }
}

customElements.define("lds-radio", LdsRadio);

export class LdsRadioGroup extends LdsBaseInputMixin(LionRadioGroup) {
  static get styles() {
    return [
      ...super.styles,
      css`
                :host([horizontal]) .input-group {
                    display: flex;
                    gap: ${spacing.s};
                }

                :host([horizontal]) ::slotted(lds-radio) {
                    max-width: 278px;
                }

                ::slotted(lds-radio) {
                    margin-bottom: ${spacing.xs};
                }

                :host([horizontal]) ::slotted(lds-radio) {
                    margin-bottom: 0;
                }
            `,
    ];
  }
}

customElements.define("lds-radio-group", LdsRadioGroup);
