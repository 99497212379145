import { css, html } from "lit";
import { ConfirmationModal } from "../../dialog-manager/confirmation-modal";
import "./components/engage-application-step-summary";

import { toastService } from "../../../../../../legl-ui/toast";
import { get, post } from "../../../../../../legl-ui/utils/fetch";

export class ForceCompleteModal extends ConfirmationModal {
  static get styles() {
    return [
      css`
                :host {
                    --legl-modal-max-width: 700px;
                }
            `,
      super.styles,
      css`
                h1 {
                    font-size: 18px;
                }
                .warning {
                    display: flex;
                    justify-content: space-between;
                }
                .warning legl-icon {
                    color: var(--legl-yellow);
                }

                .warning p {
                    padding: 0;
                    margin: 0 0 0 20px;
                }
            `,
    ];
  }

  static get properties() {
    return {
      steps: {
        attribute: false,
      },
      applicantName: {
        type: String,
        attribute: "applicant-name",
      },
      forceCompleteUrl: {
        type: String,
        attribute: "force-complete-url",
      },
    };
  }
  static get tagName() {
    return "legl-force-complete-modal";
  }

  constructor() {
    super();
    this.isLoading = false;
  }

  get modalAlert() {
    return html`<div class="warning">
            <legl-icon
                size="x-large"
                name="legl-icon-warning-triangle"
            ></legl-icon>
            <p>
                By force completing the engage request you will submit any
                information your client has already entered and permanently skip
                any remaining steps.
            </p>
        </div>`;
  }

  get modalBody() {
    if (this.steps) {
      return html` <h1>
                    ${`Force complete engage request${
                      this.applicantName ? ` for ${this.applicantName}` : ""
                    }`}
                </h1>
                <legl-engage-application-step-summary
                    .steps=${this.steps}
                ></legl-engage-application-step-summary>`;
    }
    return "";
  }

  get isValid() {
    return super.isValid && this.steps;
  }

  async fetchForceCompletePreview() {
    try {
      const res = await get(this.forceCompleteUrl);
      if (res.ok) {
        this.steps = await res.json();
      } else {
        throw new Error(
          `${res.status}: Unable to fetch force complete preview`,
        );
      }
    } catch (err) {
      toastService.showError("Unable to load force complete preview");
    }
  }

  firstUpdated(props) {
    super.firstUpdated(props);
    this.fetchForceCompletePreview();
  }
}

customElements.define(ForceCompleteModal.tagName, ForceCompleteModal);

export async function forceComplete(application) {
  if (!application) {
    return;
  }

  try {
    let applicantName = "";
    if (application.product_activities?.[0]?.contact) {
      applicantName = `${application.product_activities[0].contact.first_name} ${application.product_activities[0].contact.last_name}`;
    }

    const forceCompleteUrl = `/api/engage_applications/${application.id}/force_complete/`;
    const { comment } = await ForceCompleteModal.create({
      title: "Confirm action",
      cancelText: "Cancel",
      confirmText: "Force complete",
      commentLabel: "Optional Comment: (internal use only)",
      confirmVariant: "danger",
      includeComment: true,
      applicantName,
      forceCompleteUrl,
    });
    const { ok, status } = await post(forceCompleteUrl, {
      body: JSON.stringify({ comment: comment }),
    });
    if (ok) {
      toastService.showSuccess("Success: Force Completed");
      window.dispatchEvent(new CustomEvent("reload-engage-table"));

      // below is listened by view engage drawer
      window.dispatchEvent(
        new CustomEvent("force-completed-request", {
          bubbles: true,
          composed: true,
        }),
      );
      return true;
    } else {
      throw new Error(`${status}: Unable to force complete`);
    }
  } catch (err) {
    if (err) {
      toastService.showError(
        "Unable to force complete. Please try again later.",
      );
      return false;
    }
    //If no error it is presumed it's just an empty rejection from the ConfirmModal
  }
}
