import "@lion/steps/define";
import { LitElement, css, html } from "lit";
import "../../../../../../../legl-ui/icon";
import "../../../../../../../legl-ui/input";
import { neutral } from "../../../../../../../legl-ui/lds-colours";
import { toastService } from "../../../../../../../legl-ui/toast";
import "../../../../../../../legl-ui/tooltip-old";
import { get, patch } from "../../../../../../../legl-ui/utils/fetch";
import "../../tag-select.js";
import "./admin-departments-intro.js";

export class LeglAdminDepartmentsModal extends LitElement {
  static get styles() {
    return css`
            :host {
                text-align: center;
                background: white;
            }

            :host([isSaving]) {
                opacity: 0.7;
                pointer-events: none;
            }

            .modal-container {
                max-height: calc(100vh - 3rem);
                overflow-y: auto;
            }

            .intro {
                width: 100%;
                background-color: #fcfcfc;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 1rem 0;
                margin-bottom: 3rem;
            }

            .intro h1 {
                font-size: 32px;
            }

            h1,
            h2 {
                color: ${neutral["800"]};
            }

            h2 {
                font-size: 21px;
                margin-bottom: 32px;
            }

            .intro-text {
                width: 80%;
            }

            .buttons {
                margin: 3rem 0;
                display: inline-flex;
            }

            .buttons > * {
                min-width: 200px;
            }

            .close-btn {
                --legl-button-background-color: transparent;
                --legl-button-text-color: ${neutral["800"]};
                --legl-button-background-color-disabled: transparent;
                --legl-button-text-color-disabled: ${neutral["800"]};
                --legl-button-background-color-hover: transparent;
                --legl-button-text-color-hover: ${neutral["800"]};
            }

            .breadcrumb {
                display: flex;
                justify-content: space-between;
                width: 110px;
                position: relative;
                padding: 0;
                margin: 0;
            }

            .breadcrumb:before {
                position: absolute;
                top: 50%;
                left: 30px;
                right: 30px;
                border-top: dashed 1px #d1d1d1;
                content: "";
            }

            .breadcrumb li {
                box-sizing: border-box;
                list-style-type: none;
                width: 30px;
                height: 30px;
                border-radius: 15px;
                border: solid 1px currentColor;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                opacity: 0.5;
                transition: opacity 0.25s;
            }

            .breadcrumb li.is-active {
                opacity: 1;
            }

            .inner {
                width: 80%;
                margin: 0 auto;
            }

            .confirmation-icon {
                color: var(--legl-green, #28a745);
                font-size: 100px;
            }

            .intercom-link {
                text-align: right;
            }

            .intercom-link a {
                color: #8e0000;
            }
        `;
  }

  static get properties() {
    return {
      config: {
        type: Object,
      },
      isLoading: { type: Boolean },
      hasLoaded: { type: Boolean },
      isSaving: { type: Boolean, reflect: true },
      hasSaved: { type: Boolean },
      currentStep: {
        type: Number,
      },
      practiceAreaDepartments: {
        type: Array,
      },
      operationalDepartments: {
        type: Array,
      },
    };
  }

  static departmentsForSelectControl(
    allDepartments,
    companyDepartments,
    groupName,
  ) {
    return allDepartments
      .filter((department) => department.group.name === groupName)
      .map((department) => {
        return {
          label: department.name,
          value: department.url,
          checked: companyDepartments.includes(department.url),
        };
      });
  }

  constructor() {
    super();
    this.isSaving = false;
    this.hasSaved = false;
    this.currentStep = 0;
    this.practiceAreaDepartments = [];
    this.operationalDepartments = [];
    this.companyUpdateUrl = null;
  }

  async fetchDepartments() {
    try {
      const [departmentsRes, companyRes] = await Promise.all([
        get("/api/departments/"),
        await get("/api/company/"),
      ]);
      const [departmentsJson, companyJson] = await Promise.all([
        departmentsRes.json(),
        companyRes.json(),
      ]);
      this.companyUpdateRecord = companyJson;
      this.operationalDepartments =
        LeglAdminDepartmentsModal.departmentsForSelectControl(
          departmentsJson.results,
          companyJson.departments,
          "Operational",
        );
      this.practiceAreaDepartments =
        LeglAdminDepartmentsModal.departmentsForSelectControl(
          departmentsJson.results,
          companyJson.departments,
          "Practice Area",
        );
    } catch (err) {
      this.onClose();
      toastService.showError("Could not display dialog");
    } finally {
      this.isLoading = false;
      this.hasLoaded = true;
    }
  }

  get modalStepsElement() {
    return this.shadowRoot.querySelector("lion-steps");
  }

  get allDepartments() {
    return [...this.operationalDepartments, ...this.practiceAreaDepartments];
  }

  get selectedDepartments() {
    return this.allDepartments.filter(
      (department) => department.checked === true,
    );
  }

  get isContinueDisabled() {
    return this.currentStep === 2 && this.selectedDepartments.length === 0;
  }

  get introText() {
    if (this.currentStep === 1) {
      return html`<p>
                Help us customize your teams experience and provide better
                reporting by identifying the departments that make sense within
                your firm.
            </p>`;
    }
    if (this.currentStep === 2) {
      return html`<p>
                Not all law firm users of Legl are fee earners. In fact, some of
                our most active users are in the operational, financial and tech
                teams in firms. Would you like to include users in these
                categories? If so, select departments that your firm has and as
                new users join, they can select which applies to them.
            </p>`;
    }
  }

  updatePracticeAreaDepartments(options) {
    this.practiceAreaDepartments = this.practiceAreaDepartments.map(
      (department) => {
        return {
          ...department,
          checked: options[department.value],
        };
      },
    );
  }

  updateOperationalDepartments(options) {
    this.operationalDepartments = this.operationalDepartments.map(
      (department) => {
        return {
          ...department,
          checked: options[department.value],
        };
      },
    );
  }

  onClose() {
    this.dispatchEvent(new CustomEvent("closeModal"));
  }

  async onNext(button) {
    button.isLoading = true;
    if (this.currentStep === 2) {
      await this.submitPreferences();
    } else {
      this.modalStepsElement.next();
    }
    button.isLoading = false;
  }

  async submitPreferences() {
    try {
      this.requestUpdate();
      await patch(this.companyUpdateRecord.url, {
        body: JSON.stringify({
          departments: this.selectedDepartments.map(
            (department) => department.value,
          ),
        }),
      });

      this.hasSaved = true;

      this.modalStepsElement.next();
    } catch (err) {
      console.error(err);
      toastService.showError(
        "There was an error saving your preferences, please try again.",
      );
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.isLoading = true;
    this.hasLoaded = false;
    this.fetchDepartments();
  }

  onStepChange(e) {
    this.currentStep = e.detail.toStep.number;
  }

  render() {
    return html`
            ${
              this.hasLoaded
                ? html`<div class="modal-container">
                      <div class="intro">
                          ${
                            this.currentStep &&
                            this.currentStep > 0 &&
                            this.currentStep < 3
                              ? html`<ul class="breadcrumb">
                                    <li
                                        class="${
                                          this.currentStep === 1
                                            ? "is-active"
                                            : ""
                                        }"
                                    >
                                        1
                                    </li>
                                    <li
                                        class="${
                                          this.currentStep === 2
                                            ? "is-active"
                                            : ""
                                        }"
                                    >
                                        2
                                    </li>
                                </ul>`
                              : ""
                          }
                          ${
                            this.currentStep !== 3
                              ? html`<h1>Configure your firm’s departments</h1>`
                              : ""
                          }
                          <div class="intro-text">${this.introText}</div>
                      </div>
                      <div class="inner">
                          <lion-steps
                              class="modal-steps"
                              @transition=${this.onStepChange.bind(this)}
                          >
                              <lion-step initial-step>
                                  <legl-admin-departments-intro
                                      introImage="${this.config.introImage}"
                                  ></legl-admin-departments-intro
                              ></lion-step>
                              <lion-step>
                                  <legl-tag-select
                                      title=""
                                      @valueChange=${(e) => {
                                        this.updatePracticeAreaDepartments(
                                          e.detail.updatedOptions,
                                        );
                                      }}
                                      .options=${this.practiceAreaDepartments}
                                  >
                                      <h2 slot="before">
                                          Please select all Practice Areas that
                                          apply:
                                      </h2>
                                      <p class="intercom-link" slot="after">
                                          <a
                                              href="#"
                                              @click=${(e) => {
                                                e.preventDefault();
                                                if (window.Intercom) {
                                                  Intercom("show");
                                                }
                                              }}
                                              >Don’t see one you are looking
                                              for?
                                          </a>
                                      </p>
                                  </legl-tag-select></lion-step
                              >
                              <lion-step>
                                  <legl-tag-select
                                      @valueChange=${(e) => {
                                        this.updateOperationalDepartments(
                                          e.detail.updatedOptions,
                                        );
                                      }}
                                      .options=${this.operationalDepartments}
                                  >
                                      <h2 slot="before">
                                          Please select all Management,
                                          Operations and Financial teams that
                                          apply:
                                      </h2>
                                      <p class="intercom-link" slot="after">
                                          <a
                                              href="#"
                                              @click=${(e) => {
                                                e.preventDefault();
                                                if (window.Intercom) {
                                                  Intercom("show");
                                                }
                                              }}
                                              >Don’t see one you are looking
                                              for?
                                          </a>
                                      </p>
                                  </legl-tag-select></lion-step
                              >
                              <lion-step>
                                  <div class="confirmation">
                                      <legl-icon
                                          class="confirmation-icon"
                                          name="legl-icon-confirm-circle"
                                      ></legl-icon>
                                      <h2>All set!</h2>
                                      <p>
                                          Now that your departments have been
                                          selected, users will be able to select
                                          departments only from the list which
                                          you have set. This will enable better
                                          insights reporting and tracking across
                                          all products and users. If you have
                                          more questions please contact us via
                                          intercom chat.
                                      </p>
                                  </div>
                              </lion-step>
                          </lion-steps>
                          <div class="buttons">
                              <legl-button
                                  class="close-btn"
                                  @click=${() => this.onClose()}
                              >
                                  Close
                              </legl-button>
                              ${
                                this.currentStep !== 3
                                  ? html`<legl-button
                                        .disabled=${this.isContinueDisabled}
                                        @click=${(event) =>
                                          this.onNext(event.target)}
                                    >
                                        Continue</legl-button
                                    >`
                                  : ""
                              }
                          </div>
                      </div>
                  </div>`
                : ``
            }
        `;
  }
}

customElements.define(
  "legl-admin-departments-modal",
  LeglAdminDepartmentsModal,
);
