import { LitElement, css, html } from "lit";
import { neutral } from "../../../legl-ui/lds-colours";

import { classMap } from "lit/directives/class-map.js";
import { userConfig } from "../../../apps/lawyers-app/static-src/js/config/user-config.js";
import "../../../legl-ui/tooltip-menu";
import "./nav-section.js";

export class LdsNav extends LitElement {
  static get properties() {
    return {
      navigationConfig: {
        type: Object,
      },
      isVisibleOnMobile: {
        type: Boolean,
      },
    };
  }

  static get styles() {
    return css`
            @media print {
                :host {
                    display: none;
                }
            }

            :host .nav-header {
                width: 226px;
                position: fixed;
                top: 0;
                left: 0;
                height: 116px;
                background: #ffffff;
                background-color: ${neutral["900"]};
            }

            :host nav {
                width: 226px;
                background-color: ${neutral["900"]};
                color: ${neutral["0"]};
                position: fixed;
                top: 116px;
                left: 0;
                height: calc(100vh - 116px);
                overflow: visible;
                z-index: 1010;
                transition: width 0.25s ease-in-out;
            }

            :host nav .inner {
                position: absolute;
                top: 0;
                left: 0;
                height: calc(100% - 100px);
                width: 226px;
                overflow: auto;
                margin-top: 30px;
            }

            :host ::slotted(div) {
                display: flex;
                justify-content: center;
            }

            .company {
                padding: 0.5rem;
                text-align: center;
            }

            :host h2 {
                font-size: 14px;
                font-weight: normal;
                color: ${neutral["0"]};
                margin: 0 0 20px 0;
            }

            .logout-bar {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 58px;
                display: flex;
                justify-content: space-between;
                align-content: center;
                align-items: center;
                padding: 14px;
                background: ${neutral["900"]};
                box-sizing: border-box;
            }

            .logout-bar:before {
                height: 0;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                border-top: 1px solid ${neutral["800"]};
                background: transparent;
                content: "";
            }

            .logout-bar__image {
                width: 30px;
            }

            .logout-bar__lawyer-name {
                font-size: 12px;
                font-weight: bold;
            }

            .logout-bar__logout-btn {
                font-size: 16px;
                color: ${neutral["0"]};
                display: flex;
                align-items: center;
                text-decoration: none;
            }

            .nav-header__toggle-btn,
            .nav-header__logout-btn {
                display: none;
            }

            @media screen and (max-width: 1030px) {
                .nav-header__toggle-btn {
                    display: block;
                    position: relative;
                    border: none;
                    background: none;
                    cursor: pointer;
                    margin-left: 16px;
                    width: 20px;
                    height: 18px;
                }

                .nav-header__toggle-line {
                    position: absolute;
                    display: block;
                    width: 20px;
                    height: 2px;
                    top: 8px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    background-color: ${neutral["0"]};
                    transition: 0.2s ease-out;
                }

                .nav-header__toggle-line:nth-child(1) {
                    transform: translateY(-7px);
                }

                .is-visible-on-mobile .nav-header__toggle-line:nth-child(1) {
                    transform: translateY(0px) rotate(45deg);
                }

                .nav-header__toggle-line:nth-child(3) {
                    transform: translateY(7px);
                }

                .is-visible-on-mobile .nav-header__toggle-line:nth-child(3) {
                    transform: translateY(0px) rotate(-45deg);
                }

                .is-visible-on-mobile .nav-header__toggle-line:nth-child(2) {
                    opacity: 0;
                }

                .nav-header__logout-btn {
                    display: block;
                    margin-left: auto;
                    color: ${neutral["0"]};
                    margin-right: 16px;
                    font-size: 16px;
                }

                :host .nav-header {
                    height: 62px;
                    width: 100%;
                    display: flex;
                    z-index: 1;
                    align-items: center;
                }

                :host .nav-header h2 {
                    display: none;
                }

                :host nav {
                    width: 0;
                    top: 62px;
                    height: calc(100% - 62px);
                    overflow: hidden;
                }

                :host .is-visible-on-mobile nav {
                    width: 226px;
                    overflow: visible;
                }

                .logout-bar {
                    display: none;
                }
            }
        `;
  }

  constructor() {
    super();

    this.isVisibleOnMobile = false;
  }

  get navSections() {
    return [...this.shadowRoot.querySelectorAll(".section")];
  }

  get navClasses() {
    return classMap({
      "nav-wrapper": true,
      "is-visible-on-mobile": this.isVisibleOnMobile,
    });
  }

  onNavExpanded(excludedItems = []) {
    this.navSections.forEach((section) => {
      section.collapseSubMenus(excludedItems);
    });
  }

  onNavToggleClicked() {
    this.isVisibleOnMobile = !this.isVisibleOnMobile;
  }

  get createNewMenu() {
    return html`<legl-tooltip-menu
            icon="lds-icon-AddCircleFilled"
            button-text="Quick actions"
            data-cy-create-button
        >
            <legl-tooltip-menu-item
                slot="main-section"
                text="Create an engage request"
                sub-text="Send a workflow request to an individual or contact"
                icon="lds-icon-Engage"
                drawer-element="legl-engage-request-create"
                drawer-id="engage-create"
                drawer-title="Create Engage Request"
                data-cy-engage-create-button
                ?disabled=${!(
                  userConfig.permissions?.engage &&
                  userConfig.permissions?.create_engage_request
                )}
            ></legl-tooltip-menu-item>
            <legl-tooltip-menu-item
                slot="main-section"
                text="Create Pay Request"
                sub-text="Generate and send a request for payment"
                icon="lds-icon-CreditCard"
                drawer-element="legl-pay-request-create"
                drawer-id="pay-create"
                drawer-title="Create Pay Request"
                data-cy-payment-create-button
                ?disabled=${!userConfig.permissions?.pay}
            ></legl-tooltip-menu-item>
            <legl-tooltip-menu-item
                slot="main-section"
                text="Take a phone payment"
                sub-text="Take a card payment from an individual over the phone"
                icon="lds-icon-PhonePayment"
                drawer-element="legl-phone-payment"
                drawer-id="phone-payment-create"
                drawer-title="Take phone payment"
                ?disabled=${!userConfig.permissions?.phone}
            ></legl-tooltip-menu-item>
            <legl-tooltip-menu-item
                slot="main-section"
                text="Create a new contact"
                sub-text="Create a new contact record to keep track of information in a single place"
                icon="lds-icon-PersonOutlined"
                drawer-element="legl-contact-create"
                drawer-id="contact-create"
                drawer-title="Create a new contact"
                drawer-icon="lds-icon-PersonOutlined"
                data-cy-contact-create-button
                .visible=${true}
            ></legl-tooltip-menu-item>
        </legl-tooltip-menu> `;
  }

  render() {
    if (this.navigationConfig) {
      return html`
                <div class=${this.navClasses}>
                    <div class="nav-header">
                        <button
                            class="nav-header__toggle-btn"
                            aria-label="Toggle Nav"
                            @click=${() => this.onNavToggleClicked()}
                        >
                            <span class="nav-header__toggle-line"></span>
                            <span class="nav-header__toggle-line"></span>
                            <span class="nav-header__toggle-line"></span>
                        </button>
                        <div class="company">
                            <slot name="logo"></slot>
                            <h2>${this.navigationConfig.title}</h2>
                        </div>
                        <a
                            class="nav-header__logout-btn"
                            href="${this.navigationConfig.logOutUrl}"
                        >
                            <legl-icon
                                tabindex="0"
                                aria-label="Logout"
                                name="legl-icon-log-out"
                            >
                            </legl-icon>
                        </a>
                    </div>
                    <nav>
                        ${this.createNewMenu}
                        <div class="inner">
                            ${this.navigationConfig.sections.map(
                              (section) =>
                                html`<legl-lawyer-account-nav-section
                                        @navExpanded=${(e) => {
                                          this.onNavExpanded([e.detail.item]);
                                        }}
                                        class="section"
                                        .section=${section}
                                    ></legl-lawyer-account-nav-section>`,
                            )}
                        </div>
                        <div class="logout-bar">
                            <span class="logout-bar__lawyer-name">
                                ${this.navigationConfig.lawyerName}
                            </span>
                            <a
                                class="logout-bar__logout-btn"
                                data-cy-logout
                                href="${this.navigationConfig.logOutUrl}"
                            >
                                <lds-icon
                                    tabindex="0"
                                    aria-label="Logout"
                                    name="lds-icon-Logout"
                                >
                                </lds-icon>
                            </a>
                        </div>
                    </nav>
                </div>
            `;
    }
    return html``;
  }
}

customElements.define("lds-nav", LdsNav);
