import { pushEvent } from "../../../../../legl-ui/utils";
import { DialogRouter } from "./dialog-router.js";

export class DrawerManager extends DialogRouter {
  constructor() {
    super("drawer");
    this.drawerId = "drawer-router";
    this.drawerElement = this.generateDrawerElement();
    this.drawerElement.addEventListener("hidden", () => {
      this.onHidden();
    });
    this.hasNewIncomingDrawer = false;
  }

  generateDrawerElement() {
    let drawerElement = document.getElementById(this.drawerId);

    if (!drawerElement) {
      drawerElement = document.createElement("legl-drawer");
      drawerElement.id = this.drawerId;
      document.body.appendChild(drawerElement);
    }
    return drawerElement;
  }

  shouldShowDialog(id, params) {
    // If there's no dialog registered we can't show
    if (!this.hasDialog(id)) {
      return false;
    }

    // If it's a completely different dialog configuration we can definitely show
    if (this.activeDialog !== id) {
      return true;
    }

    // If it's the same dialog but differing params we should re-show
    return this.activeParams !== params.toString() ? params.toString() : null;
  }

  async show(id, params) {
    const { component, config } = this.getDialogConfig(id, params);
    if (component) {
      try {
        if (this.hasActiveDialog) {
          this.hasNewIncomingDrawer = true;
          await this.hideActiveDialog();
        }
        component.params = params;
        component.addEventListener("close", (e) => {
          e.preventDefault();
          this.hideActiveDialog();
        });
        if (config.props) {
          Object.assign(this.drawerElement, config.props);
        }
        this.drawerElement.drawerId = id;
        this.drawerElement.innerHTML = "";
        this.drawerElement.appendChild(component);

        await this.drawerElement.show();
        pushEvent("$pageview");
        this.activeDialog = id;
        this.activeParams = params.toString() ? params.toString() : null;
        return this;
      } catch (err) {
        console.error(err);
      }
      return this;
    }
    return Promise.reject(new Error(`Cannot show ${id} with ${params}`));
  }

  async hideActiveDialog() {
    if (this.hasActiveDialog) {
      try {
        if (this.hasNewIncomingDrawer) {
          this.drawerElement.withCloseWarning = false;
        } else {
          await this.drawerElement.closeHandler();
        }
        return this;
      } catch (err) {
        console.error(err);
        throw new Error("Error hiding drawer");
      }
    }
    return this;
  }

  onHidden() {
    pushEvent("$pageleave");
    this.drawerElement.innerHTML = "";
    this.activeDialog = null;
    this.activeParams = null;
    window.location.hash = "";
    this.hasNewIncomingDrawer = false;
  }
}

export const drawers = new DrawerManager();
