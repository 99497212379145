import { createPopper } from "@popperjs/core";
import addMonths from "date-fns/addMonths";
import differenceInMonths from "date-fns/differenceInMonths";
import format from "date-fns/format";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";
import isSameMonth from "date-fns/isSameMonth";
import isSameYear from "date-fns/isSameYear";
import isThisMonth from "date-fns/isThisMonth";
import isWithinInterval from "date-fns/isWithinInterval";
import setMonth from "date-fns/setMonth";
import setYear from "date-fns/setYear";
import subMonths from "date-fns/subMonths";
import subYears from "date-fns/subYears";
import { LitElement, css, html, nothing } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { neutral, primary } from "../../lds-colours";
import { spacing } from "../../lds-spacing";
import { typographyPresets } from "../../lds-typography";
import { monthOptions } from "./month-options";

import "./lds-date-picker-actions";
import "./lds-date-picker-calendar";
import "./lds-date-picker-selector";

export class LdsDatePicker extends LitElement {
  static get styles() {
    return css`
            .button-container {
              width: 100%;
              position: relative;
            }
            .label {
              ${typographyPresets.bodyBold};
              color: ${neutral["800"]};
              margin-bottom: ${spacing.xxs};
              display: block;
            }
            .trigger {
                padding: 12px 15px 12px 12px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                background: #ffffff;
                border: 1px solid ${neutral["300"]};
                border-radius: 4px;
                height: 48px;
                width: 100%;
                cursor: pointer;
            }
            .trigger-highlight {
                border-color: ${primary["500"]};
                border-width: 2px;
            }
            .trigger-inner-container {
                display: flex;
                align-items: center;
            }
            .trigger-label {
                ${typographyPresets.body};
                color: ${neutral["800"]};
                cursor: pointer;
            }
            .trigger-calendar-icon {
                color: ${neutral["700"]};
            }
            .empty-trigger-text {
                color: ${neutral["300"]};
            }
            .actions-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            #tooltip {
                display: inline-block;
                font-weight: bold;
                font-size: 13px;
                border-radius: 4px;
                padding: ${spacing.m};
                background: #ffffff;
                box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.08),
                    0px 3px 8px -1px rgba(0, 0, 0, 0.06),
                    0px 6px 12px -1px rgba(0, 0, 0, 0.05),
                    0px 16px 16px -1px rgba(0, 0, 0, 0.04);
                border-radius: 4px;
                width: 384px;
                box-sizing: border-box;
                z-index: 1000;
            }
            .calendar-month-container {
                display: flex;
                justify-content: space-between;
            }
            .change-buttons {
                display: flex;
            }
            .change-button-icon {
                position: absolute;
                top: 0px;
            }
            .change-button-icon.left {
                left: 3px;
            }
            .change-button-icon.left-center {
                left: 0px;
            }
            .change-button-icon.right {
                right: 3px;
            }
            .change-button-icon.right-center {
                right: 0px;
            }
            .change-button {
                border: none;
                background-color: transparent;
                cursor: pointer;
                padding: 0;
                position: relative;
                height: 16px;
                width: 19px;
            }
            .calendars-container {
                display: flex;
                justify-content: space-between;
            }
            .button-label {
                width: 80px;
                text-align: center;
            }
            .buttons-container {
                display: flex;
                justify-content: flex-end;
                margin-top: 12px;
            }
            .cancel-button {
                margin-right: 12px;
            }
        `;
  }

  static get properties() {
    return {
      label: {
        type: String,
      },
      minDate: {
        type: Object,
      },
      maxDate: {
        type: Object,
      },
      _isOpen: {
        state: true,
      },
      _showMonthSelector: {
        state: true,
      },
      _showYearSelector: {
        state: true,
      },
      _currentDate: {
        state: true,
      },
      _originalDate: {
        state: true,
      },
      modelValue: {
        attribute: false,
      },
    };
  }

  constructor() {
    super();
    this._isOpen = false;
    this._currentDate = new Date();
    this.modelValue = null;
    this._showMonthSelector = false;
    this._showYearSelector = false;
  }

  connectedCallback() {
    super.connectedCallback();
    if (this.modelValue) {
      this._currentDate = this.modelValue;
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener("mouseup", this.onClickBound);
  }

  get triggerText() {
    if (this.modelValue) {
      return format(this.modelValue, "dd / MM / yyyy");
    } else {
      return "Select a date";
    }
  }

  get currentMonthIsthisMonth() {
    return isThisMonth(this._currentDate);
  }

  get currentYearIsWithinYear() {
    const today = new Date();
    return isWithinInterval(this._currentDate, {
      start: subYears(today, 1),
      end: today,
    });
  }

  async handleOpenCalendar() {
    this._isOpen = !this._isOpen;

    if (this._isOpen) {
      window.addEventListener("mouseup", this.onClickBound);
      this._originalDate = this.modelValue;

      await this.updateCompleted;
      const popcorn = this.shadowRoot.querySelector("#popcorn");
      const tooltip = this.shadowRoot.querySelector("#tooltip");

      createPopper(popcorn, tooltip, {
        placement: "bottom-start",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 12],
            },
          },
        ],
      });
    } else {
      this.handleCancel();
    }
  }

  subMonths = (sub) => {
    this._currentDate = subMonths(this._currentDate, sub);
  };

  addMonths = (add) => {
    this._currentDate = addMonths(this._currentDate, add);
  };

  handleDateSelection = async (date) => {
    this.modelValue = date;
    this.requestUpdate();
    await this.updateComplete;
  };

  handleCancel() {
    this.modelValue = this._originalDate;
    this.close();
  }

  handleApply() {
    this.dispatchEvent(new CustomEvent("model-value-changed"));
    this.close();
  }

  onClick(event) {
    const clickedElementIsOutsideCalendar = !event
      .composedPath()
      .includes(this);

    if (clickedElementIsOutsideCalendar) {
      this.handleCancel();
    }
  }

  onClickBound = this.onClick.bind(this);

  close() {
    this._isOpen = false;
    window.removeEventListener("mouseup", this.onClickBound);
  }

  toggleShowMonthSelector() {
    if (this._showYearSelector) {
      this._showYearSelector = false;
    }
    this._showMonthSelector = !this._showMonthSelector;
  }

  toggleShowYearSelector() {
    if (this._showMonthSelector) {
      this._showMonthSelector = false;
    }
    this._showYearSelector = !this._showYearSelector;
  }

  handleMonthSelection({ detail: { selection } }) {
    this._currentDate = setMonth(this._currentDate, selection);
    this.toggleShowMonthSelector();
  }

  handleYearSelection({ detail: { selection } }) {
    this._currentDate = setYear(this._currentDate, selection);
    this.toggleShowYearSelector();
  }

  get yearOptions() {
    const currentYear = getYear(this._currentDate);
    const optionsStart = currentYear - 100 < 0 ? 0 : currentYear - 100;

    let maxYear;
    let minYear;
    let maxMonth;
    let minMonth;
    if (this.maxDate) {
      maxYear = getYear(this.maxDate);
      maxMonth = getMonth(this.maxDate);
    }
    if (this.minDate) {
      minYear = getYear(this.minDate);
      minMonth = getMonth(this.minDate);
    }
    const currentMonth = getMonth(this._currentDate);
    // create an array of values 100 years ahead and behind the current year
    const options = Array.from(Array(201)).reduce(
      (options, currentValue, currentIndex) => {
        const currentOption = optionsStart + (currentIndex + 1);
        let isDisabled = false;
        if (maxYear) {
          if (
            currentOption > maxYear ||
            (currentOption === maxYear && currentMonth > maxMonth)
          ) {
            isDisabled = true;
          }
        }
        if (minYear && !isDisabled) {
          if (
            currentOption < minYear ||
            (currentOption === minYear && currentMonth < minMonth)
          ) {
            isDisabled = true;
          }
        }
        options.push({
          label: currentOption,
          value: currentOption,
          isDisabled,
        });
        return options;
      },
      [],
    );
    return options;
  }

  get monthOptions() {
    let maxYear;
    let minYear;
    let maxMonth;
    let minMonth;
    if (this.maxDate) {
      maxYear = getYear(this.maxDate);
      maxMonth = getMonth(this.maxDate);
    }
    if (this.minDate) {
      minYear = getYear(this.minDate);
      minMonth = getMonth(this.minDate);
    }
    const currentYear = getYear(this._currentDate);
    return monthOptions.map((month) => {
      let isDisabled = false;
      if (maxYear && maxMonth) {
        if (currentYear === maxYear && month.value > maxMonth) {
          isDisabled = true;
        }
      }
      if (minYear && minMonth) {
        if (currentYear === minYear && month.value < minMonth) {
          isDisabled = true;
        }
      }
      return { ...month, isDisabled };
    });
  }

  render() {
    return html`<div class="button-container">
            <label class="label">${this.label}</label>
            <button
                class=${classMap({
                  trigger: true,
                  "trigger-highlight": this._isOpen,
                })}
                id="popcorn"
                @click=${() => this.handleOpenCalendar()}
                aria-describedby="tooltip"
            >
                <label
                    class=${classMap({
                      "trigger-label": true,
                      "empty-trigger-text": !this.modelValue,
                    })}
                    >${this.triggerText}</label
                >
                <lds-icon
                    class="trigger-calendar-icon"
                    name="lds-icon-Calendar"
                ></lds-icon>
            </button>
            ${
              this._isOpen
                ? html` <div id="tooltip" role="tooltip">
                      <div class="actions-container">
                          <lds-date-picker-actions
                              @show-selector=${this.toggleShowMonthSelector}
                              .label=${format(this._currentDate, "LLL")}
                              .addMonths=${() => this.addMonths(1)}
                              .subMonths=${() => this.subMonths(1)}
                              .minDate=${this.minDate}
                              .maxDate=${this.maxDate}
                              .isDisabled=${this._showYearSelector}
                              .subButtonIsDisabled=${isSameMonth(
                                this._currentDate,
                                this.minDate,
                              )}
                              .addButtonIsDisabled=${isSameMonth(
                                this._currentDate,
                                this.maxDate,
                              )}
                          >
                          </lds-date-picker-actions>
                          <lds-date-picker-actions
                              @show-selector=${this.toggleShowYearSelector}
                              .label=${format(this._currentDate, "yyyy")}
                              .addMonths=${() => this.addMonths(12)}
                              .subMonths=${() => this.subMonths(12)}
                              .minDate=${this.minDate}
                              .maxDate=${this.maxDate}
                              .currentDate=${this._currentDate}
                              .isDisabled=${this._showMonthSelector}
                              .subButtonIsDisabled=${
                                isSameYear(this._currentDate, this.minDate) ||
                                differenceInMonths(
                                  this._currentDate,
                                  this.minDate,
                                ) < 12
                              }
                              .addButtonIsDisabled=${
                                isSameYear(this._currentDate, this.maxDate) ||
                                differenceInMonths(
                                  this._currentDate,
                                  this.maxDate,
                                ) > -11
                              }
                          >
                          </lds-date-picker-actions>
                      </div>
                      ${
                        this._showYearSelector
                          ? html` <lds-date-picker-selector
                                .isDisabled=${this._showMonthSelector}
                                .options=${this.yearOptions}
                                .currentSelection=${getYear(this._currentDate)}
                                @on-select=${this.handleYearSelection}
                            >
                            </lds-date-picker-selector>`
                          : this._showMonthSelector
                            ? html` <lds-date-picker-selector
                                .isDisabled=${this._showYearSelector}
                                .options=${this.monthOptions}
                                .currentSelection=${getMonth(this._currentDate)}
                                @on-select=${this.handleMonthSelection}
                            >
                            </lds-date-picker-selector>`
                            : html` <div>
                                <div class="calendars-container">
                                    <lds-date-picker-calendar
                                        .onSelectDay=${this.handleDateSelection}
                                        .date=${this.modelValue}
                                        .currentDate=${this._currentDate}
                                        .minDate=${this.minDate}
                                        .maxDate=${this.maxDate}
                                    ></lds-date-picker-calendar>
                                </div>
                                <div class="buttons-container">
                                    <lds-button
                                        class="cancel-button"
                                        id="cancel-button"
                                        variant="outlined"
                                        @click=${this.handleCancel}
                                        small
                                    >
                                        <span class="button-label">Cancel</span>
                                    </lds-button>
                                    <lds-button
                                        id="apply-button"
                                        @click=${this.handleApply}
                                        colour="primary"
                                        ?disabled=${!this.modelValue}
                                        small
                                    >
                                        <span class="button-label">Select</span>
                                    </lds-button>
                                </div>
                            </div>`
                      }
                  </div>`
                : nothing
            }
        </div>`;
  }
}

customElements.define("lds-date-picker", LdsDatePicker);
