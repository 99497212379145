import { LitElement, css, html } from "lit";

export class LeglAdminDepartmentsIntro extends LitElement {
  static get styles() {
    return css`
            :host .columns {
                padding: 0 35px;
            }

            :host .column {
                margin: 0 35px;
                line-height: 2rem;
            }

            :host .column p:first-child {
                margin-top: 0;
            }

            :host .column img {
                width: 100%;
            }
        `;
  }
  static get properties() {
    return {
      introImage: {
        type: String,
      },
    };
  }

  render() {
    return html`
            <div class="columns">
                <div class="column">
                    <p>
                        Help us
                        <strong>customize your teams experience</strong>
                        and provide better reporting by identifying the
                        departments that make sense within your firm. This will
                        enable clear insights for each user and client across
                        all departments.
                    </p>
                    <p>
                        If you have more questions please contact us via
                        intercom chat always in the bottom corner of Legl.
                    </p>
                </div>
                <div class="column">
                    ${
                      this.introImage
                        ? html`<img
                              src="${this.introImage}"
                              alt="Intro Image"
                          />`
                        : ""
                    }
                </div>
            </div>
        `;
  }
}

customElements.define(
  "legl-admin-departments-intro",
  LeglAdminDepartmentsIntro,
);
