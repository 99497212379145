import { html, render } from "lit";
import { BaseAdapter } from "./base-adapter";

export class StripeAdapter extends BaseAdapter {
  scriptSrc = "https://js.stripe.com/v3/";

  async _initialize(elementSlot) {
    const component = elementSlot.getElementsByTagName(
      "pay-stripe-component",
    )[0];
    await component.updateComplete;
    this.#applyConfiguration(component);
    component.initializeStripe();
  }

  #applyConfiguration(component) {
    component.clientSecret = this.providerDetails?.client_secret;
    component.acceptHigherFeeCards =
      this.providerDetails?.accept_higher_fee_cards;
    component.paymentIntentId = this.providerDetails?.payment_intent_id;
    component.currentUrl = this.providerDetails?.checkout_url;
    component.paymentDetails = this.paymentDetails;

    component.amount = this.amount;
  }

  async _appendPaymentMethod(DomElement) {
    const component = html`<pay-stripe-component
            company-name="Legl Rules"
            initialize-manually
        >
        </pay-stripe-component>`;

    render(component, DomElement);

    await component.updateComplete;
  }
}
