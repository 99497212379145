import { LitElement, css, html } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { neutral } from "../../../legl-ui/lds-colours";
import { typographyPresets } from "../../../legl-ui/lds-typography";
import "./nav-item-group.js";

export class LeglLawyerAccountNavSection extends LitElement {
  static get properties() {
    return {
      section: {
        type: Object,
      },
    };
  }

  static get styles() {
    return css`
            .section {
                position: relative;
                padding-top: 16px;
            }

            .section.has-top-border:before {
                height: 0;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                border-bottom: 1px solid ${neutral["800"]};
                background: transparent;
                content: "";
            }

            h3 {
                ${typographyPresets.medium};
                letter-spacing: 1px;
                font-weight: bold;
                text-transform: uppercase;
                margin: 0 0 16px 0;
                padding-left: 20px;
            }
        `;
  }

  get sectionClasses() {
    return {
      section: true,
      "has-top-border": this.section.lineAbove,
    };
  }

  get navGroup() {
    return this.shadowRoot.querySelector(".nav-group");
  }

  collapseSubMenus(excludedItems = []) {
    this.navGroup.collapseSubMenus(excludedItems);
  }

  render() {
    return html`
            <div class="${classMap(this.sectionClasses)}">
                ${
                  this.section.title ? html`<h3>${this.section.title}</h3>` : ""
                }
                <legl-lawyer-account-nav-item-group
                    class="nav-group"
                    .items=${this.section.children}
                ></legl-lawyer-account-nav-item-group>
            </div>
        `;
  }
}

customElements.define(
  "legl-lawyer-account-nav-section",
  LeglLawyerAccountNavSection,
);
