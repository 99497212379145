import { LitElement, css, html } from "lit";
import { toastService } from "../../../../../../legl-ui/toast";
import { millisecondsToDate } from "../../../../../../legl-ui/utils";
import { get } from "../../../../../../legl-ui/utils/fetch";

export default class SignaturePanelStep extends LitElement {
  static get styles() {
    return css`
            dl dd {
                margin: 0 0 12px 0;
            }
            .panel-details {
                margin-top: -10px;
            }
        `;
  }
  static get properties() {
    return {
      panel: {
        type: Object,
      },
      metadata: { type: Object },
      signedTime: { type: String },
      label: { type: String },
      signedDocUrl: { type: String },
      stepTitle: { type: String },
    };
  }

  constructor() {
    super();
    this.label = "";
  }

  async connectedCallback() {
    super.connectedCallback();
    const sigRequestId = this.panel.element_config.signature_request_id;
    const sig = await get(`/api/hellosign/signature_request/${sigRequestId}`);
    if (sig.ok) {
      this.metadata = await sig.json();
      this.calculateSignatureStatus();
    } else {
      toastService.showError(
        "An unexpected error has occurred, please check your internet connection and try again.",
      );
    }
  }

  calculateSignatureStatus() {
    if (
      !this.metadata?.signature_request?.signatures[0]?.last_viewed_at &&
      !this.metadata?.signature_request?.is_complete
    ) {
      this.label = "";
      this.panelStatus = "Created";
    } else if (this.metadata?.signature_request?.is_declined) {
      this.signedTime = millisecondsToDate(
        new Date(
          this.metadata.signature_request.signatures[0].last_viewed_at * 1000,
        ),
        "#DD# #MMM# #YYYY#, #hhhh#:#mm#",
      );
      this.panelStatus = "Declined";
      this.label = "Declined:";
    } else if (
      this.metadata?.signature_request?.signatures[0]?.last_viewed_at &&
      !this.metadata?.signature_request?.is_complete
    ) {
      this.signedTime = millisecondsToDate(
        new Date(
          this.metadata.signature_request.signatures[0].last_viewed_at * 1000,
        ),
        "#DD# #MMM# #YYYY#, #hhhh#:#mm#",
      );

      this.panelStatus = "In progress";
      this.label = "Viewed:";
    } else if (
      this.metadata?.signature_request?.signatures[0]?.last_viewed_at &&
      this.metadata?.signature_request?.is_complete
    ) {
      this.signedTime = millisecondsToDate(
        new Date(
          this.metadata.signature_request.signatures[0].signed_at * 1000,
        ),
        "#DD# #MMM# #YYYY#, #hhhh#:#mm#",
      );
      this.panelStatus = "Completed";
      this.label = "Signed:";
    }
  }

  render() {
    return html` <div class="panel">
            <legl-engage-panel
                .status=${this.panelStatus}
                .canHavePDF=${true}
                .isLocked=${false}
                .pdfURL=${this.panel.pdf_url}
                title=${this.stepTitle || "Signature request"}
            >
                <dl class="panel-details">
                    <dd>
                        <strong>File name:</strong>
                        ${this.panel.element_config.signature_request_filename}
                    </dd>
                    <dd>
                        <strong>${this.label}</strong>
                        ${this.signedTime}
                    </dd>
                </dl>
            </legl-engage-panel>
        </div>`;
  }
}
customElements.define("legl-signature-panel-step", SignaturePanelStep);
