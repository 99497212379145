import { LitElement, css, html } from "lit";
import "../../../../../../../legl-ui/action-bar";
import { LdsModal } from "../../../../../../../legl-ui/lds-modal";
import permissions from "../../../../../../../services/getCurrentUserPermissions";
import "../../../pages/engage/components/modals/close-link/close-link-modal";
import { createClipboardButton } from "../../../utils/clipboard.js";
import { forceComplete } from "../force-complete.js";

export class LeglEngageReviewActions extends LitElement {
  static get styles() {
    return css`
            :host {
                --legl-action-bar-min-width: 0;
            }
        `;
  }

  static get properties() {
    return {
      application: {
        type: Object,
        attribute: false,
      },
    };
  }

  onForceComplete() {
    forceComplete(this.application);
  }

  get paymentLink() {
    const params = new URLSearchParams();
    params.append("contactUid", this?.application?.contact?.uid);
    params.append("firstName", this.application.first_name);
    params.append("lastName", this.application.last_name);
    params.append("email", this.application.email);
    return `#/drawer/pay-create/?${params.toString()}`;
  }

  firstUpdated(changedProperties) {
    super.firstUpdated(changedProperties);
    const copyButton = this.shadowRoot.querySelector("#clipboard-button");
    if (copyButton) {
      createClipboardButton(copyButton);
    }
  }

  render() {
    return html`
            <legl-action-bar>
                <button
                    id="clipboard-button"
                    data-toast-success="true"
                    data-reference="${this.application.reference}"
                    .disabled=${this.application.disable_buttons}
                    title="Copy engage request link"
                    data-cy-copy-link
                    data-action="copy-link-button"
                    data-clipboard-text="${this.application.link}"
                    slot="secondary"
                >
                    Copy Link
                </button>
                <button
                    data-cy-close-link
                    .disabled=${!this.application.is_closable}
                    slot="secondary"
                    @click=${() => {
                      LdsModal.create({
                        title: "Close Request Link",
                        slotComponent: "close-link-modal",
                        slotComponentProperties: {
                          reference: this.application.reference,
                          endpoint: "/engage-mark-closed/",
                          linkType: "engage",
                        },
                      });
                    }}
                >
                    Close Link
                </button>
                ${
                  permissions.hasPayAccess()
                    ? html` <a
                          data-button="payment_link_button"
                          data-reference="${this.application.reference}"
                          data-cy-pay-request
                          href=${this.paymentLink}
                          slot="secondary"
                      >
                          Send Pay Request
                      </a>`
                    : ""
                }
                <button
                    @click=${() => this.onForceComplete()}
                    data-cy-force-complete
                    slot="secondary"
                    .disabled=${
                      this.application.status.toLowerCase() !== "in progress"
                    }
                >
                    Force Complete
                </button>
            </legl-action-bar>
        `;
  }
}

customElements.define("legl-engage-review-actions", LeglEngageReviewActions);
