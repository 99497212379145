import { createReducer } from "@reduxjs/toolkit";
import {
  addContact,
  loadContacts,
  loadMoreContacts,
  resetContacts,
  setContactsCount,
  setCurrentContact,
  setNextPageUrl,
  updateContact,
} from "../../actions/contacts";

const contactsReducer = createReducer(
  {
    list: {},
    currentContact: {},
    count: 0,
    nextPageUrl: null,
  },
  (builder) => {
    builder.addCase(setCurrentContact, (state, action) => {
      state.currentContact = action.payload;
      return state;
    });
    builder.addCase(setContactsCount, (state, action) => {
      state.count = action.payload;
      return state;
    });
    builder.addCase(setNextPageUrl, (state, action) => {
      state.nextPageUrl = action.payload;
      return state;
    });
    builder.addCase(loadContacts, (state, action) => {
      state.list = Object.keys(action.payload).reduce((acc, cv) => {
        if (state.list.hasOwnProperty(cv)) {
          acc[cv] = state.list[cv];
        } else {
          acc[cv] = action.payload[cv];
        }
        return acc;
      }, {});
      return state;
    });
    builder.addCase(loadMoreContacts, (state, action) => {
      state.list = Object.keys({
        ...action.payload,
        ...state.list,
      }).reduce((acc, cv) => {
        if (state.list.hasOwnProperty(cv)) {
          acc[cv] = state.list[cv];
        } else {
          acc[cv] = action.payload[cv];
        }
        return acc;
      }, {});
      return state;
    });
    builder.addCase(addContact, (state, action) => {
      state.list[action.payload.uid] = action.payload;
      return state;
    });
    builder.addCase(updateContact, (state, action) => {
      state.currentContact = {
        ...state.currentContact,
        ...action.payload,
      };

      if (state.list[action.payload.uid]) {
        state.list[action.payload.uid] = {
          ...state.list[action.payload.uid],
          ...action.payload,
        };
      }
      return state;
    });

    builder.addCase(resetContacts, (state) => {
      state.list = {};
      state.count = 0;
      state.nextPageUrl = null;
      return state;
    });
  },
);

export default contactsReducer;
