import { LitElement, css, html } from "lit";
import { classMap } from "lit/directives/class-map.js";
import "../../../../../../../legl-ui/results-steps";
import "./engage-cdd.js";
export class EngageApplicationStepSummary extends LitElement {
  static get styles() {
    return css`
            :host {
                display: block;
                text-transform: capitalize;
            }

            legl-results-step {
                margin-bottom: 32px;
            }

            legl-results-step.is-full-width {
                width: 100%;
            }
        `;
  }
  static get properties() {
    return {
      steps: {
        type: Array,
      },
    };
  }
  render() {
    return html` <legl-results-steps>
            ${this.steps.map((step) => {
              if (
                !["basic-information", "cdd-documents"].includes(
                  step.element_type,
                )
              ) {
                return EngageApplicationStepSummary.getStepTemplate(step);
              }
            })}
        </legl-results-steps>`;
  }

  static getStepIcon(step) {
    if (step.element_type !== "onfido" && step.status) {
      switch (step.status.toLowerCase()) {
        case "completed":
          return "legl-icon-confirm-circle";
        case "incomplete":
        case "force completed":
          return "legl-icon-minus-circle";
        default:
      }
    }
    return;
  }

  static getStepResult(step) {
    if (step.element_type === "onfido" && !step.single_layer) {
      return html`<legl-engage-cdd
                .onfidoStep=${step}
            ></legl-engage-cdd>`;
    }
    return step.status;
  }

  static getStepColor(step) {
    if (step.status) {
      switch (step.status.toLowerCase()) {
        case "completed":
          return "success";
        case "incomplete":
        case "force completed":
          return "default";
        default:
          return "";
      }
    }
  }

  static getStepClasses(step) {
    const classes = {};
    if (step.element_type === "onfido" && !step.single_layer) {
      classes["is-full-width"] = true;
    }
    return classes;
  }

  static humanReadableType(step) {
    switch (step?.element_type) {
      case "onfido":
        return "Client Due Diligence";
      case "document-request":
        return "Document request";
      default:
        return step?.title || "";
    }
  }

  static getStepTemplate(step) {
    return html`<legl-results-step
                class="${classMap(
                  EngageApplicationStepSummary.getStepClasses(step),
                )}"
                .title=${this.humanReadableType(step)}
                .color=${EngageApplicationStepSummary.getStepColor(step)}
                .icon=${EngageApplicationStepSummary.getStepIcon(step)}
                data-cy-step-summary-step="${step.title}"
            >
                ${EngageApplicationStepSummary.getStepResult(step)} </legl-results-step
            ><br />`;
  }
}

customElements.define(
  "legl-engage-application-step-summary",
  EngageApplicationStepSummary,
);
