import { createReducer } from "@reduxjs/toolkit";
import { fetchPaymentDetails, updatePaymentStatus } from "../../actions/pay";

const payReducer = createReducer({}, (builder) => {
  builder.addCase(fetchPaymentDetails, (state, action) => {
    state[action.payload.id] = action.payload;
    return state;
  });
  builder.addCase(updatePaymentStatus, (state, action) => {
    if (state[action.payload]) {
      state[action.payload].status = "Sent";
    }
    return state;
  });
});

export default payReducer;
