import { css, html } from "lit";

import { toastService } from "../../../legl-ui/toast";
import { LeglButton } from "./legl-button.js";

export class LeglCopyButton extends LeglButton {
  static get properties() {
    return {
      copyValue: {
        type: String,
        attribute: "copy-value",
      },
      successMessage: {
        type: String,
        attribute: "success-message",
      },
      successIcon: {
        type: String,
        attribute: "success-icon",
      },
      showToast: {
        type: Boolean,
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
                :host textarea {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 2em;
                    height: 2em;
                    padding: 0;
                    border: none;
                    outline: none;
                    box-shadow: none;
                    background: transparent;
                }
            `,
    ];
  }

  constructor() {
    super();
    this.successMessage = "Copied!";
    this.successIcon = "legl-icon-tick";
    this.showToast = null;
  }

  copySuccess() {
    const buttonSlot = this.shadowRoot.querySelector("slot").assignedNodes()[0];
    const originalButtonText = buttonSlot.textContent;
    const originalIcon = this.icon;
    buttonSlot.textContent = this.successMessage;
    this.icon = this.successIcon;
    if (this.showToast) {
      toastService.showSuccess(this.successMessage);
    }
    setTimeout(() => {
      buttonSlot.textContent = originalButtonText;
      this.icon = originalIcon;
    }, 1250);
  }

  copyText() {
    try {
      const textArea = document.createElement("textarea");
      textArea.value = this.copyValue;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      this.copySuccess();
    } catch (error) {
      console.error(error);
      toastService.showError(
        "Error copying, if this persists please contact support.",
      );
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.addEventListener("click", this.copyText);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener("click", this.copyText);
  }
}

customElements.define("legl-copy-button", LeglCopyButton);
