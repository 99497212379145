import { css, html } from "lit";

import {
  LeglIsEmail,
  LeglMinLength,
  LeglNonEmpty,
  LeglRequired,
  LeglServerMessagesEmptyValue,
} from "../../../../../../legl-ui/input";
import "../../../../../../legl-ui/input/legl-select.js";
import { LdsModal } from "../../../../../../legl-ui/lds-modal";
import { ElementBase } from "../element_base.js";

import * as Sentry from "@sentry/browser";
import LeglFormValidationMixin from "../../../../../../../static-src/js/mixins/LeglFormValidationMixin";
import "../../../../../../legl-ui/input/legl-select.js";
import { neutral } from "../../../../../../legl-ui/lds-colours";
import { spacing } from "../../../../../../legl-ui/lds-spacing";
import { LdsToast } from "../../../../../../legl-ui/lds-toast";
import { typographyPresets } from "../../../../../../legl-ui/lds-typography";
import "../../../../../../legl-ui/postcode-lookup";
import { toastService } from "../../../../../../legl-ui/toast";
import { engageEventBus } from "../engage-event-bus.js";
import { countryList } from "./supported-applicant-countries";

const addressPartKeys = ["line1", "line2", "line3", "postcode"];

export class BasicInformationElement extends LeglFormValidationMixin(
  ElementBase,
) {
  static get styles() {
    return css`
            :host {
                ${typographyPresets.body};
                color: ${neutral["700"]};
            }

            .element-container {
                padding: ${spacing.xs} ${spacing.m} ${spacing.m};
            }

            @media only screen and (max-width: 1030px) {
                .element-container {
                    padding: ${spacing.xs} ${spacing.s} ${spacing.m};
                }
            }

            .form-instructions {
                margin: 0 0 20px;
                color: ${neutral["800"]};
            }

            .legl-account__form-group {
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                display: inline-block;
                height: 50px;
                width: 100%;
                border: 1px solid ${neutral["200"]};
                margin: 10px 0;
                background-color: #ffffff;
                border-radius: 3px;
                position: relative;
                padding: 0 1rem 1rem 0.5rem;
            }
            .legl-account__checkbox {
                text-align: left;
            }
            .legl-account__form-group label:not(.checkbox) {
                border-radius: 25px;
                position: absolute;
                z-index: 12;
                background-color: #ffffff;
                color: ${neutral["700"]};
                font-style: italic;
                display: block;
                top: 49%;
                left: 7px;
                font-size: 17px;
                padding: 0 0.1rem;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                -webkit-transition: top 0.25s ease-in-out,
                    font-size 0.25s ease-in-out;
                -o-transition: top 0.25s ease-in-out,
                    font-size 0.25s ease-in-out;
                transition: top 0.25s ease-in-out, font-size 0.25s ease-in-out;
            }

            .legl-account__form-group--date-of-birth:not(.checkbox) label {
                top: -2px;
                font-size: 10px;
                background: -webkit-gradient(
                    linear,
                    left top,
                    left bottom,
                    from(white),
                    color-stop(65%, white),
                    color-stop(64%, rgba(0, 0, 0, 0)),
                    to(rgba(0, 0, 0, 0))
                );
                background: -webkit-linear-gradient(
                    top,
                    white 0%,
                    white 65%,
                    rgba(0, 0, 0, 0) 64%,
                    rgba(0, 0, 0, 0) 100%
                );
                background: -o-linear-gradient(
                    top,
                    white 0%,
                    white 65%,
                    rgba(0, 0, 0, 0) 64%,
                    rgba(0, 0, 0, 0) 100%
                );
                background: linear-gradient(
                    180deg,
                    white 0%,
                    white 65%,
                    rgba(0, 0, 0, 0) 64%,
                    rgba(0, 0, 0, 0) 100%
                );
            }

            .legl-account__form-group input:focus + label {
                color: ${neutral["800"]};
            }

            .legl-account__form-group:focus-within {
                border: 1px solid ${neutral["800"]};
            }

            .legl-account__form-group input {
                top: 7px;
                font-family: "Open Sans", sans-serif !important;
                position: relative;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                width: calc(100% - 30px);
                border: none;
                background-color: transparent;
                outline-width: 0;
                font-weight: normal;
                font-size: 1.3em;
                color: ${neutral["700"]};
                padding-right: 30px;
                box-shadow: none;
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            textarea:-webkit-autofill,
            textarea:-webkit-autofill:hover,
            textarea:-webkit-autofill:focus,
            select:-webkit-autofill,
            select:-webkit-autofill:hover,
            select:-webkit-autofill:focus {
                /* Chrome transitions the background to blue or yellow and there doesn't
        seem to be a way to reliably override this other than a long delay */
                -webkit-transition-delay: 9999s;
                transition-delay: 9999s;
                background-color: transparent;
            }

            input[type="checkbox"] {
                display: none;
            }

            input[type="checkbox"] + label {
                display: block;
                position: relative;
                padding-left: 35px;
                margin-bottom: 20px;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
            }

            input[type="checkbox"] + label:last-child {
                margin-bottom: 0;
            }

            input[type="checkbox"] + label:before {
                content: "";
                display: block;
                width: 20px;
                height: 20px;
                border: 3px solid #435d54;
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0.6;
                -webkit-transition: all 0.12s, border-color 0.08s;
                transition: all 0.12s, border-color 0.08s;
            }

            input[type="checkbox"]:checked + label:before {
                width: 10px;
                top: -5px;
                left: 5px;
                border-radius: 0;
                opacity: 1;
                border-top-color: transparent;
                border-left-color: transparent;
                border-right-color: #29d273;
                border-bottom-color: #29d273;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                color: ${neutral["800"]};
            }

            .submit-button {
                cursor: pointer;
                background-color: ${neutral["800"]};
                transition: background-color 0.5s, color 0.4s,
                    opacity 0.3s ease-in-out;
                border-radius: 0 0 4px 4px;
                display: block;
                font-size: 1.3em;
                font-family: "Lato", sans-serif;
                font-style: italic;
                font-weight: normal;
                text-align: center;
                color: white;
                outline: none;
                border: none;
            }

            .submit-button:not(:disabled):hover {
                background-color: #2f2e3f;
                opacity: 1;
            }

            .submit-button:disabled,
            .submit-button.soft-disabled {
                opacity: 0.4;
            }

            a {
                color: ${neutral["700"]};
            }

            .w-34 {
                width: 34%;
            }

            .inline-group {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
            }

            .inline-group .inline-item {
                flex: 1;
                margin: 0 10px;
            }

            .inline-group .inline-wide {
                flex: 2;
            }

            .inline-group .inline-item:first-child {
                margin-left: 0;
            }

            .inline-group .inline-item:last-child {
                margin-right: 0;
            }

            @media only screen and (max-width: 480px) {
                .inline-group {
                    flex-wrap: wrap;
                }
                .inline-group .inline-item {
                    flex: none;
                    width: 100%;
                    margin: 0;
                }
            }

            .sub-heading-text {
                text-align: left;
                font-size: 1.4rem;
                color: ${neutral["700"]};
                margin-top: 1.5rem;
                margin-bottom: 1rem;
            }

            .margin-top {
                margin-top: ${spacing.s};
            }
        `;
  }

  constructor() {
    super();
    this.serverErrorMessages = {};
    this.countries = "[]";
    this.country = "GBR";
    this.isBusy = false;
    this.countryList = countryList;
    this.postcode_lookup_url = false;
  }

  render() {
    return html`
      <form @submit="${this.onSubmit}" data-cy-basic-info-form>
        <div class="element-container">
            ${
              this.hideNameAndEmailField
                ? ""
                : html`<p class="form-instructions">
                          Please enter all names and date of birth exactly as
                          they appear on your government issued photo ID.
                      </p>`
            }
          ${this.nameAndEmailFields}
          <div class="inline-group">
            <div class="inline-item inline-wide">
              <lds-input
                class="margin-top"
                label="Phone number"
                name="phone_number"
                .validators="${this.getValidatorsForField("phone_number")}"
                .modelValue="${this.phone_number}"
                @model-value-changed="${(e) => {
                  this.phone_number = e.target.modelValue;
                }}"
                data-cy-basic-info-phone>
              </lds-input>
            </div>
            <div class="inline-item">
              <lds-date-of-birth-input .dayValue=${
                this.formattedDob[0]
              } .monthValue=${this.formattedDob[1]} .yearValue=${
                this.formattedDob[2]
              }></lds-date-of-birth-input>
            </div>
          </div>
          <div>
            <div>
                <lds-select
                    data-testid="select-country"
                    data-cy-basic-info-country
                    class="margin-top"
                    label="Country of Residence"
                    name="country"
                    autocomplete="country"
                    ?disabled=${this.reportType === "standard_cdd_no_id"}
                    .modelValue="${this.country}"
                    @model-value-changed="${(e) => {
                      this.country = e.target.modelValue;
                      this.foundAddresses = null;
                      this.state = null;
                      this.dispatchInternationalAddressEvent();
                    }}">
                    <select slot="input"
                    data-cy-basic-info-country-input
                    >
                    ${this.countryList.map((option) =>
                      option.supported_document_report
                        ? html`
                                  <option
                                      ?selected=${option.alpha3 == "GBR"}
                                      class="ph-no-capture"
                                      value="${option.alpha3}"
                                  >
                                      ${option.name}
                                  </option>
                              `
                        : ``,
                    )}
                </select>
                </lds-select>
            </div>
            ${
              this.country === "GBR"
                ? html`<div class="margin-top">
                          <legl-postcode-lookup
                              data-cy-postcode-lookup
                              lookup-url=${this.postcode_lookup_url}
                              @lookupLoaded=${(e) => {
                                this.addressLookupSkipped = false;
                                this.foundAddresses = e.detail.addresses;
                                this.requestUpdate();
                              }}
                              @lookupSkipped=${() => {
                                this.addressLookupSkipped = true;
                                this.foundAddresses = null;
                              }}
                              @lookupError=${(e) => {
                                toastService.showError(
                                  "Something went wrong. Please try again later.",
                                );
                                console.error(e.detail);
                              }}
                          ></legl-postcode-lookup>
                      </div>`
                : ""
            }

          </div>
          ${this.addressSelector}
          <div .hidden=${!this.showAddressParts}>
            <lds-input
                class="source-input margin-top"
                label="Address line 1"
                name="line1"
                .validators="${this.getValidatorsForField("line1")}"
                .modelValue="${this.line1}"
                @model-value-changed="${(e) => {
                  this.line1 = e.target.modelValue;
                  // We reset the equifax_addr_fmt when a user manually edits the input field.
                  if (e.detail.isTriggeredByUser) {
                    this.address_breakdown = null;
                  }
                }}"
                data-cy-basic-info-address-line-one>
            </lds-input>
            <lds-input
                class="margin-top"
                label="Address line 2"
                name="line2"
                .validators="${this.getValidatorsForField("line2")}"
                .modelValue="${this.line2}"
                @model-value-changed="${(e) => {
                  this.line2 = e.target.modelValue;
                }}">
            </lds-input>
            <div class="inline-group">
                <div class="inline-item ${
                  this.country === "GBR" ? "inline-wide" : ""
                }">
                    <lds-input
                        class="margin-top"
                        label="Town/City"
                        name="line3"
                        .validators="${this.getValidatorsForField("line3")}"
                        .modelValue="${this.line3}"
                        @model-value-changed="${(e) => {
                          this.line3 = e.target.modelValue;
                        }}"
                        data-cy-basic-info-city>
                    </lds-input>
                    </div>
                    ${this.stateSelector}
                    <div class="inline-item w-34">
                        <lds-input
                            class="source-input margin-top"
                            label="${this.postcodeLabel}"
                            name="postcode"
                            autocomplete="postal-code"
                            .modelValue="${this.postcode}"
                            .validators="${this.getValidatorsForField(
                              "postcode",
                            )}"
                            @model-value-changed="${(e) => {
                              this.postcode = e.target.modelValue;
                            }}"
                            data-cy-basic-info-postcode>
                        </lds-input>
                    </div>
                </div>
            </div>
        </div>
      </div>
        <lds-button
          type="submit"
          class="submit-button"
          full-width
          data-cy-basic-info-continue
          .disabled=${this.isBusy || this.isFormDisabled}
          .isLoading=${this.isBusy}
          >Continue</lds-button
      >
    </form>

      `;
  }

  static get properties() {
    return {
      ...super.properties,
      first_name: { type: String },
      middle_name: { type: String },
      last_name: { type: String },
      email: { type: String },
      phone_number: { type: Number },
      date_of_birth: { type: String },
      country: { type: String },
      line1: { type: String },
      line2: { type: String },
      line3: { type: String },
      state: { type: String },
      postcode: { type: String },
      serverErrorMessages: { type: Object },
      countries: { type: String },
      us_states: { type: String },
      foundAddresses: { type: Array },
      addressLookupSkipped: { type: Boolean },
      countryList: { type: Array },
      postcode_lookup_url: { type: String },
      is_dob_optional: { type: Boolean },
    };
  }

  connectedCallback() {
    super.connectedCallback();
    this.dispatchInternationalAddressEvent();
  }

  get formattedDob() {
    return this.date_of_birth ? this.date_of_birth.split("/") : "";
  }

  get formattedAddress() {
    return BasicInformationElement.formatAddress(
      this.line1,
      this.line2,
      this.line3,
      this.postcode,
    );
  }

  get formattedName() {
    return [this.first_name, this.middle_name, this.last_name]
      .filter((namePart) => namePart && namePart.toString().length > 0)
      .join(" ");
  }

  get postcodeLabel() {
    if (this.country === "USA") {
      return "Zip code";
    }
    return "Postcode";
  }

  get completedData() {
    return `
        <div>
            <p>Name: ${this.formattedName}</p>
            <p>Email: ${this.email ?? "-"}</p>
            <p>Date of birth: ${this.date_of_birth ?? "-"}</p>
            <p>Address: ${this.formattedAddress ?? "-"}</p>
        </div>`;
  }

  get countriesForSelectBox() {
    return JSON.parse(this.countries);
  }

  get usStatesForSelectBox() {
    return [
      { label: "Select State", value: null },
      ...JSON.parse(this.us_states),
    ];
  }

  get stateSelector() {
    if (this.country === "GBR") {
      return html``;
    }
    return html`
            <div class="inline-item">
                ${
                  this.country === "USA"
                    ? html`<lds-select
                          label="State"
                          name="state"
                          .validators="${this.getValidatorsForField("state")}"
                          .modelValue="${this.state}"
                          @model-value-changed="${(e) => {
                            this.state = e.target.modelValue;
                          }}"
                      >
                          <select slot="input">
                              ${this.usStatesForSelectBox.map(
                                (option) => html`
                                      <option
                                          class="ph-no-capture"
                                          value="${option.value}"
                                      >
                                          ${option.label}
                                      </option>
                                  `,
                              )}
                          </select>
                      </lds-select>`
                    : html`<lds-input
                          label="State/Province"
                          name="state"
                          .validators="${this.getValidatorsForField("state")}"
                          .modelValue="${this.state}"
                          @model-value-changed="${(e) => {
                            this.state = e.target.modelValue;
                          }}"
                          data-cy-basic-info-state
                      >
                      </lds-input>`
                }
            </div>
        `;
  }

  get showAddressParts() {
    // We must know the country to proceed
    if (!this.country) {
      return false;
    }

    // Any country other than the UK will not use the address search at this time
    if (this.country !== "GBR") {
      return true;
    }

    // This occurs when the user explicitly selects "Find manually"
    if (this.addressLookupSkipped) {
      return true;
    }

    if (Object.keys(this.serverErrorMessages).length !== 0) {
      return true;
    }

    // If we can't find any matches for their postcode then they'll likely want to enter it manually
    if (this.foundAddresses && this.foundAddresses.length === 0) {
      return true;
    }

    // Otherwise if any address fields are filled out from prior form
    // submission or them selecting an address
    return addressPartKeys.reduce(
      (shouldShow, key) => shouldShow || (this[key] && this[key].length > 0),
      false,
    );
  }

  get addressesFormattedForSelect() {
    if (!this.foundAddresses || this.foundAddresses.length === 0) {
      return [{ label: "0 Results", value: null }];
    }
    return [
      { label: "Select an address", value: null },
      ...(this.foundAddresses || []).map((address) => {
        return {
          label: BasicInformationElement.formatAddress(
            ...Object.values(address),
          ),
          value: JSON.stringify(address),
        };
      }),
    ];
  }

  get nameAndEmailFields() {
    if (!this.hideNameAndEmailField) {
      return html`<div class="inline-group">
                    <div class="inline-item">
                        <lds-input
                            autocomplete="given-name"
                            label="Full First Name"
                            name="first_name"
                            .modelValue="${this.first_name}"
                            .validators="${this.getValidatorsForField(
                              "first_name",
                            )}"
                            @model-value-changed="${(e) => {
                              this.first_name = e.target.modelValue;
                            }}"
                            data-cy-basic-info-first-name
                        >
                        </lds-input>
                    </div>
                    <div class="inline-item">
                        <lds-input
                            label="Any Middle Names"
                            name="middle_name"
                            .modelValue="${this.middle_name}"
                            .validators="${this.getValidatorsForField(
                              "middle_name",
                            )}"
                            @model-value-changed="${(e) => {
                              this.middle_name = e.target.modelValue;
                            }}"
                            data-cy-basic-info-middle-name
                        >
                        </lds-input>
                    </div>
                    <div class="inline-item">
                        <lds-input
                            label="Full Last name"
                            name="last_name"
                            .modelValue="${this.last_name}"
                            autocomplete="family-name"
                            .validators="${this.getValidatorsForField(
                              "last_name",
                            )}"
                            @model-value-changed="${(e) => {
                              this.last_name = e.target.modelValue;
                            }}"
                            data-cy-basic-info-last-name
                        >
                        </lds-input>
                    </div>
                </div>
                <lds-input
                    class="margin-top"
                    label="Email"
                    name="email"
                    autocomplete="email"
                    .modelValue="${this.email}"
                    .validators="${this.getValidatorsForField("email")}"
                    @model-value-changed="${(e) => {
                      this.email = e.target.modelValue;
                    }}"
                    data-cy-basic-info-email
                >
                </lds-input>`;
    }
    return "";
  }

  get addressSelector() {
    if (this.country === "GBR" && this.foundAddresses) {
      return html`<lds-select
                label="Select an address"
                .disabled=${this.foundAddresses.length === 0}
                class="exclude-from-validation"
                @model-value-changed=${(e) => {
                  if (e.target.modelValue && e.target.modelValue !== "null") {
                    this.setAddress(JSON.parse(e.target.modelValue));
                  } else {
                    this.setAddress({});
                  }
                }}
            >
                <select slot="input">
                    ${this.addressesFormattedForSelect.map(
                      (option) => html`
                            <option value="${option.value}">
                                ${option.label}
                            </option>
                        `,
                    )}
                </select>
                ${
                  this.foundAddresses.length === 0
                    ? html`<p slot="feedback">
                          There were no matches found for this postcode. Try
                          again or enter address manually.
                      </p>`
                    : ""
                }</lds-select
            >`;
    }
    return "";
  }

  setAddress(parts = {}) {
    this.line1 = [
      parts.flat_number,
      parts.building_number,
      parts.building_name,
      parts.street,
    ]
      .filter(Boolean)
      .join(", ");
    this.line2 = parts.sub_street || "";
    this.line3 = parts.town || "";
    this.postcode = parts.postcode;
    this.address_breakdown = parts;
  }

  static formatAddress(...args) {
    return args
      .filter((addressPart) => addressPart && addressPart.toString().length > 0)
      .join(", ");
  }

  dispatchInternationalAddressEvent() {
    engageEventBus.dispatchEvent(
      new CustomEvent("international-address", {
        detail: { hide: this.country === "GBR" },
      }),
    );
  }

  showConfirmationModal(callback) {
    LdsModal.create({
      title: "Date of birth not entered",
      description:
        "Entering a date of birth is optional, but recommended to narrow the search. Do you wish to continue without a date a birth?",
      primaryButtonAttributes: {
        variant: "outline",
        colour: "primary",
        label: "Continue",
        onClick: callback,
      },
    });
  }

  submitRequest() {
    this.isBusy = true;
    this.sendResults({
      first_name: this.first_name,
      middle_name: this.middle_name,
      last_name: this.last_name,
      email: this.email,
      phone_number: this.phone_number,
      date_of_birth: this.formattedDate,
      country: this.country,
      line1: this.line1,
      line2: this.line2,
      line3: this.line3,
      state: this.state,
      postcode: this.postcode,
      address_breakdown: this.address_breakdown,
    });
    const event = new CustomEvent("basicInformationSubmitted", {
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(event);
  }

  onSubmit(e) {
    e.preventDefault();
    this.validate();
    const dob_input = this.shadowRoot.querySelector("lds-date-of-birth-input");
    const dob_input_fieldset = this.shadowRoot.querySelector(
      "lds-date-of-birth-fieldset",
    );
    if (!this.is_dob_optional) {
      dob_input.dispatchEvent(new Event("submit"));
    }
    const formData = new FormData(this.shadowRoot.querySelector("form"));
    const dobDay = formData.get("day-input");
    const dobMonth = formData.get("month-input");
    const dobYear = formData.get("year-input");

    if (dobDay && dobMonth && dobYear) {
      this.date_of_birth = `${dobDay.trim()}/${dobMonth.trim()}/${dobYear.trim()}`;
      this.formattedDate = `${dobYear.trim()}-${dobMonth.trim()}-${dobDay.trim()}`;
    } else {
      this.formattedDate = null;
    }
    if (this.isValid && !dob_input_fieldset.errorMessage) {
      if (!!this.date_of_birth === false && this.is_dob_optional) {
        this.showConfirmationModal(() => this.submitRequest());
        return;
      }
      this.submitRequest();
    } else {
      this.addressLookupSkipped = true;
    }
  }

  getValidatorsForField(fieldName) {
    const validators = {
      first_name: [
        new LeglRequired(),
        new LeglMinLength(2),
        new LeglNonEmpty(),
      ],
      middle_name: [],
      last_name: [new LeglRequired(), new LeglMinLength(2), new LeglNonEmpty()],
      email: [new LeglRequired(), new LeglIsEmail()],
      phone_number: [new LeglMinLength(7)],
      country: [new LeglRequired()],
      line1: ["standard_cdd", "standard_cdd_no_id", "enhanced_cdd"].includes(
        this.reportType,
      )
        ? [new LeglRequired(), new LeglNonEmpty()]
        : [],
      line2: [],
      line3: [],
      state: [],
      postcode: [],
    };
    return [
      ...validators[fieldName],
      ...this.getServerMessagesForField(fieldName),
    ];
  }

  getServerMessagesForField(fieldName) {
    if (
      Object.prototype.hasOwnProperty.call(this.serverErrorMessages, fieldName)
    ) {
      return [
        new LeglServerMessagesEmptyValue([
          ...(this.serverErrorMessages[fieldName] || []),
        ]),
      ];
    }
    return [];
  }

  handleResultResponse(response, status) {
    try {
      const errors = response?.errors;
      this.serverErrorMessages = Object.keys(errors || {}).reduce(
        (errorsOut, fieldIn) => {
          return {
            ...errorsOut,
            [fieldIn]: [response.errors[fieldIn][0].message],
          };
        },
        {},
      );
      for (const [key, value] of Object.entries(this.serverErrorMessages)) {
        const input = this.shadowRoot.querySelector(`[name=${key}]`);
        if (input) {
          input.submitted = true;
          input.modelValue = "";
        } else {
          LdsToast.showError({
            title:
              "Something went wrong, please check all information has been entered correctly and try again. If the problem persists contact support.",
          });
          Sentry.addBreadcrumb({
            category: "in-person cdd",
            message: "Basic informattion 400 error",
            level: "error",
            data: this.serverErrorMessages,
          });
          Sentry.captureException(`Cannot find input with name: ${key}`);
        }
      }
      this.requestUpdate();
      if (!errors && status === 200) {
        this.sendComplete();
      }
    } finally {
      this.isBusy = false;
    }
  }
}

customElements.define("legl-basic-information", BasicInformationElement);
