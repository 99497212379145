import { toastService } from "../../../../../legl-ui/toast";
import { get } from "../../../../../legl-ui/utils/fetch.js";

export class CompanyPaymentSettings {
  constructor() {
    this._data = null;
  }

  async fetch(forceRefresh = false) {
    if (!this._data || forceRefresh) {
      try {
        const res = await get(`/api/company_payment_settings/`);
        if (!res.ok) {
          throw new Error(res.error);
        }
        this._data = await res.json();
      } catch (err) {
        toastService.showError("Error retrieving Company Payment Settings");
        this._data = null;
      }
    }
    return this;
  }

  reset() {
    this._data = null;
  }

  get settings() {
    return this._data || {};
  }

  get referenceFieldConfigs() {
    return [
      {
        name: "invoice_reference",
        label: this.settings["invoice_reference_label"] || "Invoice reference",
        visible: Boolean(this.settings["invoice_reference_visible"]),
        required: Boolean(this.settings["invoice_reference_required"]),
      },
      {
        name: "matter_reference",
        label: this.settings["matter_reference_label"] || "Matter reference",
        visible: Boolean(this.settings["matter_reference_visible"]),
        required: Boolean(this.settings["matter_reference_required"]),
      },
      {
        name: "custom_reference",
        label: this.settings["custom_reference_label"] || "Firm reference",
        visible: Boolean(this.settings["custom_reference_visible"]),
        required: Boolean(this.settings["custom_reference_required"]),
      },
    ];
  }

  get visibleReferenceFieldConfigs() {
    return this.referenceFieldConfigs.filter(({ visible }) => visible === true);
  }

  get maximumPhonePayment() {
    return this.settings?.maximum_phone_payment;
  }

  get acceptHigherFeeCards() {
    return this.settings?.accept_higher_fee_cards;
  }

  get companyName() {
    return this.settings?.company?.name;
  }

  get stripePaymentLimit() {
    return Number.parseInt(this.settings?.payment_limits.stripe, 10);
  }

  get bankedPaymentLimit() {
    return Number.parseInt(this.settings?.payment_limits.banked, 10);
  }

  get minimumPayment() {
    return Number.parseFloat(this.settings?.minimum_payment);
  }

  get reminderStartAfterDays() {
    return Number.parseInt(this.settings?.reminder_start_after_days, 10);
  }

  get ccOnRequestEmail() {
    return this.settings?.cc_on_request_email;
  }

  get bccOnRequestEmail() {
    return this.settings?.bcc_on_request_email;
  }

  get reminderStartAfterDaysOptions() {
    return this.settings?.reminder_start_after_days_options;
  }
}

const companyPaymentSettings = new CompanyPaymentSettings();
export default companyPaymentSettings;
