import { LitElement, css, html, nothing } from "lit";
import { neutral } from "../../../legl-ui/lds-colours";
import { spacing } from "../../../legl-ui/lds-spacing";

export class LeglCddSelector extends LitElement {
  static get styles() {
    return css`
            :host {
                --legl-green: #01b88e;
            }
            legl-card {
                color: ${neutral["700"]};
            }
            legl-card h1 {
                font-weight: 600;
                font-size: 1.25rem;
            }

            legl-card h2 {
                font-weight: 600;
                font-size: 1rem;
                line-height: 1rem;
            }

            legl-card h3 {
                font-size: 0.875rem;
                font-weight: 600;
                line-height: 1rem;
                letter-spacing: 0.01em;
                opacity: 0.9;
            }
            .sub-title {
                font-size: 12px;
                font-weight: 700;
                color: ${neutral["700"]};
            }
            p,
            li,
            .toggle-label {
                color: ${neutral["700"]};
            }

            li {
                display: flex;
                align-items: center;
                padding-bottom: 12px;
            }

            .step-cdd__row {
                display: flex;
                justify-content: flex-end;
                flex-wrap: wrap;
            }

            .step-cdd__border {
                width: calc(25% - 1.5rem);
                margin-right: 1.5rem;
                border-right: 1px solid ${neutral["200"]};
                min-width: 16.8rem;
                padding: 0;
            }

            .step-cdd__col:not(.step-cdd__col--header) {
                padding: 0 1.5rem;
                margin: 0 0 17px;
                min-height: 95px;
            }

            .step-cdd__row:not(.step-cdd__row--headers) {
                border: 2px solid ${neutral["200"]};
                border-radius: 4px;
                padding: 1.5rem 0 0;
                margin: ${spacing.xxs} 0 ${spacing.s} 0;
                background: white;
                min-height: 115px;
                cursor: pointer;
            }

            .step-cdd__row.is-active {
                border: solid 2px var(--legl-green, #01b88e);
            }
            .step-cdd__col--title {
                flex: 1;
                min-width: 4rem;
            }

            .step-cdd__col--title p {
              margin-top: 0px;
            }

            .step-cdd__col--checklist,
            .step-cdd__col--header {
                width: 25%;
                display: flex;
                flex-direction: column;
            }

            .step-cdd__col--header {
                margin: 0 5%;
                text-align: center;
            }

            .step-cdd__col--checklist ul {
                margin: 0;
                padding: 0;
                flex: 1;
                list-style: none;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .step-cdd__col--checklisticon:before {
                font-family: "legl-icon";
                content: var(--legl-icon-tick);
                color: var(--legl-green, #01b88e);
                font-size: 12px;
                margin-right: 0.5rem;
            }

            .step-cdd__documents:not(.step-cdd__documents--editable) {
                display: none;
            }

            .step-cdd__row:not(.step-cdd__row--headers):hover {
                border: 2px solid var(--legl-green, #01b88e);
                color: var(--legl-green, #01b88e);
                cursor: pointer;
            }

            .hint-text {
                font-size: 0.8rem;
                line-height: normal;
            }

            .step-cdd__message--container {
                flex: 0 0 100%;
                color: ${neutral["800"]};
                background: ${neutral["200"]};
                font-size: 11px;
            }

            .step-cdd__message--text {
                padding: 8px 12px;
                margin: 0;
            }
        `;
  }

  static get properties() {
    return {
      options: { type: Object },
      selectedOption: { type: String },
    };
  }

  constructor() {
    super();
    this.selectedOption = undefined;
  }

  formattedCheckList(listItems) {
    if (!Array.isArray(listItems) || listItems.length === 0) {
      return "";
    }

    return html`
            <ul>
                ${listItems.map(
                  (listItem) =>
                    html`
                            <li class="step-cdd__col--checklisticon">
                                ${listItem}
                            </li>
                        `,
                )}
            </ul>
        `;
  }

  handleSelection(option) {
    this.scrollToDrawerBottom();
    return this.dispatchEvent(
      new CustomEvent("cdd-selection", {
        detail: {
          option,
        },
        bubbles: true,
        composed: true,
      }),
    );
  }

  scrollToDrawerBottom() {
    const el = document
      .querySelector("#drawer-router")
      .shadowRoot.querySelector(".drawer__content");
    el.scrollTop = el.scrollHeight - el.clientHeight;
  }

  render() {
    return html`<div class="step-cdd__list">
                    <div class="step-cdd__row step-cdd__row--headers">
                        <div class="step-cdd__col step-cdd__col--header">
                            <h2 class="sub-title">
                                Identity checks
                            </h2>

                        </div>
                        <div class="step-cdd__col step-cdd__col--header">
                            <h2 class="sub-title">
                                Screenings
                            </h2>
                        </div>
                        </div>
                        ${Object.entries(this.options).map(
                          ([cddOption, cddConfig]) =>
                            this.leglCard(cddOption, cddConfig),
                        )}
                    </div>
                </div>`;
  }

  leglCard(cddOption, cddConfig) {
    return html`
            <legl-card
                data-cy-step-option="${cddOption}"
                class="step-cdd__row ${
                  this.selectedOption === cddOption ? "is-active" : ""
                }"
                @click=${() => {
                  this.selectedOption = cddOption;
                  this.handleSelection(cddOption);
                }}
            >
                <div class="step-cdd__col step-cdd__col--title">
                    <h1 class="workflow-title">${cddConfig.title}</h1>
                    ${cddConfig.subTitle ? html`<p class="workflow-subtitle">${cddConfig.subTitle}</p>` : nothing}
                </div>
                <div
                    class="step-cdd__border step-cdd__col step-cdd__col--checklist"
                >
                    ${this.formattedCheckList(cddConfig.kyc)}
                </div>
                <div class="step-cdd__col step-cdd__col--checklist">
                    ${this.formattedCheckList(cddConfig.reportBreakdown)}
                </div>
                <div class="step-cdd__message--container">
                    <p class="step-cdd__message--text">${cddConfig.message}</p>
                </div>
            </legl-card>
        `;
  }
}

customElements.define("legl-cdd-selector", LeglCddSelector);
