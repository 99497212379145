import { LitElement, html, nothing } from "lit";
import { styleMap } from "lit/directives/style-map.js";

export class LdsDateInput extends LitElement {
  get labelStyles() {
    return {
      "font-family": `'Lato', sans-serif`,
      "font-size": "16px",
      "line-height": "24px",
      "font-weight": "700",
      color: "#253658",
      margin: "0 0 8px",
      display: "block",
    };
  }

  get fieldsetStyles() {
    return {
      border: "none",
      padding: "0",
      margin: "0",
      display: "flex",
      gap: "8px",
    };
  }

  get inputStyles() {
    return {
      "font-family": `'Lato', sans-serif`,
      "font-size": "16px",
      "line-height": "24px",
      "font-weight": "400",
      color: "#253658",
      padding: "11px",
      "border-radius": "4px",
      border: "1px solid #C5CAD3",
      margin: "0",
      "box-sizing": "border-box",
      outline: "none !important",
      "text-align": "center",
    };
  }

  get inputFocusStyles() {
    return {
      "border-color": "#068165",
      outline: "#068165 solid 1px",
    };
  }

  get errorMessageStyles() {
    return {
      "font-family": "Lato, sans-serif",
      "font-size": "12px",
      "line-height": "16px",
      "font-weight": "400",
      display: "flex",
      color: "rgb(229, 25, 75)",
      margin: "0px 0px 4px",
    };
  }

  static get properties() {
    return {
      label: { attribute: false },
      modelValue: { attribute: false },
      name: { attribute: false },
      errorMessage: {
        attribute: false,
      },
      day: { state: true },
      month: { state: true },
      year: { state: true },
      focusMap: { state: true },
    };
  }

  createRenderRoot() {
    return this;
  }

  constructor() {
    super();
    this.label = "";
    this.modelValue = "";
    this.name = "";
    this.errorMessage = "";
    this.day = "";
    this.month = "";
    this.year = "";
    this.focusMap = {
      day: false,
      month: false,
      year: false,
    };
  }

  connectedCallback() {
    super.connectedCallback();
    if (this.modelValue) {
      const dateArray = this.modelValue.split("-");
      if (dateArray.length === 3) {
        this.year = dateArray[0];
        this.month = dateArray[1];
        this.day = dateArray[2];
      }
    }
  }

  setModelValue() {
    if (this.day == "" && this.month == "" && this.year == "") {
      this.modelValue = "";
    } else {
      this.modelValue = `${this.year}-${this.month}-${this.day}`;
    }

    this.emitModelValue();
  }

  emitModelValue() {
    const event = new CustomEvent("model-value-changed");
    this.dispatchEvent(event);
  }

  onDayChange(event) {
    this.day = event.target.value;
    this.setModelValue();
  }

  onMonthChange(event) {
    this.month = event.target.value;
    this.setModelValue();
  }

  onYearChange(event) {
    this.year = event.target.value;
    this.setModelValue();
  }

  onFocus(inputName) {
    this.focusMap[inputName] = true;
    this.requestUpdate();
  }

  onBlur(inputName) {
    this.focusMap[inputName] = false;
    this.requestUpdate();
  }

  render() {
    return html`
    <label for="date" style=${styleMap(this.labelStyles)} >${this.label}</label>
    <input id="date" name=${this.name} .value=${this.modelValue} hidden />
    ${
      this.errorMessage
        ? html`<p class="error-message" style=${styleMap(
            this.errorMessageStyles,
          )}>${this.errorMessage}</p>`
        : nothing
    }
    <fieldset style=${styleMap(this.fieldsetStyles)}>
      <label for="day" hidden>Day</label>
      <input style=${styleMap({
        width: "55px",
        ...this.inputStyles,
        ...(this.focusMap.day && this.inputFocusStyles),
      })} id="day" placeholder="DD" value=${this.day} maxlength="2" @input=${
        this.onDayChange
      } @focus=${() => this.onFocus("day")} @blur=${() => this.onBlur("day")} />
      <label for="month" hidden>Month</label>
      <input style=${styleMap({
        width: "55px",
        ...this.inputStyles,
        ...(this.focusMap.month && this.inputFocusStyles),
      })} id="month" placeholder="MM" value=${
        this.month
      } maxlength="2" @input=${this.onMonthChange} @focus=${() =>
        this.onFocus("month")} @blur=${() => this.onBlur("month")} //>
      <label for="year" hidden>Year</label>
      <input style=${styleMap({
        width: "65px",
        ...this.inputStyles,
        ...(this.focusMap.year && this.inputFocusStyles),
      })} id="year" placeholder="YYYY" value=${
        this.year
      } maxlength="4" @input=${this.onYearChange} @focus=${() =>
        this.onFocus("year")} @blur=${() => this.onBlur("year")} //>
    </fieldset>`;
  }
}

if (!customElements.get("lds-date-input")) {
  customElements.define("lds-date-input", LdsDateInput);
}
