import { LitElement, css, html, nothing } from "lit";
import {
  danger,
  neutral,
  primary,
} from "../../../../../../legl-ui/lds-colours";
import { spacing } from "../../../../../../legl-ui/lds-spacing";
import {
  typographyPresets,
  weight,
} from "../../../../../../legl-ui/lds-typography";
import { toastService } from "../../../../../../legl-ui/toast";
import { millisecondsToDate } from "../../../../../../legl-ui/utils";
import { get, post } from "../../../../../../legl-ui/utils/fetch";
import { formatDateTime } from "../../../../../../legl-ui/utils/functions";

export default class ESignaturePanelStep extends LitElement {
  static get styles() {
    return css`
            p {
                margin: 0;
                ${typographyPresets.body};
            }
            p span {
                margin-right: ${spacing.xs};
                font-weight: ${weight.bold};
            }
            .files-names {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            table {
                border-collapse: collapse;
                width: 100%;
            }
            tbody tr {
                border-top: 1px solid ${neutral["200"]};
            }
            td,
            th {
                align-items: center;
                padding: ${spacing.s};
                padding-left: 0;
            }
            td {
                ${typographyPresets.medium};
            }
            th {
                ${typographyPresets.bodyBold};
                padding-bottom: ${spacing.xs};
            }
            .status-column {
                width: 140px;
            }
            .signer-status {
                display: flex;
            }
            .signer-status-label {
                ${typographyPresets.mediumBold};
            }
            .primary-tag {
                ${typographyPresets.smallSemiBold};
                color: ${neutral["800"]};
                background-color: ${neutral["100"]};
                border: 1px solid ${neutral["400"]};
                border-radius: 12px;
                padding: 0 ${spacing.xs};
            }
            .signer-status-date {
                ${typographyPresets.small};
                color: ${neutral["800"]};
            }
            .signer-status-icon {
                margin-right: ${spacing.xs};
                margin-top: 3px;
            }
            .signer-name {
                ${typographyPresets.mediumBold};
            }
            .completed {
                color: ${primary["500"]};
            }
            .declined {
                color: ${danger["500"]};
            }
            .info-message {
                display: flex;
                align-items: center;
            }
            .info-icon {
                margin-right: ${spacing.xs};
            }
            .resend-button-column {
                text-align: right;
            }
        `;
  }
  static get properties() {
    return {
      panel: {
        type: Object,
      },
      application: {
        attribute: false,
      },
      stepTitle: { type: String },
      signatureRequest: { state: true },
      fetchReviewInformation: { attribute: false },
      resendEmailLoadingStates: { attribute: false },
    };
  }

  constructor() {
    super();
    this.signatureRequest = {};
    this.resendEmailLoadingStates = {};
  }

  async connectedCallback() {
    super.connectedCallback();
    const sigRequestId = this.panel.element_config.signature_request_id;
    try {
      const response = await get(
        `/api/hellosign/signature_request/${sigRequestId}`,
      );
      const json = await response.json();

      this.signatureRequest = json.signature_request;
    } catch (error) {
      toastService.showError(
        "An unexpected error has occurred, please check your internet connection and try again.",
      );
    }
  }

  get panelStatus() {
    if (this.signatureRequest?.is_declined) {
      return "Declined";
    } else if (this.signatureRequest?.is_complete) {
      return "Completed";
    } else {
      return "Awaiting Signatures";
    }
  }

  getSignatureStatus(signature, isPrimary) {
    switch (signature.status_code) {
      case "success":
      case "signed":
        return {
          label: "Signed",
          icon: "lds-icon-CheckCircleFilled",
          class: "completed",
          date: millisecondsToDate(
            new Date(signature.signed_at * 1000),
            "#DD# #MMM# #YYYY#, #hhhh#:#mm#",
          ),
        };
      case "declined":
        return {
          label: "Declined",
          icon: "lds-icon-WarningFilled",
          class: "declined",
          date: millisecondsToDate(
            new Date(signature.last_viewed_at * 1000),
            "#DD# #MMM# #YYYY#, #hhhh#:#mm#",
          ),
        };
      default:
        if (signature.last_viewed_at) {
          return {
            label: "Viewed",
            icon: "lds-icon-VisibilityOn",
            class: "awaiting-signature",
            date: millisecondsToDate(
              new Date(signature.last_viewed_at * 1000),
              "#DD# #MMM# #YYYY#, #hhhh#:#mm#",
            ),
          };
        }
        if (
          !isPrimary &&
          this.signatureRequest?.signatures?.[0].status_code ===
            "awaiting_signature"
        ) {
          return {
            label: "Waiting for primary contact",
            icon: "lds-icon-Clock",
            class: "awaiting-signature",
          };
        }
        // Using the primary's signed_at date as the send date for additional signers
        // This is the closes date/time we can find for when the signature request was sent
        if (
          !isPrimary ||
          (isPrimary && this.application.email_sent_count > 0)
        ) {
          let sentAt;
          if (isPrimary) {
            const initialEmailSentEvent =
              this.application.application_events.find(
                (applicationEvent) =>
                  applicationEvent.event_type === "initial_email_sent",
              );
            if (initialEmailSentEvent) {
              sentAt = formatDateTime(initialEmailSentEvent.created_at);
            } else {
              sentAt = null;
            }
          } else {
            sentAt = millisecondsToDate(
              new Date(this.signatureRequest?.signatures?.[0].signed_at * 1000),
              "#DD# #MMM# #YYYY#, #hhhh#:#mm#",
            );
          }
          return {
            label: "Sent",
            icon: "lds-icon-Send",
            class: "awaiting-signature",
            date: sentAt,
          };
        }
        return null;
    }
  }

  async handleResendSignatureRequestEmail(signature, event) {
    try {
      this.resendEmailLoadingStates[signature.signature_id] = true;
      this.requestUpdate();
      const response = await post(
        `/api/application_steps/${this.panel.id}/send_additional_signer_email/`,
        {
          body: JSON.stringify(signature),
        },
      );
      if (!response.ok) {
        toastService.showError(
          "An unexpected error has occurred, please check your internet connection and try again.",
        );
      } else {
        toastService.showSuccess("Email sent");
        await this.fetchReviewInformation();
      }
    } catch (error) {
      console.error(error);
      toastService.showError(
        "An unexpected error has occurred, please check your internet connection and try again.",
      );
    }
    this.resendEmailLoadingStates[signature.signature_id] = false;
    this.requestUpdate();
  }

  render() {
    return html` <div class="panel">
            <legl-engage-panel
                .status=${this.panelStatus}
                .canHavePDF=${true}
                .isLocked=${true}
                .pdfURL=${this.panel.pdf_url}
                title=${this.stepTitle || "E-signature request"}
            >
                <lds-tooltip
                    text=${html`${this.panel.element_config.signature_request_filenames.map(
                      (filename) => html`${filename}<br />`,
                    )}`}
                >
                    <p class="files-names">
                        <span
                            >File
                            ${
                              this.panel.element_config
                                .signature_request_filenames.length === 1
                                ? "name"
                                : "names"
                            }:</span
                        >${this.panel.element_config.signature_request_filenames.join(
                          ", ",
                        )}.
                    </p>
                </lds-tooltip>
                <table>
                    <thead>
                        <tr>
                            <th colspan="2">Signers:</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${this.signatureRequest?.signatures?.map(
                          (signature, index) => {
                            const signatureStatus = this.getSignatureStatus(
                              signature,
                              index === 0,
                            );
                            return html`
                                    <tr id="additional-signer-row">
                                        ${
                                          this.getSignatureStatus(
                                            this.signatureRequest
                                              ?.signatures[0],
                                            true,
                                          )
                                            ? html` <td class="status-column">
                                                  <div
                                                      class=${`${signatureStatus.class} signer-status`}
                                                  >
                                                      <lds-icon
                                                          class="signer-status-icon"
                                                          small
                                                          name=${
                                                            signatureStatus.icon
                                                          }
                                                      ></lds-icon>
                                                      <div>
                                                          <div
                                                              class="signer-status-label"
                                                          >
                                                              ${
                                                                signatureStatus.label
                                                              }
                                                          </div>
                                                          ${
                                                            signatureStatus.date
                                                              ? html`<div
                                                                    class="signer-status-date"
                                                                >
                                                                    ${signatureStatus.date}
                                                                </div>`
                                                              : nothing
                                                          }
                                                      </div>
                                                  </div>
                                              </td>`
                                            : nothing
                                        }
                                        <td>
                                            <div class="signer-name">
                                                ${signature.signer_name}
                                                ${
                                                  index === 0 &&
                                                  this.signatureRequest
                                                    ?.signatures?.length > 1
                                                    ? html`<span
                                                          class="primary-tag"
                                                          >Primary</span
                                                      >`
                                                    : nothing
                                                }
                                            </div>
                                            <div class="signer-email">
                                                ${
                                                  signature.signer_email_address
                                                }
                                            </div>
                                        </td>
                                        ${
                                          this.signatureRequest?.signatures[0]
                                            .status_code === "signed" &&
                                          this.application.status ===
                                            "In progress"
                                            ? html`<td
                                                  class="resend-button-column"
                                              >
                                                  ${
                                                    index !== 0 &&
                                                    signature.status_code !==
                                                      "signed"
                                                      ? html`<lds-button
                                                            data-testid="resend-email-button-${index}"
                                                            colour="primary"
                                                            small
                                                            .disabled=${
                                                              this
                                                                .resendEmailLoadingStates[
                                                                signature
                                                                  .signature_id
                                                              ]
                                                            }
                                                            .isLoading=${
                                                              this
                                                                .resendEmailLoadingStates[
                                                                signature
                                                                  .signature_id
                                                              ]
                                                            }
                                                            @click=${(event) =>
                                                              this.handleResendSignatureRequestEmail(
                                                                signature,
                                                                event,
                                                              )}
                                                            >Resend</lds-button
                                                        >`
                                                      : nothing
                                                  }
                                              </td>`
                                            : nothing
                                        }
                                    </tr>
                                `;
                          },
                        )}
                        ${
                          this.signatureRequest?.signatures?.length > 1 &&
                          this.signatureRequest?.signatures?.[0].status_code ===
                            "awaiting_signature"
                            ? html`
                                  <tr>
                                      <td colspan="2">
                                          <div class="info-message">
                                              <lds-icon
                                                  class="info-icon"
                                                  small
                                                  name="lds-icon-ErrorFilled"
                                              ></lds-icon>
                                              Once the primary contact has
                                              signed, we will send signature
                                              requests to all additional
                                              signers.
                                          </div>
                                      </td>
                                  </tr>
                              `
                            : nothing
                        }
                    </tbody>
                </table>
            </legl-engage-panel>
        </div>`;
  }
}
customElements.define(
  "legl-e-signature-request-v2-panel-step",
  ESignaturePanelStep,
);
