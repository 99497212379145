import { dedupeMixin } from "@lion/core";
import { LitElement, css, html, nothing } from "lit";
import { neutral } from "../../../legl-ui/lds-colours";
import "../../../legl-ui/lds-icon";
import "../../../legl-ui/lds-tooltip";

export const LeglExpandablePanelMixin = dedupeMixin(
  (superclass) =>
    class LeglExpandablePanel extends superclass {
      static get styles() {
        return css`
                    .wrapper {
                        background: white;
                        box-shadow: 2px 2px 15px 1px lightgrey;
                        position: relative;
                        margin-bottom: 20px;
                    }

                    .title {
                        margin-top: 0;
                        margin-bottom: 0;
                    }

                    .panel {
                        display: flex;
                        flex-direction: row;
                        align-items: baseline;
                        padding: 1.25rem;
                    }

                    .panel-content {
                        width: calc(100% - 45px);
                    }

                    .panel-header {
                        display: flex;
                        flex-direction: row;
                        align-items: baseline;
                        margin-bottom: 10px;
                        color: ${neutral["800"]};
                    }

                    .panel-info {
                        font-size: 14px;
                        font-weight: 400;
                        color: var(--legl-grey-dark);
                        padding: 0 12px;
                    }

                    :host([hide-expand-button]) .expand-btn {
                        display: none;
                    }
                    :host([is-expanded]) .expand-btn {
                        transform: rotate(90deg);
                    }

                    .expandable-content {
                        width: 100%;
                        position: relative;
                    }

                    .actions {
                        position: absolute;
                        right: 1rem;
                        top: 1.5rem;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-evenly;
                        align-items: flex-start;
                        width: auto;
                    }

                    .action {
                        font-size: 24px;
                        max-width: 70px;
                    }

                    .action > span {
                        font-size: 12px;
                    }

                    .action.download-btn {
                        font-size: 18px;
                    }

                    .action:active,
                    .action:visited {
                        color: ${neutral["800"]};
                    }

                    lds-tooltip {
                        top: 6px;
                        flex: 1;
                    }

                    legl-action-bar {
                        --legl-action-bar-min-width: 0;

                        margin-left: auto;
                    }

                    ::slotted(footer) {
                        background-color: var(--legl-grey-background);
                        padding: 10px 65px;
                    }
                `;
      }

      static get properties() {
        return {
          isExpanded: {
            type: Boolean,
            attribute: "is-expanded",
            reflect: true,
          },
          isLocked: {
            type: Boolean,
            attribute: "is-locked",
            reflect: true,
          },
          hideExpandButton: {
            type: Boolean,
            attribute: "hide-expand-button",
            reflect: true,
          },
          title: {
            type: String,
          },
          progressText: {
            type: String,
          },
          tooltipText: {
            type: String,
          },
        };
      }

      get isExpandable() {
        return true;
      }

      get isExpandButtonEnabled() {
        return !this.isLocked && this.isExpandable;
      }

      get _panelBefore() {
        return nothing;
      }

      get _expandableContent() {
        return html`<slot name="expandable-content"></slot>`;
      }

      get _primaryContent() {
        return html`<slot></slot>`;
      }

      get actions() {
        return html`<button
                    ?disabled="${!this.isExpandButtonEnabled}"
                    class="action expand-btn"
                    data-cy-expandable-panel-expand-btn
                    slot="primary"
                    @click=${() => {
                      this.isExpanded = !this.isExpanded;
                    }}
                >
                    <lds-icon name="lds-icon-ChevronRight"></lds-icon>
                </button>`;
      }

      constructor() {
        super();
        this.isExpanded = false;
      }

      render() {
        return html`
                    <div class="wrapper" part="wrapper">
                        <div class="panel">
                            ${this._panelBefore}
                            <div class="panel-content">
                                <div class="panel-header">
                                    <h2
                                        class="title"
                                        title=""
                                        data-cy-panel-title
                                    >
                                        ${this.title}
                                    </h2>
                                    ${
                                      this.progressText
                                        ? html`<span class="panel-info">
                                              ${this.progressText}
                                          </span>`
                                        : nothing
                                    }
                                    ${
                                      this.tooltipText
                                        ? html`<lds-tooltip
                                              .text="${this.tooltipText}"
                                              placement="top"
                                          >
                                              <lds-icon
                                                  class="info-filled-tooltip-icon"
                                                  size="small"
                                                  name="lds-icon-InfoFilled"
                                              ></lds-icon>
                                          </lds-tooltip>`
                                        : nothing
                                    }
                                    <legl-action-bar>
                                        ${this.actions}
                                    </legl-action-bar>
                                </div>
                                ${this._primaryContent}
                                ${
                                  this.isExpandable && this.isExpanded
                                    ? html`
                                          <div class="expandable-content">
                                              ${this._expandableContent}
                                          </div>
                                      `
                                    : ""
                                }
                            </div>
                        </div>
                        <slot name="footer"></slot>
                    </div>
                `;
      }
    },
);

customElements.define(
  "legl-expandable-panel",
  LeglExpandablePanelMixin(LitElement),
);
