import { LitElement, css, html } from "lit";
import { neutral } from "../../legl-ui/lds-colours";

export class LeglAvatar extends LitElement {
  static get properties() {
    return {
      name: { type: String },
      short: { type: String },
    };
  }

  static get styles() {
    return css`
            :host([size="default"]) {
                min-width: 30px;
                min-height: 30px;
                --legl-avatar-font-size: 10px;
            }

            :host([size="medium"]) {
                min-width: 32px;
                min-height: 32px;
                --legl-avatar-font-size: 12px;
            }

            :host([size="big"]) {
                min-width: 34px;
                min-height: 34px;
                --legl-avatar-font-size: 14px;
            }

            :host {
                font-size: var(--legl-avatar-font-size, 10px);
                height: 2.6em;
                width: 2.6em;
                background-color: ${neutral["700"]};
                border-radius: 50%;
                display: flex;
                justify-content: center;
            }

            :host p {
                font-size: 1em;
                font-style: normal;
                font-weight: 700;
                line-height: 2.7em;
                letter-spacing: 0px;
                color: var(--brand-white, #fff);
                margin: 0;
                text-transform: uppercase;
            }
        `;
  }

  constructor() {
    super();
    this.size = "default";
    this.name = "";
    this.short = this.short || false;
  }

  get initials() {
    if (!this.name) return;
    const nameArr = this.name.split(" ");
    if (this.short) {
      return `${nameArr[0][0]}`;
    }
    if (nameArr.length === 1) {
      return `${nameArr[0][0]}${nameArr[0][1]}`;
    }
    return `${nameArr[0][0]}${nameArr[nameArr.length - 1][0]}`;
  }

  render() {
    return html`
            <span>
                ${
                  this.name === "Legl"
                    ? html`<p>Legl</p>`
                    : html`<p>${this.initials}</p>`
                }
            </span>
        `;
  }
}

customElements.define("legl-avatar", LeglAvatar);
