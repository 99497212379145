import { LitElement, css, html, nothing } from "lit";
import "../../../../../../legl-ui/activity-log";
import { LeglMaxLength } from "../../../../../../legl-ui/input";
import { LdsToast } from "../../../../../../legl-ui/lds-toast";
import { toastService } from "../../../../../../legl-ui/toast";
import "../../../../../../legl-ui/tooltip";
import { get, post } from "../../../../../../legl-ui/utils/fetch";
import {
  formatDateTime,
  formatFileName,
} from "../../../../../../legl-ui/utils/functions.js";
import store, { engageActions } from "../../../../../../redux/lawyers-admin";
import "../../../../../lawyers-contacts/contact-card.js";

import { connect } from "pwa-helpers";
import "../../../../../../legl-ui/lds-alert";
import "../../../../../../legl-ui/lds-button";
import {
  neutral,
  primary,
  warning,
} from "../../../../../../legl-ui/lds-colours";
import { spacing } from "../../../../../../legl-ui/lds-spacing";
import { typographyPresets } from "../../../../../../legl-ui/lds-typography";
import "../../../../../../legl-ui/permissions-overlay";
import location from "../../../../../../legl-ui/utils/location.js";
import { userConfig } from "../../config/user-config";
import { changeReviewer } from "../../pages/engage/change-reviewer";
import "../../pages/engage/components/engage-application-step-summary.js";
import "../../pages/engage/components/engage-panel.js";
import "../../pages/engage/components/engage-review-actions.js";
import { initializeSendInitialEmail } from "../email-reminders-row/SendInitialEmailModal.js";
import "../email-reminders-row/email-reminders-row.js";
import "./e-signature-request-v2-engage-panel";
import "./signature-engage-panel";

export class LeglEngageReview extends connect(store)(LitElement) {
  static get styles() {
    return css`
            :host {
                padding: 32px 32px 100px 32px;
            }

            textarea {
                width: 100%;
                min-height: 100px;
                font-family: inherit;
                font-size: inherit;
                line-height: inherit;
            }

            .title {
                color: var(--lds-colour-neutral-800);
                width: 500px;
                margin-top: 8px;
                margin-bottom: 0;
            }

            .workflow-title-and-progress-container {
                margin-bottom: 16px;
            }

            .workflow-steps-title {
                color: var(--lds-colour-neutral-800);
                margin-bottom: 8px;
            }

            .actions-container {
                display: flex;
                align-items: center;
            }

            .second-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
            }

            .first-row {
                display: flex;
                flex-direction: row;
            }

            lds-alert {
                margin: 12px 0;
            }

            .third-row {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                margin-top: 24px;
                gap: 8px;
            }

            .application-details-card-row-one {
                display: flex;
                flex-direction: column;
                padding-top: 18px;
            }

            .application-details-card-row-two {
                display: flex;
                padding-top: 18px;
                gap: 46px;
            }

            .col {
                width: 50%;
            }

            .col > p {
                display: grid;
                grid-template-columns: 40% auto;
            }

            .applicant-detail {
                margin: 0;
                padding-bottom: 8px;
                font-weight: 600;
            }

            .applicant-detail.no-padding {
                padding-bottom: 0;
            }

            .buttons {
                display: flex;
                flex-direction: row-reverse;
                margin-top: ${spacing.s};
            }

            .cdd {
                margin-bottom: 10px;
                padding-top: 1.25rem;
            }

            .form-group small {
                text-align: right;
                font-style: italic;
                display: inline-block;
                width: 100%;
            }

            .mark-as-reviewed-checkbox {
                margin-bottom: ${spacing.m};
                margin-top: ${spacing.l};
            }

            .sof {
                display: flex;
            }

            .force-complete-warning {
                display: flex;
                padding-bottom: 15px;
            }

            .force-complete-warning p {
                margin-top: 0;
                margin-left: 0.5rem;
            }

            .help-container {
                display: flex;
                gap: 6px;
            }

            .help-container > lds-icon {
                color: var(--lds-colour-neutral-700);
            }

            .warning-circle {
                color: ${warning["400"]};
                margin-top: 0.25rem;
            }

            .total {
                display: flex;
                padding-right: 10px;
            }

            .sof-label {
                padding-right: 10px;
            }

            .hidden {
                display: none;
            }

            dl dd {
                margin: 0 0 12px 0;
            }

            .label {
                color: var(--legl-primary-cta, #0e9944);
                font-weight: bold;
                text-transform: uppercase;
            }

            .label--Paid:before {
                font-family: "legl-icon";
                content: var(--legl-icon-credit-card);
            }

            .label--Transferred:before {
                font-family: "legl-icon";
                content: var(--legl-icon-transfers);
            }

            .flex-span {
                min-width: 8rem;
                word-break: break-word;
            }

            .title {
                text-transform: capitalize;
            }

            .comment {
                font-style: italic;
            }

            .panel-details {
                margin-top: -10px;
            }

            .recent-activity {
                margin: 2px;
                padding-top: 20px;
                color: ${neutral["800"]};
            }
            .change-reviewer,
            .change-reviewer:visited,
            .change-reviewer:active {
                color: ${neutral["800"]};
            }
            .change-reviewer:hover {
                font-weight: bold;
            }

            .panel-link,
            .panel-link:visited,
            .panel-link:active {
                color: ${neutral["800"]};
                text-decoration: none;
            }

            .panel-link:hover {
                text-decoration: underline;
            }

            .panel-link.footer-link,
            .panel-link.footer-link:visited,
            .panel-link.footer-link:active {
                color: ${neutral["800"]};
                display: flex;
                align-items: center;
                gap: 0.4rem;
            }

            .action-button {
                padding: 8px;
                margin-right: 20px;
                background-color: ${neutral["100"]};
                border: 1px solid ${neutral["800"]};
                color: ${neutral["800"]};
            }
            .action-button:hover {
                background-color: ${neutral["800"]};
                color: ${neutral["100"]};
            }
            .tooltip-text {
                font-size: 12px;
                padding: 5px 10px;
            }

            .company_report_button_icon {
                padding-right: 5px;
            }

            .reviewed-with-comment {
                display: grid;
                justify-items: center;
            }

            .reviewed-with-comment button {
                ${typographyPresets.body};
                color: ${primary["600"]};
                text-decoration: underline;
                background: none;
                border: none;
                padding: 0;
                cursor: pointer;
            }

            .reviewed-with-comment p {
                color: ${neutral["800"]};
            }

            .reviewed-with-comment-message {
                margin: 0;
            }

            .reviewed-with-comment-alert {
                margin-bottom: ${spacing.s};
                margin-top: ${spacing.m};
            }
        `;
  }

  static get properties() {
    return {
      params: {
        type: URLSearchParams,
      },
      application: {
        type: Object,
      },
      isSaving: {
        type: Boolean,
      },
      markReviewedValue: {
        type: String,
      },
      commentValue: {
        type: String,
      },
      reviewedAtDate: {
        type: String,
      },
      reviewed: { type: Boolean },
      showApplicationUnavailableMessage: { type: Boolean, state: true },
    };
  }

  constructor() {
    super();
    this.markReviewedValue = "off";
    this.addEventListener("close-link-clicked-from-drawer", () => {
      this.fetchReviewInformation();
      this.reloadEngageTable();
    });
    this.fetchReviewer = this.fetchReviewInformation.bind(this);
    this.reviewed = false;
    this.showApplicationUnavailableMessage = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this.fetchReviewInformation();
    // The force complete modal will emit the below event on which we can re-fetch the data and update the drawer
    window.addEventListener("force-completed-request", this.fetchReviewer);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener("force-completed-request", this.fetchReviewer);
  }

  stateChanged(state) {
    this.application = state.engage[this.params.get("appId")];
  }

  reloadEngageTable() {
    window.dispatchEvent(new CustomEvent("reload-engage-table"));
  }

  // the below returns true if businessId in the url is a number
  URLSearchParams() {
    if (!isNaN(Number.parseInt(this.params.get("businessId")))) {
      return "?enable_business=true";
    }
    return "";
  }

  async fetchReviewInformation() {
    const res = await get(
      `/api/engage_applications/${this.params.get(
        "appId",
      )}/${this.URLSearchParams()}`,
    );
    if (res.status === 404) {
      this.showApplicationUnavailableMessage = true;
    } else if (res.ok) {
      const application = await res.json();
      store.dispatch(engageActions.fetchApplicationDetails(application));
      this.reviewedAtDate = new Date(
        application.reviewed_at,
      ).toLocaleDateString();
    }
  }

  async onSubmit() {
    if (!this.isFormDisabled) {
      try {
        this.isSaving = true;
        const body = new URLSearchParams();
        body.append("mark_reviewed", this.markReviewedValue);
        if (this.reviewCommentValue) {
          body.append("review_comment", this.reviewCommentValue);
        }
        const res = await post(
          `/engage-review-applicant/${this.params.get("appId")}/`,
          {
            headers: {
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
            body: body.toString(),
          },
        );
        if (res.ok) {
          await this.fetchReviewInformation();

          LdsToast.showSuccess({
            title: "Engage request successfully marked as reviewed",
            autoClose: true,
          });

          if (this.application.review_comment && this.onfidoStep) {
            LdsToast.showSuccess({
              title:
                "Client Due Diligence report with comment will be ready shortly",
              autoClose: true,
            });
            this.reviewed = true;
          }
          this.reloadEngageTable();
        } else {
          const text = await res.text();
          throw new Error(`${res.status}: ${text}`);
        }
      } catch (err) {
        this.isSaving = false;
        LdsToast.showError({
          text: "Error saving, please try again",
          autoClose: true,
        });
      }
    }
  }

  get isFormDisabled() {
    return this.isSaving || this.markReviewedValue !== "on";
  }

  get isReviewed() {
    return this.application.reviewed_by;
  }

  get onfidoStep() {
    const onfidoStep = this.application.all_steps.find(
      (step) => step.element_type === "onfido",
    );

    return onfidoStep;
  }

  get panelSteps() {
    const includedElementTypes = [
      "onfido",
      "nfc",
      "document-request",
      "source-of-funds",
      "shared-document",
      "payment",
      "signature-request",
      "e-signature-request-v2",
      "custom-form",
    ];

    return this.application.all_steps.filter((step) =>
      includedElementTypes.includes(step?.element_type),
    );
  }

  get panels() {
    return this.panelSteps.map((panel) => {
      return this.getPanelTemplate(panel);
    });
  }

  get reviewer() {
    if (this.application.reviewed_by) {
      return html`
                <span
                    >${this.application.reviewed_by.name},
                    ${this.reviewedAtDate}</span
                >
            `;
    } else if (this.application.assigned_reviewer) {
      return html`
                <span
                    >${this.application.assigned_reviewer.name}<br />${this.reviewerLink}</span
                >
            `;
    } else if (this.application.created_by) {
      return html`
                <span
                    >${this.application.created_by.name}<br />${this.reviewerLink}</span
                >
            `;
    }
    return "";
  }

  get reviewerLink() {
    if (!this.isReviewed) {
      return html`
                <a
                    class="change-reviewer"
                    href="#"
                    @click=${this.onUpdateClicked}
                    >change</a
                >
            `;
    }
    return "";
  }

  get stepSummary() {
    var stepSummaryData = [];
    this.application.all_steps.map((step) => {
      if (!["basic-information", "cdd-documents"].includes(step.element_type)) {
        stepSummaryData.push({
          element_type: step.element_type,
          status: step.status,
          title: step.title,
          single_layer: "true",
        });
      }
    });
    return stepSummaryData;
  }

  get engageHelpLink() {
    if (userConfig.engageHelpLink) {
      return html`
                <div class="help-container">
                    <lds-icon name="lds-icon-BookClosed"></lds-icon>
                    <a
                        class="panel-link footer-link"
                        .href=${userConfig.engageHelpLink}
                        target="_blank"
                    >
                        Check out our guide to learn how to read your CDD report
                    </a>
                </div>
            `;
    }
    return "";
  }

  async onUpdateClicked(e) {
    e.preventDefault();
    const updated = await changeReviewer(
      this.application?.reviewer?.pk,
      this.application.reference,
    );
    if (updated === true) {
      await this.fetchReviewInformation();
    }
  }

  formatCurrency(value) {
    if (!!value) {
      return new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
      }).format(value);
    }
  }

  getCddSteps() {
    return {
      onfido: "ID Check",
      nfc: "NFC Check",
      "basic-information": "Basic Information",
      "cdd-documents": "Additional Docs",
    };
  }

  formatSteps(steps) {
    const dictionary = this.getCddSteps();

    if (steps.length > 0) {
      return steps.slice(1).reduce((string, step, index) => {
        if (index < steps.length - 2) {
          return `${string}, ${dictionary[step]}`;
        } else {
          return `${string} & ${dictionary[step]}`;
        }
      }, dictionary[steps[0]]);
    }
  }

  getTooltipText(title, steps) {
    return `${title} consists of ${steps.length} parts: ${this.formatSteps(
      steps,
    )}`;
  }

  getProgressText(completedCount, totalCount) {
    return `${completedCount} out of ${totalCount} complete`;
  }

  getCddMetaData({ title }, steps) {
    let cddStepsCount = 0;
    let cddCompleteStepsCount = 0;
    const cddSteps = [];

    steps?.forEach((step) => {
      if (Object.keys(this.getCddSteps()).includes(step?.element_type)) {
        if (["Completed", "Processing"].includes(step?.status))
          cddCompleteStepsCount++;
        cddStepsCount++;
        cddSteps.push(step.element_type);
      }
    });

    return {
      progressText: this.getProgressText(cddCompleteStepsCount, cddStepsCount),
      tooltipText: this.getTooltipText(title, cddSteps),
    };
  }

  getPanelTemplate(panel) {
    switch (panel.element_type) {
      case "onfido":
      case "nfc":
        const { progressText, tooltipText } = this.getCddMetaData(
          panel,
          this.application.all_steps,
        );

        return html` <div class="panel">
                    <legl-engage-panel
                        .status=${panel.status}
                        .canHavePDF=${panel.can_have_pdf}
                        .canHavePayment=${panel.can_have_payment}
                        .pdfURL=${panel.pdf_url}
                        .pdfURLWithComments=${panel.pdf_url_with_comments}
                        .pdfURLWithLeglAssist=${panel.legl_assist?.pdf_summary}
                        .elementType=${panel.element_type}
                        isOnfidoStep
                        title=${panel.title}
                        .progressText=${progressText}
                        .tooltipText=${tooltipText}
                        .isLocked=${false}
                    >
                        <div class="cdd panel-details">
                            <legl-engage-cdd
                                .onfidoStep=${panel}
                                .application_id=${this.application.id}
                                .app_uuid=${this.application.uuid}
                            ></legl-engage-cdd>
                        </div>
                        <footer slot="footer">${this.engageHelpLink}</footer>
                    </legl-engage-panel>
                </div>`;
      case "source-of-funds":
        return html` <div class="panel">
                    <legl-engage-panel
                        .status=${panel.status}
                        .canHavePDF=${panel.can_have_pdf}
                        .canHavePayment=${panel.can_have_payment}
                        .pdfURL=${panel.pdf_url}
                        .filename=${panel.results_pdf_filename}
                        .elementType=${panel.element_type}
                        title=${panel.title}
                    >
                        <div class="sof panel-details">
                            <div class="total">
                                <strong class="sof-label">Total:</strong>
                                <span>
                                    ${this.formatCurrency(
                                      panel.source_of_funds_total,
                                    )}</span
                                >
                            </div>
                            <div class="count">
                                <strong class="sof-label"
                                    >No. Of Sources:</strong
                                >
                                ${panel.source_of_funds_count}
                            </div>
                        </div>
                    </legl-engage-panel>
                </div>`;
      case "shared-document":
        return html` <div class="panel">
                    <legl-engage-panel
                        .status=${panel.status}
                        .canHavePDF=${panel.can_have_pdf}
                        .canHavePayment=${panel.can_have_payment}
                        .pdfURL=${panel.pdf_url}
                        .filename=${panel.shared_document_filename}
                        .elementType=${panel.element_type}
                        title=${panel.title}
                    >
                        <dl class="panel-details">
                            <dd>
                                <strong>File name:</strong>
                                ${panel.shared_document_filename}
                            </dd>
                            <dd>
                                <strong>Agreement required:</strong>
                                ${
                                  panel.element_config
                                    .acknowledgement_receipt === true
                                    ? `required (agreed: ${formatDateTime(
                                        panel.completed_at,
                                      )})`
                                    : "n/a"
                                }
                            </dd>
                        </dl>
                    </legl-engage-panel>
                </div>`;
      case "payment":
        return html` <div class="panel">
                    <legl-engage-panel
                        .status=${panel.status}
                        .canHavePDF=${panel.can_have_pdf}
                        .canHavePayment=${panel.can_have_payment}
                        .payment=${panel?.payment}
                        .elementType=${panel.element_type}
                        title=${panel.title}
                    >
                        <dl class="panel-details">
                            <dd>
                                <strong>To account:</strong>
                                ${panel.stripe_connect_account?.display_name}
                            </dd>
                            <dd>
                                <strong
                                    >Total payment amount (including
                                    VAT):</strong
                                >
                                ${this.formatCurrency(
                                  panel.element_config.amount,
                                )}
                            </dd>
                            ${
                              panel.payment
                                ? html` <dd>
                                      <strong>Payment status:</strong>
                                      <span
                                          class="label label--${panel.payment.status}"
                                      >
                                          ${panel.payment.status}
                                      </span>
                                  </dd>`
                                : ""
                            }
                        </dl>
                    </legl-engage-panel>
                </div>`;
      case "document-request":
        return html` <div class="panel">
                    <legl-engage-panel
                        .status=${panel.status}
                        .canHavePDF=${true}
                        .isLocked=${true}
                        .pdfURL=${panel.zipfile_url}
                        .elementType=${panel.element_type}
                        title="Request a document"
                    >
                        <dl class="panel-details">
                            <dd data-cy-engage-panel-document-request-step-name>
                                <strong>Step name:</strong>
                                ${panel.title}
                            </dd>
                            <dd
                                data-cy-engage-panel-document-request-files-uploaded
                            >
                                <strong>Files uploaded:</strong>
                                ${panel.number_of_files}
                            </dd>
                            <dd>
                                <strong>Uploaded:</strong>
                                ${formatDateTime(panel.completed_at)}
                            </dd>
                        </dl>
                    </legl-engage-panel>
                </div>`;
      case "custom-form":
        return html` <div class="panel">
                    <legl-engage-panel
                        .status=${panel.status}
                        .canHavePDF=${true}
                        .canHavePayment=${panel.can_have_payment}
                        .pdfURL=${panel.pdf_url}
                        .filename=${panel.results_pdf_filename}
                        .elementType=${panel.element_type}
                        title=${panel.title}
                        .progressText=${"(Custom Form)"}
                    >
                        <dl class="panel-details">
                            <dd>
                                <strong>File name:</strong>
                                ${formatFileName(
                                  panel.results_pdf_filename,
                                  "-",
                                )}
                            </dd>
                            <dd>
                                <strong>Date:</strong>
                                ${formatDateTime(panel.completed_at, "-")}
                            </dd>
                        </dl>
                    </legl-engage-panel>
                </div>`;
      case "signature-request":
        return html` <legl-signature-panel-step
                    .panel=${panel}
                    .stepTitle=${panel.element_config.title}
                    .elementType=${panel.element_type}
                ></legl-signature-panel-step>`;
      case "e-signature-request-v2":
        return html` <legl-e-signature-request-v2-panel-step
                    .panel=${panel}
                    .application=${this.application}
                    .stepTitle=${panel.element_config.title}
                    .elementType=${panel.element_type}
                    .fetchReviewInformation=${this.fetchReviewInformation.bind(
                      this,
                    )}
                ></legl-e-signature-request-v2-panel-step>`;
      default:
        return nothing;
    }
  }

  getEventIndividual(event) {
    return event.triggered_by ? event.triggered_by.name : "Legl";
  }

  getActivityText(event) {
    switch (event.event_type) {
      case "reviewer_assigned":
        return event.triggered_by
          ? `${event?.triggered_by?.name} assigned ${event?.associated_individual?.name} to review this request`
          : "";
      case "application_reviewed":
        return event.triggered_by
          ? `${event?.triggered_by?.name} reviewed this request`
          : "";
      case "force_completed":
        return event.triggered_by
          ? `${event.triggered_by.name} force completed this request`
          : "";
      case "reminders_sent":
        return "An email reminder was sent";
      case "initial_email_sent":
        return event.triggered_by
          ? `${event.triggered_by.name} sent this request via Legl`
          : "This request has been sent via Legl";
      case "reminders_disabled":
        return event.triggered_by
          ? `${event.triggered_by.name} has disabled email reminders`
          : "Reminders were disabled by the system";
      case "reminders_enabled":
        return event.triggered_by
          ? `${event.triggered_by.name} has enabled email reminders`
          : "";
      case "marked_closed":
        return event.triggered_by
          ? `${event.triggered_by.name} has marked this request as closed`
          : "";
      case "marked_closed_automatically":
        return event.associated_individual
          ? `This request was automatically marked closed when the contact's email address was updated by ${event.associated_individual.name}`
          : "";
      case "application_regenerated":
        return event.associated_individual
          ? `This request was automatically generated when the contact's email address was updated by ${event.associated_individual.name}. The previous request was marked closed`
          : "";
      case "monitoring_enabled":
        const triggeredBy = event.triggered_by
          ? ` by ${event.triggered_by.name}`
          : "";
        return `Created automatically as the result of Ongoing Monitoring being turned on${triggeredBy} for this client`;
      case "additional_signer_email_sent":
        return event.triggered_by
          ? `${event.triggered_by.name} resent this signature request via Legl to:`
          : "";
      default:
        return "";
    }
  }

  hasMonitoringEnabledEvent() {
    return this.application.application_events?.some(
      (event) => event.event_type === "monitoring_enabled",
    );
  }

  isRegeneratedApplication() {
    return this.application.application_events?.some(
      (event) => event.event_type === "application_regenerated",
    );
  }

  getForceCompletedEvent(applicationEvents) {
    return applicationEvents?.find(
      (forceCompletedEvent) =>
        forceCompletedEvent.event_type === "force_completed",
    );
  }

  getForceCompletedBy(applicationEvents) {
    const forceCompletedEvent = this.getForceCompletedEvent(applicationEvents);
    if (forceCompletedEvent) {
      return html`
                <div class="applicant-item">
                    <p class="applicant-detail">Force completed by</p>
                    <span>${forceCompletedEvent?.triggered_by?.name}</span>
                </div>
            `;
    }
    return "";
  }

  get emailSentCount() {
    if (!isNaN(this.updatedEmailSentCount)) {
      return this.updatedEmailSentCount;
    }
    return isNaN(this.application.email_sent_count)
      ? 0
      : this.application.email_sent_count;
  }

  get sortedHistory() {
    return this.application.application_events?.map((event) => {
      return html`
                <legl-activity-log
                    data-cy-review-activity-log
                    comment=${event.comment}
                    user=${this.getEventIndividual(event)}
                    .activityText=${
                      event.event_message ?? this.getActivityText(event)
                    }
                    date=${event.created_at}
                ></legl-activity-log>
            `;
    });
  }

  get fakeCreatedEvent() {
    if (this.hasMonitoringEnabledEvent() || this.isRegeneratedApplication()) {
      return "";
    }

    return html` <legl-activity-log
            user=${this.application.created_by.name}
            .activityText="${this.application.created_by.name} created this request"
            date=${this.application.created_at}
        ></legl-activity-log>`;
  }

  onEmailSent(e) {
    toastService.showSuccess("Email sent!");
    this.updatedEmailSentCount = e.detail.response.data.email_sent_count;
  }

  humanReadable(value) {
    return value.replace(/_/g, " ");
  }

  get isEngageRequestClosed() {
    return (
      this.application.status.toLowerCase() === "ready for review" ||
      this.application.status.toLowerCase() === "reviewed" ||
      this.application.status.toLowerCase() === "marked closed"
    );
  }

  get emailButtonDisabled() {
    return (
      this.application.contact?.email === null || this.isEngageRequestClosed
    );
  }

  get shouldShowEmailTooltip() {
    return (
      this.application.contact?.email === null && !this.isEngageRequestClosed
    );
  }

  get requestUrl() {
    return `/api/engage_applications/${this.application.id}/`;
  }

  get sendEmailUrl() {
    return `/api/engage_applications/${this.application.id}/send_engage_email/`;
  }

  get contactEmail() {
    return this.application.contact?.email;
  }

  get isStatusCreated() {
    return this.application.status === "Created";
  }

  isApplicationComplete() {
    return (
      this.application.submitted_at ||
      this.application.status === "Marked closed"
    );
  }

  get isCompanyReportPerson() {
    return !!this.application.company_report_person;
  }

  onViewClicked() {
    location.setCurrentLocation(
      `/business_cdd/reports/${this.application.company_report_person.creditsafe_report_reference}/`,
    );
  }

  render() {
    if (this.showApplicationUnavailableMessage) {
      return html`
                <legl-permissions-overlay
                    overlayMessage="This Engage Request could not be found"
                ></legl-permissions-overlay>
            `;
    }
    if (this.application) {
      return html`
                <div class="content" id="engage-review">
                    <div class="request-details">
                        <div class="first-row">
                            <span>
                                <legl-request-status
                                    status="${this.application.status}"
                                >
                                    ${this.application.status}
                                </legl-request-status>
                            </span>
                        </div>
                        ${
                          this.application?.status === "Processing"
                            ? html`<lds-alert
                                  title="One or more checks are still processing"
                                  message="You can review the results of other checks in the PDF now"
                                  type="warning"
                              ></lds-alert>`
                            : nothing
                        }
                        <div class="second-row">
                            <h2 class="title" data-cy-application-name>
                                ${this.application.flow_name}
                            </h2>
                            <div class="actions-container">
                                <legl-tooltip-dynamic
                                    .hover=${true}
                                    .isEnabled=${this.shouldShowEmailTooltip}
                                >
                                    <span slot="content">
                                        <p class="tooltip-text">
                                            The contact does not have an email
                                            address. An email address can be
                                            added by viewing the contact page.
                                        </p>
                                    </span>
                                    ${
                                      this.isStatusCreated
                                        ? html`
                                              <legl-button
                                                  class="action-button"
                                                  icon="legl-icon-send"
                                                  size="small"
                                                  .disabled=${
                                                    this.emailButtonDisabled
                                                  }
                                                  data-cy-send-email-button
                                                  @click=${() =>
                                                    initializeSendInitialEmail({
                                                      contactEmail:
                                                        this.contactEmail,
                                                      sendEmailUrl:
                                                        this.sendEmailUrl,
                                                      requestUrl:
                                                        this.requestUrl,
                                                      requestId:
                                                        this.application.id,
                                                      productType: "engage",
                                                    })}
                                              >
                                                  Send Email
                                              </legl-button>
                                          `
                                        : nothing
                                    }
                                    ${
                                      this.isCompanyReportPerson
                                        ? html`
                                              <lds-button
                                                  class="action-button"
                                                  id="view-company-report"
                                                  small
                                                  icon="lds-icon-CompanyReport"
                                                  variant="outlined"
                                                  @click=${() =>
                                                    this.onViewClicked()}
                                                  data-cy-show-report-person
                                              >
                                                  View company report
                                              </lds-button>
                                          `
                                        : nothing
                                    }
                                </legl-tooltip-dynamic>
                                <legl-engage-review-actions
                                    .application=${this.application}
                                ></legl-engage-review-actions>
                            </div>
                        </div>
                        <legl-card class="third-row">
                            <h1 slot="title" variant="corner">details</h1>
                            <div class="application-details-card-row-one">
                                <p class="applicant-detail no-padding">
                                    Contact
                                </p>
                                <legl-contact-card
                                    .contact="${this.application.contact}"
                                ></legl-contact-card>
                            </div>

                            <div class="application-details-card-row-two">
                                <div class="">
                                    <p class="applicant-detail">
                                        Client Reference
                                    </p>
                                    <span
                                        data-cy-review-engage-client-reference
                                    >
                                        ${
                                          this.application.client_reference ||
                                          "-"
                                        }</span
                                    >
                                </div>
                                <div class="">
                                    <p class="applicant-detail">
                                        Matter reference
                                    </p>
                                    <span
                                        data-cy-review-engage-client-reference
                                    >
                                        ${
                                          this.application.matter_reference ||
                                          "-"
                                        }</span
                                    >
                                </div>
                                <div class="">
                                    <p class="applicant-detail">
                                        Legl reference
                                    </p>
                                    <span data-cy-review-engage-legl-reference>
                                        ${this.application.reference || "-"}</span
                                    >
                                </div>
                                <div class="">
                                    <p class="applicant-detail">Reviewer</p>
                                    ${this.reviewer}
                                </div>
                            </div>
                        </legl-card>
                    </div>

                    ${
                      !this.application.manual
                        ? html`
                              <email-reminders-row
                                  .reminders=${this.application.reminders}
                                  .isRequestClosedOrComplete=${Boolean(
                                    this.isApplicationComplete(),
                                  )}
                                  .requestUrl=${this.requestUrl}
                                  .sendEmailUrl=${this.sendEmailUrl}
                                  .isStatusCreated=${this.isStatusCreated}
                                  .contactEmail=${this.contactEmail}
                                  .requestId=${this.application.id}
                                  productType="engage"
                              ></email-reminders-row>
                          `
                        : nothing
                    }

                    <div class="workflow-steps">
                        <div class="workflow-title-and-progress-container">
                            <h2 class="workflow-steps-title">Workflow steps</h2>
                            <legl-lawyers-table-cell-engage-status
                                .row=${this.application}
                                hideStatus
                            ></legl-lawyers-table-cell-engage-status>
                        </div>
                        ${
                          this.getForceCompletedEvent(
                            this.application.application_events,
                          )
                            ? html`
                                <div
                                    data-cy-force-complete-warning
                                    class="force-complete-warning"
                                >
                                    <legl-icon
                                        name="legl-icon-warning-circle"
                                        class="warning-circle"
                                    ></legl-icon>
                                    <p>
                                        <strong
                                        >This request was force completed.</strong
                                        >
                                        Some steps are incomplete or partially complete.
                                    </p>
                                </div
                                    data-cy-force-complete-warning
                                    class="force-complete-warning">`
                            : ""
                        }
                        ${this.panels}

                        <div
                            class="form-group ${
                              this.application.disable_review ? "hidden" : ""
                            }"
                        >
                            <lds-checkbox
                                class="mark-as-reviewed-checkbox"
                                data-cy-review-engage-mark-reviewed
                                @model-value-changed=${(e) => {
                                  this.markReviewedValue = e.target.checked
                                    ? "on"
                                    : "off";
                                }}
                            >
                                <label slot="label"> Mark as reviewed * </label>
                            </lds-checkbox>
                        </div>
                        <div
                            class="form-group ${
                              this.application.disable_review ? "hidden" : ""
                            }"
                        >
                            <lds-textarea
                                id="application-comment"
                                label="Reviewer comment"
                                help-text=${
                                  this.onfidoStep
                                    ? "We'll attach your comment to the Client Due Diligence report"
                                    : "We'll add your comment to the request activity"
                                }
                                data-cy-review-engage-comment
                                name="review_comment"
                                maxlength="10000"
                                ?optional="${true}"
                                .validators=${[new LeglMaxLength(10000)]}
                                .modelValue=${this.reviewCommentValue}
                                .disabled=${this.isSaving}
                                @model-value-changed="${(e) => {
                                  this.reviewCommentValue =
                                    e.currentTarget.value;
                                }}"
                            ></lds-textarea>
                        </div>
                        ${
                          !this.application.disable_review
                            ? html` <div class="buttons">
                                      <lds-button
                                          type="submit"
                                          data-cy-review-engage-submit
                                          colour="primary"
                                          @click=${(e) => {
                                            e.preventDefault();
                                            this.onSubmit();
                                          }}
                                          .disabled=${this.isFormDisabled}
                                          .isLoading=${this.isSaving}
                                      >
                                          Submit review
                                      </lds-button>
                                  </div>

                        </div>
                        `
                            : nothing
                        }
                        ${
                          this.application.review_comment && this.onfidoStep
                            ? html` <div class="reviewed-with-comment">
                                  ${
                                    !this.onfidoStep?.pdf_url_with_comments
                                      ? html`<lds-alert
                                            type="info"
                                            class="reviewed-with-comment-alert"
                                            ><p
                                                slot="content"
                                                class="reviewed-with-comment-message"
                                            >
                                                The CDD report with comment will
                                                be ready shortly. Please
                                                <button
                                                    class=""
                                                    @click=${() =>
                                                      window.location.reload()}
                                                >
                                                    refresh the page
                                                </button>
                                                in a bit to download the report,
                                                this usually takes a few
                                                seconds.
                                            </p></lds-alert
                                        >`
                                      : nothing
                                  }
                                  <lds-button
                                      colour="primary"
                                      variant="contained"
                                      ?disabled=${!this.onfidoStep
                                        ?.pdf_url_with_comments}
                                      @click=${() =>
                                        (window.location =
                                          this.onfidoStep?.pdf_url_with_comments)}
                                      role="link"
                                  >
                                      Download CDD report with comment
                                  </lds-button>
                              </div>`
                            : nothing
                        }

                        <div
                            data-cy-review-history
                            class="recent-activity"
                            id="engage-request-history"
                        >
                            <h3>Engage request history</h3>
                            ${this.sortedHistory} ${this.fakeCreatedEvent}
                        </div>
                    </div>
                </div>
            `;
    }
    return "";
  }
}

customElements.define("legl-engage-review", LeglEngageReview);
