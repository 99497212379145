import { LitElement, css, html } from "lit";
import { neutral } from "../../../../../../legl-ui/lds-colours";
import { spacing } from "../../../../../../legl-ui/lds-spacing";
import { typographyPresets } from "../../../../../../legl-ui/lds-typography";

class NoIdDocumentModal extends LitElement {
  static get styles() {
    return [
      css`
        .modal {
          padding: 0 ${spacing.m} ${spacing.m} ${spacing.m};
          color: ${neutral["800"]};
          ${typographyPresets.body};
        }
        .button-container {
          display: flex;
          justify-content: flex-end;
        }
        .key {
          ${typographyPresets.bodyBold};
          width: 110px;
          display: inline-block;
          margin-right: ${spacing.xs};
        }
        #no-id-modal-message {
          margin-top: 0;
        }
      `,
    ];
  }

  static get properties() {
    return {
      companyPhoneNumber: { type: String },
      companyEmail: { type: String },
      companyName: { type: String },
    };
  }

  constructor() {
    super();
    this.companyPhoneNumber = "";
    this.companyEmail = "";
    this.companyName = "";
  }

  handleClose() {
    this.parentElement.onClickClose();
  }

  render() {
    return html`
      <div class="modal">
        <p id="no-id-modal-message">If you do not have a government-issued identity document, or if your identity document has expired, please contact ${this.companyName} directly to discuss alternative methods of verifying your identity.</p>
        <p id="no-id-modal-phone-number"><span class="key">Phone number</span> ${this.companyPhoneNumber || "-"}</p>
        <p id="no-id-modal-email"><span class="key">Email</span> ${this.companyEmail || "-"}</p>
        <div class="button-container">
          <lds-button colour="primary" @click=${this.handleClose}>
            Close
          </lds-button>
        </div>
      </div>
    `;
  }
}

customElements.define("no-id-document-modal", NoIdDocumentModal);

export default NoIdDocumentModal;
