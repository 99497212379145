import { LitElement, css, html } from "lit";

import { nothing } from "lit";

import { classMap } from "lit/directives/class-map.js";
import { neutral } from "../../../legl-ui/lds-colours";
import { typographyPresets } from "../../../legl-ui/lds-typography";

export class LeglDrawer extends LitElement {
  static get styles() {
    return css`
            :host {
                color: ${neutral["700"]};
            }

            :host([isShown]) .drawer__wrapper {
                transform: translateX(0);
            }

            .drawer__wrapper {
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
                max-width: var(--my-drawer-max-width, 60%);
                z-index: 1020;
                overflow: auto;
                transition: transform 0.3s;
                will-change: transform;
                background-color: ${neutral["100"]};
                display: flex;
                flex-direction: column;
                transform: translateX(100%);
            }

            @media only screen and (min-width: 1512px) {
                .drawer__wrapper {
                    max-width: 907px;
                }
            }

            @media only screen and (max-width: 1030px) {
                .drawer__wrapper {
                    max-width: 100%;
                }
            }

            .drawer__wrapper.drawer__wrapper--is-left {
                right: auto;
                left: 0;
                transform: translateX(-100%);
            }
            .drawer__content {
                scroll-behavior: smooth;
                position: absolute;
                overflow-x: hidden;
                overflow-y: auto;
                flex-grow: 1;
                padding: 1.9rem 2.5rem 2.5rem;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            .drawer__content.drawer__content--has-title {
                top: 60px;
            }

            .drawer__header {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                color: white;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 0;
                padding: 16px 32px;
                background: ${neutral["800"]};
                box-sizing: border-box;
                height: 60px;
            }

            .draw___title-container {
                display: flex;
                align-items: center;
            }

            .drawer__title {
                color: var(--brand-white, #fff);
                margin: 0;
                z-index: 1;
            }

            .drawer__title-icon {
                margin-right: 8px;
            }

            .drawer__title-button {
                ${typographyPresets.bodyBold};
                color: #ffffff;
                background: none;
                border: none;
                outline: none;
                cursor: pointer;
                display: flex;
                align-items: center;
            }

            :host([isShown]) .drawer__overlay {
                opacity: 0.4;
                z-index: 1010;
                transition: z-index 0s 0s;
            }

            .drawer__overlay {
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                z-index: -1;
                opacity: 0;
                transition: opacity 0.3s, z-index 0s 0.3s;
                will-change: opacity;
                background-color: #000;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }

            .warning-message-pane {
                position: fixed;
                top: 60px;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: var(--lds-colour-neutral-200);
                z-index: 2;
                opacity: 0.93;
            }

            .warning-message-wrapper {
                text-align: center;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            .warning-message {
                margin-bottom: 26px;
                font-size: 18px;
            }

            .warning-message p:first-child {
                font-weight: bold;
                font-size: 20px;
            }

            .warning-icon {
                font-size: 54px;
            }

            .stay-btn {
                margin-right: 16px;
            }
        `;
  }

  static get properties() {
    return {
      isShown: {
        type: Boolean,
        reflect: true,
      },
      position: {
        type: String,
      },
      showOverlay: {
        type: Boolean,
      },
      drawerTitle: {
        type: String,
      },
      drawerIcon: {
        type: String,
      },
      drawerId: {
        type: String,
      },
      withCloseWarning: {
        type: Boolean,
        attribute: "with-close-warning",
      },
      hasCloseWarning: {
        type: Boolean,
        attribute: false,
      },
    };
  }

  constructor() {
    super();
    this.position = "right";
    this.isShown = false;
    this.showOverlay = true;
    this.transitionTimeout = null;
    this.resetUrlOnClose = true;
    this.withCloseWarning = false;
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener(
      "vaadin-router-location-changed",
      this.handleVaadinLocationChanged,
    );
  }

  disconnectedCallback() {
    window.removeEventListener(
      "vaadin-router-location-changed",
      this.handleVaadinLocationChanged,
    );
    super.disconnectedCallback();
  }

  handleVaadinLocationChanged = (e) => {
    if (!e.detail.location.hash) {
      this.hide();
    }
  };

  clearTransitionTimeout() {
    if (this.transitionTimeout) {
      clearTimeout(this.transitionTimeout);
      this.transitionTimeout = null;
    }
  }

  async transition() {
    this.clearTransitionTimeout();
    return new Promise((resolve) => {
      this.transitionTimeout = setTimeout(() => {
        resolve();
        this.clearTransitionTimeout();
      }, this.transitionDuration);
    });
  }

  async toggle() {
    this.isShown = !this.isShown;
    await this.transition();
    return this.isShown;
  }

  async show() {
    this.isShown = true;
    await this.transition();
    this.dispatchEvent(new CustomEvent("shown"));
  }

  async hide() {
    this.isShown = false;
    await this.transition();
    this.hasCloseWarning = false;
    this.withCloseWarning = false;
    this.dispatchEvent(
      new CustomEvent("hidden", {
        detail: { id: this.drawerId },
        bubbles: true,
        composed: true,
      }),
    );
  }

  get drawerWrapper() {
    return this.shadowRoot.querySelector(".drawer__wrapper");
  }

  get positionClass() {
    if (["left", "right"].includes(this.position)) {
      return `is-${this.position}`;
    }
    return `is-right`;
  }

  get wrapperClasses() {
    return classMap({
      drawer__wrapper: true,
      [this.positionClass]: true,
    });
  }

  get contentClasses() {
    return classMap({
      drawer__content: true,
      "drawer__content--has-title": this.hasTitle,
    });
  }

  set showOverlay(value) {
    this._showOverlay = value;
  }

  get showOverlay() {
    return this._showOverlay;
  }

  get transitionDuration() {
    const style = getComputedStyle(this.drawerWrapper).transitionDuration;
    // fallback value
    if (!style || Number.isNaN(style)) {
      return 500;
    }
    return Math.round(Number.parseFloat(style) * 1000);
  }

  get hasTitle() {
    return !!this.drawerTitle;
  }

  get warningMessagePane() {
    return html`
            <div class="warning-message-pane">
                <div class="warning-message-wrapper">
                    <lds-icon
                        class="warning-icon"
                        name="lds-icon-WarningOutlined"
                    ></lds-icon>
                    <div class="warning-message">
                        <p>There are unsubmitted changes</p>
                        <p>
                            All unsubmitted changes on this page will be lost if
                            you do not submit before leaving.
                        </p>
                        <p>Are you sure you want to leave this page?</p>
                    </div>
                    <lds-button
                        class="stay-btn"
                        data-cy-warning-stay-btn
                        variant="outlined"
                        colour="primary"
                        @click=${() => (this.hasCloseWarning = false)}
                        >Stay on page</lds-button
                    >
                    <lds-button
                        data-cy-warning-leave-btn
                        colour="primary"
                        @click=${() => this.hide()}
                        >Leave page</lds-button
                    >
                </div>
            </div>
        `;
  }

  closeHandler() {
    if (this.withCloseWarning) {
      this.hasCloseWarning = true;
    } else {
      this.hide();
      this.dispatchEvent(
        new CustomEvent("drawer-closed", {
          bubbles: true,
          composed: true,
        }),
      );
    }
  }

  render() {
    return html`
            ${
              this.showOverlay
                ? html`<div
                      class="drawer__overlay"
                      @click=${this.closeHandler}
                      tabindex="-1"
                  ></div>`
                : ""
            }
            <div class="${this.wrapperClasses}">
                ${
                  this.hasTitle
                    ? html`<div class="drawer__header">
                          <div class="draw___title-container">
                              <lds-icon
                                  name="${this.drawerIcon}"
                                  class="drawer__title-icon"
                              ></lds-icon>
                              <h2 class="drawer__title">${this.drawerTitle}</h2>
                          </div>
                          <button
                              class="drawer__title-button"
                              @click=${this.closeHandler}
                          >
                              Close
                              <lds-icon name="lds-icon-ChevronRight"></lds-icon>
                          </button>
                      </div>`
                    : ""
                }
                ${this.hasCloseWarning ? this.warningMessagePane : nothing}
                <div class="${this.contentClasses}" data-cy="drawer-scroll">
                    <div class="drawer__slot">
                        <slot></slot>
                    </div>
                </div>
            </div>
        `;
  }
}

customElements.define("legl-drawer", LeglDrawer);
