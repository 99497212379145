import { LitElement, css, html } from "lit";
import "../../../../../../../../../legl-ui/lds-checkbox";
import { danger } from "../../../../../../../../../legl-ui/lds-colours";
import { spacing } from "../../../../../../../../../legl-ui/lds-spacing";
// import { LdsToast } from "../../../../../../../../../legl-ui/lds-toast";
import { typographyPresets } from "../../../../../../../../../legl-ui/lds-typography";
// import { post } from "../../../../../../../../../legl-ui/utils/fetch.js";

class MarkCompanyMonitoringUpdateReviewUI extends LitElement {
  static get styles() {
    return css`
            .markReviewForm {
                margin-top: ${spacing.l};
            }
            .markReviewForm__checkbox__label.-required {
                color: ${danger.base};
            }

            .markReviewForm__checkbox {
                margin-bottom: ${spacing.m};
            }

            .markReviewForm__rightGroup {
                display: flex;
                justify-content: end;
            }

            .markReviewForm__rightGroup > div {
                display: flex;
                gap: ${spacing.m};
            }

            .markReviewForm__rightGroup legl-button {
                margin-top: ${spacing.m};
            }

            .markReviewForm__rightGroup__limit {
                ${typographyPresets.medium}
            }
        `;
  }

  static get properties() {
    return {
      alertId: { attribute: false },
      markAsReviewed: { attribute: false },
      markAsReviewedAndUpdate: { attribute: false },
      isSaving: { state: true },
      markReviewedValue: { state: true },
      commentValue: { state: true },
    };
  }

  get isFormDisabled() {
    return this.isSaving || this.markReviewedValue !== "on";
  }

  handleReview = () => {
    this.markAsReviewed(this.commentValue);
  };

  handleReviewAndUpdate = async () => {
    this.isSaving = true;
    await this.markAsReviewedAndUpdate(this.commentValue);
    this.isSaving = false;
  };

  render() {
    return html`
            <form class="markReviewForm">
                <lds-checkbox
                    class="markReviewForm__checkbox"
                    data-cy="review-monitoring-mark-reviewed"
                    @model-value-changed=${(e) => {
                      this.markReviewedValue = e.target.checked ? "on" : "off";
                    }}
                    label="Mark reviewed *"
                ></lds-checkbox>
                <lds-textarea
                    label="Optional reviewer comments (internal use only)"
                    maxlength="250"
                    name="review_comment"
                    @model-value-changed="${(e) => {
                      this.commentValue = e.currentTarget.value;
                    }}"
                ></lds-textarea>
                <br />
                <div class="markReviewForm__rightGroup">
                    <div>
                        <lds-button
                            type="button"
                            id="no-action"
                            colour="primary"
                            variant="contained"
                            data-cy-monitoring-submit
                            ?disabled=${this.isFormDisabled}
                            @click=${this.handleReview}
                        >
                            No action required
                        </lds-button>
                        <lds-button
                            type="button"
                            id="update-report"
                            colour="primary"
                            variant="contained"
                            data-cy-monitoring-submit
                            ?is-loading=${this.isSaving}
                            ?disabled=${this.isFormDisabled}
                            @click=${this.handleReviewAndUpdate}
                        >
                            Update company report
                        </lds-button>
                    </div>
                </div>
            </form>
        `;
  }
}

customElements.define(
  "mark-company-monitoring-update-review-ui",
  MarkCompanyMonitoringUpdateReviewUI,
);
