import { LitElement, css, html, nothing } from "lit";
import { connect } from "pwa-helpers";
import "../../../../../../legl-ui/action-bar";
import "../../../../../../legl-ui/activity-log";
import "../../../../../../legl-ui/card";
import "../../../../../../legl-ui/lds-alert";
import { neutral, primary } from "../../../../../../legl-ui/lds-colours";
import { spacing } from "../../../../../../legl-ui/lds-spacing";
import { typographyPresets } from "../../../../../../legl-ui/lds-typography";
import "../../../../../../legl-ui/number";
import { get } from "../../../../../../legl-ui/utils/fetch";
import { formatDateTime } from "../../../../../../legl-ui/utils/functions.js";
import store, { payActions } from "../../../../../../redux/lawyers-admin";
import companyPaymentSettings from "../../config/company-payment-settings.js";
import { createRefundModal } from "../../pages/pay/components/modals/refund/refund-modal";
import "../../pages/pay/components/pay-review-actions.js";
import { createClipboardButton } from "../../utils/clipboard";
import "../email-reminders-row/email-reminders-row.js";

export class LeglPayReview extends connect(store)(LitElement) {
  static get styles() {
    return css`
            :host {
                --legl-action-bar-min-width: 0;
            }

            .heading-actions-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 20px;
            }

            .refund-button-container {
                flex-grow: 1;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 16px;
            }

            .action-buttons {
                display: flex;
                align-items: center;
            }

            .open-engage-drawer-btn {
                align-self: center;
                color: ${neutral["700"]};
                border: none;
                cursor: pointer;
                transform-origin: 50% 50%;
                transition: transform 0.125s ease-in-out 0s;
                padding: 15px 5px;
                background: none;
                font-size: 12px;
                min-width: 44px;
                margin-right: 10;
                display: grid;
                grid-template-rows: 22px auto;
            }

            .open-engage-drawer-btn:hover {
                background-color: ${neutral["200"]};
                border-radius: 4px;
                text-decoration: none;
            }

            .open-engage-drawer-btn lds-icon {
                font-size: 16px;
            }

            .title {
                margin-top: 0;
            }

            .content-details {
                color: var(--legl-grey-dark);
            }

            .payment-content-details {
                display: flex;
                flex-direction: column;
                flex-flow: wrap;
            }

            .contact-details {
                display: flex;
            }

            .engage-request-info {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }

            .payment-details {
                display: flex;
                flex-flow: wrap;
                justify-content: space-between;
                width: 100%;
            }

            .workflow-details {
                display: flex;
                justify-content: space-between;
                padding-bottom: 50px;
                flex-wrap: wrap;
                width: 100%;
            }

            .contact-details lds-icon {
                padding-right: 5px;
            }

            .request-details-row {
                display: flex;
            }

            .request-details-column {
                padding-right: 35px;
            }

            .content-item {
                padding: 15px;
            }

            .engage-content-item {
                padding: 5px 5px 5px 15px;
                display: flex;
                justify-content: space-between;
                width: 300px;
            }

            .engage-request-status {
                margin-right: -20px;
            }

            .full-width {
                width: 100%;
                display: flex;
                justify-content: flex-end;
            }

            .content-item-total {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }

            .margin-bottom {
                margin-bottom: 0.1em;
            }

            .pay-request-history {
                padding-top: 5px;
                color: ${neutral["800"]};
            }

            .title {
                font-weight: 600;
            }

            .pay-drawer__card {
                margin-bottom: 15px;
            }

            .invoice-link {
                color: ${primary["500"]};
                display: flex;
                gap: ${spacing.xs};
                text-decoration: none;
            }

            .invoice-link span {
                text-decoration: underline;
            }

            .payment-provider {
                display: flex;
            }

            .payment-provider lds-icon {
                margin-right: ${spacing.xs};
            }

            .payment-provider:first-child {
                margin-right: ${spacing.s};
            }

            lds-alert {
                margin-bottom: ${spacing.s};
            }

            lds-alert span {
                color: ${neutral["800"]};
                ${typographyPresets.bodyBold};
            }

            lds-alert a {
                color: ${primary["500"]};
                ${typographyPresets.bodyBold};
            }
        `;
  }
  static get properties() {
    return {
      params: {
        type: URLSearchParams,
      },
      paymentRequest: {
        type: Object,
      },
      companyPaymentSettings: {
        type: Object,
      },
      showPaymentUnavailableMessage: { type: Boolean, state: true },
      isLoadingRefund: { type: Boolean, state: true },
    };
  }

  constructor() {
    super();
    this.addEventListener("close-link-clicked-from-drawer", () =>
      this.fetchReviewInformation(),
    );
    this.showPaymentUnavailableMessage = false;
    this.isLoadingRefund = false;
  }

  stateChanged(state) {
    this.paymentRequest = state.pay[this.params.get("appId")];
  }

  get isPortalPayment() {
    return this.paymentRequest.source === "PAYMENT_PORTAL";
  }

  get isPaymentTransferComplete() {
    return (
      this.paymentRequest.transfer_id &&
      this.paymentRequest.transfer_time &&
      this.paymentRequest.bank_account_name &&
      this.paymentRequest.amount
    );
  }

  get paymentIcon() {
    if (this.paymentRequest.wallet_type === "apple_pay") {
      return "ApplePay";
    } else if (this.paymentRequest.wallet_type === "google_pay") {
      return "GooglePay";
    } else if (this.paymentRequest.source === "PHONE_PAYMENT") {
      return "Phone";
    }
    return "CreditCard";
  }

  get cardCountry() {
    if (this.paymentRequest && this.paymentRequest.card_country) {
      return this.paymentRequest.card_country;
    }
    return "";
  }

  get last4() {
    if (this.paymentRequest && this.paymentRequest.last4) {
      return this.paymentRequest.last4;
    }
    return "----";
  }

  get paymentMethod() {
    if (this.paymentRequest.provider_name === "banked") {
      return html`<span class="payment-provider"
                ><lsd-icon name="lds-icon-Bank"></lds-icon>Pay by bank</span
            >`;
    }
    if (this.paymentRequest.provider_name === "dummy") {
      return html`<span class="payment-provider"
                ><lds-icon name="lds-icon-SettingsOutlined"></lds-icon>Dummy
                test payment</span
            >`;
    }
    if (this.paymentRequest.provider_name === "adyen") {
      const cardType = this?.paymentRequest?.card_brand || "-";
      return html` <span class="payment-provider">
                <lds-icon .name="lds-icon-${this.paymentIcon}"></lds-icon>
                <span class="card-type">${cardType}</span> ${this.cardCountry}
                *${this.last4}
            </span>`;
    }
    if (this.paymentRequest.provider_name === "stripe") {
      const cardType = this?.paymentRequest?.card_brand || "-";
      return html` <span class="payment-provider">
                <lds-icon .name="lds-icon-${this.paymentIcon}"></lds-icon>
                <span class="card-type">${cardType}</span> ${this.cardCountry}
                *${this.last4}
            </span>`;
    }

    return html`<span class="payment-provider">-</span>`;
  }

  get engageRequestReviewer() {
    if (this.paymentRequest.engage_request.reviewed_by) {
      return html`
                <span
                    >${this.paymentRequest.engage_request.reviewed_by.name}
                </span>
            `;
    } else if (this.paymentRequest.engage_request.assigned_reviewer) {
      return html`
                <span
                    >${this.paymentRequest.engage_request.assigned_reviewer.name}
                </span>
            `;
    } else if (this.paymentRequest.engage_request.created_by) {
      return html`
                <span
                    >${this.paymentRequest.engage_request.created_by.name}
                </span>
            `;
    }
    return "-";
  }

  connectedCallback() {
    super.connectedCallback();
    this.fetchReviewInformation();
    this.fetchCompanyPaymentSettings();
    window.addEventListener("reload-payment-drawer", () => {
      this.fetchReviewInformation();
    });
  }

  async fetchReviewInformation() {
    const res = await get(`/api/payments/${this.params.get("appId")}/`);
    if (res.status === 404) {
      this.showPaymentUnavailableMessage = true;
    } else {
      const paymentRequest = await res.json();
      store.dispatch(payActions.fetchPaymentDetails(paymentRequest));
    }
  }

  async fetchCompanyPaymentSettings() {
    this.firmReferenceLabel1 = "Invoice reference";
    this.firmReferenceLabel2 = "Matter reference";
    await companyPaymentSettings.fetch();
    this.companyPaymentSettings = companyPaymentSettings.settings;
    this.setFirmReferenceLabels();
  }

  setFirmReferenceLabels() {
    if (this.companyPaymentSettings.invoice_reference_label) {
      this.firmReferenceLabel1 =
        this.companyPaymentSettings.invoice_reference_label;
    }

    if (this.companyPaymentSettings.matter_reference_label) {
      this.firmReferenceLabel2 =
        this.companyPaymentSettings.matter_reference_label;
    }
  }

  firstUpdated(changedProperties) {
    super.firstUpdated(changedProperties);
    const copyButton = this.shadowRoot.querySelector("#clipboard-button");
    if (copyButton) {
      createClipboardButton(copyButton);
    }
  }

  onViewClicked() {
    window.location.hash = `/drawer/engage-review/?appId=${this.paymentRequest.engage_request.id}`;
  }

  isPaymentComplete() {
    return (
      this.paymentRequest.status === "Paid" ||
      this.paymentRequest.status === "Transferred" ||
      this.paymentRequest.marked_paid ||
      this.paymentRequest.closed
    );
  }

  showRefundButton() {
    return (
      this.paymentRequest.status === "Paid" ||
      this.paymentRequest.status === "Transferred" ||
      this.paymentRequest.status === "Partially Refunded" ||
      this.paymentRequest.status === "Refund Processing" ||
      !this.paymentRequest.provider_name === "banked"
    );
  }

  handleRefund() {
    if (this.paymentRequest.provider_name === "adyen") {
      this.openRefundModal();
    } else {
      window.open(window.STRIPE_REFUNDS_FORM_URL, "_blank");
    }
  }

  get refundButtonText() {
    if (this.paymentRequest.status === "Refund Processing") {
      return "View refund request";
    }
    return "Refund payment";
  }

  get requestUrl() {
    return `/api/payments/${this.paymentRequest.id}/`;
  }

  get isStatusCreated() {
    return this.paymentRequest.status === "Created";
  }

  get sendEmailUrl() {
    return `api/payments/send_payments_email/${this.paymentRequest.reference}/`;
  }

  get contactEmail() {
    return this.paymentRequest.contact?.email;
  }

  get isReminderRowHidden() {
    return !(
      this.paymentRequest.source === "PAY_REQUEST" ||
      (this.paymentRequest.source === "SCHEDULED_PAYMENT" &&
        !this.paymentRequest.off_session)
    );
  }

  getEventIndividual(event) {
    return event.triggered_by ? event.triggered_by.name : "Legl";
  }

  getActivityText(event) {
    switch (event.type) {
      case "off_session_payment_failed":
        return `Legl <strong>declined</strong> this payment. The reason was: <strong>${event?.comment}</strong>`;
      case "off_session_payment_retried":
        return `${event?.triggered_by?.name} <strong>retried</strong> this payment.`;
      case "off_session_payment_retried_automatically":
        return "Legl <strong>retried</strong> this payment automatically.";
      case "refund_requested":
        return `${
          event.triggered_by.name
        } <strong>requested</strong> a refund for this payment of amount <strong><legl-currency currency="${
          this.paymentRequest.currency
        }" value="${
          event.metadata?.amount
        }" locale="${this.getLocale()}"></legl-currency></strong>. The reason was: <strong>${
          event.comment
        }</strong>`;
      case "refund_cancelled":
        return `${event.triggered_by.name} <strong>cancelled</strong> the refund request for this payment.`;
      case "fake_event":
        return event.comment;
      default:
        return "";
    }
  }

  getRetrySuccessUser() {
    const retryEvents = this.paymentRequest.events.filter((event) => {
      return (
        event.type ===
        ("off_session_payment_retried" ||
          "off_session_payment_retried_automatically")
      );
    });
    const successRetryEvent = retryEvents[0];
    return successRetryEvent?.triggered_by
      ? successRetryEvent.triggered_by.name
      : "Legl";
  }

  getEvents() {
    const events = [...this.paymentRequest.events];

    if (this.paymentRequest.autogenerated) {
      events.push({
        type: "fake_event",
        triggered_by: {
          name: "Legl",
        },
        comment:
          "This request was automatically generated when the contact's email address was updated. The previous request was marked closed.",
        created_at: this.paymentRequest.created_at,
        payRequestComment: this.paymentRequest.comment,
      });
    }
    if (
      this.paymentRequest.requested_by &&
      this.paymentRequest.source !== "PHONE_PAYMENT" &&
      !this.paymentRequest.autogenerated &&
      !this.paymentRequest.off_session
    ) {
      events.push({
        type: "fake_event",
        triggered_by: { name: this.paymentRequest.requested_by?.name },
        comment: `${this.paymentRequest.requested_by?.name} <strong>requested</strong> this payment.`,
        created_at: this.paymentRequest.created_at,
        payRequestComment: this.paymentRequest.comment,
      });
    }
    if (this.paymentRequest.off_session) {
      events.push({
        type: "fake_event",
        triggered_by: { name: "Legl" },
        comment: "Legl <strong>created</strong> this payment automatically.",
        created_at: this.paymentRequest.created_at,
      });
    }
    if (this.paymentRequest.engage_payment) {
      events.push({
        type: "fake_event",
        triggered_by: {
          name: this.paymentRequest.engage_request?.created_by?.name,
        },
        comment: `${this.paymentRequest.engage_request?.created_by?.name} <strong>created</strong> this payment as part of a workflow.`,
        created_at: this.paymentRequest.created_at,
      });
    }
    if (
      this.paymentRequest.completed_at &&
      this.paymentRequest.source !== "PHONE_PAYMENT" &&
      !this.isPortalPayment &&
      this.paymentRequest.off_session
    ) {
      const actor = this.getRetrySuccessUser();
      events.push({
        type: "fake_event",
        triggered_by: { name: actor },
        comment: `${actor} <strong>completed</strong> this payment${
          actor === "Legl" ? " automatically" : ""
        }.`,
        created_at: this.paymentRequest.completed_at,
      });
    }
    if (
      this.paymentRequest.completed_at &&
      this.paymentRequest.source !== "PHONE_PAYMENT" &&
      !this.isPortalPayment &&
      !this.paymentRequest.off_session
    ) {
      events.push({
        type: "fake_event",
        triggered_by: {
          name: `${this.paymentRequest.first_name} ${this.paymentRequest.last_name}`,
        },
        comment: `${this.paymentRequest.first_name} ${this.paymentRequest.last_name} <strong>completed</strong> this payment.`,
        created_at: this.paymentRequest.completed_at,
      });
    }
    if (this.paymentRequest.completed_at && this.isPortalPayment) {
      events.push({
        type: "fake_event",
        triggered_by: {
          name: `${this.paymentRequest.first_name} ${this.paymentRequest.last_name}`,
        },
        comment: `${this.paymentRequest.first_name} ${this.paymentRequest.last_name} <strong>paid</strong> this request from payment portal.`,
        created_at: this.paymentRequest.completed_at,
      });
    }
    if (this.paymentRequest.source === "PHONE_PAYMENT") {
      events.push({
        type: "fake_event",
        triggered_by: { name: this.paymentRequest.requested_by?.name },
        comment: `${this.paymentRequest.requested_by?.name} <strong>completed a phone payment</strong>
                                    on behalf of ${this.paymentRequest.first_name} ${this.paymentRequest.last_name}.`,
        created_at: this.paymentRequest.completed_at,
      });
    }
    if (
      this.paymentRequest.marked_paid &&
      !this.paymentRequest.marked_paid_by
    ) {
      events.push({
        type: "fake_event",
        triggered_by: { name: "Legl" },
        comment: "This payment was <strong>marked as paid</strong>.",
        created_at: this.paymentRequest.marked_paid_at,
      });
    }
    if (this.paymentRequest.marked_paid && this.paymentRequest.marked_paid_by) {
      events.push({
        type: "fake_event",
        triggered_by: { name: this.paymentRequest.marked_paid_by.name },
        comment: `${this.paymentRequest.marked_paid_by.name} <strong>marked this payment as paid</strong>.`,
        created_at: this.paymentRequest.marked_paid_at,
      });
    }
    if (this.paymentRequest.closed && !this.paymentRequest.closed_by) {
      events.push({
        type: "fake_event",
        triggered_by: { name: "Legl" },
        comment: "This payment was <strong>marked as closed</strong>.",
        created_at: this.paymentRequest.closed_at,
      });
    }
    if (this.paymentRequest.closed && this.paymentRequest.closed_by) {
      events.push({
        type: "fake_event",
        triggered_by: { name: this.paymentRequest.closed_by.name },
        comment: `${this.paymentRequest.closed_by.name} <strong>marked this payment as closed</strong>.`,
        created_at: this.paymentRequest.closed_at,
      });
    }
    if (this.isPaymentTransferComplete) {
      events.push({
        type: "fake_event",
        triggered_by: { name: "Legl" },
        comment: `<legl-currency currency="${
          this.paymentRequest.currency
        }" value="${
          this.paymentRequest.amount
        }" locale="${this.getLocale()}"></legl-currency> was <strong>transferred</strong> to ${
          this.paymentRequest.bank_account_name
        }.`,
        created_at: this.paymentRequest.transfer_time,
      });
    }
    return events.sort(
      (ev1, ev2) => new Date(ev2.created_at) - new Date(ev1.created_at),
    );
  }

  getLocale() {
    return this.companyPaymentSettings?.company?.locale || "en-GB";
  }

  async openRefundModal() {
    if (this.disableRefundButton) {
      return;
    }
    this.isLoadingRefund = true;
    const url = "/refund/?reference=" + this.paymentRequest.reference;
    const response = await get(url);

    if (response.status === 200) {
      const refundRequest = await response.json();
      createRefundModal(this.paymentRequest, refundRequest);
    } else {
      LdsToast.showError({
        text: "Something went wrong. Please try again.",
      });
    }

    this.isLoadingRefund = false;
  }

  render() {
    if (this.showPaymentUnavailableMessage) {
      return html`
                <legl-permissions-overlay
                    overlayMessage="This Payment Request could not be found"
                ></legl-permissions-overlay>
            `;
    }
    if (this.paymentRequest) {
      return html`
                <div class="heading-actions-row">
                    <legl-request-status
                        status="${this.paymentRequest.status}"
                    >
                        ${this.paymentRequest.status}
                    </legl-request-status>
                    ${
                      this.showRefundButton()
                        ? html` <div class="refund-button-container">
                                  <lds-button
                                      .isLoading=${this.isLoadingRefund}
                                      .disabled=${this.disableRefundButton}
                                      small
                                      @click=${this.handleRefund}
                                  >
                                      ${this.refundButtonText}
                                  </lds-button>
                              </div>`
                        : nothing
                    }
                    <legl-pay-review-actions
                        .paymentRequest=${this.paymentRequest}
                    ></legl-pay-review-actions>
                </div>
                ${
                  this.paymentRequest.source === "SCHEDULED_PAYMENT"
                    ? html`<lds-alert type="info">
                              <div slot="content">
                                  <span
                                      >This payment is part of a payment
                                      plan.</span
                                  >
                                  <a
                                      data-cy-payment-schedule-link
                                      href=${`#/drawer/payment-plan-review?uid=${this.paymentRequest.payment_schedule_id}`}
                                      >View payment plan details</a
                                  >
                              </div>
                          </lds-alert>`
                    : nothing
                }
                ${
                  !this.isReminderRowHidden
                    ? html`
                              <email-reminders-row
                                  .params=${this.params}
                                  .reminders=${this.paymentRequest.reminders}
                                  .isRequestClosedOrComplete=${Boolean(
                                    this.isPaymentComplete(),
                                  )}
                                  .requestUrl=${this.requestUrl}
                                  .sendEmailUrl=${this.sendEmailUrl}
                                  .contactEmail=${this.contactEmail}
                                  .isStatusCreated=${this.isStatusCreated}
                                  .requestId=${this.paymentRequest.id}
                                  productType="pay"
                              ></email-reminders-row>
                          `
                    : nothing
                }
                ${
                  this.paymentRequest.engage_payment
                    ? html`
                            <legl-card class="pay-drawer__card">
                                <h3 slot="title" variant="corner">WORKFLOW DETAILS</h3>
                                  <div class="workflow-details">
                                      <div class="engage-request-info">
                                          <strong class="content-item"
                                              >${
                                                this.paymentRequest
                                                  .engage_request.flow_name
                                              }</strong
                                          >
                                          <div class="request-details-row">
                                              <div
                                                  class="request-details-column"
                                              >
                                                  <div
                                                      class="engage-content-item"
                                                  >
                                                      <span class="title">Status</span>
                                                      <legl-request-status
                                                          class="engage-request-status"
                                                          status="${
                                                            this.paymentRequest
                                                              .engage_request
                                                              .status
                                                          }"
                                                      >
                                                          ${
                                                            this.paymentRequest
                                                              .engage_request
                                                              .status
                                                          }
                                                      </legl-request-status>
                                                  </div>

                                                  <div
                                                      class="engage-content-item"
                                                  >
                                                      <span class="title">Reviewer</span>
                                                      ${
                                                        this
                                                          .engageRequestReviewer
                                                      }
                                                  </div>
                                              </div>

                                              <div>
                                                  <div
                                                      class="engage-content-item"
                                                  >
                                                      <span class="title">Matter reference</span class="title">
                                                      ${
                                                        this.paymentRequest
                                                          .engage_request
                                                          .matter_reference
                                                          ? html`<span
                                                                    >${this.paymentRequest.engage_request.matter_reference}
                                                                </span>`
                                                          : "-"
                                                      }
                                                  </div>

                                                  <div
                                                      class="engage-content-item"
                                                  >
                                                    <span class="title">Client reference</span>
                                                      ${
                                                        this.paymentRequest
                                                          .engage_request
                                                          .client_reference
                                                          ? html`<span
                                                                    >${this.paymentRequest.engage_request.client_reference}
                                                                </span>`
                                                          : "-"
                                                      }
                                                  </div>
                                                    ${
                                                      this.paymentRequest
                                                        .custom_reference
                                                        ? html`<div
                                                                  class="content-item"
                                                              >
                                                                  <span
                                                                      class="title"
                                                                      >${
                                                                        this
                                                                          .paymentRequest
                                                                          .custom_reference_label ||
                                                                        "Firm reference"
                                                                      } </span
                                                                  ><br />
                                                                  ${
                                                                    this
                                                                      .paymentRequest
                                                                      .custom_reference
                                                                      ? html`<span
                                                                            data-cy-custom-reference
                                                                            >${this.paymentRequest.custom_reference}
                                                                        </span>`
                                                                      : "-"
                                                                  }
                                                                  <br />
                                                              </div>`
                                                        : ""
                                                    }
                                              </div>
                                          </div>
                                      </div>
                                      <button
                                          data-cy-review
                                          @click=${() => this.onViewClicked()}
                                          class="open-engage-drawer-btn"
                                      >
                                          <lds-icon
                                              name="lds-icon-Review"
                                          ></lds-icon>
                                          view
                                      </button>
                                    </div>
                                    </legl-card>
                                  `
                    : ""
                }
                <legl-card class="pay-drawer__card">
                    <h3 slot="title" variant="corner">REQUEST DETAILS</h3>
                    <div class="content-details">
                        <div class="request-details-row">
                             <div class="content-item">
                                <span class="title">Contact</span class="title"><br />
                                <legl-contact-card
                                    .contact="${this.paymentRequest.contact}"
                                ></legl-contact-card>
                             </div>

                             <div class="content-item">
                                <span class="title">Created</span class="title"><br />
                                <span>
                                    ${formatDateTime(
                                      this.paymentRequest.created_at,
                                    )}
                                    <br />
                                </span>
                             </div>

                             <div class="content-item">
                                <span class="title">Requested by</span class="title"><br />
                                ${
                                  this.paymentRequest.requested_by
                                    ? html`<span
                                              >${this.paymentRequest.requested_by.name}
                                          </span>`
                                    : "-"
                                }
                                <br />
                             </div>

                            <div class="content-item">
                                <span class="title">Source</span class="title"><br />
                                ${this.paymentRequest.payment_source}
                                <br />
                            </div>

                            ${
                              this.paymentRequest.source === "PAY_REQUEST"
                                ? html` <div class="content-item">
                                          <span class="title"
                                              >Available payment methods</span
                                          >
                                          <br />
                                          <div
                                              class="payment-providers-wrapper"
                                          >
                                              ${
                                                this.paymentRequest.available_payment_providers?.includes(
                                                  "stripe",
                                                )
                                                  ? html`<span
                                                        class="payment-provider"
                                                        ><lds-icon
                                                            name="lds-icon-CreditCard"
                                                        ></lds-icon
                                                        >Pay by card</span
                                                    >`
                                                  : nothing
                                              }
                                              ${
                                                this.paymentRequest.available_payment_providers?.includes(
                                                  "banked",
                                                )
                                                  ? html`<span
                                                        class="payment-provider"
                                                        ><lds-icon
                                                            name="lds-icon-Bank"
                                                        ></lds-icon
                                                        >Pay by bank</span
                                                    >`
                                                  : nothing
                                              }
                                          </div>
                                          <br />
                                      </div>`
                                : nothing
                            }

                        </div>

                         ${
                           !this.paymentRequest.engage_payment
                             ? html` <div class="request-details-row">
                                       <div class="content-item">
                                           <span class="title"
                                               >${this.firmReferenceLabel2} </span
                                           ><br />
                                           ${
                                             this.paymentRequest
                                               .matter_reference
                                               ? html`<span
                                                     data-cy-firm-reference-2
                                                     >${this.paymentRequest.matter_reference}
                                                 </span>`
                                               : "-"
                                           }
                                           <br />
                                       </div>

                                       <div class="content-item">
                                           <span class="title"
                                               >${this.firmReferenceLabel1} </span
                                           ><br />
                                           ${
                                             this.paymentRequest
                                               .invoice_reference
                                               ? html`<span
                                                     data-cy-firm-reference-1
                                                     >${this.paymentRequest.invoice_reference}
                                                 </span>`
                                               : "-"
                                           }
                                           <br />
                                       </div>
                                       <div class="content-item">
                                           <span class="title"
                                               >Legl Reference </span
                                           ><br />
                                           <span data-cy-legl-reference
                                               >${
                                                 this.paymentRequest
                                                   .reference || "-"
                                               }
                                           </span>
                                           <br />
                                       </div>
                                       ${
                                         this.paymentRequest.custom_reference
                                           ? html`<div class="content-item">
                                                 <span class="title"
                                                     >${
                                                       this.paymentRequest
                                                         .custom_reference_label ||
                                                       "Firm reference"
                                                     } </span
                                                 ><br />
                                                 ${
                                                   this.paymentRequest
                                                     .custom_reference
                                                     ? html`<span
                                                           data-cy-custom-reference
                                                           >${this.paymentRequest.custom_reference}
                                                       </span>`
                                                     : "-"
                                                 }
                                                 <br />
                                             </div>`
                                           : ""
                                       }
                                       <div class="content-item">
                                           <span class="title"
                                               >Invoice attachment</span
                                           >
                                           <br />
                                           ${
                                             this.paymentRequest.has_document
                                               ? html`<span
                                                     data-cy-custom-reference
                                                 >
                                                     <a
                                                         class="invoice-link"
                                                         href=${this.paymentRequest.document_url}
                                                         target="_blank"
                                                         rel="noopener noreferrer"
                                                         ><lds-icon
                                                             name="lds-icon-VisibilityOn"
                                                         ></lds-icon>
                                                         <span>View</span></a
                                                     >
                                                 </span>`
                                               : "-"
                                           }
                                           <br />
                                       </div>
                                   </div>`
                             : ""
                         }
                        </div>

                    </div>

                </legl-card>
                <legl-card class="pay-drawer__card">
                    <h3 slot="title" variant="corner">PAYMENT DETAILS</h3>
                    <div class="content-details payment-details">
                        <div class="payment-content-details">
                            <div class="content-item">
                                        <span class="title">To account</span><br />
                                        ${
                                          this.paymentRequest.bank_account_name
                                            ? html`<span
                                                      >${this.paymentRequest.bank_account_name}
                                                  </span>`
                                            : "-"
                                        }
                                        <br />
                            </div>

                            <div class="content-item">
                                        <span class="title">Payment method</span><br />
                                            ${this.paymentMethod}
                                        <br />
                            </div>

                            <div class="content-item">
                                        <span class="title">Payment provider</span><br />
                                            ${this.paymentRequest.provider_name}
                                        <br />
                            </div>

                            <div class="content-item">
                                        <span class="title">Billing postcode</span><br />
                                        ${
                                          this.paymentRequest.postcode
                                            ? html`<span
                                                      >${this.paymentRequest.postcode}
                                                  </span>`
                                            : "-"
                                        }
                                        <br />
                            </div>

                            <div class="content-item">
                                        <span class="title">Payment time</span><br />
                                        ${
                                          this.paymentRequest.completed_at
                                            ? html`<span
                                                      >${formatDateTime(
                                                        this.paymentRequest
                                                          .completed_at,
                                                      )}
                                                  </span>`
                                            : "-"
                                        }
                                        <br />
                            </div>

                            <div class="content-item">
                                        <span class="title">Transfer ID</span><br />
                                        ${
                                          this.paymentRequest.transfer_id
                                            ? html`<span
                                                      >${this.paymentRequest.transfer_id}
                                                  </span>`
                                            : "-"
                                        }
                                        <br />
                            </div>

                            <div class="content-item">
                                        <span class="title">Transfer time</span><br />
                                        ${
                                          this.paymentRequest.transfer_time
                                            ? html`<span
                                                      >${formatDateTime(
                                                        this.paymentRequest
                                                          .transfer_time,
                                                      )}
                                                  </span>`
                                            : "-"
                                        }
                                        <br />
                            </div>

                            <div class="content-item">
                                        <span class="title">Comment</span><br />
                                        ${
                                          this.paymentRequest.comment
                                            ? html`<span
                                                      >${this.paymentRequest.comment}
                                                  </span>`
                                            : "-"
                                        }
                                        <br />
                            </div>
                        </div>

                        <div class="full-width">
                            <div class="content-item-total">
                                <p class="margin-bottom">Total</p>
                                <span class="title">
                                    ${
                                      this.paymentRequest.amount
                                        ? html`<legl-currency
                                                  .currency=${
                                                    this.paymentRequest
                                                      ?.currency
                                                  }
                                                  locale="${this.getLocale()}"
                                                  >${this.paymentRequest.amount}
                                              </legl-currency>`
                                        : "-"
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                </legl-card>
                 <div class="pay-request-history">
                    <h2>Payment history</h2>
                     ${this.getEvents().map((event) => {
                       return html`<legl-activity-log
                             user=${this.getEventIndividual(event)}
                             .activityText=${this.getActivityText(event)}
                             date=${event.created_at}
                             comment=${event.payRequestComment}
                         ></legl-activity-log>`;
                     })}
                </div>
            `;
    }
  }
}

customElements.define("legl-pay-review", LeglPayReview);
