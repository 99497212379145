import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import * as Sentry from "@sentry/browser";
import { toastService } from "../../../legl-ui/toast";
import { put } from "../../../legl-ui/utils/fetch";
import "./styles.css";

import { LitElement, html, nothing } from "lit";

const errorMessage = "Please try again with another card.";

export class LeglAdyenComponent extends LitElement {
  #MOUNT_ELEMENT_ID = "#pay-adyen-component";

  static get properties() {
    return {
      session: {},
      source: { type: String },
      clientKey: {},
      environment: {},
      onPaymentCompleted: {},
      sessionProcessUrl: {},
      isPaymentSubmitting: { type: Boolean },
      cardComponent: { state: true },
    };
  }

  createRenderRoot() {
    return this;
  }

  constructor() {
    super();
    this.isPaymentSubmitting = false;
  }

  async submitAdyenPayment() {
    if (this.isPaymentSubmitting) {
      return;
    }
    this.isPaymentSubmitting = true;
    this.cardComponent.submit();
    if (!this.cardComponent.isValid) {
      this.isPaymentSubmitting = false;
    }
  }

  async initializeAdyen() {
    const cardInputStyleObject = {
      base: {
        color: "#253658",
        fontFamily: "Lato, sans-serif",
        fontSize: "16px",
        lineHeight: "14px",
        fontSmoothing: "antialiased",
      },
      placeholder: {
        color: "#C5CAD3",
        fontFamily: "Lato, sans-serif",
        fontSize: "16px",
      },
    };
    const configuration = {
      session: this.session,
      clientKey: this.clientKey,
      environment: this.environment,
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          billingAddressRequired: true,
          styles: cardInputStyleObject,
          data: {
            holderName: " ",
          },
        },
      },
      onPaymentCompleted: (result, component) =>
        this.onPaymentCompleted(result, component),
    };
    // Create an instance of AdyenCheckout using the configuration object.
    this.checkout = await AdyenCheckout(configuration);

    // Create an instance of the Component and mount it to the container you created.
    this.#mountCardComponent(this.checkout.create("card"));

    if (this.applePay) {
      await this.initializeApplePay();
    }
  }

  get applePay() {
    return (
      waffle.flag_is_active("adyen-apple-pay") &&
      this.source !== "PHONE_PAYMENT"
    );
  }

  async initializeApplePay() {
    const applePayComponent = this.checkout.create("applepay");
    applePayComponent
      .isAvailable()
      .then(() => {
        this.hasMultiplePaymentMethods = true;
        applePayComponent.mount("#applepay-container");
        document.getElementById("or-pay-with").innerHTML = "Or pay with";
      })
      .catch((e) => {
        //Apple Pay is not available
      });
  }

  #mountCardComponent(component) {
    this.cardComponent = component.mount(this.#MOUNT_ELEMENT_ID);
  }

  dispatchPhonePaymentComplete() {
    this.dispatchEvent(
      new CustomEvent("phone-payment-complete", {
        bubbles: true,
        composed: true,
      }),
    );
  }

  dispatchEngagePaymentComplete() {
    this.dispatchEvent(
      new CustomEvent("engage-payment-complete", {
        bubbles: true,
        composed: true,
      }),
    );
  }

  async onPaymentCompleted(result, component) {
    if (result.resultCode === "Authorised") {
      try {
        const res = await put(this.sessionProcessUrl, {});
      } catch (exception) {
        console.log(exception);
      } finally {
        if (this.source === "PHONE_PAYMENT") {
          this.dispatchPhonePaymentComplete();
        } else if (this.source === "ENGAGE_PAYMENT") {
          this.dispatchEngagePaymentComplete();
        } else {
          if (window.isNewPayApp) {
            window.dispatchEvent(new CustomEvent("payment-completed"));
          } else {
            window.location.href = `/pay/success/`;
          }
        }
      }
    } else {
      toastService.showError(result.resultCode);
      this.#mountCardComponent(component);
      this.isPaymentSubmitting = false;
      if (result.resultCode === "Error") {
        Sentry.captureMessage(`Adyen payment failed.·${result.resultCode}`);
      }
    }
  }

  render() {
    return html`
      ${
        this.applePay
          ? html`<div data-cy-adyen-apple-pay id="applepay-container"></div>
          <div data-cy-or-pay-with id="or-pay-with"></div>`
          : nothing
      }
      <div id="pay-adyen-component" data-testid="pay-adyen-component" ></div>
      <div class="container-payment-submit">
        ${
          this.paymentAmount && this.cardComponent
            ? html`<lds-button
          data-cy-payment-submit-button
          role="button"
          id="payment-submit"
          colour="primary"
          variant="contained"
          icon="lds-icon-LockOutlined"
          ?is-loading="${this.isPaymentSubmitting}"
          ?disabled="${this.isPaymentSubmitting}"
          @click="${this.submitAdyenPayment}"
          >Pay £${this.paymentAmount}
        </lds-button>`
            : nothing
        }
      </div>
    `;
  }
}

customElements.define("legl-adyen-component", LeglAdyenComponent);
