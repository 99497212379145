import { LitElement, css, html } from "lit";

import { classMap } from "lit/directives/class-map.js";
import { LeglExpandablePanelMixin } from "../../../../../../legl-ui/expandable-panel";
import { neutral } from "../../../../../../legl-ui/lds-colours";

class OngoingMonitoringPdfPanel extends LeglExpandablePanelMixin(LitElement) {
  static get styles() {
    return [
      super.styles,
      css`
                .expandable-content > iframe {
                    width: 100%;
                    height: 70vh;
                }

                .title {
                    margin-bottom: 0;
                    color: ${neutral["800"]};
                    display: flex;
                    align-items: center;
                }

                .icon-monitor {
                    padding-right: 18px;
                }
            `,
    ];
  }

  static get properties() {
    return {
      pdfUrl: { attribute: "pdf-url" },
      title: { attribute: false },
    };
  }

  constructor() {
    super();
    this.pdfUrl = null;
    this.title = html`<lds-icon
                title=""
                name="lds-icon-Monitor"
                class="icon-monitor"
                large
            ></lds-icon
            >Monitoring update report`;
  }

  get _expandableContent() {
    return html`<iframe src="${this.pdfUrl}?download=0"></iframe>`;
  }

  get isExpandable() {
    return Boolean(this.pdfUrl);
  }

  get actions() {
    return html`<a
                class=${classMap({ "is-disabled": !this.isExpandable })}
                slot="primary"
                href="${this.pdfUrl}"
                download="ongoing-monitoring.pdf"
                data-cy-alert-pdf-download-btn
                target="_blank"
                ><lds-icon name="lds-icon-Download"></lds-icon> </a
            >${super.actions}`;
  }
}

customElements.define(
  "legl-ongoing-monitoring-pdf-panel",
  OngoingMonitoringPdfPanel,
);
