import { html } from "lit";
import { ConfirmationModal } from "./confirmation-modal.js";

export class SelectOptionConfirmationModal extends ConfirmationModal {
  static get properties() {
    return {
      ...super.properties,
      label: {
        type: String,
      },
      value: {
        type: Number,
      },
      originalValue: {
        type: Number,
      },
      options: {
        type: Array,
      },
    };
  }
  static get tagName() {
    return "legl-select-option-confirmation-modal";
  }

  constructor() {
    super();
    this.options = [];
    this.value = null;
    this.originalValue = null;
    this.label = "Select an option";
  }

  get closeEventDetails() {
    return {
      ...super.closeEventDetails,
      value: this.value,
    };
  }

  get valueChanged() {
    return this.value != this.originalValue || this.comment;
  }

  get modalBody() {
    return html`
            ${super.modalBody}
            <legl-select
                data-cy-select-modal-select
                .label=${this.label}
                .modelValue=${this.value || "Select One"}
                @model-value-changed=${(e) =>
                  (this.value = e.target.modelValue)}
            >
                <select slot="input">
                    <option disabled>Select One</option>
                    ${this.options.map(
                      ({ value, label }) =>
                        html`<option .value=${value}>${label}</option>`,
                    )}
                </select>
            </legl-select>
        `;
  }

  get isValid() {
    return (
      super.isValid &&
      this.value &&
      this.valueChanged &&
      !Number.isNaN(this.value)
    );
  }

  firstUpdated() {
    this.originalValue = this.value;
  }
}

customElements.define(
  SelectOptionConfirmationModal.tagName,
  SelectOptionConfirmationModal,
);
