const LeglFormValidationMixin = (superclass) =>
  class extends superclass {
    get inputSelector() {
      return ".source-input";
    }

    get formControls() {
      return [...this.shadowRoot.querySelectorAll(this.inputSelector)];
    }

    validate() {
      this.formControls.forEach((field) => {
        field.submitted = true;
      });
    }

    reset() {
      this.formControls.forEach((field) => {
        field.modelValue = "";
        field.resetInteractionState();
        field.touched = false;
        field.dirty = false;
        field.totalChar = 0;
      });
    }

    formValidationErrors() {
      const map = new Map();
      return this.formControls.reduce((validationErrors, field) => {
        if (field?.validationStates?.error) {
          validationErrors.set(field.name, field?.validationStates?.error);
        }
        return validationErrors;
      }, map);
    }

    get isValid() {
      return this.formControls.reduce((validityState, field) => {
        if (validityState === false) {
          return validityState;
        }
        const error =
          field && field.validationStates && field.validationStates.error
            ? field.validationStates.error
            : {};

        return Object.keys(error).length === 0;
      }, true);
    }
  };

export default LeglFormValidationMixin;
