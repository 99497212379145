export const WaffleFlags = {
  SIGNATURE_REQUEST_SWITCH: "signature_request",
  COOKIE_CONSENT_ENABLED: "cookie_consent_enabled",
  ENABLE_LEGL_ASSIST_CDD: "enable_legl_assist_cdd",
  SHOW_LEGL_ASSIST_SUMMARY: "show_legl_assist_summary",
  SHOW_CLIENT_EMAIL_FOOTER_SWITCH: "show_client_email_footer_switch",
  ADYEN_MOTO_PAYMENTS: "adyen_moto_payments",
  E_SIGS_V2_MULTI_SIGNATURES_FLAG: "e_sigs_v2_multi_signatures",
  CONFLICT_CHECKS_FLAG: "conflict_checks",
  ADYEN_APPLE_PAY_FLAG: "adyen-apple-pay",
  ADYEN_PAYSTEP_FLAG: "adyen-paystep",
  VUE_PAY_CHECKOUT_FLAG: "vue-pay-checkout",
  NEW_PAYMENT_SELECTOR: "use-new-payment-selector",
  ADYEN_PAYMENT_PLANS: "adyen-payment-plans",
  STANDARD_CDD_NO_ID: "standard_cdd_no_id",
  ADYEN_REFUND_SWITCH: "adyen_refund_switch",
  ENTITIES_NAV_FLAG: "entities_nav_flag",
  USE_NEW_PAYMENT_METHOD_SERVICE: "use-new-payment-method-service",
};
