import { LionButtonSubmit } from "@lion/button";
import { css, html } from "lit";

import "../../../legl-ui/icon";
export class LeglButton extends LionButtonSubmit {
  static get properties() {
    return {
      isLoading: {
        type: Boolean,
        reflect: true,
        attribute: "is-loading",
      },
      variant: {
        type: String,
        reflect: true,
      },
      icon: {
        type: String,
      },
      size: {
        type: String,
        reflect: true,
      },
      link: {
        type: String,
        attribute: "link",
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
                :host {
                    min-height: 0.5rem;
                }

                :host([size="tiny"]) {
                    --legl-button-padding: 0.2rem;
                    --legl-button-font-size: 0.5rem;
                    line-height: 5px;
                }

                :host([size="small"]) {
                    --legl-button-padding: 0 0.5rem;
                    --legl-button-font-size: 0.75rem;
                    min-width: 5rem;
                }
                :host([size="medium"]) {
                    --legl-button-padding: 0.6rem;
                    --legl-button-font-size: 1rem;
                    min-width: 5rem;
                }
                :host([size="large"]) {
                    font-size: 14px;
                }

                :host([variant="transparent"]),
                :host([variant="outlined"]) {
                    --legl-button-background-color: transparent;
                    --legl-button-text-color: var(--legl-system, #45435d);
                    --legl-button-background-color-disabled: transparent;
                    --legl-button-text-color-disabled: var(
                        --legl-grey-lightest
                    );
                    --legl-button-background-color-hover: var(
                        --legl-grey-light
                    );
                    --legl-button-text-color-hover: var(--legl-system, #45435d);
                }

                :host([variant="outlined"]) {
                    --legl-button-border: 1px solid currentColor;
                    --legl-button-text-color-disabled: var(--legl-system);
                }

                :host([variant="light"]) {
                    --legl-button-background-color: var(
                        --legl-grey-medium,
                        #f7f7f7
                    );
                    --legl-button-text-color: var(--legl-system, #45435d);
                    --legl-button-background-color-disabled: var(
                        --legl-grey-medium,
                        #f7f7f7
                    );
                    --legl-button-text-color-disabled: var(
                        --legl-system,
                        #45435d
                    );
                    --legl-button-background-color-hover: var(
                        --legl-system,
                        #45435d
                    );
                    --legl-button-text-color-hover: white;
                }
                :host([variant="success"]) {
                    --font-style: normal;
                    --legl-button-background-color: var(
                        --legl-state-success,
                        #29d273
                    );
                    --legl-button-background-color-hover: var(
                        --legl-state-success-hover,
                        #17b554
                    );
                    --legl-button-background-color-disabled: var(
                        --legl-state-success-hover
                    );
                }

                :host([variant="danger"]) {
                    --font-style: normal;
                    --legl-button-background-color: var(--legl-red);
                    --legl-button-background-color-hover: var(--legl-red-dark);
                    --legl-button-background-color-disabled: var(
                        --legl-red-light
                    );
                }

                :host([variant="tab"]) {
                    --legl-button-font-size: 14px;
                    --legl-button-font-weight: 400;
                    --legl-button-border: 1px solid var(--legl-grey-borders);
                    --legl-button-padding: 0;
                    --legl-button-border-radius: 0;
                    height: var(--legl-button-height, 40px);
                    width: var(--legl-button-width, 270px);
                }

                :host([full-width]) {
                    width: 100%;
                }

                :host {
                    cursor: pointer;
                    background-color: var(
                        --legl-button-background-color,
                        #45435d
                    );
                    transition: background-color 0.5s, color 0.4s,
                        opacity 0.3s ease-in-out;
                    border-radius: var(--legl-button-border-radius, 4px);
                    padding: var(--legl-button-padding, 1em);
                    font-size: var(--legl-button-font-size, 1.3em);
                    font-family: "Lato", sans-serif;
                    font-style: var(--font-style);
                    font-weight: var(--legl-button-font-weight, normal);
                    color: var(--legl-button-text-color, white);
                    outline: none;
                    border: var(--legl-button-border, none);
                    margin: 0;
                    box-sizing: border-box;
                    justify-content: center;
                    line-height: inherit;
                }

                :host([disabled]) {
                    background-color: var(
                        --legl-button-background-color-disabled,
                        #45435d
                    );
                    color: var(--legl-button-text-color-disabled, white);
                    opacity: 0.4;
                    cursor: not-allowed;
                }

                :host(:hover) {
                    background-color: var(
                        --legl-button-background-color-hover,
                        #2f2e3f
                    );
                    color: var(--legl-button-text-color-hover, white);
                }

                :host .loading-indicator {
                    display: none;
                    font-size: 20px;
                }

                :host([is-loading]) .loading-indicator {
                    display: inline-block;
                }

                :host([is-loading]) slot {
                    display: none;
                }

                :host(:focus:not([disabled])) {
                    outline: none;
                }

                :host legl-icon {
                    display: flex;
                }

                :host([size="medium"]) legl-icon {
                    margin-right: 0.6rem;
                }

                :host([size="small"]) legl-icon {
                    margin-right: 0.25rem;
                }
            `,
    ];
  }

  constructor() {
    super();
    this.isLoading = false;
    this.link = "";
  }

  handleLinkClick() {
    window.location = this.link;
  }

  _beforeTemplate() {
    if (this.link) {
      this.addEventListener("click", this.handleLinkClick);
      this.role = "link";
    }
    if (this.isLoading) {
      return html`<legl-icon
                class="loading-indicator"
                name="legl-icon-cog"
                .isSpinning=${true}
            ></legl-icon>`;
    }

    if (this.icon) {
      return html`<legl-icon
                part="icon"
                name="${this.icon}"
            ></legl-icon>`;
    }

    return "";
  }

  _afterTemplate() {
    return "";
  }

  render() {
    return html`<div class="button-content">
            ${this._beforeTemplate()}${super.render()}${this._afterTemplate()}
        </div>`;
  }
}

customElements.define("legl-button", LeglButton);
