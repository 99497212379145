/*  This exists mostly to mock window location in tests
and to not make components dependent directly
on the window object */

export const getCurrentLocation = () => {
  return window.location.href;
};

export const setCurrentLocation = (location) => {
  if (location) {
    window.location.href = location;
  }
};

export const getCurrentHash = () => {
  return window.location.hash;
};

export const getCurrentSubDomain = () => {
  return window.location.host.split(".")[0];
};

export const setQueryString = (searchParams) => {
  const queryString = searchParams.toString();
  const newQueryString =
    queryString && queryString.length > 0 ? `?${queryString}` : "";
  window.history.replaceState(
    {},
    null,
    `${window.location.origin}${window.location.pathname}${newQueryString}${window.location.hash}`,
  );

  window.dispatchEvent(new PopStateEvent("popstate"));
};

export const getQueryString = () => {
  return new URLSearchParams(window.location.search);
};

export const addParamsToQueryString = (params) => {
  const currentQuery = location.getQueryString();
  Object.entries(params).forEach(([key, value]) => {
    currentQuery.set(key, value);
  });

  return currentQuery;
};

export const removeParamsFromQueryString = (keys) => {
  if (!keys || keys.length === 0) {
    return;
  }
  const currentQuery = location.getQueryString();
  let shouldUpdate = false;
  keys.forEach((key) => {
    if (currentQuery.has(key)) {
      shouldUpdate = true;
      currentQuery.delete(key);
    }
  });
  if (shouldUpdate) {
    location.setQueryString(currentQuery);
  }
};

export const updateQueryParams = (params) => {
  location.setQueryString(location.addParamsToQueryString(params));
};

export const removeQueryParamsByPrefix = (prefix) => {
  if (!prefix || prefix.length === 0) {
    return;
  }
  location.removeParamsFromQueryString(
    [...location.getQueryString().keys()].filter(
      (key) => key.indexOf(prefix) === 0,
    ),
  );
};

export const reloadWindow = () => {
  window.location.reload();
};

const location = {
  getCurrentLocation,
  setCurrentLocation,
  reloadWindow,
  getQueryString,
  setQueryString,
  removeQueryParamsByPrefix,
  removeParamsFromQueryString,
  updateQueryParams,
  addParamsToQueryString,
  getCurrentHash,
  getCurrentSubDomain,
};

export default location;
