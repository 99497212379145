import { toastService } from "../../../../../legl-ui/toast";
import { get } from "../../../../../legl-ui/utils/fetch.js";

export class CompanyEngageSettings {
  constructor() {
    this._data = null;
  }

  async fetch(forceRefresh = false) {
    if (!this._data || forceRefresh) {
      try {
        const res = await get(`/api/company_engage_settings/`);
        if (!res.ok) {
          throw new Error(res.error);
        }
        this._data = await res.json();
      } catch (err) {
        toastService.showError("Error retrieving Company Engage Settings");
        this._data = null;
      }
    }
    return this;
  }

  reset() {
    this._data = null;
  }

  get settings() {
    return this._data || {};
  }

  get reminderStartAfterDays() {
    return Number.parseInt(this.settings?.reminder_start_after_days, 10);
  }

  get bccOnRequestEmail() {
    return this.settings?.bcc_on_request_email;
  }

  get ccOnRequestEmail() {
    return this.settings?.cc_on_request_email;
  }

  get reminderStartAfterDaysOptions() {
    return this.settings?.reminder_start_after_days_options;
  }

  get matterReferenceRequired() {
    return Boolean(this.settings?.matter_reference_validation?.required);
  }
}

const companyEngageSettings = new CompanyEngageSettings();
export default companyEngageSettings;
