import { BaseAdapter } from "./base-adapter";

export class AdyenAdapter extends BaseAdapter {
  async _initialize(elementSlot) {
    const component = elementSlot.getElementsByTagName(
      "legl-adyen-component",
    )[0];
    await component.updateComplete;
    this.#applyConfiguration(component);
    component.initializeAdyen();
  }

  #applyConfiguration(component) {
    component.session = {
      id: this.providerDetails?.session.id,
      sessionData: this.providerDetails?.session.sessionData,
    };
    component.source = this.providerDetails?.source;
    component.clientKey = this.providerDetails?.clientKey;
    component.environment = this.providerDetails?.environment;
    component.sessionProcessUrl =
      this.providerDetails.paymentUrl + "session_processing/";
    component.paymentAmount = this.amount;
  }

  async _appendPaymentMethod(DomElement) {
    const component = document.createElement("legl-adyen-component");

    DomElement.appendChild(component);
    await component.updateComplete;
  }
}
