import { createReducer } from "@reduxjs/toolkit";
import {
  fetchApplicationDetails,
  selectedContact,
  updateApplicationStatus,
} from "../../actions/engage";

const engageReducer = createReducer({}, (builder) => {
  builder.addCase(selectedContact, (state, action) => {
    state.selectedContact = action.payload;
    return state;
  });
  builder.addCase(fetchApplicationDetails, (state, action) => {
    state[action.payload.id] = action.payload;
    return state;
  });
  builder.addCase(updateApplicationStatus, (state, action) => {
    if (state[action.payload]) {
      state[action.payload].status = "Sent";
    }
    return state;
  });
});

export default engageReducer;
