import { LitElement, css, html } from "lit";
import { classMap } from "lit/directives/class-map.js";
import "../../lds-button";
import { neutral } from "../../lds-colours";
import "../../lds-icon";
import { typographyPresets } from "../../lds-typography";

export class LdsDatePickerActions extends LitElement {
  static get styles() {
    return css`
            .change-buttons {
                display: flex;
                align-items: center;
            }
            .button {
                border: none;
                background-color: transparent;
                cursor: pointer;
                padding: 0;
            }
            .change-button {
                height: 48px;
                width: 48px;
            }
            .expand-button {
                height: 48px;
                width: 62px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            label {
                color: ${neutral["800"]};
                ${typographyPresets.medium};
                cursor: pointer;
            }
            lds-icon {
                color: ${neutral["700"]};
            }
            .sub-button {
                margin-right: 4px;
            }
            .add-button {
                margin-left: 4px;
            }
            .is-disabled {
                pointer-events: none;
            }
            .is-disabled lds-icon {
                color: ${neutral["300"]};
            }
            .is-disabled label {
                color: ${neutral["300"]};
            }
        `;
  }

  static get properties() {
    return {
      label: {
        attribute: false,
        type: String,
      },
      addMonths: {
        attribute: false,
        type: Object,
      },
      subMonths: {
        attribute: false,
        type: Object,
      },
      isDisabled: {
        attribute: false,
      },
      subButtonIsDisabled: {
        attribute: false,
      },
      addButtonIsDisabled: {
        attribute: false,
      },
    };
  }

  showSelector() {
    this.dispatchEvent(
      new CustomEvent("show-selector", {
        bubbles: true,
        composed: true,
      }),
    );
  }

  render() {
    return html`<div class="change-buttons">
            <button
                class=${classMap({
                  "button change-button sub-button": true,
                  "is-disabled": this.subButtonIsDisabled || this.isDisabled,
                })}
                .disabled=${this.subButtonIsDisabled || this.isDisabled}
                @click=${this.subMonths}
            >
                <lds-icon name="lds-icon-ChevronLeft"></lds-icon>
            </button>
            <button
                class=${classMap({
                  "button expand-button": true,
                  "is-disabled": this.isDisabled,
                })}
                .disabled=${this.isDisabled}
                @click=${this.showSelector}
            >
                <label>${this.label}</label>
                <lds-icon name="lds-icon-ExpandMore"></lds-icon>
            </button>
            <button
                class=${classMap({
                  "button change-button add-button": true,
                  "is-disabled": this.addButtonIsDisabled || this.isDisabled,
                })}
                .disabled=${this.addButtonIsDisabled || this.isDisabled}
                @click=${this.addMonths}
            >
                <lds-icon name="lds-icon-ChevronRight"></lds-icon>
            </button>
        </div> `;
  }
}

customElements.define("lds-date-picker-actions", LdsDatePickerActions);
