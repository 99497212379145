import { LitElement, css, html } from "lit";
import "../../../legl-ui/input/legl-input-amount.js";
import { neutral } from "../../../legl-ui/lds-colours";
import {
  LeglMaxAmount,
  LeglMinAmount,
  LeglRequired,
} from "../../../legl-ui/lds-input";

import { userConfig } from "../../../apps/lawyers-app/static-src/js/config/user-config";
import "./legl-mini-accordion-e-sig";
import "./legl-mini-accordion-e-signature-request-v2";
/* This is file being ignored is a temporary solution to
bolster the branch test coverage to stop Contacts
being delayed by legacy files tanking the coverage.
There is currently a story to add the missing
coverage here: https://app.clubhouse.io/legl/story/8751 */
/* istanbul ignore next */

export class LeglMiniAccordionItemIncludes extends LitElement {
  static get properties() {
    return {
      steps: {
        type: Array,
      },
      isValid: { type: Boolean, attribute: false },
      extraData: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return css`
            .accordion-includes {
                color: ${neutral["800"]};
            }

            .accordion-content {
                padding-left: 4px;
                color: ${neutral["800"]};
            }

            .accordion-content__steps {
                padding-left: 16px;
            }

            .accordion-content__steps p {
                color: ${neutral["800"]};
                margin-top: 8px;
                margin-bottom: 8px;
            }

            .message--error:after {
                content: var(--legl-icon-info-circle);
                font-family: "legl-icon";
                font-size: 20px;
                padding: 7px;
            }
            .message--error {
                top: 0;
                right: 0;
            }

            .accordion-content__view-pdf,
            .accordion-content__view-pdf:active,
            .accordion-content__view-pdf:visited {
                color: var(--legl-grey-dark, #828282);
            }

            .accordion-content__view-pdf:hover {
                color: ${neutral["800"]};
            }

            .payment-section {
                color: ${neutral["800"]};
            }

            .source-of-funds-includes {
                margin-top: 8px;
                margin-bottom: 8px;
            }
        `;
  }

  constructor() {
    super();
    this.steps = [];
    this.maxValueExceeded = false;
    this.extraData = {};
  }

  static get properties() {
    return {
      fileToESign: {
        type: Object,
      },
      maxValueExceeded: {
        attribute: false,
      },
    };
  }

  get showEnableAICheckboxForSourceOfFunds() {
    return userConfig?._data?.company?.source_of_funds_ai_enabled === true;
  }

  maxValueWarning() {
    if (this.maxValueExceeded) {
      return html`<div class="max-amount-warning">
                <p>
                    Please note, this Pay Request exceeds the typical maximum
                    payment amount set by ${companyName}. Are you sure the
                    amount is correct?
                </p>
            </div>`;
    }
  }

  get isValid() {
    const paymentInput = this.shadowRoot.querySelector(
      "[data-cy-input-amount]",
    );
    if (paymentInput && !this.extraData.payment_amount) {
      this.extraData.payment_amount = 0;
      paymentInput.submitted = true;
      paymentInput.value = "";
    }
    return true;
  }

  handleFileUploaded(event) {
    this.extraData.bespoke_documents = {
      ...(this?.extraData?.bespoke_documents || {}),
      [event.target.extraTokenData.stepId]: event.detail.token.upload_token,
    };
  }

  handleSigantureUpload({ stepId, fileNames, signatureRequestId }) {
    this.extraData.signature_requests = {
      ...(this?.extraData?.signature_requests || {}),
      [stepId]: {
        file_names: fileNames,
        signature_request_id: signatureRequestId,
      },
    };
  }
  handleSigantureUploadBound = this.handleSigantureUpload.bind(this);

  firstUpdated() {
    const fileUploads = this.shadowRoot.querySelectorAll(
      "legl-input-file-upload",
    );
    if (fileUploads.length > 0) {
      fileUploads.forEach((element) => {
        element.addEventListener("file-uploaded", (event) =>
          this.handleFileUploaded(event),
        );
      });
    }
  }

  description(step) {
    if (
      step.element_type === "shared-document" &&
      step.element_config.bespoke_document
    ) {
      return html` <p>${step.element_config["document_name"]}</p>
                <legl-input-file-upload
                    data-cy-flow-template-upload-document-url
                    .maxFiles=${1}
                    .acceptedFiles=${["pdf", "doc", "docx", "xls", "xlsx"]}
                    upload-url="/api/shared_documents/"
                    param-name="document"
                    .extraTokenData=${{ stepId: step.pk }}
                    ><input
                        slot="input"
                        type="hidden"
                        name="bespoke_document_details[]"
                /></legl-input-file-upload>`;
    }
    if (
      step.element_type === "shared-document" &&
      !step.element_config.bespoke_document
    ) {
      return html` <div>
                    <p>
                        Document Name: ${step.element_config["document_name"]}
                    </p>
                </div>
                <p>
                    Filename: ${step.shared_document_filename}
                    <a
                        @click=${(e) => e.stopPropagation()}
                        href="${step.shared_document_url}"
                        target="_blank"
                        class="accordion-content__view-pdf"
                    >
                        <legl-icon name="legl-icon-eye"></legl-icon
                    ></a>
                </p>`;
    }
    if (step.element_type === "payment") {
      return html` <div class="payment-section">
                <p>To account: ${step.bank_account_name}</p>
                <div>
                    ${
                      step.element_config["amount"]
                        ? html`Total payment amount (including VAT):
                              £${step.element_config["amount"]}
                              <legl-tooltip variant="top-left">
                                  <legl-icon
                                      name="legl-icon-info-circle"
                                  ></legl-icon>
                                  <span slot="content"
                                      >This is a preset amount defined in the
                                      workflow.</span
                                  >
                              </legl-tooltip>`
                        : html`<lds-input-amount
                                  data-cy-input-amount
                                  id="${step.pk}"
                                  type="number"
                                  label="Total payment amount (including VAT)"
                                  .validators=${[
                                    new LeglMinAmount(5),
                                    new LeglMaxAmount(1000000),
                                    new LeglRequired(),
                                  ]}
                                  @click=${(e) => e.stopPropagation()}
                                  @model-value-changed=${(e) => {
                                    this.extraData.payment_amount =
                                      Number.parseFloat(e.target.modelValue);
                                    if (typeof maxPayment !== "undefined") {
                                      this.maxValueExceeded =
                                        maxPayment < e.target.modelValue;
                                    }
                                    if (e.target.modelValue) {
                                      e.target.submitted = true;
                                    }
                                  }}
                              ></lds-input-amount>
                              ${this.maxValueWarning()}`
                    }
                </div>
            </div>`;
    }
    if (step.element_type === "signature-request") {
      return html`<legl-mini-accordion-e-sig
                .step=${step}
                .handleSigantureUpload=${this.handleSigantureUploadBound}
            ></legl-mini-accordion-e-sig>`;
    }
    if (step.element_type === "e-signature-request-v2") {
      return html`<legl-mini-accordion-e-signature-request-v2
                .step=${step}
                .handleSigantureUpload=${this.handleSigantureUploadBound}
            ></legl-mini-accordion-e-signature-request-v2>`;
    }

    return html` <p>${step.describe.join(", ")}</p> `;
  }

  render() {
    return html`
            <p class="accordion-includes">Includes:</p>
            ${this.steps.map((step, index) => {
              return html`<strong class="accordion-content"
                        >${step.title}</strong
                    >
                    <div class="accordion-content__steps">
                        ${this.description(step)}
                    </div>
                    ${index < step.describe.length - 1 ? html`<hr />` : ""}`;
            })}
        `;
  }
}

customElements.define(
  "legl-mini-accordion-item-includes",
  LeglMiniAccordionItemIncludes,
);
