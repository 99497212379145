import { LitElement, css, html } from "lit";
import { nothing } from "lit";

import { classMap } from "lit/directives/class-map.js";

const defaultMessage = `Your account does not have access to use this feature.\nAsk your account Admin User(s) for permission to view this.`;

export class LeglPermissionsOverlay extends LitElement {
  static get styles() {
    return css`
            :host {
                display: inline-block;
                width: 100%;
            }
            .wrapper {
                position: relative;
                width: var(--legl-permissions-overlay-width, auto);
            }
            .legl-icon-padlock {
                font-size: var(--legl-permissions-overlay-icon-font-size, 18px);
            }
            .overlay {
                z-index: 1;
                filter: blur(5px);
                user-select: none;
            }
            .message-wrapper {
                z-index: 2;
                position: absolute;
                width: 100%;
                height: 100%;
                text-align: center;
            }
            .message {
                max-width: var(
                    --legl-permissions-overlay-message-max-width,
                    90%
                );
                font-size: var(
                    --legl-permissions-overlay-message-font-size,
                    14px
                );
                position: relative;
                top: 40%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                font-weight: 600;
            }
            .text {
                white-space: pre-line;
            }
        `;
  }

  static get properties() {
    return {
      hasPermissions: { type: Boolean },
      overlayMessage: { type: String },
      width: { type: Number },
    };
  }

  constructor() {
    super();
    this.overlayMessage = defaultMessage;
  }

  get wrapper() {
    return this.shadowRoot.querySelector(".wrapper");
  }

  get thinTemplate() {
    return html`
            <div
                data-cy-permissions-overlay-message
                class="message-wrapper"
                title=${this.overlayMessage}
            >
                <div class="message">
                    <legl-icon
                        class="legl-icon-padlock"
                        name="legl-icon-padlock"
                    ></legl-icon>
                </div>
            </div>
        `;
  }

  get wideTemplate() {
    return html`
            <div class="message-wrapper" data-cy-permissions-overlay-message>
                <div class="message">
                    <legl-icon
                        class="legl-icon-padlock"
                        name="legl-icon-padlock"
                    ></legl-icon>
                    <div class="text">${this.overlayMessage}</div>
                </div>
            </div>
        `;
  }

  get overlayTemplate() {
    if (this.hasPermissions) {
      return nothing;
    }
    if (this.width < 200) {
      return this.thinTemplate;
    }
    return this.wideTemplate;
  }

  onResize(entries) {
    this.width = entries[0].contentRect.width;
  }

  firstUpdated() {
    this.width = this.wrapper.clientWidth;
    this.resizeObserver = new ResizeObserver(this.onResize.bind(this));
    this.resizeObserver.observe(this.wrapper);
  }

  render() {
    return html`
            <div class="wrapper">
                ${this.overlayTemplate}
                <div
                    class=${classMap({
                      overlay: !this.hasPermissions,
                    })}
                >
                    <slot></slot>
                </div>
            </div>
        `;
  }
}

customElements.define("legl-permissions-overlay", LeglPermissionsOverlay);
