import { LionDialog } from "@lion/dialog";
import { html } from "lit";
import { DialogRouter } from "./dialog-router.js";

customElements.define("lion-dialog", LionDialog);

export class ModalManager extends DialogRouter {
  constructor() {
    super("modal");
    this.modalElement = this.generateModalElement();
    this.modalContent = document.querySelector(`#${this.modalId}__content`);
  }

  generateModalElement() {
    this.modalId = `modal-manager-${Date.now()}`;
    const existingModal = document.querySelector(`#${this.modalId}`);
    if (existingModal) {
      return existingModal;
    }

    const newModal = document.createElement("lion-dialog");
    newModal.id = this.modalId;
    const content = document.createElement("div");
    content.classList.add("modal-manager-modal");
    content.id = `${this.modalId}__content`;
    content.slot = "content";
    newModal.appendChild(content);
    document.body.appendChild(newModal);
    return newModal;
  }

  async show(id) {
    if (this.shouldShowDialog(id)) {
      try {
        if (this.hasActiveDialog) {
          await this.hideActiveDialog();
        }
        const { component } = this.getDialogConfig(id);
        component.setAttribute("slot", "content");
        if (component) {
          this.modalContent.innerHTML = "";
          this.modalContent.appendChild(component);
          component.addEventListener("closeModal", () => {
            this.hideActiveDialog();
          });
          this.modalElement.opened = true;
          this.activeDialog = id;
          return this.activeDialog;
        }
      } catch (err) {
        console.error(err);
        throw new Error("Error showing modal");
      }
    }

    return null;
  }

  async hideActiveDialog() {
    if (this.hasActiveDialog) {
      try {
        this.modalElement.opened = false;
        setTimeout(() => {
          this.modalContent.innerHTML = "";
        }, 500);
        this.activeDialog = null;
      } catch (err) {
        console.error(err);
        throw new Error("Error hiding modal");
      }
      this.activeDialog = null;
      window.location.hash = "";
    }
    return null;
  }
}

export const modals = new ModalManager();
