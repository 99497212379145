import { LitElement, css, html, nothing } from "lit";
import { connect } from "pwa-helpers";
import LeglModal from "../../../../../../legl-ui/modal";
import { patch } from "../../../../../../legl-ui/utils/fetch";
import { formatDate } from "../../../../../../legl-ui/utils/functions.js";
import store, {
  engageActions,
  payActions,
} from "../../../../../../redux/lawyers-admin";
import { fetchPaymentDetails } from "../../../../../../redux/lawyers-admin/actions/pay";
import { initializeSendInitialEmail } from "./SendInitialEmailModal.js";

class EmailRemindersModal extends LeglModal {
  static get properties() {
    return {
      areRemindersOn: {
        type: Boolean,
      },
      isStatusCreated: {
        type: Boolean,
      },
    };
  }

  static get tagName() {
    return "email-reminders-modal";
  }

  get modalContent() {
    if (!this.areRemindersOn) {
      return html`
                <p>
                    Switch reminders on to send automated reminder emails after
                    1, 3, 7, 14 and 30 days from today.
                </p>
                <p>Would you like to switch reminders on?</p>
            `;
    }
    return html` <p>Are you sure you want to stop reminders?</p> `;
  }
}

customElements.define(EmailRemindersModal.tagName, EmailRemindersModal);

export class EmailRemindersRow extends connect(store)(LitElement) {
  static get styles() {
    return css`
            :host {
                display: block;
                padding: 16px 0 20px;
                border-top: 1px solid #d0d0d0;
            }
            th {
                font-weight: 600;
                padding: 0 16px;
            }
            td {
                vertical-align: top;
                padding: 5px 16px 0;
            }
            th:first-child,
            td:first-child {
                padding-left: 0;
            }
        `;
  }

  static get properties() {
    return {
      reminders: {
        type: Object,
      },
      isRequestClosedOrComplete: {
        type: Boolean,
      },
      requestUrl: {
        type: String,
      },
      sendEmailUrl: {
        type: String,
      },
      isStatusCreated: {
        type: Boolean,
      },
      contactEmail: {
        type: String,
      },
      requestId: {
        type: String,
      },
      productType: {
        type: String,
      },
    };
  }

  async openReminderModal(checked) {
    if (this.isStatusCreated) {
      initializeSendInitialEmail({
        contactEmail: this.contactEmail,
        sendEmailUrl: this.sendEmailUrl,
        requestUrl: this.requestUrl,
        enableReminders: true,
        requestId: this.requestId,
        productType: this.productType,
      });
    } else {
      try {
        await EmailRemindersModal.create({
          title: `Switch reminders ${checked ? "off" : "on"}`,
          confirmText: `Switch ${checked ? "off" : "on"}`,
          cancelText: "Cancel",
          areRemindersOn: checked,
        });

        const resp = await patch(this.requestUrl, {
          body: JSON.stringify({
            reminders: {
              enabled: !checked,
            },
          }),
        });
        const request = await resp.json();
        if (this.productType === "engage") {
          store.dispatch(engageActions.fetchApplicationDetails(request));
        } else if (this.productType === "pay") {
          store.dispatch(payActions.fetchPaymentDetails(request));
        }
        this.reminders = { request };
      } catch (e) {
        return e;
      }
    }
  }

  calculateNextDueDate(nextDue) {
    const date = new Date(nextDue);
    let nextDate = date;
    // The hour is set to 11 as it matches the cron job time for sending reminder emails
    // If the cron job time changes, the hour should be changed to match.
    const dateAt11 = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      11,
    );
    if (date > dateAt11) {
      nextDate = new Date(date);
      nextDate.setDate(nextDate.getDate() + 1);
    }
    return nextDate.toString();
  }

  render() {
    return html`
            <table>
                <thead>
                    <tr>
                        ${
                          !this.isRequestClosedOrComplete
                            ? html`<th>Reminders</th>`
                            : nothing
                        }
                        <th>Reminders sent</th>
                        ${
                          !this.isRequestClosedOrComplete
                            ? html`<th>Next reminder</th>`
                            : nothing
                        }
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        ${
                          !this.isRequestClosedOrComplete
                            ? html`
                                  <td>
                                      <legl-checkbox
                                          ?checked="${this.reminders?.enabled}"
                                          label="${
                                            this.reminders?.enabled
                                              ? "ON"
                                              : "OFF"
                                          }"
                                          variant="toggle"
                                          @click=${(e) => {
                                            e.preventDefault();
                                            this.openReminderModal(
                                              e.currentTarget.modelValue
                                                .checked,
                                            );
                                          }}
                                          data-cy-reminders-checkbox
                                      ></legl-checkbox>
                                  </td>
                              `
                            : nothing
                        }
                        <td data-cy-email-reminders-sent>
                            ${this.reminders?.sent}
                        </td>
                        ${
                          !this.isRequestClosedOrComplete
                            ? html`
                                  <td data-cy-email-reminders-next-date>
                                      ${
                                        this.reminders?.enabled
                                          ? formatDate(
                                              this.calculateNextDueDate(
                                                this.reminders?.next_due,
                                              ),
                                              {
                                                month: "short",
                                                day: "numeric",
                                                year: "numeric",
                                              },
                                            )
                                          : "-"
                                      }
                                  </td>
                              `
                            : nothing
                        }
                    </tr>
                </tbody>
            </table>
        `;
  }
}

customElements.define("email-reminders-row", EmailRemindersRow);
