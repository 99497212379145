import { configureStore } from "@reduxjs/toolkit";

import contactsReducer from "./reducers/contacts";
import engageReducer from "./reducers/engage";
import ongoingMonitoringReducer from "./reducers/ongoing-monitoring";
import payReducer from "./reducers/pay";

const store = configureStore({
  reducer: {
    contacts: contactsReducer,
    engage: engageReducer,
    pay: payReducer,
    ongoingMonitoring: ongoingMonitoringReducer,
  },
});

export default store;
