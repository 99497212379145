import HelloSign from "hellosign-embedded";
import { LitElement, css, html } from "lit";
import { connect } from "pwa-helpers";
import { userConfig } from "../../../apps/lawyers-app/static-src/js/config/user-config";
import { LdsModal } from "../../../legl-ui/lds-modal";
import { spacing } from "../../../legl-ui/lds-spacing";
import { post } from "../../../legl-ui/utils/fetch";
import store from "../../../redux/lawyers-admin";
import { toastService } from "../../toast/legl-toast-service";
import "./modals/legl-add-additional-signers-modal";

export class LeglMiniAccordionESignatureRequestV2 extends connect(store)(
  LitElement,
) {
  static get styles() {
    return css`
            :host p:nth-child(2) {
                margin-bottom: 0;
            }
            .tag-button-container {
                display: flex;
                justify-content: end;
            }
            lds-button:first-of-type {
                margin-right: ${spacing.xs};
            }
        `;
  }

  static get properties() {
    return {
      step: {
        type: Object,
      },
      filesToESign: {
        type: Array,
      },
      signatureButtonText: {
        type: String,
      },
      disabled: {
        type: Boolean,
      },
      signatureRequestId: {
        type: String,
      },
      signatureRequestFileNames: {
        type: Array,
      },
      handleSigantureUpload: {
        type: Object,
      },
      additionalSigners: {
        state: true,
      },
    };
  }

  constructor() {
    super();
    this.signatureButtonText = "Tag document(s)";
    this.disabled = true;
    this.filesToESign = [];
    this.signatureRequestFileNames = [];
    this.signatureRequestId = null;
    this.additionalSigners = [];
  }

  addFiles() {
    this.filesToESign = Array.from(
      this.shadowRoot.querySelector("legl-input-file-upload").dropzone.files,
    );
    this.signatureRequestFileNames = this.filesToESign.map(
      (file) => file?.name,
    );
    this.signatureRequestId = null;
    this.handleSigantureUpload({
      stepId: this.step.pk,
      fileNames: this.signatureRequestFileNames,
      signatureRequestId: this.signatureRequestId,
    });
    this.disabled = this.filesToESign.length === 0;
    this.signatureButtonText = "Tag document(s)";
  }

  removeFiles() {
    this.filesToESign = Array.from(
      this.shadowRoot.querySelector("legl-input-file-upload").dropzone.files,
    );
    this.disabled = this.filesToESign.length === 0;
    this.signatureRequestFileNames = this.filesToESign.map(
      (file) => file?.name,
    );
    this.signatureRequestId = null;
    this.handleSigantureUpload({
      stepId: this.step.pk,
      fileNames: this.signatureRequestFileNames,
      signatureRequestId: this.signatureRequestId,
    });
    this.signatureButtonText = "Tag document(s)";
  }

  stateChanged(state) {
    this.selectedContact = state.engage.selectedContact;
  }

  async tagDocument() {
    this.disabled = true;
    const formData = new FormData();

    const formattedAdditionalSigners = this.additionalSigners.map((signer) => {
      return {
        name: signer.first_name + " " + signer.last_name,
        email_address: signer.email_address,
      };
    });

    const signers = [
      {
        name: this.selectedContact.name,
        email_address: this.selectedContact.email || "",
      },
      ...formattedAdditionalSigners,
    ];
    this.filesToESign.forEach((file, index) => {
      formData.append(`file[${index}]`, file);
    });
    formData.append("requester_email_address", userConfig.userEmail);
    formData.append("type", "request_signature");

    signers.forEach((signer, index) => {
      formData.append(`signers[${index}][name]`, signer.name);
      formData.append(`signers[${index}][email_address]`, signer.email_address);
    });
    formData.append("subject", "Request Signature");
    formData.append("is_for_embedded_signing", "1");
    formData.append("skip_me_now", "0");
    formData.append("field_options[date_format]", "DD / MM / YYYY");

    const requestOptions = {
      body: formData,
      // as we are using FormData the "Content-Type": "application/json" Header will break the request
      removeJSONHeader: true,
      redirect: "follow",
    };

    const path = this.signatureRequestId
      ? `/api/hellosign/unclaimed_draft/edit_and_resend/${this.signatureRequestId}`
      : "/api/hellosign/unclaimed_draft/create_embedded";

    const additionalSignersModal = document.querySelector(
      "legl-add-additional-signers-modal",
    );

    try {
      const response = await post(
        path,
        this.signatureRequestId ? null : requestOptions,
      );
      const responseJson = await response.json();

      if (response.ok) {
        const { claim_url, signature_request_id } =
          responseJson.unclaimed_draft;
        const client = new HelloSign({
          clientId: userConfig.hellosign_client_id,
        });
        // HelloSign events: https://app.hellosign.com/api/embeddedSigningWalkthrough
        client.on("close", () => (this.disabled = false));
        client.on("finish", () => {
          // set the signatureRequestId only after the document is signed as we need to generate a new url
          // each time we want to tag a document first time
          this.signatureRequestId = signature_request_id;
          this.handleSigantureUpload({
            stepId: this.step.pk,
            fileNames: this.signatureRequestFileNames,
            signatureRequestId: this.signatureRequestId,
          });
          this.signatureButtonText = "Edit document(s)";
          this.disabled = false;
        });
        if (additionalSignersModal) {
          additionalSignersModal.close();
        }
        client.open(claim_url);
      } else {
        let message =
          "Error uploading and generating the document. Please try again.";
        if (responseJson.error.error_msg) {
          message = responseJson.error.error_msg;
        }
        toastService.showError(message);
        this.disabled = false;
        if (additionalSignersModal) {
          additionalSignersModal.isLoading = false;
        }
      }
    } catch {
      toastService.showError(
        "Error uploading and generating the document. Please try again.",
      );
      if (additionalSignersModal) {
        additionalSignersModal.isLoading = false;
      }
      this.disabled = false;
    }
  }

  openAdditionalSignersModal() {
    LdsModal.create({
      title: "Document signers",
      slotComponent: "legl-add-additional-signers-modal",
      slotComponentProperties: {
        setAdditionalSigners: this.setAdditionalSigners.bind(this),
        additionalSigners: this.additionalSigners,
        primarySigner: {
          first_name: this.selectedContact.first_name,
          last_name: this.selectedContact.last_name,
          email_address: this.selectedContact.email || "",
        },
      },
      headerCloseBtn: true,
      widthAuto: true,
    });
  }

  setAdditionalSigners(additionalSigners) {
    this.additionalSigners = additionalSigners;
    this.tagDocument();
  }

  render() {
    return html`<div>
            <p>
                Document Name: ${this.step?.element_config?.document_name || ""}
            </p>
            <p data-cy-e-sign-file-name>
                Files: ${this.signatureRequestFileNames.join(", ")}
            </p>
            <div class="doc-upload-container">
                <legl-input-file-upload
                    data-cy-flow-template-upload-document-url
                    .maxFiles=${Number.POSITIVE_INFINITY}
                    .acceptedFiles=${[
                      "pdf",
                      "doc",
                      "docx",
                      "txt",
                      "jpg",
                      "jpeg",
                      "png",
                    ]}
                    .autoProcessQueue=${false}
                    param-name="document"
                    .extraTokenData=${{ stepId: this.step.pk }}
                    @file-added="${() => {
                      this.addFiles();
                    }}"
                    @file-removed="${() => {
                      this.removeFiles();
                    }}"
                    ><input
                        slot="input"
                        type="hidden"
                        name="bespoke_document_details[]"
                /></legl-input-file-upload>
                <div class="tag-button-container">
                    <lds-button
                        icon="lds-icon-Edit"
                        ?disabled=${this.disabled}
                        @click=${this.openAdditionalSignersModal}
                    >
                        ${this.signatureButtonText}
                    </lds-button>
                </div>
            </div>
        </div> `;
  }
}

customElements.define(
  "legl-mini-accordion-e-signature-request-v2",
  LeglMiniAccordionESignatureRequestV2,
);
