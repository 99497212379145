import { LitElement, css, html } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { danger, neutral, primary } from "../../../legl-ui/lds-colours";
import { spacing } from "../../../legl-ui/lds-spacing";
import { typographyPresets } from "../../../legl-ui/lds-typography";

export class LdsInputV2 extends LitElement {
  static get styles() {
    return css`
            :host {
                display: inline-flex;
                flex-direction: column;
                color: ${neutral["800"]};
            }
            :host([u-sr-only]) ::slotted(label) {
                border: 0;
                clip: rect(0 0 0 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }
            :host([u-sr-only]) ::slotted([slot="help-text"]) {
                margin-bottom: 0;
            }
            ::slotted(label) {
                ${typographyPresets.bodyBold};
                display: inline-block;
                margin-bottom: ${spacing.xxs};
            }
            ::slotted([slot="help-text"]) {
                ${typographyPresets.medium};
                color: ${neutral["700"]};
                display: flex;
                margin-bottom: ${spacing.xxs};
                margin-top: 0px;
            }
            ::slotted(input) {
                ${typographyPresets.body};
                color: ${neutral["800"]};
                padding: 11px;
                border: 1px solid ${neutral["300"]};
                border-radius: 4px;
                outline: none;
            }
            ::slotted(input:focus) {
                border-color: ${primary["500"]};
                outline: ${primary["500"]} solid 1px;
            }
            ::slotted(input)::placeholder {
                color: ${neutral["300"]};
            }
            .isInvalid::slotted(input) {
                border-color: ${danger["500"]};
            }
            .isInvalid::slotted(input:focus) {
                outline: ${danger["500"]} solid 1px;
            }
            .error-message {
                ${typographyPresets.small};
                display: flex;
                color: ${danger["500"]};
                margin-bottom: ${spacing.xxs};
            }
        `;
  }

  static get properties() {
    return {
      errorMessage: { type: String },
      isInvalid: { type: Boolean },
      validator: {},
    };
  }

  constructor() {
    super();
    this.isInvalid =
      this.errorMessage !== undefined ? !!this.errorMessage : this.isInvalid;
  }

  willUpdate() {
    this.isInvalid =
      this.errorMessage !== undefined ? !!this.errorMessage : this.isInvalid;
  }

  render() {
    return html`
            <slot name="label"></slot>
            <slot name="help-text"></slot>
            <div class="error-message">${this.errorMessage}</div>
            <slot
                name="input"
                class="${classMap({ isInvalid: this.isInvalid })}"
                @change="${(e) => {
                  if (this.validator) {
                    const errorMessage = this.validator(e.target.value);
                    if (errorMessage) {
                      this.errorMessage = errorMessage;
                    } else {
                      this.errorMessage = "";
                    }
                  }
                }}"
            ></slot>
        `;
  }
}

if (!customElements.get("lds-input-v2")) {
  customElements.define("lds-input-v2", LdsInputV2);
}
