import { LitElement, css, html, nothing } from "lit";
import "../../../../../../../../../legl-ui/lds-alert";
import "../../../../../../../../../legl-ui/lds-button";
import { spacing } from "../../../../../../../../../legl-ui/lds-spacing";
import { post } from "../../../../../../../../../legl-ui/utils/fetch.js";

export class BulkCloseModal extends LitElement {
  static get styles() {
    return css`
            .container {
                padding: 0 24px 24px 24px;
                font-family: "Lato", sans-serif;
                box-sizing: border-box;
            }
            .warning-message {
                margin: 0;
            }
            .error-contacts {
                margin-top: 8px;
                margin-bottom: 0;
            }
            .acknowledgement-checkbox {
                margin-bottom: 24px;
            }
            .buttons-container {
                margin-top: 36px;
                display: flex;
                width: 100%;
                justify-content: flex-end;
            }
            .primary-button {
                margin-left: 15px;
            }
            .loader-container {
                display: flex;
                justify-content: center;
            }
            .loader__circle {
                flex: 0 0 auto;
                height: 100px;
                width: 100px;
                animation: rotation 0.8s infinite linear;
            }
            .loader__circle circle {
                stroke: #29d273;
                stroke-width: 2;
                fill: transparent;
                stroke-dasharray: 207.24;
                stroke-dashoffset: 0;
            }
            @keyframes rotation {
                from {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(359deg);
                }
            }

            lds-alert {
                margin-bottom: ${spacing.l};
            }
        `;
  }

  static get properties() {
    return {
      reference: { attribute: false },
      endpoint: { attribute: false },
      showSummary: {
        state: true,
      },
      isLoading: {
        state: true,
      },
      closeSuccessful: {
        state: true,
      },
      actionAcknowledged: {
        state: true,
      },
    };
  }

  constructor() {
    super();
    this.showSummary = false;
    this.isLoading = false;
    this.closeSuccessful = false;
    this.actionAcknowledged = false;
  }

  async handleCloseLink() {
    this.isLoading = true;
    try {
      const body = new URLSearchParams();
      body.append("reference", this.reference);

      const response = await post(this.endpoint, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: body.toString(),
      });

      const res = await response.json();

      this.closeSuccessful = true;

      const drawer = document.querySelector("legl-engage-review");
      if (drawer) {
        drawer.dispatchEvent(new CustomEvent("close-link-clicked-from-drawer"));
      } else {
        window.dispatchEvent(new CustomEvent(`reload-engage-table`));
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
      this.showSummary = true;
    }
  }

  onClickClose() {
    this.parentElement.onClickClose();
  }

  render() {
    return html`
            <div id="bulk-close-modal" class="container">
                ${
                  !this.isLoading
                    ? html`<div>
                          ${
                            !this.showSummary
                              ? html`<div>
                                    <lds-alert .type=${"warning"}>
                                        <p
                                            class="warning-message"
                                            id="warning-message"
                                            slot="content"
                                        >
                                            You are about to close a request
                                            link, this procedure is
                                            irreversible. If your client has
                                            already started their request you
                                            can force complete their request
                                            instead to retain any complete
                                            steps.
                                        </p>
                                    </lds-alert>
                                    <legl-checkbox
                                        id="acknowledgement-checkbox"
                                        class="acknowledgement-checkbox"
                                        @model-value-changed=${(e) =>
                                          (this.actionAcknowledged =
                                            e.target.checked)}
                                    >
                                        <label slot="label">
                                            I acknowledge this action cannot be
                                            undone.
                                        </label>
                                    </legl-checkbox>
                                </div>`
                              : html` <div>
                                    ${
                                      this.closeSuccessful
                                        ? html`
                                              <lds-alert
                                                  .type=${"success"}
                                                  .title=${"Link has been successfully closed"}
                                              >
                                              </lds-alert>
                                          `
                                        : nothing
                                    }
                                    ${
                                      !this.closeSuccessful
                                        ? html` <lds-alert
                                              .type=${"error"}
                                              .title=${"We were unable to close the request link"}
                                          >
                                          </lds-alert>`
                                        : nothing
                                    }
                                </div>`
                          }
                          <div class="buttons-container">
                              <lds-button
                                  variant="outlined"
                                  colour="neutral"
                                  @click=${this.onClickClose}
                                  >Cancel</lds-button
                              >
                              ${
                                !this.showSummary
                                  ? html`<lds-button
                                        id="primary-button"
                                        class="primary-button"
                                        .disabled=${!this.actionAcknowledged}
                                        colour="primary"
                                        @click=${this.handleCloseLink}
                                        >Close link</lds-button
                                    >`
                                  : nothing
                              }
                          </div>
                      </div>`
                    : html`
                          <div class="loader-container">
                              <div>
                                  <svg
                                      viewBox="0 0 100 100"
                                      xmlns="http://www.w3.org/2000/svg"
                                      class="loader__circle"
                                  >
                                      <circle cx="50" cy="50" r="45" />
                                  </svg>
                              </div>
                          </div>
                      `
                }
            </div>
        `;
  }
}

customElements.define("close-link-modal", BulkCloseModal);
