import { LitElement, html, nothing } from "lit";
import "../../../../../../legl-ui/button";
import "../../../../../../legl-ui/cdd-selector";
import "../../../../../../legl-ui/lds-button";
import { LdsModal } from "../../../../../../legl-ui/lds-modal";
import "../../../../../../legl-ui/steps";
import { toastService } from "../../../../../../legl-ui/toast";
import { patch, post } from "../../../../../../legl-ui/utils/fetch";
import { userConfig } from "../../config/user-config";
import { cddOptions } from "./cdd-options";
import "./manual_CDD_accordion.js";
import "./reviewer-selection";
import "./summary-screen";
import "./terms-of-agreement";
import {
  AMLOnlyWorkflow,
  EnableMonitoringWorkflow,
  FinancialCheckOnlyWorkflow,
  ManualStandardCDDNoIDWorkflow,
  ManualStandardCDDWorkflow,
} from "./workflows";

export class LeglManualCDD extends LitElement {
  static get properties() {
    return {
      showSummary: { type: Boolean, attribute: false },
      reportType: { type: String, attribute: false },
      reviewer: { type: String, attribute: false },
      note: { type: String, attribute: false },
      disableContinue: { type: Boolean, attribute: false },
      selectedContact: {
        attribute: false,
      },
      hideContactDetailsContinue: { attribute: false },
      application: { attribute: false },
      isReportTypeUrlParam: { type: Boolean },
      matterReference: { type: String, attribute: false },
      disableBackButton: {
        type: Boolean,
        attribute: false,
      },
    };
  }

  constructor(props) {
    super(props);
    this.disableContinue = true;
    this.selectedContact = {};
    this.hideContactDetailsContinue = true;
    this.application = null;
    this.note = null;
    this.termsAccepted = null;
    this.matterReference = null;
    this.disableBackButton = false;
  }

  get steps() {
    return this.querySelector("legl-steps");
  }

  get reportTypeUrlParam() {
    return this.params.get("reportType");
  }

  connectedCallback() {
    super.connectedCallback();
    const loadingElement = document.createElement(
      "legl-fullscreen-loading-spinner",
    );
    this.addEventListener("manual-cdd-loading", () => {
      if (
        this.reportType !== "standard_cdd" &&
        this.reportType !== "standard_cdd_no_id"
      ) {
        document.body.appendChild(loadingElement);
      }
    });
    this.addEventListener("manual-cdd-loading-finished", () => {
      if (
        this.reportType !== "standard_cdd" &&
        this.reportType !== "standard_cdd_no_id"
      ) {
        document.body.removeChild(loadingElement);
      }
    });
    this.matterReference = this.params.get("in_person_cdd_matter_reference");
    if (this.reportTypeUrlParam) {
      this.reportType = this.reportTypeUrlParam;
      this.selectedContact.uid = this.params.get("contactUid");
      /* Ensure reportType url parameter is constrained */
      this.isReportTypeUrlParam =
        this.reportType === "standard_cdd" ||
        this.reportType === "peps_and_sanctions_only" ||
        this.reportType === "enable_monitoring";

      if (this.isReportTypeUrlParam) {
        this.disableContinue = false;
        this.reviewer = userConfig.userId;
        this.note = "";
      }
    }
  }

  firstUpdated(_changedProperties) {
    super.firstUpdated(_changedProperties);
    this.addEventListener("cdd-selection", (e) => {
      this.reportType = e.detail.option;
      this.disableContinue = false;
    });
    this.addEventListener("reviewer-selection-change", (e) => {
      this.reviewer = e.detail?.reviewer;
      this.note = e.detail?.note;
    });
  }

  onContactChanged(e) {
    if (!e?.detail?.uid) {
      this.selectedContact = {};
      this.hideContactDetailsContinue = true;
      return;
    }
    this.selectedContact = e.detail;
    this.hideContactDetailsContinue = false;
  }

  onNext() {
    if (this.termsAccepted === null) {
      const isFlagActive = waffle.flag_is_active("enable_financial_checks");
      const acceptedTermsAndConditions =
        userConfig?.meta_data?.equifax_terms_and_conditions?.accepted;

      const reportRequiresAgreement = [
        "standard_cdd",
        "standard_cdd_no_id",
        "financial_checks",
      ].includes(this.reportType);

      if (
        isFlagActive &&
        acceptedTermsAndConditions !== true &&
        reportRequiresAgreement
      ) {
        return this.fireFinancialChecksTermsAndConditionsModal();
      }
    }

    return this.steps.next();
  }

  async acceptTermsAndConditions() {
    const payload = {
      meta_data: {
        equifax_terms_and_conditions: { accepted: true },
      },
    };
    const response = await patch(`/api/lawyers/${userConfig.userId}/`, {
      body: JSON.stringify(payload),
    });

    if (response.status === 200) {
      this.termsAccepted = true;
      return this.steps.next();
    }
  }

  onClose() {
    this.dispatchEvent(
      new CustomEvent("close", { bubbles: true, composed: true }),
    );
  }

  fireFinancialChecksTermsAndConditionsModal() {
    LdsModal.create({
      title: "Financial Screening Terms of Use",
      description: html`
                <legl-terms-of-agreement></legl-terms-of-agreement>
            `,
      primaryButtonAttributes: {
        onClick: () => this.acceptTermsAndConditions(),
        variant: "contained",
        colour: "primary",
        label: "I agree",
      },
    });
  }

  completeFlow() {
    // disable close warning
    document.querySelector("legl-drawer").withCloseWarning = false;
    // show summary screen
    this.showSummary = true;
    // refresh engage table
    window.dispatchEvent(new CustomEvent("reload-engage-table"));
  }

  isHidden(step) {
    const reports = step.element_config.reports;

    if (reports?.length === 1) {
      const report_types = ["watchlist_kyc", "financial_checks"];
      return reports.some((key) => report_types.includes(key));
    }

    return false;
  }

  get verificationScreen() {
    if (this.application) {
      return html`
                ${this.application?.all_steps.map((step, i) => {
                  return html`
                        <manual-cdd-accordion
                            @engageStepResult=${() =>
                              (this.disableBackButton = true)}
                            class="content-card"
                            element-type=${step.element_type}
                            step-url="/engage/${
                              this.application.reference
                            }/step/${step.id}/"
                            ?show-alpha=${
                              this.application?.all_steps.find(
                                (step) => step.completed_at === null,
                              ).id === step.id
                            }
                            ?hidden=${this.isHidden(step)}
                            .onFlowComplete=${() => {
                              this.completeFlow();
                            }}
                            .isActiveStep=${step.is_active_step}
                            report-type=${this.reportType}
                            show-status-icon
                        >
                            <section slot="title">
                                <span class="heading-text">${step.title}</span>
                            </section>
                            <section slot="icon-alpha">
                                <legl-icon
                                    disabled
                                    name="legl-icon-chevron-down"
                                    data-cy-chevron-down-btn
                                ></legl-icon>
                            </section>
                            <section slot="icon-beta">
                                <legl-icon
                                    disabled
                                    name="legl-icon-chevron-righ"
                                    data-cy-chevron-down-btn
                                ></legl-icon>
                            </section>
                            <section slot="content-alpha"></section>
                            <section slot="content-beta"></section>
                        </manual-cdd-accordion>
                    `;
                })}
            `;
    }
  }

  get contactDetailsContinueButton() {
    return html` <div class="button-container">
            <legl-button
                data-cy-manual-cdd-continue-btn
                id="contact-details-continue-button"
                size="medium"
                .disabled=${this.disableContinue}
                @click=${() => this.createApplication()}
                >Continue</legl-button
            >
        </div>`;
  }
  getStep3Description() {
    let message = `Enter the information to verify the contact. Providing as much information as possible will increase the quality of the screening results.`;

    switch (this.reportType) {
      case "standard_cdd":
      case "standard_cdd_no_id":
        message = `Enter the information to verify the contact.
                    You will need their date of birth, current address
                    or residency and valid government-issued photo ID
                    to proceed.`;
        break;
    }

    return message;
  }

  async createApplication() {
    this.disableContinue = true;
    let body;

    switch (this.reportType) {
      case "standard_cdd":
        body = ManualStandardCDDWorkflow;
        break;
      case "standard_cdd_no_id":
        body = ManualStandardCDDNoIDWorkflow;
        break;
      case "enable_monitoring":
        body = EnableMonitoringWorkflow;
        break;
      case "financial_checks":
        body = FinancialCheckOnlyWorkflow;
        break;
      case "peps_and_sanctions_only":
      default:
        body = AMLOnlyWorkflow;
    }

    body.assigned_reviewer = `http://account.dev.legl.tech/api/lawyers/${this.reviewer}/`;
    body.first_name = this.selectedContact.first_name;
    body.last_name = this.selectedContact.last_name;
    body.email = this.selectedContact.email;
    body.contact_uid = this.selectedContact.uid;
    body.client_reference = this.selectedContact.client_reference;
    body.optional_note = this.note;
    body.matter_reference = this.matterReference;

    const response = await post("/api/engage_applications/", {
      body: JSON.stringify(body),
    });
    this.disableContinue = false;

    try {
      this.requestUpdate();
      const responseJson = await response.json();
      if (response.ok) {
        this.application = responseJson;
        if (this.selectedContact.entity_id) {
          await patch(`/v2/entities/${this.selectedContact.entity_id}/`, {
            body: JSON.stringify({ applications: [this.application.id] }),
          });
        }

        this.onNext();
      } else if (responseJson.error && responseJson.message) {
        toastService.showError(responseJson.message);
      } else {
        toastService.showError(
          "There was an error processing the request. Please try again.",
        );
      }
    } catch (e) {
      toastService.showError(
        "There was an error processing the request. Please try again.",
      );
    }
  }

  // can't use shadow root because of the onfido SDK needed in manual CDD
  createRenderRoot() {
    return this;
  }

  render() {
    const step1 = html`<legl-step title="Report type">
            <p class="paragraph">
                Run a background check for an individual yourself. Alternatively
                you can ask a contact to provide their details and complete the
                information themselves by sending an
                <a class="engage-request-link" href="#/drawer/engage-create"
                    >Engage Request</a
                >.
            </p>
            <h2 class="heading">Step 1: Select the report you need</h2>
            <legl-cdd-selector .options=${cddOptions}></legl-cdd-selector>
            <div class="manual-cdd-matter-reference-container">
                <label class="manual-cdd-matter-reference-text"
                    >Is there a matter reference to associate with this
                    workflow?
                </label>
                <lds-input
                    label="Matter reference (optional)"
                    .modelValue="${this.matterReference || ""}"
                    name="matter_reference"
                    @model-value-changed="${(e) => {
                      this.matterReference = e.target.modelValue;
                      this.requestUpdate();
                    }}"
                ></lds-input>
            </div>

            <legl-reviewer-selection></legl-reviewer-selection>
            <div class="button-container">
                <lds-button
                    variant="text"
                    colour="neutral"
                    class="button--cancel"
                    id="cancel-manual-cdd"
                    @click=${() => this.onClose()}
                    >Cancel</lds-button
                ><lds-button
                    colour="primary"
                    id="continue-manual-cdd"
                    .disabled=${this.disableContinue}
                    @click=${() => this.onNext()}
                    >Continue</lds-button
                >
            </div>
        </legl-step>`;
    return html` <legl-steps .disableBackButton=${this.disableBackButton}>
            ${this.isReportTypeUrlParam ? "" : step1}
            <legl-step title="Contact details">
                <h2 class="heading-contact-details">
                    Step 2: Enter contact's details
                </h2>
                <p class="paragraph-contact-details">
                    Enter the information of the contact this check is for. You
                    can enter date of birth, address of residency and any
                    documents in the next step.
                </p>
                <legl-contact-selector
                    .selectedContactUid=${this.selectedContact.uid}
                    .hideExpandButton=${true}
                    @contact-changed=${this.onContactChanged}
                    .emailRequired="${false}"
                    .isManualCDD="${true}"
                    .backButtonText=${"Change Contact"}
                    .firstName=${this.params.get("in_person_cdd_first_name")}
                    .middleName=${this.params.get("in_person_cdd_middle_name")}
                    .lastName=${this.params.get("in_person_cdd_last_name")}
                    .email=${this.params.get("in_person_cdd_email")}
                    .clientReference=${this.params.get("in_person_cdd_client_reference")}
                >
                </legl-contact-selector>
                ${
                  this.hideContactDetailsContinue
                    ? nothing
                    : this.contactDetailsContinueButton
                }
            </legl-step>
            <legl-step title="Verification">
                ${
                  this.showSummary
                    ? html`<legl-manual-cdd-summary
                          .application=${this.application}
                          .reviewer=${this.reviewer}
                          .note=${this.note}
                          .selectedContact=${this.selectedContact}
                          heading="This In-person check has been submitted and will be ready for
                review shortly."
                          text="The assigned reviewer will be notified as soon as this request
                is ready for review."
                      ></legl-manual-cdd-summary>`
                    : html` <h2 class="heading-contact-details">
                              Step 3: Verification
                          </h2>
                          <p class="paragraph-contact-details">
                              ${this.getStep3Description()}
                          </p>
                          ${this.verificationScreen}`
                }
            </legl-step>
        </legl-steps>`;
  }
}

customElements.define("legl-manual-cdd", LeglManualCDD);
