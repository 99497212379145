import { LitElement, css, html } from "lit";
import "../../../legl-ui/icon";

export class LeglStepProgress extends LitElement {
  static get styles() {
    return css`
            :host {
                align-self: center;
                justify-self: end;
                display: flex;
            }

            :host([hide-step="0"]) [part="steps-progress__step"]:first-of-type {
                display: none;
            }

            [part="steps-progress__step"] {
                display: grid;
                grid-template:
                    "dot line" 1px
                    "title ." 34px
                    "sub-title ." auto / 50px 50px;
            }
            [part="steps-progress__step"]:last-child {
                grid-template:
                    "dot line" 1px
                    "title ." 34px
                    "sub-title ." auto / 50px 0;
            }
            [part="steps-progress__line"] {
                grid-area: line;
                width: 155%;
                border-bottom: 1px solid var(--legl-grey);
                justify-self: center;
                align-self: center;
            }
            [part="steps-progress__dot"] {
                grid-area: dot;
                justify-self: center;
                place-self: center;
            }
            [part="steps-progress__title"] {
                grid-area: title;
                justify-self: center;
                margin: 12px 0;
                color: var(--legl-grey-dark);
                font-size: var(--legl-font-size);
                text-align: center;
                width: var(--legl-width);
            }
            [part="steps-progress__sub-title"] {
                grid-area: sub-title;
                justify-self: center;
                text-align: center;
                font-size: 9px;
                width: 199%;
                color: var(--legl-grey);
            }
            [name="legl-icon-black-hole"] {
                font-size: 12px;
            }
            [part="steps-progress__line"][hidden] {
                width: 0;
            }
            .green {
                color: var(--legl-green);
            }
            .grey {
                color: var(--legl-grey);
            }
        `;
  }

  static get properties() {
    return {
      steps: {
        type: Object,
        attribute: "steps",
      },
      currentStep: {
        type: Object,
        attribute: "current-step",
      },
    };
  }

  iconName(index) {
    if (index == this.currentStep) {
      return "legl-icon-dot-o";
    } else if (index > this.currentStep) {
      return "legl-icon-ring";
    }
    return "legl-icon-black-hole";
  }

  iconColor(index) {
    if (this.currentStep < index) {
      return "grey";
    }
    return "green";
  }

  render() {
    return html`
            ${this.steps.map((step, index) => {
              return html`
                    <div part="steps-progress__step">
                        <legl-icon
                            part="steps-progress__dot"
                            name=${this.iconName(index)}
                            class=${this.iconColor(index)}
                        ></legl-icon>
                        <span
                            part="steps-progress__line"
                            ?hidden=${!(index < this.steps.length - 1)}
                        ></span>
                        <p part="steps-progress__title">${step.title}</p>
                        <small part="steps-progress__sub-title"
                            >${step.subTitle}</small
                        >
                    </div>
                `;
            })}
        `;
  }
}

customElements.define("legl-step-progress", LeglStepProgress);
