import { LitElement, css, html } from "lit";
import { neutral } from "../../legl-ui/lds-colours";

export class LeglCard extends LitElement {
  static get styles() {
    return css`
            :host {
                background-color: white;
                box-sizing: border-box;
                box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
                border-radius: 4px;
                padding: 1.5rem;
                position: relative;
                display: flex;
                flex-direction: row;
                font-style: normal;
                font-weight: normal;
                color: ${neutral["800"]};
                letter-spacing: 0.01rem;
                position: relative;
            }

            :host([no-padding]) {
                padding: 0;
            }

            :host(.card-col) {
                flex-direction: column;
            }

            :host(.card-bg) {
                background-color: white;
            }

            :host(.card-border) {
                border: 1px solid ${neutral["200"]};
            }

            :host(.card-dotted) {
                border: 1px dashed ${neutral["400"]};
                align-items: center;
            }

            :host(.card-interactive) {
                cursor: pointer;
            }

            :host(.card-interactive:hover) {
                background-color: #eaf6ec;
                border: 1px solid var(--legl-state-success, #28a745);
                color: var(--legl-state-success, #28a745);
            }

            ::slotted([slot="title"][variant="corner"]) {
                position: absolute;
                top: 0;
                left: 0;
                margin: 0;
                background: var(--legl-grey-light);
                color: var(--legl-grey-dark);
                text-transform: uppercase;
                padding: 4px 28px;
                border-radius: 4px 0 4px 0;
                font-size: 12px;
            }

            ::slotted([slot="title"][variant="underline"]) {
                color: ${neutral["900"]};
                font-weight: bold;
                font-size: 14px;
                line-height: 19px;
                position: relative;
                padding: 0px 4px 8px 0px;
                border-bottom: 1px solid var(--legl-grey-light, #ebebeb);
            }

            :host([variant="clear"]) {
                background: transparent;
                box-shadow: unset;
                flex-direction: column;
                padding: 0;
            }

            :host([variant="stacked"]) {
                border-bottom: 1px solid var(--legl-grey-light);
                box-shadow: 0 9px 0px 0px white, rgb(0 0 0 / 13%) 0px 1px 1px,
                    0px 4px 4px rgba(0, 0, 0, 0.13),
                    0px 4px 4px rgba(0, 0, 0, 0.13);
            }
        `;
  }

  static get properties() {
    return {
      title: {
        type: String,
        attribute: "title",
      },
    };
  }

  render() {
    return html` <slot name="title"></slot>
            <slot></slot>`;
  }
}

customElements.define("legl-card", LeglCard);
