import { LionTextarea } from "@lion/textarea";
import { css, html } from "lit";
import { when } from "lit/directives/when.js";
import { neutral } from "../../../legl-ui/lds-colours";
import { LdsBaseInputMixin } from "../../../legl-ui/lds-input";
import { spacing } from "../../../legl-ui/lds-spacing";
import { typographyPresets } from "../../../legl-ui/lds-typography";

export class LdsTextarea extends LdsBaseInputMixin(LionTextarea) {
  static get styles() {
    return [
      super.styles,
      css`
                :host .char-limit {
                    text-align: right;
                    padding-top: ${spacing.xxs};
                    color: ${neutral["500"]};
                    ${typographyPresets.medium}
                }
            `,
    ];
  }

  constructor() {
    super();
    this.totalChar = 0;
  }

  static get properties() {
    return {
      maxlength: { type: Number },
    };
  }

  truncateAtCharLimit(value) {
    if (this.maxlength) {
      value = value.slice(0, this.maxlength);
    }
    this.totalChar = value.length;
    return value;
  }

  internalPreprocessor = (value) => {
    const newValue =
      this.propsProcessor instanceof Function
        ? this.propsProcessor(value)
        : value;
    return this.truncateAtCharLimit(newValue);
  };

  connectedCallback() {
    super.connectedCallback();
    this.propsProcessor = this.preprocessor;
    this.preprocessor = this.internalPreprocessor;
  }

  render() {
    return html`
            ${super.render()}
            ${when(
              this.maxlength,
              () =>
                html`<div class="char-limit">
                        You have
                        ${(this.maxlength - this.totalChar).toLocaleString(
                          "en-GB",
                        )}
                        characters remaining
                    </div>`,
            )}
        `;
  }
}

customElements.define("lds-textarea", LdsTextarea);
