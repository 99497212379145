import { dedupeMixin } from "@lion/core";
import { LitElement, css, html } from "lit";
import { LdsButton } from "../../lds-button/src/lds-button";

export const LeglInlineActionMixin = dedupeMixin(
  (superclass) =>
    class LeglInlineActionMixin extends superclass {
      static get properties() {
        return {
          actionUrl: {
            type: String,
            attribute: "action-url",
          },
          actionMethod: {
            type: String,
            attribute: "action-method",
          },
          actionPayload: {
            type: Object,
            attribute: "action-payload",
          },
          actionAsFormData: {
            type: Boolean,
            attribute: "action-as-form-data",
          },
          resetAfterMS: {
            type: Number,
            attribute: "reset-interval",
          },
          hasActionSucceeded: {
            type: Boolean,
            attribute: "has-action-succeeded",
            reflect: true,
          },
          minimumActionDuration: {
            type: Number,
            attribute: "minimum-action-duration",
          },
        };
      }
      constructor() {
        super();
        this.actionMethod = "POST";
        this.boundOnClick = this.onClick.bind(this);
        this.minimumActionDuration = 2000;
      }

      onClick(e) {
        this.performAction();
      }

      async performAction() {
        if (!this.isLoading) {
          this.isLoading = true;
          this.disabled = true;
          this.hasActionSucceeded = false;
          let config;
          if (this.actionUrl) {
            config = {
              method: this.actionMethod,
              credentials: "same-origin",
              headers: {
                "X-CSRFTOKEN": CSRF_TOKEN,
              },
            };

            if (this.actionPayload) {
              if (this.actionAsFormData) {
                config.body = Object.entries(this.actionPayload).reduce(
                  (formOut, [key, value]) => {
                    return `${formOut}${key}=${value}`;
                  },
                  "",
                );
                config.headers["Content-Type"] =
                  "application/x-www-form-urlencoded; charset=UTF-8";
              } else {
                config.body = JSON.stringify(this.actionPayload);
              }
            }
          }
          try {
            const res = this.actionCallback
              ? await this.actionCallback()
              : await fetch(this.actionUrl, config);
            const filteredArrayRes =
              Array.isArray(res) && res.filter((x) => x instanceof Response);
            if (
              filteredArrayRes ? !filteredArrayRes.some((x) => !x.ok) : res.ok
            ) {
              const data = filteredArrayRes
                ? await Promise.all(
                    filteredArrayRes.map(async (x) => await x.json()),
                  )
                : await res.json();
              setTimeout(() => {
                this.isLoading = false;
                this.disabled = true;
                this.hasActionSucceeded = true;
                this.dispatchEvent(
                  new CustomEvent("action-success", {
                    detail: {
                      response: {
                        data,
                      },
                    },
                  }),
                );
                if (!!this.resetAfterMS) {
                  setTimeout(() => {
                    this.resetButton();
                  }, this.resetAfterMS);
                }
              }, this.minimumActionDuration);
            } else {
              const error = filteredArrayRes
                ? Promise.all(
                    filteredArrayRes.filter((x) => !x.ok).map((x) => x.text()),
                  ).then((x) => x.join(", "))
                : await res.text();
              const errorText = await error;
              this.dispatchEvent(
                new CustomEvent("action-error", {
                  detail: {
                    response: {
                      error: errorText,
                    },
                  },
                }),
              );
              this.resetButton();
            }
          } catch (error) {
            this.dispatchEvent(
              new CustomEvent("action-error", {
                detail: {
                  response: {
                    error,
                  },
                },
              }),
            );
            this.resetButton();
          }
        }
      }

      resetButton() {
        this.isLoading = false;
        this.disabled = this.disabled || false;
        this.hasActionSucceeded = false;
        this.requestUpdate();
      }

      connectedCallback() {
        super.connectedCallback();
        this.addEventListener("click", this.boundOnClick);
      }

      disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener("click", this.boundOnClick);
      }
    },
);

export class LeglInlineAction extends LeglInlineActionMixin(LitElement) {
  render() {
    return html`<slot></slot>`;
  }
}

export class LeglAsyncActionButton extends LeglInlineActionMixin(LdsButton) {
  static get properties() {
    return {
      ...super.properties,
      loadingMessage: {
        type: String,
        attribute: "loading-message",
      },
      successMessage: {
        type: String,
        attribute: "success-message",
        reflect: true,
      },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
                :host([success-variant="success"][has-action-succeeded]) {
                    --legl-button-background-color: var(
                        --legl-state-success,
                        #28a745
                    );
                    --legl-button-background-color-disabled: var(
                        --legl-state-success,
                        #28a745
                    );
                }

                :host([size="small"]) {
                    --legl-button-padding: 0 0.25rem;
                    --legl-button-font-size: 0.75rem;
                }

                :host(
                        [success-variant="success"][has-action-succeeded][disabled]
                    ) {
                    opacity: 0.4;
                }

                :host([has-action-succeeded][success-message]) slot {
                    display: none;
                }
            `,
    ];
  }

  constructor() {
    super();
    this.successMessage = "Sent";
  }

  _beforeTemplate() {
    if (this.hasActionSucceeded) {
      return html`<lds-icon name="lds-icon-Check"></lds-icon>`;
    }
    return super._beforeTemplate();
  }

  _afterTemplate() {
    if (this.hasActionSucceeded && this.successMessage) {
      return html`<span class="sending">${this.successMessage}</span>`;
    }
  }
}

customElements.define("legl-async-action-button", LeglAsyncActionButton);

customElements.define("legl-inline-action", LeglInlineAction);
