import { LitElement, css, html } from "lit";
import "../../legl-ui/icon";

export class LeglContactMiniView extends LitElement {
  static get styles() {
    return css`
            :host {
                display: grid;
                grid-template:
                    "avatar name" 20px
                    ". email" 20px / 20px auto;
                align-items: center;
                color: var(--legl-grey-dark);
                font-size: 12px;
                padding: 10px 0;
            }
            .mini-view__avatar {
                grid-area: avatar;
            }
            .mini-view__email {
                grid-area: email;
                align-self: start;
            }
            .mini-view__name {
                grid-area: name;
            }

            p {
                margin: 0;
                padding: 0;
            }
        `;
  }

  static get properties() {
    return {
      contactName: {
        type: String,
        attribute: "contact-name",
      },
      contactEmail: {
        type: String,
        attribute: "contact-email",
      },
    };
  }

  render() {
    return html`
            <legl-icon
                class="mini-view__avatar"
                name="legl-icon-individual"
            ></legl-icon>
            <p class="mini-view__name">${this.contactName}</p>
            <p class="mini-view__email">${this.contactEmail}</p>
        `;
  }
}

customElements.define("legl-contact-mini-view", LeglContactMiniView);
