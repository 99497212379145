import { userConfig } from "../apps/lawyers-app/static-src/js/config/user-config";

export const hasPayAccess = function hasPayAccess() {
  return userConfig.permissions.pay;
};

const permissions = {
  hasPayAccess,
};

export default permissions;
