import { LitElement, css, html } from "lit";
import { neutral } from "../../legl-ui/lds-colours";

export class Alert extends LitElement {
  static get styles() {
    return css`
            :host {
                --legl-alert-background-color: #f00;
                --legl-alert-text-color: #fff;
                display: none;
                font-family: "Lato", sans-serif;
                font-style: normal;
            }
            :host([is-shown]) {
                display: flex;
            }
            :host .alert-container {
                display: flex;
                flex-direction: column;
                text-align: start;
                width: 100%;
                margin: 10px 0;
                border-radius: 3px;
                background-color: ${neutral["100"]};
                color: var(--legl-alert-text-color, #333232);
                padding: 18px;
            }
            :host([variant="error"]) .alert-container {
                --legl-alert-background-color: #fee7ea;
                --legl-alert-text-color: #840800;
            }

            :host([variant="warning"]) .alert-container {
                --legl-alert-background-color: #ffc107;
                --legl-alert-text-color: ${neutral["800"]};
            }

            :host([variant="info"]) .alert-container {
                --legl-alert-background-color: ${neutral["200"]};
                --legl-alert-text-color: ${neutral["800"]};
            }

            :host .alert-title {
                margin-bottom: 5px;
                font-weight: bold;
                font-size: 16px;
            }
            :host .alert-content {
                font-size: 15px;
            }
        `;
  }

  static get properties() {
    return {
      isShown: { type: Boolean, attribute: "is-shown" },
      alertTitle: { type: String, attribute: "alert-title" },
      alertContent: { type: String, attribute: "alert-content" },
    };
  }

  constructor() {
    super();
    this.isShown = false;
    this.alertTitle = "";
    this.alertContent = "";
  }

  render() {
    return html`
            <div class="alert-container">
                <div class="alert-title">
                    <slot name="Title"> ${this.alertTitle} </slot>
                </div>
                <div class="alert-content">
                    <slot> ${this.alertContent} </slot>
                </div>
            </div>
        `;
  }
}

customElements.define("legl-alert", Alert);
