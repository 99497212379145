import { html } from "lit";

import { LeglMaxLength } from "../../../../../legl-ui/input";
import LeglModal from "../../../../../legl-ui/modal";
export class ConfirmationModal extends LeglModal {
  static get properties() {
    return {
      message: {
        type: String,
      },
      includeComment: {
        type: Boolean,
        attribute: "include-comment",
      },
      requireComment: {
        type: Boolean,
        attribute: "require-comment",
      },
      commentLabel: {
        type: String,
        attribute: "comment-label",
      },
      comment: {
        type: String,
        attribute: false,
      },
    };
  }

  constructor() {
    super();
    this.confirmText = "Confirm";
    this.cancelText = "Cancel";
    this.commentLabel = "Comment";
    this.confirmVariant = null;
  }

  get closeEventDetails() {
    return {
      comment: this.comment,
    };
  }

  get modalContent() {
    return html`${this.modalBody} ${this.commentBox}`;
  }

  get modalBody() {
    return html`<p>${this.message}</p>`;
  }

  get commentBox() {
    if (this.includeComment) {
      return html`<legl-textarea
                data-cy-confirm-modal-comment
                @model-value-changed=${(e) =>
                  (this.comment = e.target.modelValue)}
                .label=${this.commentLabel}
                .validators="${[new LeglMaxLength(250)]}"
            ></legl-textarea>`;
    }
    return "";
  }

  isFieldValid(fieldSelector) {
    const field = this.shadowRoot.querySelector(fieldSelector);
    return field && Object.keys(field.validationStates.error).length === 0;
  }

  get isValid() {
    if (this.requireComment) {
      return (
        !!this.comment &&
        this.comment.length > 0 &&
        this.isFieldValid("legl-textarea")
      );
    }
    if (this.includeComment) {
      return this.isFieldValid("legl-textarea");
    }
    return true;
  }

  static get tagName() {
    return "legl-confirmation-modal";
  }
}

customElements.define(ConfirmationModal.tagName, ConfirmationModal);
