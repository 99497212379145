import { LionInputAmount } from "@lion/input-amount";
import { css } from "lit";
import { neutral } from "../../legl-ui/lds-colours";

import { LeglBaseInputMixin } from "./base-input-mixin";

export class LeglInputAmount extends LeglBaseInputMixin(LionInputAmount) {
  static get styles() {
    return [
      super.styles,
      css`
                .input-group__container > .input-group__prefix {
                    position: absolute;
                    top: 0.5rem;
                    color: ${neutral["700"]};
                    font-size: 1.4rem;
                    opacity: 0;
                    transition: opacity 0.25s ease-in-out;
                }

                :host([focused]) .input-group__container > .input-group__prefix,
                :host([filled]) .input-group__container > .input-group__prefix {
                    opacity: 1;
                }

                .input-group__container
                    > .input-group__input
                    ::slotted(.legl-form-control) {
                    padding-left: 1rem;
                }
            `,
    ];
  }

  get slots() {
    return {
      ...super.slots,
      prefix: () => {
        const icon = document.createElement("i");
        icon.classList.add("currency-symbol");
        icon.innerHTML = "&pound;";

        return icon;
      },
    };
  }

  constructor() {
    super();
    const originalFormatter = this.formatter;
    this.formatter = (value) => {
      /* The default formatter uses the Intl API which adds commas for number groups eg 1,000.00
            unfortunately the commas don't seem to play nice with the server side validation so I'm stripping them out
            originally I used a custom formatter with the Intl API but unfortunately IE11,EdgeHTML don't support NumberFormat.formatToParts */
      const formatted = originalFormatter(value);
      return formatted.split(",").join("");
    };
  }

  stripNonNumericCharacters() {
    this._inputNode.value = this._inputNode.value.replace(/[^0-9.]/g, "");
  }

  select() {
    this._inputNode.select();
  }

  firstUpdated(changedProperties) {
    super.firstUpdated(changedProperties);
    this._inputNode.addEventListener("focus", (e) => {
      this.select();
    });
    this._inputNode.addEventListener("keyup", (e) => {
      this.stripNonNumericCharacters();
    });
  }
}
customElements.define("legl-input-amount", LeglInputAmount);
