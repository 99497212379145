import { css, html } from "lit";
import "../../legl-ui/button";
import { neutral } from "../../legl-ui/lds-colours";
import { spacing } from "../../legl-ui/lds-spacing";
import { typographyPresets } from "../../legl-ui/lds-typography";
import { LeglMenu } from "../../legl-ui/menu";

export { LeglTooltipMenuItem } from "./legl-tooltip-menu-item.js";

export class LeglTooltipMenu extends LeglMenu {
  static get styles() {
    return css`
            :host {
                padding: 0;
                z-index: 2;
            }
            .tooltip-menu__button {
                ${typographyPresets.body}
                border: none;
                color: ${neutral["0"]};
                width: 100%;
                justify-content: flex-start;
                padding: ${spacing.xs} 20px;
                display: flex;
                align-items: center;
                cursor: pointer;
                background-color: transparent;
            }
            .tooltip-menu__button:hover {
                font-weight: bold;
            }
            lds-icon {
                color: var(--legl-green);
                margin-right: 8px;
            }
            .tooltip-menu__container {
                position: absolute;
                box-shadow: rgb(0 0 0 / 10%) 0px 10px 15px 0px,
                    rgb(0 0 0 / 1%) 0px 1px 10px 0px;
                border-radius: 5px;
                width: 500px;
                left: 235px;
                top: -6px;
                z-index: 2;
                background: ${neutral["800"]};
                overflow: visible;
            }
            .tooltip-menu__container:before {
                z-index: -1;
                content: "";
                position: absolute;
                width: 30px;
                height: 30px;
                background: ${neutral["800"]};
                transform: rotate(45deg);
                top: 12px;
                left: -15px;
                box-shadow: rgb(0 0 0 / 10%) -5px 13px 8px -4px;
            }

            ::slotted(hr) {
                margin: 0 10px;
            }
            ::slotted(legl-button) {
                width: 100%;
                padding: 10px;
                border-radius: 0 0 5px 5px;
                color: var(--legl-grey-dark);
            }
            ::slotted(legl-button:hover) {
                background: var(--legl-grey-light);
            }
            :host([disabled]) {
                cursor: not-allowed;
            }
        `;
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener("popstate", this.bindedOnClose);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener("popstate", this.bindedOnClose);
  }

  bindedOnClose = super.onClose.bind(this);

  static get properties() {
    return {
      buttonText: {
        type: String,
        attribute: "button-text",
      },
      disabled: {
        type: Boolean,
      },
    };
  }

  onToggle() {
    if (!this.disabled) {
      super.onToggle();
    }
  }

  render() {
    return html`
            <button
                class="tooltip-menu__button"
                ?disabled=${this.disabled}
                @click=${(e) => this.onToggle()}
            >
                <lds-icon name=${this.icon} small></lds-icon>
                ${this.buttonText}
            </button>
            <div class="tooltip-menu__container" ?hidden=${this.closed}>
                <slot name="main-section"></slot>
                <slot
                    name="bottom-section"
                    @click=${(e) => this.onClose()}
                ></slot>
            </div>
        `;
  }
}

customElements.define("legl-tooltip-menu", LeglTooltipMenu);
