import { LitElement, css, html } from "lit";
import "./nav-item.js";

export class LeglLawyerAccountNavItemGroup extends LitElement {
  static get properties() {
    return {
      items: {
        type: Array,
      },
      isNested: {
        type: Boolean,
      },
    };
  }

  static get styles() {
    return css`
            .group {
                display: flex;
                flex-direction: column;
            }
        `;
  }

  get navItems() {
    return [...this.shadowRoot.querySelectorAll(".nav-item")];
  }

  collapseSubMenus(excludedItems = []) {
    this.navItems.forEach((navItem) => {
      if (excludedItems.includes(navItem) === false) {
        navItem.isExplicitlyExpanded = false;
      }
    });
  }

  render() {
    return html`
            <div class="group">
                ${this.items.map(
                  (item) =>
                    html` <legl-lawyer-account-nav-item
                            class="nav-item"
                            .href=${item.url}
                            .icon=${item.icon}
                            .isAuthorized=${item.isAuthorized}
                            .isBeta=${item.isBeta}
                            .isNested=${this.isNested}
                            .children=${item.children}
                            .target=${item.target}
                            >${item.title}
                        </legl-lawyer-account-nav-item>`,
                )}
            </div>
        `;
  }
}

customElements.define(
  "legl-lawyer-account-nav-item-group",
  LeglLawyerAccountNavItemGroup,
);
