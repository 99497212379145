import { LitElement } from "lit";

/* This is file being ignored is a temporary solution to
bolster the branch test coverage to stop Contacts
being delayed by legacy files tanking the coverage.
There is currently a story to add the missing
coverage here: https://app.clubhouse.io/legl/story/8751 */
/* istanbul ignore next */

export class ElementBase extends LitElement {
  static get properties() {
    return {
      resultResponse: {
        type: Object,
        attribute: "result-response",
      },
      completed_at: {
        type: String,
      },
      allowOutOfOrderStepCompletion: {
        type: Boolean,
      },
    };
  }

  get completedData() {
    return null;
  }

  sendResults(payload) {
    this.resultResponse = null;
    const event = new CustomEvent("engageStepResult", {
      detail: { payload },
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(event);
  }

  sendComplete(content = this.completedData) {
    const event = new CustomEvent("engageStepComplete", {
      detail: { betaContent: content },
      bubbles: true,
      composed: true,
    });
    this.dispatchEvent(event);
  }

  updated(changedProperties) {
    changedProperties.forEach((oldValue, propName) => {
      if (propName == "resultResponse" && this.resultResponse) {
        this.handleResultResponse(
          this.resultResponse.body,
          this.resultResponse.status,
        );
      }
    });
  }

  connectedCallback() {
    super.connectedCallback();
    if (this.completed_at) {
      this.sendComplete();
    }
  }

  lock(elementTypes) {
    this.dispatchEvent(
      new CustomEvent("lock", {
        detail: { elementTypes: elementTypes },
        bubbles: true,
        composed: true,
      }),
    );
  }
}

customElements.define("legl-element-base", ElementBase);
