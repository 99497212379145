import { LitElement, css, html } from "lit";
import "../../../../../../legl-ui/lds-checkbox";
import { danger } from "../../../../../../legl-ui/lds-colours";
import { spacing } from "../../../../../../legl-ui/lds-spacing";
import { typographyPresets } from "../../../../../../legl-ui/lds-typography";
import { toastService } from "../../../../../../legl-ui/toast";

class MarkReviewUI extends LitElement {
  static get styles() {
    return css`
            .markReviewForm {
                margin-top: ${spacing.l};
            }
            .markReviewForm__checkbox__label.-required {
                color: ${danger.base};
            }

            .markReviewForm__checkbox {
                margin-bottom: ${spacing.m};
            }

            .markReviewForm__rightGroup {
                display: flex;
                justify-content: end;
            }

            .markReviewForm__rightGroup > div {
                text-align: right;
            }

            .markReviewForm__rightGroup legl-button {
                margin-top: ${spacing.m};
            }

            .markReviewForm__rightGroup__limit {
                ${typographyPresets.medium}
            }
        `;
  }

  static get properties() {
    return {
      action: {
        type: Object,
      },
      isSaving: {
        type: Boolean,
      },
      markReviewedValue: {
        type: String,
      },
      commentValue: {
        type: String,
      },
    };
  }

  get isFormDisabled() {
    return this.isSaving || this.markReviewedValue !== "on";
  }

  async onSubmit(e) {
    e.preventDefault();
    if (!this.isFormDisabled) {
      try {
        this.isSaving = true;
        await this.action(this.commentValue);
      } catch {
        this.isSaving = false;
      }
    }
  }

  render() {
    return html`
            <form class="markReviewForm" @submit=${this.onSubmit}>
                <lds-checkbox
                    class="markReviewForm__checkbox"
                    data-cy="review-monitoring-mark-reviewed"
                    @model-value-changed=${(e) => {
                      this.markReviewedValue = e.target.checked ? "on" : "off";
                    }}
                    label="Mark reviewed *"
                ></lds-checkbox>
                <lds-textarea
                    label="Optional reviewer comments (internal use only)"
                    maxlength="250"
                    name="review_comment"
                    @model-value-changed="${(e) => {
                      this.commentValue = e.currentTarget.value;
                    }}"
                ></lds-textarea>
                <br />
                <div class="markReviewForm__rightGroup">
                    <div>
                        <lds-button
                            colour="primary"
                            variant="contained"
                            data-cy-monitoring-submit
                            ?disabled=${this.isFormDisabled}
                        >
                            Submit review
                        </lds-button>
                    </div>
                </div>
            </form>
        `;
  }
}

customElements.define("mark-review-ui", MarkReviewUI);
