import { LitElement, css, html } from "lit";
import "../../../legl-ui/icon";
import { neutral } from "../../../legl-ui/lds-colours";
import { toastService } from "../../../legl-ui/toast";
import location from "../../../legl-ui/utils/location.js";

export class LeglLawyerAccountNavItem extends LitElement {
  static get properties() {
    return {
      children: {
        type: Array,
      },
      readOnly: {
        type: Boolean,
      },
      href: {
        type: String,
      },
      icon: {
        type: String,
      },
      isExplicitlyExpanded: {
        type: Boolean,
      },
      isNested: {
        type: Boolean,
      },
      isAuthorized: {
        type: Boolean,
      },
      isBeta: {
        type: Boolean,
        attribute: "is-beta",
      },
    };
  }

  static get styles() {
    return css`
            :host .nav-item .nav-item__children {
                display: block;
                max-height: 0;
                overflow: hidden;
                transition: max-height 0.25s ease-in-out;
            }

            :host .nav-item.is-expanded .nav-item__children {
                /* I use max-height for the transition here and set it for a variety of specific scenarios below, this will need to change
                if we ever have more than 6 children in the nav but I'd rather this than a bunch of hacky JS to get smooth transitions */
                max-height: 350px;
                transition: max-height 0.25s ease-in-out;
            }

            :host .nav-item.is-expanded .nav-item__children[data-children="1"] {
                max-height: 40px;
            }

            :host .nav-item.is-expanded .nav-item__children[data-children="2"] {
                max-height: 80px;
            }

            :host .nav-item.is-expanded .nav-item__children[data-children="3"] {
                max-height: 120px;
            }

            :host .nav-item.is-expanded .nav-item__children[data-children="4"] {
                max-height: 160px;
            }

            :host .nav-item {
                position: relative;
                background-color: transparent;
                transition: background-color 0.25s ease-in-out;
                padding-bottom: 8px;
            }

            :host .nav-item.is-nested {
                padding-bottom: 0px;
            }

            :host .nav-item.is-expanded {
                background-color: ${neutral["800"]};
            }

            :host .nav-item.is-nested:not(.has-icon) a {
                padding-left: 56px;
            }

            :host .nav-item.is-nested.has-icon a .icon {
                margin-right: 20px;
            }

            :host .nav-item a {
                color: ${neutral["0"]};
                text-decoration: none;
                font-size: 16px;
                padding: 8px 20px 8px 46px;
                display: inline-flex;
                align-items: start;
                width: 100%;
                position: relative;
                box-sizing: border-box;
            }

            :host .nav-item.has-icon a {
                padding: 8px 20px;
                display: flex;
                align-items: center;
            }

            :host .nav-item.is-readonly a {
                cursor: default;
            }

            :host .nav-item.not-authorized a {
                opacity: 0.5;
            }

            :host .nav-item.is-active a,
            :host .nav-item:hover > a {
                font-weight: bold;
            }

            :host .nav-item .beta-flag {
                background: ${neutral["800"]};
                color: white;
                border-radius: 4px;
                padding: 0px 8px;
                font-size: 12px;
                position: absolute;
                right: 12px;
                top: 50%;
                margin-top: -9px;
            }

            :host .nav-item.not-authorized .beta-flag {
                display: none;
            }

            :host .nav-item.is-active a .beta-flag,
            :host .nav-item:hover > a .beta-flag {
                font-weight: normal;
            }

            :host .nav-item .icon {
                font-size: 16px;
                margin-right: 8px;
                display: flex;
            }

            :host .expand-btn {
                border: none;
                background: transparent;
                position: absolute;
                right: 20px;
                top: 8px;
                cursor: pointer;
                color: ${neutral["0"]};
            }

            :host .not-authorized-tooltip {
                position: absolute;
                right: 45px;
                top: 13px;
                font-size: 12px;
                opacity: 0.5;
            }

            :host .nav-item.is-nested .not-authorized-tooltip {
                right: 20px;
            }

            :host .nav-item.not-authorized .expand-btn {
                opacity: 0.5;
            }
        `;
  }

  constructor() {
    super();
    this.href = "#";
    this.isExplicitlyExpanded = false;
  }

  get isActive() {
    return location.getCurrentLocation().indexOf(this.href) > -1;
  }

  get isChildActive() {
    if (!this.hasChildren) {
      return false;
    }
    const currentLocation = location.getCurrentLocation();
    return !!this.children.find(
      (child) => currentLocation.indexOf(child.url) > -1,
    );
  }

  get hasChildren() {
    return this.children && this.children.length > 0;
  }

  get isExpanded() {
    return this.isExplicitlyExpanded || this.isChildActive;
  }

  get linkTarget() {
    if (this.target) {
      return this.target;
    }
    return "_self";
  }

  get currentExpandIcon() {
    if (this.isExpanded) {
      return "lds-icon-ExpandLess";
    }
    return "lds-icon-ExpandMore";
  }

  get navItemClasses() {
    const classes = [];
    if (this.isExpanded) {
      classes.push("is-expanded");
    }

    if (this.isActive || this.isChildActive) {
      classes.push("is-active");
    }

    if (this.icon) {
      classes.push("has-icon");
    }

    if (this.isNested) {
      classes.push("is-nested");
    }

    if (!this.href) {
      classes.push("is-readonly");
    }

    if (!this.isAuthorized) {
      classes.push("not-authorized");
    }

    if (this.hasChildren) {
      classes.push("has-children");
    }
    return classes.join(" ");
  }

  get computedHref() {
    if (this.href && this.isAuthorized) {
      return this.href;
    }
    return "#";
  }

  onExpandClicked() {
    this.isExplicitlyExpanded = !this.isExplicitlyExpanded;
    this.dispatchEvent(
      new CustomEvent("navExpanded", {
        detail: {
          isExpanded: this.isExplicitlyExpanded,
          item: this,
        },
        bubbles: true,
        composed: true,
      }),
    );
  }

  launchUnauthorizedToast() {
    toastService.showDefault(
      "You do not have permission to access this. Please contact your administrator.",
    );
  }

  onClick(e) {
    if (!this.isAuthorized) {
      e.preventDefault();
      this.launchUnauthorizedToast();
    }
  }

  render() {
    return html`
            <div class="nav-item ${this.navItemClasses}">
                <a
                    .href=${this.computedHref}
                    .target=${this.linkTarget}
                    @click=${(e) => this.onClick(e)}
                    >${
                      this.icon
                        ? html`<lds-icon
                              class="icon"
                              name="${this.icon}"
                              small
                          ></lds-icon>`
                        : ""
                    }<slot></slot>${
                      this.isBeta
                        ? html`<span class="beta-flag">Beta</span>`
                        : ""
                    }</a
                >
                ${
                  this.hasChildren
                    ? html`<button
                              class="expand-btn"
                              aria-label="Expand Menu"
                              @click=${(e) => {
                                this.onExpandClicked(e);
                              }}
                          >
                              <lds-icon
                                  name="${this.currentExpandIcon}"
                              ></lds-icon>
                          </button>
                          <legl-lawyer-account-nav-item-group
                              class="nav-item__children"
                              data-children=${this.children.length}
                              .isNested=${true}
                              .items=${this.children}
                          ></legl-lawyer-account-nav-item-group>`
                    : ""
                }
                ${
                  !this.isAuthorized
                    ? html`<lds-icon
                          class="not-authorized-tooltip"
                          name="lds-icon-VisibilityOff"
                          small
                          @click=${(e) => this.launchUnauthorizedToast()}
                      ></lds-icon>`
                    : ""
                }
            </div>
        `;
  }
}

customElements.define("legl-lawyer-account-nav-item", LeglLawyerAccountNavItem);
