import { LeglIsEmail, LeglRequired } from "../../legl-ui/input";
import { post, put } from "./fetch.js";
import { camelToSnakeCase, snakeToCamelCase } from "./functions";

function getAllPropertyDescriptors(obj) {
  if (!obj) {
    return Object.create(null);
  } else {
    const proto = Object.getPrototypeOf(obj);
    return {
      ...getAllPropertyDescriptors(proto),
      ...Object.getOwnPropertyDescriptors(obj),
    };
  }
}

export class DRFModelError extends Error {
  constructor(message, status, messageFromJSONDetail = false) {
    super(message);
    this.status = status;
    this.messageFromJSONDetail = messageFromJSONDetail;
  }
}

/**
 * This class serves as a base class when dealing with saving data to DRF endpoints. It is intended to be extended
 * and by default will derive the endpoint name based on your class name eg: Contact becomes: /api/contacts/,
 * EngageApplication would become `/api/engage_applications/`.
 *
 * To be used properly a JSON schema needs to be provided to the configuration or as a static getter to the extended class
 * have a look at frontend/apps/lawyers-contacts/services/contact.js and the unit tests for this file to see examples.
 *
 * Based on this schema getters and setters will be exposed but they can be overridden if extra behaviour is required. The
 * unit test is the best place to see examples of this.
 */

export class BaseModel {
  constructor(options = {}) {
    this._options = {
      errorOnUnknownSetter: false,
      ...options,
    };
    if (this.constructor.schema) {
      this._options.schema = this.constructor.schema;
    }
    this._data = {};
    this.isSaving = false;
    return new Proxy(this, {
      set: (target, name, value) => {
        if (
          [
            "_data",
            "_options",
            ...Object.keys(getAllPropertyDescriptors(target)),
          ].includes(name)
        ) {
          target[name] = value;
          return true;
        } else if (this.schemaPropertyNames.includes(name)) {
          target._data[camelToSnakeCase(name)] = value;
          return true;
        } else if (this._options.errorOnUnknownSetter) {
          throw new Error(`Cannot set ${name}`);
        } else {
          //Silently fail in case schema falls out of date with response from backend
          return true;
        }
      },
      get: (target, name) => {
        if (
          [
            "_data",
            "_options",
            ...Object.keys(getAllPropertyDescriptors(this)),
          ].includes(name)
        ) {
          return target[name];
        } else if (this.schemaPropertyNames.includes(name)) {
          return target._data[camelToSnakeCase(name)];
        }
      },
    });
  }

  get schema() {
    return this?._options?.schema || {};
  }

  get schemaPropertyNames() {
    if (!this.schema?.properties) {
      return [];
    }

    return Object.keys(this.schema?.properties).map((key) =>
      snakeToCamelCase(key),
    );
  }

  get requiredFields() {
    return this.schema?.required || [];
  }

  get endpoint() {
    if (this?._options?.endpoint) {
      return this._options.endpoint;
    }
    if (this.constructor.endpoint) {
      return this.constructor.endpoint;
    }
    return `/api/${camelToSnakeCase(this.constructor.name)}s/`;
  }

  validate() {
    this.requiredFields.forEach((key) => {
      if (!this._data?.[key]) {
        throw new Error(`${key} is required`);
      }
    });
  }

  async save() {
    if (this.isSaving) {
      return;
    }

    this.isSaving = true;
    this.validate();
    let res;
    const bodyObject = Object.entries(this._data).reduce(
      (dataOut, [key, value]) => {
        if (Boolean(value) || value === null) {
          dataOut[key] = value;
        }
        return dataOut;
      },
      {},
    );

    const body = JSON.stringify(bodyObject);

    if (this.uid && this.url) {
      res = await put(this.url, {
        body,
      });
    } else {
      res = await post(this.endpoint, {
        body,
      });
    }

    if (!res.ok) {
      let text = await res.text();
      let messageFromJSONDetail = false;
      try {
        const json = JSON.parse(text);
        if (json.detail) {
          text = json.detail;
          messageFromJSONDetail = true;
        } else if (Object.keys(json).length) {
          text = Object.entries(json)
            .map(([key, value]) => `${key.replace(/_/g, " ")}: ${value}`)
            .join(", ");
          messageFromJSONDetail = true;
        }
      } finally {
        this.isSaving = false;
        throw new DRFModelError(text, res.status, messageFromJSONDetail);
      }
    } else {
      const json = await res.json();
      // Iterate through response values and set via setters in case the setter logic has been overridden
      Object.entries(json).forEach(([key, value]) => {
        this[snakeToCamelCase(key)] = value;
      });
    }
    this.isSaving = false;
    return this._data;
  }

  getValidatorsForField(fieldName) {
    const validators = [];

    if (
      this.schema.required.includes(fieldName) ||
      this.schema.required.includes(camelToSnakeCase(fieldName))
    ) {
      validators.push(new LeglRequired());
    }

    const fieldSchema =
      this.schema.properties[fieldName] ||
      this.schema.properties[camelToSnakeCase(fieldName)];

    return [
      ...validators,
      ...BaseModel.getJsonSchemaFormatValidators(fieldSchema.format),
    ];
  }

  static getJsonSchemaFormatValidators(format) {
    if (!format) {
      return [];
    }
    const formatMap = {
      email: [LeglIsEmail],
    };
    if (formatMap[format]) {
      return formatMap[format].map((validatorClass) => new validatorClass());
    }

    return [];
  }
}
