import { LitElement, css, html } from "lit";
import LeglFormValidationMixin from "../../../../../../../../static-src/js/mixins/LeglFormValidationMixin.js";
import { LeglRequired } from "../../../../../../../legl-ui/input";
import "../../../../../../../legl-ui/lds-alert";
import { neutral } from "../../../../../../../legl-ui/lds-colours";
import { spacing } from "../../../../../../../legl-ui/lds-spacing";
import { LdsToast } from "../../../../../../../legl-ui/lds-toast";
import { patch } from "../../../../../../../legl-ui/utils/fetch";

export class AssignMonitoringReviewer extends LeglFormValidationMixin(
  LitElement,
) {
  static get styles() {
    return css`
            :host {
                padding: 0 ${spacing.m} ${spacing.m};
                color: ${neutral["800"]};
            }
            p {
                margin-top: 0;
            }
            lds-select {
                margin-bottom: ${spacing.s};
            }
            .default-buttons-container {
                display: flex;
                justify-content: flex-end;
                gap: ${spacing.s};
                margin-top: ${spacing.m};
            }
        `;
  }

  static get properties() {
    return {
      monitoringAlertId: { attribute: false },
      reviewersOptions: { attribute: false },
      assignedReviewer: { attribute: false },
      monitoringUpdateType: { attribute: false },
    };
  }

  onClickClose() {
    this.parentElement.onClickClose();
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.validate();
    if (!this.isValid) {
      return;
    }
    const isCompanyMonitoringUpdateDrawer =
      this.monitoringUpdateType === "company_report";
    const formData = JSON.stringify(
      Object.fromEntries(new FormData(this.shadowRoot.querySelector("form"))),
    );
    const isBatchFlagActive = waffle.flag_is_active("company-monitoring-batch");
    const updateRoute = isBatchFlagActive
      ? "company_report_monitoring_update_batch"
      : "company_report_monitoring_update";
    const url = isCompanyMonitoringUpdateDrawer
      ? `/api/${updateRoute}/${this.monitoringAlertId}/change_reviewer/`
      : `/api/monitoring_alerts/${this.monitoringAlertId}/change_reviewer/`;

    const res = await patch(url, {
      body: formData,
    });
    this.onClickClose();
    if (res.ok) {
      LdsToast.showSuccess({
        title: "Reviewer has been assigned on the monitoring update.",
        autoClose: true,
      });
      const data = await res.json();
      const drawer = isCompanyMonitoringUpdateDrawer
        ? document.querySelector("legl-company-report-monitoring-update")
        : document.querySelector("legl-monitoring-update");
      if (drawer) {
        drawer.alert = data;
        drawer.reloadTables();
      }
    } else {
      LdsToast.showError({
        title: "Unable to assign reviewer. Please try again later.",
        autoClose: true,
      });
    }
  }

  render() {
    return html`
            <form
                id="assign-monitoring-reviewer"
                class="container"
                @submit=${this.handleSubmit}
            >
                <p>
                    Select a person to be assigned as the reviewer for this
                    monitoring update. The selected reviewer will be notified.
                </p>
                <lds-select
                    class="source-input input"
                    name="assigned_reviewer_id"
                    label="Select reviewer"
                    .validators=${[new LeglRequired()]}
                    .modelValue=${this.assignedReviewer}
                >
                    <select slot="input">
                        <option value="">Select reviewer</option>
                        ${this.reviewersOptions.map(
                          ({ value, label }) =>
                            html`<option .value=${value}>${label}</option>`,
                        )}
                    </select>
                </lds-select>
                <lds-textarea
                    class="source-input input"
                    .validators=${[new LeglRequired()]}
                    name="comment"
                    label="Add a comment"
                    placeholder="Add a comment for the reviewer"
                ></lds-textarea>
                <div class="default-buttons-container">
                    <lds-button
                        id="lds-modal-close-button"
                        variant="outlined"
                        colour="neutral"
                        type="button"
                        @click=${this.onClickClose}
                        >Cancel</lds-button
                    >
                    <lds-button
                        id="lds-modal-primary-button"
                        class="primary-button"
                        variant="contained"
                        colour="primary"
                        type="submit"
                        >Assign reviewer</lds-button
                    >
                </div>
            </form>
        `;
  }
}

customElements.define("assign-monitoring-reviewer", AssignMonitoringReviewer);
